import React, { FC, PropsWithChildren, useCallback } from 'react';
import { Box } from '@material-ui/core';
import Accordion from '@/components/molecules/Accordion/Accordion';
import useClasses from '@/components/organisms/ProductImportModal/style';
import SearchInput from '@/components/molecules/SearchInput/SearchInput';

interface Props {
  header: string | React.ReactElement;
  disabled: boolean;
  index: number;
  selectedIndex: number | null;
  onChange(index: number): void;
  onSearchChange?(value: string): void;
  onSearchCancel?(): void;
}

const AccordionResultItems: FC<PropsWithChildren<Props>> = ({
  header,
  disabled,
  children,
  index,
  selectedIndex,
  onChange,
  onSearchChange,
  onSearchCancel,
}) => {
  const classes = useClasses();

  const handleChange = useCallback((event, expanded) => {
    if (expanded) {
      onChange(index);
    }
  }, [index, onChange]);

  return (
    <Box mb={1}>
      <Accordion
        expanded={selectedIndex === index}
        onChange={handleChange}
        disabled={disabled}
        header={(
          <Box display="flex" flexDirection="column">
            <Box className={classes.title}>
              {header}
            </Box>
            {onSearchChange && !disabled && selectedIndex === index && (
              <SearchInput
                onChange={onSearchChange}
                onCancel={onSearchCancel}
              />
            )}
          </Box>
      )}
      >
        {children}
      </Accordion>
    </Box>
  );
};

export default AccordionResultItems;
