import React, { Component } from 'react';
import Typography from '@/components/atoms/Typography';
import EditableList from '@/components/atoms/EditableList/EditableList';
import { deleteDocumentById, postDocument } from '@/utils/fetch';
import { DEFAULT_CHECKED_COLUMN } from '@/constants/DefaultCheckedColumn';

class DocumentEditableList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }

  onAdd = (newDocName) => {
    const doc = {
      name: newDocName,
      format: 'EXCEL',
      documentColumns: DEFAULT_CHECKED_COLUMN.map((name) => ({ name })),
    };
    this.setLoading(true);
    postDocument(doc)
      .then(this.props.onAdd)
      .finally(() => this.setLoading(false));
  };

  onDelete = (id) => {
    this.setLoading(true);
    deleteDocumentById(id)
      .then((response) => {
        this.props.onDelete(response.id);
      })
      .finally(() => this.setLoading(false));
  };

  setLoading = (loading) => {
    this.setState({ loading });
    this.props.setLoading(loading);
  };

  documentToOption = (document) => ({ value: document, label: document.name });

  render() {
    const {
      classes,
      selectedDocument,
      documents,
      onSelect,
    } = this.props;
    const loading = this.state.loading || this.props.loading;
    return (
      <div className={classes.paper}>
        <Typography className={classes.title} component="legend">Документ</Typography>
        <EditableList
          onDelete={this.onDelete}
          onAdd={this.onAdd}
          loading={loading}
          selectedDocument={selectedDocument}
          onSelect={onSelect}
          list={documents.map(this.documentToOption)}
        />
      </div>
    );
  }
}

export default DocumentEditableList;
