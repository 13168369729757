import React, { FC, memo } from 'react';
import cx from 'classnames';
import LinearProgress from '@/components/atoms/LinearProgress/LinearProgress';
import InputField from '@/components/atoms/TextField/InputField';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { useStyles } from '../../../molecules/CustomAutocomplete/tableInputStyle';

interface Props {
  loading?: boolean
  warning?: boolean;
  error?: boolean | string;
  maxLength?: number;
}

const CustomInput: FC<Props & TextFieldProps> = ({
  loading,
  fullWidth,
  inputProps,
  warning,
  error,
  maxLength,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${fullWidth ? 'full-width' : ''}`}>
      <InputField
        maxLength={maxLength}
        fullWidth={fullWidth}
        {...other}
        error={error}
        inputProps={inputProps}
        rootClasses={cx({
          [classes.warning]: warning,
          [classes.error]: !warning && error,
        })}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export default memo(CustomInput);
