import Button from '@/components/atoms/Button';
import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { Delete } from '@material-ui/icons';
import { TEXT } from '@/utils/Text';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { deleteCompanyById } from '@/utils/fetch';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import ApplicationContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import Typography from '@/components/atoms/Typography';
import ModalWindow from '../ModalWindow/ModalWindow';

interface DeleteCompanyButtonProps {
  companyId: string;
}

const DeleteCompanyButton: FC<DeleteCompanyButtonProps> = ({
  companyId,
}: DeleteCompanyButtonProps) => {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const handleClick = useCallback(() => (
    setOpened(true)
  ), []);

  const handleDialogClosed = useCallback(() => (
    setOpened(false)
  ), []);

  const handleDeleteClick = () => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteCompanyById(companyId)
        .then(({ id }) => {
          dispatch(CompanyInfoActions.remove(id));
          dispatch(CompanyUserInfoActions.removeAll());
          dispatch(CompanyUserInfoActions.removeSelected());
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<Delete />}
      >
        {TEXT.BUTTON.DELETE}
      </Button>
      <ModalWindow
        isOpen={isOpened}
        onClose={handleDialogClosed}
        header={TEXT.HEADER.DELETE_COMPANY}
      >
        <Box p={4}>
          <Typography>{TEXT.DELETE_COMPANY_MESSAGE}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Button
            disabled={loading}
            onClick={handleDeleteClick}
          >
            {TEXT.BUTTON.DELETE}
          </Button>
          <Button
            disabled={loading}
            onClick={handleDialogClosed}
          >
            {TEXT.BUTTON.CANCEL}
          </Button>
        </Box>
      </ModalWindow>

    </>
  );
};

export default DeleteCompanyButton;
