const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    outline: 'none',
    maxHeight: '100%',
    overflow: 'auto',
    width: 'auto',
    maxWidth: '100%',
    height: 'auto',
    padding: '25px',
    background: 'rgb(255, 255, 255)',
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: '30px',
    boxShadow: theme.shadows[5],
  },
  fullscreen: {
  },
  closeButton: {
    position: 'absolute',
    top: -15,
    right: -15,
    zIndex: 10,
  },
  closeBtnWrapper: {
    zIndex: 10,
    position: 'relative',
    width: '100%',
    height: 0,
  },
});

export default styles;
