import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { getAttributes } from '@/utils/fetch';
import AttributeChip from '@/components/atoms/AttributeChip';
import { TEXT } from '@/utils/Text';
import IconButton from '@/components/atoms/IconButton';
import { AddCircle } from '@material-ui/icons';
import AddAttributeDialog from '@/components/organisms/AddAttributeModal';
import AttributeOption from '@/components/molecules/AttributeOption';
import CustomAutocomplete from '@/components/molecules/CustomAutocomplete';
import { AttributeOptionI, attributeToOption } from '@/utils/toOption';
import { Box } from '@material-ui/core';

export const MultipleModeListbox = styled('ul')`
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99;

  & li {
    padding: 5px 12px;
    display: flex;
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`;

interface Props {
  value: AttributeOptionI[];
  onChange(options: AttributeOptionI[]): {};
  addButtonEnabled?: boolean;
}

const AttributeAutocomplete: FC<Props> = ({
  value = [],
  onChange,
  addButtonEnabled,
}) => {
  const [options, setOptions] = useState<AttributeOptionI[]>(value);
  const [loading, setLoading] = useState(false);
  const [isAddAttributeDialogOpened, setAddAttributeDialogOpened] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAttributes()
      .then((attributes) => {
        setOptions(attributes.map(attributeToOption));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleAdd = useCallback(() => {
    setAddAttributeDialogOpened(true);
  }, []);

  const handleAddAttributeDialogClose = useCallback(() => {
    setAddAttributeDialogOpened(false);
  }, []);

  const handleAttributeSave = useCallback((attribute) => {
    setOptions([attributeToOption(attribute), ...options]);
  }, [options]);

  const memoizedRenderTags = useCallback((tags: AttributeOptionI[], getTagProps) => (
    tags.map((tag, index) => (
      <AttributeChip
        name={tag.title}
        color={tag.data.color}
        size="small"
        {...getTagProps({ index })}
      />
    ))), []);

  return (
    <Box display="flex">
      {isAddAttributeDialogOpened && (
        <AddAttributeDialog
          isOpened={isAddAttributeDialogOpened}
          onClose={handleAddAttributeDialogClose}
          onSave={handleAttributeSave}
        />
      )}
      <Box style={{ flex: 1 }}>
        <CustomAutocomplete
          multiple
          label={TEXT.ATTRIBUTES}
          value={value}
          defaultOptions={options}
          onChange={onChange}
          renderTags={memoizedRenderTags}
          renderOption={(option, { selected }) => (
            <AttributeOption
              attribute={option.data}
              selected={selected}
            />
          )}
          disableCloseOnSelect
        />
      </Box>
      {addButtonEnabled && (
        <div className="flex-bottom">
          <IconButton
            onClick={handleAdd}
            title={TEXT.BUTTON.ADD}
          >
            <AddCircle color="primary" />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default AttributeAutocomplete;
