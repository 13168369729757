import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { Box, Paper } from '@material-ui/core';
import Typography from '@/components/atoms/Typography';
import { getReconciliationActByClientId, postCredit } from '@/utils/fetch';
import { ModalProps } from '@material-ui/core/Modal/Modal';
import { Skeleton } from '@material-ui/lab';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import { TEXT } from '@/utils/Text';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import TextArea from '@/components/atoms/TextArea/TextArea';
import Button from '@/components/atoms/Button';
import { getDateTimeNow } from '@/utils/Utils';
import { buildCurrencyHeader, buildCurrencyValue } from '@/app/applicationSettings';
import useStyles from './styles';

interface Props {
  clientId: string;
  name: string;
  isOpen: boolean;
}

type ClientBalanceModalProps = Props & Omit<ModalProps, 'children'|'open'>;

const ClientBalanceModal: FC<ClientBalanceModalProps> = ({
  clientId,
  name,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState('');
  const [formedSum, setFormedSum] = useState('');
  const [expectedBalance, setExpectedBalance] = useState('');
  const [creditValue, setCreditValue] = useState(0);
  const [description, setDescription] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLoading(true);
    getReconciliationActByClientId(clientId)
      .then((response) => {
        setBalance(response.balance);
        setFormedSum(response.formedSum);
        setExpectedBalance(response.expectedBalance);
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({ [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId]);

  const handleCreditChange = useCallback((value) => {
    setCreditValue(value);
  }, []);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const handleDocumentNameChange = useCallback((event) => {
    setDocumentName(event.target.value);
  }, []);

  const handleAddCredit = useCallback(() => {
    setLoading(false);
    postCredit({
      clientId,
      description,
      documentName,
      sum: creditValue,
      receivingTime: getDateTimeNow(),
    })
      .then(() => {
        setCreditValue(0);
        setDocumentName('');
        setDescription('');
      })
      .then(() => getReconciliationActByClientId(clientId)
        .then((response) => {
          setBalance(response.balance);
          setFormedSum(response.formedSum);
          setExpectedBalance(response.expectedBalance);
        }))
      .catch((error) => {
        if (error && error.field) {
          setErrors({ [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, description, documentName, creditValue]);

  return (
    <ModalWindow
      onClose={onClose}
      loading={loading}
      isOpen={isOpen}
      header={(
        <Box>
          <Box>Баланс клиента</Box>
          <Box fontSize={18}>
            {`"${name}"`}
          </Box>
        </Box>
      )}
    >
      <Box display="flex">
        <Paper className={classes.paper}>
          <Box mb={1}>
            <Typography variant="headerBold">
              {TEXT.BALANCE}
            </Typography>
            <Box mx={3}>
              {loading ? <Skeleton variant="text" /> : (
                <Typography variant="header">
                  {buildCurrencyValue(balance)}
                </Typography>
              )}
            </Box>
          </Box>
          <Box mb={1}>
            <Typography variant="headerBold">
              {TEXT.FORMED_SUM}
            </Typography>
            <Box mx={3}>
              {loading ? <Skeleton variant="text" /> : (
                <Typography variant="header">
                  {buildCurrencyValue(formedSum)}
                </Typography>
              )}
            </Box>
          </Box>
          <Box>
            <Typography variant="headerBold">
              {TEXT.EXPECTED_BALANCE}
            </Typography>
            <Box mx={3}>
              {loading ? <Skeleton variant="text" /> : (
                <Typography variant="header">
                  {buildCurrencyValue(expectedBalance)}
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
        <Paper className={classes.paper}>
          <Box display="flex" flexDirection="column">
            <Box mb={2} display="flex" justifyContent="space-between">
              <Typography variant="headerBold">Внести кредит</Typography>
              <Button
                disabled={!creditValue || loading}
                size="small"
                color="primary"
                variant="outlined"
                onClick={handleAddCredit}
              >
                {TEXT.BUTTON.ADD}
              </Button>
            </Box>
            <Box mb={1} display="flex">
              <CustomNumberInput
                id="sum"
                error={errors.sum}
                label={buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM)}
                value={creditValue}
                onChange={handleCreditChange}
              />
              <CustomInput
                error={errors.documentName}
                maxLength={127}
                id="documentName"
                label={TEXT.DOCUMENT}
                onChange={handleDocumentNameChange}
                value={documentName}
              />
            </Box>
            <Box display="flex">
              <TextArea
                fullWidth
                error={errors.description}
                maxLength={127}
                id="description"
                label={TEXT.DESCRIPTION}
                rows={1}
                onChange={handleDescriptionChange}
                value={description}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box className={classes.actionPanel}>
        <Button
          color="primary"
          onClick={onClose}
        >
          {TEXT.READY}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default ClientBalanceModal;
