import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import { compareByField } from '@/utils/comparator';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import { useSelector } from 'react-redux';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import { SupplierInfoOption, supplierToOption } from '@/utils/toOption';
import { Box, Tooltip } from '@material-ui/core';
import { Column } from 'material-table';
import { CustomColumn } from '@/interfaces/CustomColumn';
import ClientInfoOption from '@/components/molecules/ClientInfoOption';

function useSupplierColumn(
  editable: 'always' | 'onUpdate' | 'onAdd' | 'never' | ((columnDef: Column<any>, rowData: any) => boolean) | undefined = 'always',
  column: CustomColumn | null = null,
  error = null,
): Column<any> {
  const suppliers = useSelector(selectSupplierInfos);
  const supplierOptions = useMemo(() => suppliers.map(supplierToOption), [suppliers]);

  const renderOptionWithTooltip = useCallback(({ data: item }) => (
    <ClientInfoOption
      item={item}
    >
      <div className="flex flex-space-between full">
        <span className="text-overflow-ellipsis f-1 mr-5">
          {item.organization}
        </span>
        <span style={{ textAlign: 'end' }}>{item.phoneNumber || ''}</span>
      </div>
    </ClientInfoOption>
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    editable,
    cellStyle: { minWidth: 150 },
    removable: false,
    title: TEXT.COLUMN.HEADER.SUPPLIER,
    customSort: compareByField('supplierName'),
    field: 'supplierId',
    customFilterAndSearch: (value, item) => (item.supplierName ? item.supplierName.toUpperCase()
      .includes(value.toUpperCase()) : false),
    render: ({ supplierName }) => (
      <Tooltip title={supplierName}>
        <Box>{supplierName}</Box>
      </Tooltip>
    ),
    editComponent: ({ rowData, onRowDataChange, value }) => {
      const { manufacturer } = rowData;
      return (
        <InputSelect
          disabled={!!rowData.synchronizingProductId}
          width={150}
          error={error}
          id="supplierId"
          label={TEXT.COLUMN.HEADER.SUPPLIER}
          defaultOptions={supplierOptions}
          value={supplierOptions.find((supplierOption) => (
            value === (supplierOption ? supplierOption.value : null)
          ))}
          renderOption={renderOptionWithTooltip}
          onChange={(option: SupplierInfoOption) => {
            onRowDataChange({
              ...rowData,
              supplierId: option ? option.value : null,
              manufacturer: manufacturer || (option ? option.data.organization : ''),
              external: option ? option.data.external : false,
            });
          }}
        />
      );
    },
  }), [error, column, editable, supplierOptions]);
}

export default useSupplierColumn;
