import { Action, handleActions } from 'redux-actions';
import replaceOneById, { deleteById, replaceAllById } from '@/utils/arrayUtils';
import ProductInfo from '@/interfaces/ProductInfo';
import ProductInfoActions from '@/redux/actions/ProductInfoInfoActions';

const initialState: ProductInfo[] = [];

const processSet = (
  state: ProductInfo[],
  action: Action<ProductInfo[]>,
): ProductInfo[] => action.payload;

const processUpdateOne = (
  state: ProductInfo[],
  action: Action<ProductInfo>,
): ProductInfo[] => replaceOneById<ProductInfo>(state, action.payload);

const processUpdateAll = (
  state: ProductInfo[],
  action: Action<ProductInfo[]>,
): ProductInfo[] => replaceAllById<ProductInfo>(state, action.payload);

const processDelete = (
  state: ProductInfo[],
  action: Action<string>,
): ProductInfo[] => deleteById(state, action.payload);

const processAdd = (
  state: ProductInfo[],
  action: Action<ProductInfo>,
): ProductInfo[] => [action.payload, ...state];

const productInfoReducer = handleActions<ProductInfo[], ProductInfoPayload>({
  [ProductInfoActions.Type.SET]: processSet,
  [ProductInfoActions.Type.UPDATE_ONE]: processUpdateOne,
  [ProductInfoActions.Type.UPDATE_ALL]: processUpdateAll,
  [ProductInfoActions.Type.DELETE_ONE]: processDelete,
  [ProductInfoActions.Type.ADD]: processAdd,
},
initialState);

type ProductInfoPayload = ProductInfo & ProductInfo[] & string;

export default productInfoReducer;
