import React, { FC, memo, ReactElement } from 'react';
import { TEXT } from '@/utils/Text';
import { ClientFieldsBase } from '@/interfaces/ClientFieldsBase';
import { Box, Tooltip } from '@material-ui/core';
import TooltipLine from '@/components/organisms/ProductImportModal/TooltipLine';

interface ClientInfoOptionProps {
  children: ReactElement;
  item: ClientFieldsBase;
}

const ClientInfoOption: FC<ClientInfoOptionProps> = ({
  children,
  item,
}: ClientInfoOptionProps) => {
  const {
    organization,
    phoneNumber,
    contactEmail,
    unp,
    address,
  } = item;

  const renderProperty = (property) => property || '—';

  return (
    <Tooltip
      interactive
      placement="right"
      arrow
      title={(
        <Box display="flex" flexDirection="column" maxWidth={250}>
          <TooltipLine
            header={`${TEXT.ORGANIZATION} / ${TEXT.NAME}`}
            text={renderProperty(organization)}
          />
          <TooltipLine header={TEXT.UNP} text={renderProperty(unp)} />
          <TooltipLine header={TEXT.PHONE_NUMBER} text={renderProperty(phoneNumber)} />
          <TooltipLine header={TEXT.CONTACT_EMAIL} text={renderProperty(contactEmail)} />
          <TooltipLine header={TEXT.ADDRESS} text={renderProperty(address)} />
        </Box>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default memo(ClientInfoOption);
