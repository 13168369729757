import React, { FC } from 'react';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TEXT } from '@/utils/Text';
import { TextField } from '@material-ui/core';

interface PhoneFieldProps {
  value: string;
  defaultCountry?: string;
  onChange: any;
  inputExtraProps: any;
}

const PhoneField: FC<PhoneFieldProps> = ({
  value,
  onChange,
  inputExtraProps,
  defaultCountry,
  ...other
}: PhoneFieldProps) => (
  <ReactPhoneInput
    {...other}
    placeholder=""
    value={value}
    country={defaultCountry}
    onChange={onChange}
    component={TextField}
    inputProps={{
      fullWidth: true,
      label: TEXT.PHONE_NUMBER,
      ...inputExtraProps,
    }}
  />
);

export default PhoneField;
