import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { Column } from 'material-table';

const useSecondNameColumn = (
  column?,
  error?,
): Column<any> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomInput
      error={error}
      id="secondName"
      maxLength={40}
      label={TEXT.COLUMN.HEADER.SECOND_NAME}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error]);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.SECOND_NAME,
    defaultSort: 'asc',
    field: 'secondName',
    editComponent: handleEditComponent,
  }), [handleEditComponent, column]);
};

export default useSecondNameColumn;
