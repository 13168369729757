import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useSlaChipStyles = makeStyles((theme: Theme) => ({
  declined: {
    background: '#981818',
    color: 'white',
  },
  formed: {
    background: '#e3e3e3',
  },
  pending: {
    background: '#ebd847',
  },
  approved: {
    background: '#108d15',
    color: 'white',
  },
  sent: {
    background: '#3a96cf',
    color: 'white',
  },
  received: {
    background: '#135a02',
    color: 'white',
  },
  root: {
    width: 125,
    letterSpacing: '1px',
  },
}));
