import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  selected: {
    height: '34px !important',
    fontSize: 15,
    background: '#1B3D59 !important',
    color: '#00C3C6 !important',
    boxShadow: `${theme.shadows[15]} !important`,
    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,1)"
  },
  root: {
    boxShadow: theme.shadows[1],
    background: '#1B3D59 !important',
    height: theme.spacing(4),
    color: '#ffffff',
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: theme.spacing(1),
    '&:focus': {
      background: '#1B3D59',
      color: '#00C3C6',
    },
    '&:hover': {
      background: '#1B3D59',
      color: '#00C3C6',
    },
  },
}));
