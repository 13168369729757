import { SupplierInfo } from '@/interfaces/SupplierInfo';
import ColumnLabel from '@/constants/ColumnLabel';
import ColumnName from '@/enum/ColumnName';
import { ProductInfoOptionData } from '@/interfaces/ProductInfo';
import { ClientMinorInfo } from '@/interfaces/ClientInfo';
import { SaleState } from '@/enum/SaleState';
import { renderSaleStateText } from '@/components/atoms/SaleStateChip';
import { Attribute } from '@/interfaces/Attribute';

export interface SupplierInfoOption {
  value: string;
  label: string;
  title: string;
  data: SupplierInfo;
}

export const supplierToOption = (
  supplier: SupplierInfo,
): SupplierInfoOption | null => {
  if (!supplier) {
    return null;
  }
  return {
    value: supplier.id,
    label: supplier.organization,
    title: supplier.organization,
    data: supplier,
  };
};

export interface SaleStateOptionI {
  value: SaleState;
  label: string;
  title: string;
}

export const saleStateToOption = (
  saleState: SaleState,
): SaleStateOptionI => ({
  value: saleState,
  label: renderSaleStateText(saleState),
  title: renderSaleStateText(saleState),
});

export interface AttributeOptionI {
  value: string;
  label: string;
  title: string;
  data: Attribute;
}

export const attributeToOption = (
  attribute: Attribute,
): AttributeOptionI => ({
  value: attribute.id,
  label: attribute.name,
  title: attribute.name,
  data: attribute,
});

export const optionByIdAndName = (data) => {
  if (!data || !data.id) {
    return null;
  }
  return {
    value: data.id,
    label: data.name,
    title: data.name,
    data,
  };
};

export const optionBySaleItemIdAndName = (data) => {
  if (!data || !data.saleItemId) {
    return null;
  }
  return {
    value: data.saleItemId,
    label: data.name,
    title: data.name,
    data,
  };
};

export const optionBySupplierOrderItemId = (data) => {
  if (!data || !data.supplierOrderItemId) return null;
  return {
    value: data.supplierOrderItemId,
    label: data.name,
    title: data.name,
    data,
  };
};

export const optionByProductId = (data) => {
  if (!data || !data.productId) return null;
  return {
    value: data.productId,
    label: data.name,
    title: data.name,
    data,
  };
};

export const productToOption = (
  product: ProductInfoOptionData,
): {
  value: string,
  label: string,
  title: string,
  data: ProductInfoOptionData
} =>
  // if (!product) return null;
  ({
    value: product.id,
    label: product.name,
    title: product.name,
    data: product,
  });

export interface AutocompleteOption<T> {
  label: string;
  data: T;
  value: string;
  title: string;
}

export function toOption<T>(
  label: string,
  data: T,
  id?: string,
): AutocompleteOption<T> | null {
  if (!id) return null;

  return {
    value: id, label, title: label, data,
  };
}

export interface ClientRowData {
  external: boolean,
  clientId: string,
  clientFirstName: string,
  priceFormationTypeId?: string,
}

export const toPreselectedClientOption = (
  rowData: ClientRowData,
): AutocompleteOption<ClientMinorInfo> | null => {
  if (!rowData) return null;

  return toOption<ClientMinorInfo>(
    rowData.clientFirstName,
    { id: rowData.clientId, organization: rowData.clientFirstName },
    rowData.clientId,
  );
};

export const patternToOption = (pattern) => ({ label: pattern.name, value: pattern.id });

export const columnToOption = (
  name: ColumnName,
  checked: boolean,
) => ({
  value: name,
  label: ColumnLabel[name],
  checked,
});

export const unitableRowDataToOption = (
  rowData,
) => {
  if (!rowData || !rowData.unitId) {
    return null;
  }
  return {
    data: rowData,
    value: rowData.unitId,
    label: rowData.unitName,
    title: rowData.unitName,
  };
};
