import React, { FC } from 'react';
import MultipleAutocompleteOption from '@/components/molecules/MultipleAutocompleteOption';
import { SaleState } from '@/enum/SaleState';
import { renderSaleStateText } from '@/components/atoms/SaleStateChip';

interface SaleStateOptionProps {
  saleState: SaleState;
  selected: boolean;
}

const SaleStateOption: FC<SaleStateOptionProps> = ({
  selected,
  saleState,
}) => (
  <MultipleAutocompleteOption
    name={(
      renderSaleStateText(saleState)
    )}
    selected={selected}
  />
);

export default SaleStateOption;
