import { TEXT } from '@/utils/Text';
import { buildCurrencyHeader } from '@/app/applicationSettings';
import React, { useCallback, useMemo } from 'react';
import CreditSumEditColumn from '@/components/molecules/CreditSumEditColumn';

const useCreditSumColumn = (
  error = undefined,
  required = true,
) => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CreditSumEditColumn
      required={required}
      onChange={onChange}
      value={value}
      error={error}
    />
  ), [required, error]);

  return useMemo(() => ({
    removable: false,
    title: buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM_WITH_VAT),
    field: 'sum',
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [handleEditComponent]);
};

export default useCreditSumColumn;
