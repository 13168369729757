import React from 'react';
import ColumnLabel from '@/constants/ColumnLabel';
import { EditableTableCell } from '@/components/atoms/EditableTableCell';
import SaleInfo from '@/interfaces/SaleInfo';
import SaleItemInfo from '@/interfaces/SaleItemInfo';

interface Props {
  saleInfo: SaleInfo;
  saleItemInfos: SaleItemInfo[];
  tableHeader?: string;
  showProducts: boolean;
  editable: boolean;
}

function ProductWaybillTable({
  saleInfo,
  saleItemInfos,
  tableHeader,
  showProducts,
  editable,
}: Props) {
  const { count, sumNoVat, vatSum, sum } = saleInfo;

  return (
    <table className="ttn-table">
      <thead>
        {tableHeader && (
          <tr>
            <th colSpan={9}>{tableHeader}</th>
          </tr>
        )}
        <tr>
          <th>{ColumnLabel.NAME}</th>
          <th>{ColumnLabel.UNIT}</th>
          <th>{ColumnLabel.COUNT}</th>
          <th>{ColumnLabel.PRICE_NO_VAT}</th>
          <th>{ColumnLabel.SUM_NO_VAT}</th>
          <th>{ColumnLabel.VAT}</th>
          <th>{ColumnLabel.VAT_SUM}</th>
          <th>{ColumnLabel.SUM}</th>
          <th>{ColumnLabel.NOTE}</th>
        </tr>
        <tr id="ttn-header-numbers">
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
          <td>9</td>
        </tr>
      </thead>
      <tbody>
        {showProducts ? (
          saleItemInfos.map(
            (
              {
                name,
                count,
                priceNoVat,
                sumNoVat,
                vat,
                vatSum,
                sum,
                manufacturer,
                unitName,
              },
              index
            ) => (
              <>
                <tr
                  className={manufacturer ? 'ttn-product-row' : 'one-row-table'}
                  style={{ background: index % 2 === 0 ? 'white' : '#f3f5f7' }}
                >
                  <td>{name}</td>
                  <td>{unitName}</td>
                  <td>{count}</td>
                  <td>{priceNoVat}</td>
                  <td>{sumNoVat}</td>
                  <td>{vat}</td>
                  <td>{vatSum}</td>
                  <td>{sum}</td>
                  <EditableTableCell editable={editable} />
                </tr>
                {manufacturer && (
                  <tr className="ttn-supplier-row">
                    <td colSpan={9}>{manufacturer}</td>
                  </tr>
                )}
              </>
            )
          )
        ) : (
          <tr className="one-row-table">
            <td colSpan={9}>Товар согласно приложению</td>
          </tr>
        )}
        <tr id="ttn-table-footer">
          <td>Итого</td>
          <td>x</td>
          <td>{count}</td>
          <td>x</td>
          <td>{sumNoVat}</td>
          <td>x</td>
          <td>{vatSum}</td>
          <td>{sum}</td>
          <td>x</td>
        </tr>
      </tbody>
    </table>
  );
}

export default ProductWaybillTable;
