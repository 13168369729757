import { makeStyles } from '@material-ui/core';

export const useClasses = makeStyles(() => ({
  root: {
    margin: 2,
    minWidth: 50,
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
