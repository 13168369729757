import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CompanyUserState } from '@/enum/CompanyUserState';
import { Switch } from '@material-ui/core';
import { toggleCompanyUser } from '@/utils/fetch';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';

const useCompanyUserStateColumn = (
  column = null,
) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleRender = useCallback(({ userId, companyId, state }) => {
    const handleChange = () => {
      setLoading(true);
      toggleCompanyUser({
        userId,
        companyId,
        state: state === CompanyUserState.ACTIVE
          ? CompanyUserState.PENDING
          : CompanyUserState.ACTIVE,
      })
        .then((response) => {
          dispatch(CompanyUserInfoActions.updateOne(response));
        })
        .finally(() => setLoading(false));
    };

    return (
      <Switch
        checked={state === CompanyUserState.ACTIVE}
        className="status-switch"
        onChange={handleChange}
        disabled={loading}
      />
    );
  }, [dispatch, loading]);

  return useMemo(() => ({
    width: 150,
    hidden: !!column?.hidden,
    title: TEXT.STATE,
    field: 'state',
    render: handleRender,
  }), [handleRender, column]);
};

export default useCompanyUserStateColumn;
