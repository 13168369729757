import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import OrderActionIcon from '@/components/atoms/OrderActionIcon';
import { PreorderItemInfo } from '@/interfaces/PreOrderItemInfo';
import { PreOrderItemInfoState } from '@/enum/PreOrderItemInfoState';
import ErrorType from '@/enum/ErrorType';
import { getDateTimeNow } from '@/utils/Utils';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import { PreOrderItemInfoActions } from '@/redux/actions/preOrderItemInfoActions';
import { useDispatch } from 'react-redux';
import ProductActionModal from '@/components/organisms/ProductActionModal';
import { NewProductRowData } from '@/components/organisms/ProductActionModal/productActionDialog.types';
import { postPreordersByProductIds } from '@/utils/fetch';

interface Props {
  preorderItemInfo: PreorderItemInfo;
}

const OrderByProductActionIcon: FC<Props> = ({
  preorderItemInfo,
}) => {
  const dispatch = useDispatch();
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isEditProductOpened, setEditProductOpened] = useState(false);

  const { state, id, leftCount } = preorderItemInfo;

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);
  const sendOrderByProductRequest = useCallback((
    fillingExistingOrder: boolean,
  ) => {
    setLoading(true);
    postPreordersByProductIds({
      fillingExistingOrder,
      formingDate: getDateTimeNow(),
      preorderItems: [{ productId: id, count: leftCount }],
    }).then((response) => {
      dispatch(PreOrderItemInfoActions.update(response));
      context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      handleClose();
    }).catch((error) => {
      if (error.type === ErrorType.PRODUCTS_SHOULD_BE_SYNCHRONIZED
        || error.type === ErrorType.NO_PREORDER_SALE_ITEMS
      ) {
        context({ message: error.message, variant: 'warning' });
        return Promise.resolve();
      }
      if (error.type === ErrorType.PREORDER_PRODUCT_SHOULD_HAVE_SUPPLIER) {
        context({
          message: MESSAGE.WARNING_SUPPLIER_SHOULD_NOT_BE_EMPTY_FOR_ORDER,
          variant: 'warning',
        });
        setEditProductOpened(true);
        return Promise.resolve();
      }
      if (error.type === ErrorType.ARCHIVED_PRODUCT) {
        context({
          message: MESSAGE.WARNING_NO_ARCHIVED_PRODUCTS_FOR_ORDER,
          variant: 'warning',
        });
        return Promise.resolve();
      }
      return Promise.reject(error);
    }).finally(() => {
      setLoading(false);
    });
  }, [handleClose, id, context, leftCount, dispatch]);

  const handleFormNew = useCallback(() => {
    sendOrderByProductRequest(false);
  }, [sendOrderByProductRequest]);

  const handleUpdateCurrent = useCallback(() => {
    sendOrderByProductRequest(true);
  }, [sendOrderByProductRequest]);

  const handleDialogClose = useCallback(() => {
    setEditProductOpened(false);
  }, []);

  const handleProductAction = useCallback((newData: NewProductRowData) => {
    dispatch(PreOrderItemInfoActions.updateOne({
      ...preorderItemInfo,
      supplierId: newData.supplierId,
      supplierName: newData.supplierName,
    }));
  }, [preorderItemInfo, dispatch]);

  return (
    <>
      {isEditProductOpened && (
        <ProductActionModal
          onAction={handleProductAction}
          isOpened={isEditProductOpened}
          onClose={handleDialogClose}
          productId={preorderItemInfo.id}
          defaultErrors={{ supplierId: MESSAGE.ERROR_REQUIRED_FIELD }}
        />
      )}
      <OrderActionIcon
        disabled={state === PreOrderItemInfoState.OVER_ORDERED
        || state === PreOrderItemInfoState.ORDERED}
        loading={loading}
        onFormNew={handleFormNew}
        onUpdateCurrent={handleUpdateCurrent}
        isOpened={opened}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </>
  );
};

export default OrderByProductActionIcon;
