import * as TYPE from '@/constants/action';
import { CURRENT_STORE, STORES } from '@/constants/action';
import UserService from '@/model/user/UserService';
import User from '@/model/user/User';
import { RoleActionType } from '@/redux/actions/rolesActions';
import { Role } from '@/enum/Role';
import { ApplicationState } from '@/interfaces/ApplicationState';

const ACTION_HANDLERS = {
  [TYPE.SAVE_RECORD]: (state, action) => {
    const { payload, meta } = action;
    const { service } = payload;
    let { item } = payload;
    item = service ? service.parse(item) : item;
    const list = [...state[meta]];
    const index = list.findIndex((sourceItem) => sourceItem.id === item.id);
    if (index > -1) {
      const sourceItem = list[index];
      if (sourceItem.tableData) {
        const { id, showDetailPanel } = sourceItem.tableData;
        item.tableData = { id, showDetailPanel };
      }
      list[index] = item;
    } else {
      list.unshift(item);
    }
    return {
      ...state,
      [meta]: list,
    };
  },
  [TYPE.SET_RECORDS]: (state, action) => {
    const { payload, meta } = action;
    const { items, service } = payload;
    return {
      ...state,
      [meta]: service ? service.parseItems(items) : [...items],
    };
  },
  [TYPE.DELETE_RECORD]: (state, action) => {
    const records = state[action.meta];
    const newRecords = records.filter((item) => item.id !== action.id);
    return {
      ...state,
      [action.meta]: newRecords,
    };
  },
  [TYPE.USER_AUTHORIZED]: (state, action) => {
    const { payload, meta } = action;
    return {
      ...state,
      [meta]: UserService.parse(payload),
    };
  },
  [RoleActionType.SET_ROLES]: (state, { payload: roles }) => {
    const newState = {
      ...state,
    };
    newState[CURRENT_STORE] = roles.includes(Role.STOREKEEPER) ? state[STORES][0] : null;
    return newState;
  },
  [RoleActionType.STOREKEEPER_ROLE_UPDATE]: (state, enabled) => {
    const newState = {
      ...state,
    };
    newState[CURRENT_STORE] = enabled ? state[STORES][0] : null;
    return newState;
  },
  [TYPE.DELETE_SUPPLIER_ORDER_ITEM]: (state, action) => {
    const { payload } = action;
    const newState = { ...state };
    const orders = newState[TYPE.SUPPLIER_ORDERS];
    if (orders) {
      const order = orders.find((item) => item.id === payload.orderId);
      if (order) {
        order.items = order.items.filter((item) => item.id !== payload.id);
        return newState;
      }
    }
    return state;
  },
  [TYPE.SAVE_STORE]: (state: ApplicationState, action) => {
    const newState = { ...state };
    const store = action.payload;
    const { currentStore } = state;
    if (currentStore && store.id === currentStore.id) {
      newState.currentStore = store;
    }
    newState.stores = newState.stores.map((item) => {
      if (item.id === store.id) {
        return store;
      }
      return item;
    });
    return newState;
  },
};

export default function applicationReducer(
  state: ApplicationState = {
    saleInfos: [],
    suppliers: [],
    supplierOrders: [],
    currentStore: null,
    clientPatterns: [],
    units: [],
    user: new User(null, null, false),
    stores: [],
  },
  action,
): ApplicationState {
  const { type } = action;
  const handler = ACTION_HANDLERS[type];
  if (handler) {
    return handler(state, action);
  }
  return state;
}
