import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import React from 'react';
import Box from '@material-ui/core/Box';

function TtnTitle({
  editable,
  receiverUnp,
  senderUnp,
}) {
  return (
    <div className="ttn-title">
      <div className="flex-center">
        <div className="mr-5">УНП</div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Грузоотправитель</th>
                <th>Грузополучатель</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: 20 }}>
                <td>
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <EditableDocumentInput
                      initialValue={senderUnp}
                      clearable={false}
                      className="table-input"
                      hidePlaceholder
                      editing={editable}
                    />
                  </Box>
                </td>
                <td>
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                    <EditableDocumentInput
                      initialValue={receiverUnp}
                      hidePlaceholder
                      className="table-input"
                      clearable={false}
                      editing={editable}
                    />
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TtnTitle;
