import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { dateDifferenceInDays, round, toDate } from '@/utils/Utils';
import { TEXT } from '@/utils/Text';
import { compareDate } from '@/utils/comparator';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { deleteStoreItem, getAllStoreItemInfosByStoreId } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import format from 'string-format';
import SnackbarContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import {
  isCustomTableLoading,
  selectStoreTableColumns,
} from '@/redux/selectors/customTableSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '@/interfaces/Store';
import StoreItemInfo from '@/interfaces/StoreItemInfo';
import {
  createDeleteStoreItemsAction,
  createSetStoreItemsAction,
} from '@/redux/actions/storeItemActions';
import { selectStoreItems } from '@/redux/selectors/storeItemSelector';
import {
  isSelfSelected,
  selectSelectedUserId,
  selectUserStore,
} from '@/redux/selectors/companySelector';
import ReservedSaleItemDetailPanel from '@/components/organisms/ReservedSaleItemDetailPanel';
import { Column } from 'material-table';
import useProductNameColumn from '@/hook/columns/useProductNameColumn';
import { Box } from '@material-ui/core';
import HelpIconTooltip from '@/components/atoms/HelpIconTooltip';
import useSupplierColumn from '@/hook/columns/useSupplierColumn';
import { DEFAULT_COLUMN_STORE_ITEMS } from '@/constants/DefaultCheckedColumn';
import ExportIcon from '@/components/atoms/ExportIcon';
import useManufacturerColumn from '@/hook/columns/useManufacturerColumn';

function StoreItemsTable() {
  const dispatch = useDispatch();
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<StoreItemInfo[]>([]);

  const customTableLoading: boolean = useSelector(isCustomTableLoading);
  const columns = useSelector(selectStoreTableColumns);
  const store: Store | null = useSelector(selectUserStore);
  const storeItemInfos: StoreItemInfo[] = useSelector(selectStoreItems);
  const selectedUserId = useSelector(selectSelectedUserId);
  const isEditable = useSelector(isSelfSelected);

  const productNameColumn = useProductNameColumn(null, 'never');
  const supplierColumn = useSupplierColumn('never');
  const manufacturerColumn = useManufacturerColumn();

  useEffect(() => {
    setRows(storeItemInfos.map((product) => ({ ...product })));
  }, [storeItemInfos]);

  useEffect(() => {
    setLoading(true);
    getAllStoreItemInfosByStoreId(store!.id, selectedUserId)
      .then((response) => dispatch(createSetStoreItemsAction(response)))
      .finally(() => setLoading(false));
  }, [dispatch, store, selectedUserId]);

  const memoizedColumns = useMemo<Column<StoreItemInfo>[]>(() => (
    [
      productNameColumn,
      supplierColumn,
      manufacturerColumn,
      {
        hidden: columns.category ? columns.category.hidden : false,
        title: TEXT.COLUMN.HEADER.CATEGORY,
        field: 'category',
        editable: 'never',
      },
      {
        cellStyle: { minWidth: 150 },
        editable: 'never',
        hidden: columns.count ? columns.count.hidden : false,
        field: 'count',
        title: TEXT.COLUMN.HEADER.COUNT,
        render: ({
          storedCount, unitName, reservedCount, leftCount, countInTransit,
        }) => (
          <Box display="flex">
            <Box mr={1} display="flex" alignItems="center">
              <HelpIconTooltip title={(
                <Box>
                  <Box m={1}>
                    {`На складе всего: ${storedCount}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`В резерве: ${reservedCount}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`Остаток: ${leftCount}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`В пути: ${countInTransit}`}
                  </Box>
                </Box>
              )}
              />
            </Box>
            <Box>
              {`${storedCount} / ${reservedCount} / ${leftCount} ${unitName ? `(${unitName})` : ''}`}
            </Box>
          </Box>
        ),
      },
      {
        editable: 'never',
        hidden: columns.supplierPrice ? columns.supplierPrice.hidden : false,
        title: TEXT.COLUMN.HEADER.SUPPLIER_PRICE,
        field: 'supplierPrice',
      },
      {
        editable: 'never',
        hidden: columns.vat ? columns.vat.hidden : false,
        title: TEXT.COLUMN.HEADER.VAT,
        field: 'vat',
      },
      {
        editable: 'never',
        hidden: columns.receivingDate ? columns.receivingDate.hidden : false,
        title: <span className="wrap-words">{TEXT.COLUMN.HEADER.RECEIVING_DATE}</span>,
        field: 'receivingDate',
        customSort: (a, b) => compareDate(a.receivingDate, b.receivingDate),
      },
      {
        editable: 'never',
        hidden: columns.producingDate ? columns.producingDate.hidden : false,
        title: <span className="wrap-words">{TEXT.COLUMN.HEADER.PRODUCING_DATE}</span>,
        field: 'producingDate',
        customSort: (a, b) => compareDate(a.producingDate, b.producingDate),
      },
      {
        editable: 'never',
        hidden: columns.leftDays ? columns.leftDays.hidden : false,
        title: TEXT.COLUMN.HEADER.DAYS_UNTIL,
        field: 'leftDays',
        render: (storeItem) => {
          const { shelfLife, producingDate } = storeItem;
          if (shelfLife) {
            let goneDays = 0;
            if (producingDate) {
              goneDays = Math.round(dateDifferenceInDays(toDate(producingDate), Date.now()));
            }
            return round(shelfLife - goneDays, 2);
          }
          return '';
        },
      },
    ]
  ), [
    manufacturerColumn,
    productNameColumn,
    columns,
    supplierColumn,
  ]);

  const onDelete = useCallback((oldData) => new Promise((resolve, reject) => {
    const {
      id: storeItemId, storeId, name, storedCount,
    } = oldData;
    setLoading(true);
    deleteStoreItem(storeItemId, storeId)
      .then(({ id }) => {
        dispatch(createDeleteStoreItemsAction(id));
        context({
          message: format(MESSAGE.SUCCESS_STORE_ITEM_DELETE, name, storedCount),
          variant: 'success',
        });
        resolve();
      })
      .catch((response) => {
        reject();
        return Promise.reject(response);
      })
      .finally(() => setLoading(false));
  }), [context, dispatch]);

  const editable = useMemo(() => (isEditable
    ? {
      onRowDelete: onDelete,
    }
    : {}
  ), [onDelete, isEditable]);

  const rightButtonBar = useMemo(() => (
    isEditable
      ? (
        <ExportIcon
          items={storeItemInfos}
          columnNames={DEFAULT_COLUMN_STORE_ITEMS}
          disabled={!storeItemInfos.length || loading}
        />
      )
      : undefined
  ), [isEditable, storeItemInfos, loading]);

  const detailPanel = useCallback((rowData: StoreItemInfo) => (
    <ReservedSaleItemDetailPanel
      storeItemId={rowData.id}
    />
  ), []);

  const isLoading = loading || customTableLoading;
  return (
    <div className="table-container">
      <StyledTable<StoreItemInfo>
        detailPanel={detailPanel}
        rightButtonBar={rightButtonBar}
        tableKey={ID.STORE_TABLE}
        editable={editable}
        loading={isLoading}
        columns={memoizedColumns}
        data={rows}
      />
    </div>
  );
}

export default StoreItemsTable;
