import { CreditInfo } from '@/interfaces/CreditInfo';
import { CreditInfoActionType } from '@/redux/actions/creditInfoActions';

export function creditInfoReducer(
  creditInfos: CreditInfo[] = [],
  { type, payload },
): CreditInfo[] {
  switch (type) {
    case CreditInfoActionType.SET:
      return payload;
    case CreditInfoActionType.UPDATE_ONE:
      const index = creditInfos.findIndex((creditInfo) => creditInfo.id === payload.id);
      if (index > -1) {
        const newInfos = [...creditInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return [payload, ...creditInfos];

    case CreditInfoActionType.DELETE_ONE:
      return creditInfos.filter((creditInfo) => creditInfo.id !== payload);
    default:
      return creditInfos;
  }
}
