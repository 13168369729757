import useAddressColumn from '@/hook/columns/useAddressColumn';
import useDescriptionColumn from '@/hook/columns/useDescriptionColumn';
import useEmailColumn from '@/hook/columns/useEmailNumber';
import useNameColumn from '@/hook/columns/useNameColumn';
import usePhoneNumberColumn from '@/hook/columns/usePhoheNumberColumn';
import { Box } from '@material-ui/core';
import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import ApplicationContext from '@/app/snackbarContext';
import { useSelector, useDispatch } from 'react-redux';
import ID from '@/utils/id';
import { deleteCompanyUser, getCompanyUsers } from '@/utils/fetch';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import { MESSAGE } from '@/utils/message';
import useCompanyUserStateColumn from '@/hook/columns/useCompanyUserStateColumn';
import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { selectCompanyInfo, selectCompanyUserInfos } from '@/redux/selectors/companySelector';
import StyledTable from '../StyledTable/StyledTable';

const CompanyUserTable: FC = () => {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<CompanyUserInfo[]>([]);

  const companyUserInfos = useSelector(selectCompanyUserInfos);
  const company: CompanyInfo | null = useSelector(selectCompanyInfo);

  const nameColumn = useNameColumn();
  const phoneNumberColumn = usePhoneNumberColumn();
  const emailColumn = useEmailColumn();
  const addressColumn = useAddressColumn();
  const descriptionColumn = useDescriptionColumn();
  const stateColumn = useCompanyUserStateColumn();

  useEffect(() => {
    setLoading(true);
    getCompanyUsers(company ? company.id : '')
      .then((companyUsers: CompanyUserInfo[]) => {
        dispatch(CompanyUserInfoActions.setUsers(companyUsers));
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(companyUserInfos.map((companyUser) => ({ ...companyUser })));
  }, [companyUserInfos]);

  const memoizedColumns = useMemo(() => ([
    stateColumn,
    nameColumn,
    phoneNumberColumn,
    emailColumn,
    addressColumn,
    descriptionColumn,
  ]), [
    stateColumn,
    nameColumn,
    phoneNumberColumn,
    emailColumn,
    addressColumn,
    descriptionColumn,
  ]);

  const onDelete = useCallback((oldData) => new Promise(
    (resolve, reject) => {
      setLoading(true);

      const { userId, companyId } = oldData;

      const body = { userId, companyId };

      deleteCompanyUser(body)
        .then(({ id }) => {
          dispatch(CompanyUserInfoActions.removeOne(id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [dispatch, context]);

  const editable = useMemo(() => (
    {
      onRowDelete: onDelete,
    }
  ), [onDelete]);

  const options = useMemo(() => ({
    columnsButton: false,
  }), []);

  return (
    <Box
      className="table-container"
    >
      <StyledTable
        tableKey={ID.SUPPLIER_TABLE}
        loading={loading}
        editable={editable}
        columns={memoizedColumns}
        data={rows}
        options={options}
      />
    </Box>
  );
};

export default CompanyUserTable;
