import React from 'react';
import SupplierTable from '../SupplierTable/SupplierTable';

function MySuppliersSubTab() {
  return (
    <div className="table-container">
      <SupplierTable />
    </div>
  );
}

export default MySuppliersSubTab;
