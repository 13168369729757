import React, { useCallback, useEffect, useState } from 'react';
import { getSupplierPartnerRequests } from '@/utils/fetch';
import SupplierCard from '@/components/molecules/SupplierCard/SupplierCard';
import { useDispatch, useSelector } from 'react-redux';
import UserSearchView from '@/components/templates/UserSearchView/UserSearchView';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { getSupplierPartnerRequestInfos } from '@/redux/selectors/supplierPartnerRequestInfoSelector';
import { createSetSupplierPartnerRequestInfosAction } from '@/redux/actions/supplierPatnerRequestInfoActions';

function NewSupplierSearchSubTab() {
  const dispatch = useDispatch();
  const supplierPartnerInfos = useSelector(getSupplierPartnerRequestInfos);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    getSupplierPartnerRequests(searchValue)
      .then((supplierInfos: Array<UserPartnerRequestInfo>) => {
        if (!isMounted) return;
        dispatch(createSetSupplierPartnerRequestInfosAction(supplierInfos));
      })
      .finally(() => {
        if (!isMounted) return;
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, [searchValue, dispatch]);

  const handleCancel = useCallback(() => setSearchValue(''), []);

  return (
    <UserSearchView
      onCancel={handleCancel}
      loading={loading}
      handleSearchChange={setSearchValue}
      cards={
        supplierPartnerInfos.map((supplier) => (
          <SupplierCard
            key={supplier.id}
            supplier={supplier}
          />
        ))
      }
    />
  );
}

export default NewSupplierSearchSubTab;
