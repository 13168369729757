import { StoreState } from '@/redux/stores/store';
import { createSelector } from 'reselect';
import { PreOrderItemInfosState } from '@/redux/reducer/preOrderItemInfoReducer';

const selectPreOrderItemInfoState = (
  state: StoreState,
): PreOrderItemInfosState => state.preOrderItemInfoState;

export const selectPreOrderItemInfos = createSelector(
  selectPreOrderItemInfoState,
  (state) => state.preOrderItems,
);

export default selectPreOrderItemInfoState;
