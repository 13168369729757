import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';
import useStyles from './outsideToolBar.styles';

function OutsideToolBar({
  children,
}: { children?: ReactNode }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {children}
    </Box>
  );
}

export default OutsideToolBar;
