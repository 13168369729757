import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  paper: {
    padding: 16,
    margin: '16px 8px 0 0',
  },
  actionPanel: {
    margin: '16px 0 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
