import React, {
  FC, memo, useCallback, useState,
} from 'react';
import ExportButton from '@/components/atoms/ExportMenu/ExportButton';
import ExportModal from '@/components/organisms/ExportModal';
import ColumnName from '@/enum/ColumnName';
import { ExportTableRecords } from '@/interfaces/ExportTable';

interface Props {
  items: ExportTableRecords;
  columnNames: ColumnName[];
  disabled: boolean;
}

const ExportIcon: FC<Props> = ({
  items,
  columnNames,
  disabled,
}) => {
  const [exportModalOpened, setExportModalOpened] = useState(false);

  const handleExportOpen = useCallback(() => {
    setExportModalOpened(true);
  }, []);

  const handleExportClose = useCallback(() => {
    setExportModalOpened(false);
  }, []);

  return (
    <>
      <ExportButton
        onClick={handleExportOpen}
        disabled={disabled}
      />
      <ExportModal
        disableTableFooter
        columnNames={columnNames}
        records={items}
        onClose={handleExportClose}
        isOpen={exportModalOpened}
      />
    </>
  );
};

export default memo(ExportIcon);
