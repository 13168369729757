import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

class CheckboxList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      checkedItems: [],
    };
  }

  render() {
    const {
      classes,
      list,
      disabled,
      onChange,
      loading,
      autoHeight,
    } = this.props;

    const listClasses = clsx(autoHeight ? classes.autoHeight : classes.fixedHeight, classes.root);
    return (
      <List className={listClasses}>
        {list.map((item, i) => (
          <ListItem
            disabled={disabled || loading}
            key={`column-item-${i}`}
            button
            dense
            onClick={() => onChange(item.value)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={item.checked}
              />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    maxHeight: 400,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  autoHeight: {
    height: 'auto',
  },
  fixedHeight: {
    height: 150,
  },
}))(CheckboxList);
