import MyCompanyCard from '@/components/molecules/MyCompanyCard';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { isCompanyOwner, selectCompanyInfo } from '@/redux/selectors/companySelector';
import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

const MyCompanySubTab: FC = () => {
  const company: CompanyInfo | null = useSelector(selectCompanyInfo);
  const isOwner = useSelector(isCompanyOwner);

  return (
    <Box display="flex" justifyContent="center">
      {company && (
      <MyCompanyCard
        company={company}
        isOwner={isOwner}
      />
      )}
    </Box>
  );
};

export default MyCompanySubTab;
