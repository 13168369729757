import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import { compareDate } from '@/utils/comparator';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { buildCurrencyHeader } from '@/app/applicationSettings';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomTableLoading } from '@/redux/selectors/customTableSelector';
import SupplierOrderStateChip, { renderSupplierOrderStateText } from '@/components/atoms/SupplierOrderStateChip';
import { selectIncomingSupplierInfos } from '@/redux/selectors/incommingSupplierOrderInfoSelector';
import { getIncomingSupplierOrderInfos } from '@/utils/fetch';
import { PaginatedResponse } from '@/interfaces/PaginatedResponse';
import { createSetIncomingSupplierOrderInfoAction } from '@/redux/actions/incomingSupplierOrderInfoActions';
import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';
import IncomingSupplierOrderItemsTable from '@/components/organisms/IncomingSupplierOrderItemsTable';
import useCommentColumn from '@/hook/columns/useCommentColumn';

function IncomingSupplierOrderTable() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const orders = useSelector<Array<IncomingSupplierOrderInfo>>(selectIncomingSupplierInfos);
  const customTableLoading = useSelector(isCustomTableLoading);

  useEffect(() => {
    setLoading(true);
    getIncomingSupplierOrderInfos()
      .then((response: PaginatedResponse<IncomingSupplierOrderInfo>) => {
        dispatch(createSetIncomingSupplierOrderInfoAction(response.records));
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const commentColumn = useCommentColumn();

  const memoizedColumns = useMemo(() => (
    [
      {
        title: TEXT.COLUMN.HEADER.RECEIVING_DATE,
        field: 'pendingDate',
        customSort: (a, b) => compareDate(a.pendingDate, b.pendingDate),
        defaultSort: 'desc',
        editable: 'never',
      },
      {
        title: TEXT.COLUMN.HEADER.CLIENT,
        field: 'clientName',
        editable: 'never',
      },
      {
        removable: false,
        title: TEXT.COLUMN.HEADER.STATE,
        field: 'state',
        customFilterAndSearch: (value, item) => renderSupplierOrderStateText(item.state)
          .toUpperCase()
          .includes(value.toUpperCase()),
        render: (item) => (
          <SupplierOrderStateChip
            state={item ? item.state : undefined}
          />
        ),
        defaultSort: 'asc',
        editable: 'never',
      },
      {
        width: 80,
        title: TEXT.COLUMN.HEADER.COUNT,
        field: 'count',
        editable: 'never',
      },
      {
        title: buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM_WITH_VAT),
        field: 'sum',
        editable: 'never',
      },
      commentColumn,
    ]
  ), [commentColumn]);

  const detailPanel = useCallback((rowData) => (
    <IncomingSupplierOrderItemsTable
      selectedOrder={rowData}
    />
  ), []);

  const memoizedOptions = useMemo(() => (
    {
      columnsButton: false,
    }
  ), []);

  return (
    <div className="table-container">
      <StyledTable
        columns={memoizedColumns}
        data={orders}
        detailPanel={detailPanel}
        loading={loading || customTableLoading}
        options={memoizedOptions}
      />
    </div>
  );
}

export default IncomingSupplierOrderTable;
