import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import { PreorderItemInfo } from '@/interfaces/PreOrderItemInfo';
import { Column } from 'material-table';
import PreOrderItemStateChip, { getTextByState } from '@/components/atoms/PreOrderItemStateChip';

function usePreorderStatusColumn(
): Column<PreorderItemInfo> {
  const handleRender = useCallback((item: PreorderItemInfo) => (
    <PreOrderItemStateChip
      preorderItemInfo={item}
    />
  ), []);

  return useMemo(() => ({
    removable: false,
    title: TEXT.COLUMN.HEADER.STATE,
    field: 'state',
    render: handleRender,
    editable: 'never',
    customFilterAndSearch: (value, item) => getTextByState(item.state)
      .toUpperCase()
      .includes(value.toUpperCase()),
  }), [handleRender]);
}

export default usePreorderStatusColumn;
