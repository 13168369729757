import StoreItemInfo from '@/interfaces/StoreItemInfo';

export enum StoreItemActions {
  SET = 'SET_STORE_ITEMS',
  UPDATE_ONE = 'UPDATE_ONE_STORE_ITEM',
  DELETE = 'DELETE_ONE_STORE_ITEM',
}

export function createUpdateStoreItemAction(
  supplierOrderInfo: StoreItemInfo,
) {
  return {
    type: StoreItemActions.UPDATE_ONE,
    payload: supplierOrderInfo,
  };
}

export function createSetStoreItemsAction(
  storeItemInfos: StoreItemInfo[],
) {
  return {
    type: StoreItemActions.SET,
    payload: storeItemInfos,
  };
}

export function createDeleteStoreItemsAction(
  id: string,
) {
  return {
    type: StoreItemActions.DELETE,
    payload: id,
  };
}
