import React, { FC } from 'react';
import CompanyUserTable from '../CompanyUserTable';

const CompanyUserSubTab: FC = () => (
  <div className="table-container">
    <CompanyUserTable />
  </div>
);

export default CompanyUserSubTab;
