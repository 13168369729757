import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React, { FC, PropsWithChildren } from 'react';
import { Fade } from '@material-ui/core';

interface Props {
  value: number;
  index: number;
  className?: string;
  p?: number;
}

const TabPanel: FC<PropsWithChildren<Props>> = ({
  children,
  value,
  index,
  className,
  p = 3,
}) => (
  <Typography
    className={className}
    component="div"
    role="tabpanel"
    hidden={value !== index}
  >
    {value === index && (
      <Fade in={value === index} timeout={1000}>
        <Box py={p} px={p}>{children}</Box>
      </Fade>
    )}
  </Typography>
);

export default TabPanel;
