import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import Paper from '@material-ui/core/Paper';
import React, { ReactElement } from 'react';
import BusinessIcon from '@material-ui/icons/Business';

interface UserCardProps {
  organization: string,
  contactEmail: string,
  phoneNumber: string,
  description: string,
  address?: string,
  bottom?: ReactElement
}

function UserCard({
  organization,
  contactEmail,
  phoneNumber,
  address,
  description,
  bottom,
}: UserCardProps) {
  return (
    <Paper>
      <Box p={2}>
        <Box alignItems="center" display="flex">
          <Avatar>
            {
							organization
							  ? organization.split(' ')
							    .map((word) => word[0])
							    .join('')
							    .slice(0, 2)
							  : 'VI'
						}
          </Avatar>
          <Box mx={2} className="organization-info">
            <h3>
              {organization}
            </h3>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" m={1}>
          <Box pr={1} display="flex" alignItems="center">
            <MailOutlineIcon color="secondary" />
          </Box>
          <h4 className="contact-info">
            {contactEmail}
          </h4>
        </Box>

        {phoneNumber && (
        <Box display="flex" alignItems="center" m={1}>
          <Box pr={1} display="flex" alignItems="center">
            <PhoneIcon color="secondary" />
          </Box>
          <h4 className="contact-info">
            {phoneNumber}
          </h4>
        </Box>
        )}

        {address && (
        <Box display="flex" alignItems="center" m={1}>
          <Box pr={1} display="flex" alignItems="center">
            <BusinessIcon color="secondary" />
          </Box>
          <h4 className="contact-info">
            {address}
          </h4>
        </Box>
        )}

        {description && (
        <Box m={1} display="flex" alignItems="center">
          <h5 className="description-info">
            {description}
          </h5>
        </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          m={1}
        >
          {bottom}
        </Box>
      </Box>
    </Paper>
  );
}

export default UserCard;
