import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import MaterialTable, {
  Localization,
  MaterialTableProps,
  MTableAction,
  MTableBodyRow,
  MTableToolbar,
  Options,
} from 'material-table';
import { tableIcons } from '@/utils/materialTableIcon';
import './styledTableStyle.css';
import ru from '@/i18n/ru';
import LinearProgress from '@/components/atoms/LinearProgress/LinearProgress';
import { putCustomTable } from '@/utils/fetch';
import { columnVisibilityChange, columnVisibilityChanging } from '@/redux/actions/actionCreator';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomColumnsById } from '@/redux/selectors/customTableSelector';
import { Box } from '@material-ui/core';
import { TEXT } from '@/utils/Text';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@/components/atoms/IconButton';
import ListIcon from '@material-ui/icons/List';
import useStyles from './styles';

interface Props<RowData extends object> extends MaterialTableProps<RowData> {
  loading: boolean;
  id?: string;
  className?: string;
  rightButtonBar?: React.ReactElement;
  rightButtonBarSelectionMode?: React.ReactElement;
  selection?: boolean;
  onRowContextMenu?: (
    event?: React.MouseEvent,
    rowData?: RowData,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;

  onRowEditing?(rowData: RowData): void;
  onRowEditingEnd?(rowData: RowData): void;
  emptyDataSourceMessage?: string;
  tableKey?: ID;
}

function StyledTable<T extends object>({
  data = [],
  columns,
  options,
  className,
  rightButtonBar,
  rightButtonBarSelectionMode,
  loading,
  onRowEditing,
  onRowEditingEnd,
  tableKey,
  onRowContextMenu,
  components,
  detailPanel,
  emptyDataSourceMessage,
  selection,
  ...other
}: Props<T>) {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [editing, setEditing] = useState(false);

  const customColumns = useSelector(selectCustomColumnsById(tableKey));

  const handleEditingStart = useCallback(() => {
    setEditing(true);
  }, []);

  const handleEditingEnd = useCallback(() => {
    setEditing(false);
  }, []);

  const handleRowContextMenu = useCallback((event, rowData) => {
    if (typeof onRowContextMenu === 'function') {
      event.stopPropagation();
      event.preventDefault();
      onRowContextMenu(event, rowData);
    }
  }, [onRowContextMenu]);

  const onChangeColumnHidden = useCallback(({ field }, hidden) => {
    const newCustomColumns = { ...customColumns };
    if (tableKey) {
      newCustomColumns[field] = {
        ...customColumns[field],
        hidden,
        field,
      };
      dispatch(columnVisibilityChanging(true));
      putCustomTable({
        field: tableKey,
        columns: Object.values(newCustomColumns),
      })
        .then(() => {
          dispatch(columnVisibilityChange(tableKey, field, hidden));
        })
        .finally(() => dispatch(columnVisibilityChanging(false)));
    }
  }, [customColumns, tableKey, dispatch]);

  const handleRowClick = useCallback((event, rowData, togglePanel) => {
    if (detailPanel) {
      togglePanel();
    }
  }, [detailPanel]);

  const actionComponent = useCallback((props) => {
    const propsData = props.data;
    let { action } = props;
    if (typeof action === 'function') {
      action = action(propsData);
    }
    const { editTooltip, addTooltip } = ru.materialTable.body;
    const { saveTooltip, cancelTooltip } = ru.materialTable.body.editRow;
    return (
      <MTableAction
        {...props}
        action={{
          ...action,
          onClick: (event, rowData) => {
            if (action.tooltip === editTooltip || action.tooltip === addTooltip) {
              if (typeof onRowEditing === 'function') {
                onRowEditing(rowData);
              }
            }
            if (action.tooltip === saveTooltip || action.tooltip === cancelTooltip) {
              if (typeof onRowEditingEnd === 'function') {
                onRowEditingEnd(rowData);
              }
            }
            action.onClick(event, rowData);
          },
          tooltip: action.tooltip && !action.disabled
            ? action.tooltip
            : '',
        }}
      />
    );
  }, [onRowEditing, onRowEditingEnd]);

  const toolbarComponent = useCallback((props) => (
    <div className={classes.headerBar}>
      <MTableToolbar
        {...props}
        classes={{
          title: classes.toolbarTitle,
          root: classes.toolbarRoot,
          spacer: classes.spacer,
        }}
      >
        {props.children}
      </MTableToolbar>
      {(!!rightButtonBar || selection) && (
        <Box className="right-button-bar">
          {editing ? rightButtonBarSelectionMode : rightButtonBar}
          {selection && (
            <>
              {editing
                ? (
                  <IconButton
                    onClick={handleEditingEnd}
                    title={TEXT.BUTTON.CANCEL}
                  >
                    <Clear
                      color="primary"
                    />
                  </IconButton>
                )
                : (
                  <IconButton
                    onClick={handleEditingStart}
                    title={TEXT.HEADER.GROUP_SELECTION}
                  >
                    <ListIcon
                      color="primary"
                    />
                  </IconButton>
                )}
            </>
          )}
        </Box>
      )}
    </div>
  ), [
    handleEditingEnd,
    handleEditingStart,
    classes,
    rightButtonBar,
    selection,
    rightButtonBarSelectionMode,
    editing,
  ]);

  const rowComponent = useCallback((props) => (
    <MTableBodyRow
      {...props}
      tabIndex="0"
      onContextMenu={(event) => handleRowContextMenu(event, props.data)}
    />
  ), [handleRowContextMenu]);

  const overlayLoadingComponent = useCallback(() => (
    <div className="material-table-loader-container">
      <LinearProgress />
    </div>
  ), []);

  const memoizedComponents = useMemo(() => ({
    Action: actionComponent,
    Toolbar: toolbarComponent,
    Row: rowComponent,
    OverlayLoading: overlayLoadingComponent,
    ...components,
  }), [actionComponent, toolbarComponent, rowComponent, overlayLoadingComponent, components]);

  const memoizedOptions = useMemo<Options>(() => ({
    addRowPosition: 'first',
    headerStyle: {
      position: 'sticky',
      top: '0',
      paddingLeft: 5,
      paddingRight: 5,
    },
    actionsCellStyle: {
      maxWidth: 90,
    },
    showTitle: false,
    search: true,
    paging: true,
    actionsColumnIndex: 0,
    pageSize: 10,
    searchFieldAlignment: 'left',
    maxBodyHeight: undefined,
    minBodyHeight: 380,
    padding: 'dense',
    columnsButton: true,
    selection: editing,
    showTextRowsSelected: editing,
    ...options,
  }), [editing, options]);

  const tableStyles = useMemo(() => ({
    border: editing ? '2px solid red' : 'inherit',
  }), [editing]);

  const localization = useMemo<Localization>(() => ({
    ...ru.materialTable,
    body: {
      ...ru.materialTable.body,
      emptyDataSourceMessage: emptyDataSourceMessage
        || ru.materialTable.body.emptyDataSourceMessage,
    },
  }), [emptyDataSourceMessage]);

  return (
    <div
      className={`styled-material-table ${className || ''}`}
    >
      <MaterialTable
        {...other}
        style={tableStyles}
        detailPanel={detailPanel}
        data={data}
        onChangeColumnHidden={onChangeColumnHidden}
        isLoading={loading}
        tableRef={tableRef}
        onRowClick={handleRowClick}
        icons={tableIcons}
        columns={columns}
        localization={localization}
        components={memoizedComponents}
        options={memoizedOptions}
      />
    </div>
  );
}

export const subTableOptions: Options = {
  paging: false,
  minBodyHeight: undefined,
  maxBodyHeight: 500,
  columnsButton: false,
};

export const noColumnsButtonOptions: Options = {
  columnsButton: false,
};

export default StyledTable;
