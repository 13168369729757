import { RestitutionInfo } from '@/interfaces/RestitutionInfo';
import { RestitutionInfoActions } from '@/redux/actions/restitutionInfoActions';

export function restitutionInfoReducer(
  restitutionInfos: RestitutionInfo[] = [],
  { type, payload },
): RestitutionInfo[] {
  switch (type) {
    case RestitutionInfoActions.SET:
      return payload;
    case RestitutionInfoActions.UPDATE_ONE:
      const index = restitutionInfos.findIndex((restitution) => restitution.id === payload.id);
      if (index > -1) {
        const newInfos = [...restitutionInfos];
        const sourceItem = newInfos[index];
        newInfos[index] = payload;
        if (sourceItem.tableData) {
          const { id, showDetailPanel } = sourceItem.tableData;
          newInfos[index].tableData = { id, showDetailPanel };
        }
        return newInfos;
      }
      return [payload, ...restitutionInfos];

    case RestitutionInfoActions.DELETE_ONE:
      return restitutionInfos.filter((restitution) => restitution.id !== payload);
    default:
      return restitutionInfos;
  }
}
