import React from 'react';

const VendorInterfaceLogo = (({
  className,
}) => (
  <svg
    className={className}
    width="200px"
    height="200px"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="#abc8d8"
      stroke="none"
    >
      <path d="M2322 4881 c-114 -33 -190 -79 -282 -171 -135 -135 -190 -267 -190
-460 0 -193 55 -325 190 -460 135 -135 267 -190 460 -190 193 0 325 55 460
190 135 135 190 267 190 460 0 193 -55 325 -190 460 -93 93 -180 145 -296 174
-85 22 -260 20 -342 -3z"
      />
      <path d="M4 3534 c10 -26 2487 -3514 2496 -3514 9 0 2486 3488 2496 3514 6 14
-85 16 -926 16 -719 0 -929 -3 -924 -12 4 -6 72 -104 152 -216 111 -158 142
-208 136 -222 -13 -26 -928 -1310 -934 -1310 -3 0 -286 396 -630 880 l-625
880 -624 0 c-561 0 -623 -2 -617 -16z"
      />
    </g>
  </svg>
));

export default React.memo(VendorInterfaceLogo);
