import React, { FC, PropsWithChildren } from 'react';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';

interface Props extends AccordionProps {
  header: string | React.ReactElement;
}

const Accordion: FC<PropsWithChildren<Props>> = (
  {
    header,
    children,
    ...props
  },
) => (
  <MuiAccordion {...props}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      {header}
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </MuiAccordion>
);

export default Accordion;
