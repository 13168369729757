import React, { useCallback, useState } from 'react';
import 'react-dropdown/style.css';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { CallMade, CallReceived } from '@material-ui/icons';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import { TEXT } from '@/utils/Text';
import SupplierOrderTable from '@/components/organisms/SupplierOrderTable/SupplierOrderTable';
import IncomingSupplierOrderTable from '@/components/organisms/IncomingSupplierOrderTable';

function SupplierOrderTab() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          label={TEXT.OUTCOMING}
          icon={<CallMade fontSize="small" />}
          onChange={handleChange}
          index={0}
        />
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          icon={<CallReceived fontSize="small" />}
          label={TEXT.INCOMING}
          onChange={handleChange}
          index={1}
        />
      </Breadcrumbs>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <SupplierOrderTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <IncomingSupplierOrderTable />
      </SubTabPanel>
    </div>
  );
}

export default SupplierOrderTab;
