import React from 'react';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('ru');

const DatePicker = (
  {
    onChange,
    value,
    label,
    rootClasses,
    ...props
  },
) => (
  <MuiPickersUtilsProvider
    libInstance={moment}
    utils={DateFnsUtils}
    locale={ruLocale}
		>
    <KeyboardDatePicker
      {...props}
      autoOk
      classes={{
				  root: rootClasses,
      }}
      disableToolbar
      variant="inline"
      format="dd/MM/yyyy"
      margin="normal"
      label={label}
      value={value}
      onChange={onChange}
    />
  </MuiPickersUtilsProvider>
);

export default DatePicker;
