import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteVendor, getAllVendors, postVendor, putVendor,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import snackbarContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCustomTableLoading,
  selectVendorTableColumns,
} from '@/redux/selectors/customTableSelector';
import Vendor from '@/interfaces/Vendor';
import usePhoneNumberColumn from '@/hook/columns/usePhoheNumberColumn';
import { VendorTableErrors } from '@/components/organisms/VendorTable/clientsTable.types';
import useFirstNameColumn from '@/hook/columns/useFirstNameColumn';
import useLastNameColumn from '@/hook/columns/useLastNameColumn';
import selectVendors from '@/redux/selectors/vendorSelector';
import { VendorActions } from '@/redux/actions/vendorActions';
import { Column } from 'material-table';
import useSecondNameColumn from '@/hook/columns/useSecondNameColumn';

function VendorTable() {
  const dispatch = useDispatch();
  const context = useContext(snackbarContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<VendorTableErrors>({});
  const [rows, setRows] = useState<Vendor[]>([]);

  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectVendorTableColumns);
  const vendors: Vendor[] = useSelector(selectVendors);

  const firstNameColumn = useFirstNameColumn(errors.firstName);
  const lastNameColumn = useLastNameColumn(errors.lastName);
  const secondNameColumn = useSecondNameColumn(columns.secondName, errors.secondName);
  const phoneNumberColumn = usePhoneNumberColumn(null, errors.phoneNumber, false, true);

  useEffect(() => {
    setLoading(true);
    getAllVendors()
      .then((response) => (
        dispatch(VendorActions.set(response))
      )).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(vendors.map((clientInfo) => ({ ...clientInfo })));
  }, [vendors]);

  const memoizedColumns = useMemo<Column<Vendor>[]>(() => (
    [
      firstNameColumn,
      secondNameColumn,
      lastNameColumn,
      phoneNumberColumn,
    ]
  ), [firstNameColumn, secondNameColumn, lastNameColumn, phoneNumberColumn]);

  const validateVendor = useCallback(({ firstName, lastName, phoneNumber }) => {
    const result: VendorTableErrors = {};
    if (!firstName) {
      result.firstName = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    if (!lastName) {
      result.lastName = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    if (!phoneNumber) {
      result.phoneNumber = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    setErrors(result);
    return Object.keys(result).length === 0;
  }, []);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateVendor(data)) {
      return reject(data);
    }
    setLoading(true);
    return postVendor(data)
      .then((response) => {
        dispatch(VendorActions.add(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve(response);
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, dispatch, context, validateVendor]);

  const onUpdate = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateVendor(data)) {
      return reject(data);
    }
    setLoading(true);
    return putVendor({ ...data })
      .then((response) => {
        dispatch(VendorActions.update(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve(response);
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, validateVendor, context, dispatch]);

  const onDelete = useCallback((oldData) => new Promise((resolve, reject) => {
    setLoading(true);
    const { id } = oldData;
    return deleteVendor(id)
      .then((response) => {
        dispatch(VendorActions.remove(id));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve(response);
      })
      .catch((response) => {
        reject();
        return Promise.reject(response);
      })
      .finally(() => setLoading(false));
  }), [dispatch, context]);

  const memoizedEditable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowUpdate: onUpdate,
    onRowDelete: onDelete,
  }), [onAdd, onUpdate, onDelete]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  return (
    <StyledTable
      tableKey={ID.VENDOR_TABLE}
      onRowEditing={handleRowEditing}
      editable={memoizedEditable}
      loading={loading || customTableLoading}
      columns={memoizedColumns}
      data={rows}
    />
  );
}

export default VendorTable;
