import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  deleteAttribute, getAttributes, postAttribute, putAttribute,
} from '@/utils/fetch';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import { ValidationErrors } from '@/components/organisms/AttributeTable/attributeTable.types';
import { Attribute } from '@/interfaces/Attribute';
import { AttributeType } from '@/enum/AttributeType';
import tinycolor from 'tinycolor2';
import useNameColumn from '@/hook/columns/useNameColumn';
import useColorColumn from '@/hook/columns/useColorColumn';

const AttributeTable: FC = () => {
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [rows, setRows] = useState<Attribute[]>([]);

  const nameColumn = useNameColumn(errors.name);
  const colorColumn = useColorColumn(errors.color);

  useEffect(() => {
    setLoading(true);
    getAttributes()
      .then((attributes) => {
        setAttributes(attributes);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setRows(attributes.map((item) => ({ ...item })));
  }, [attributes]);

  const memoizedColumns = useMemo(() => ([
    nameColumn,
    colorColumn,
  ]), [nameColumn, colorColumn]);

  const validate = useCallback((attribute) => {
    const errors: ValidationErrors = {};
    if (!attribute.name) {
      errors.name = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    if (!attribute.color) {
      errors.color = MESSAGE.ERROR_REQUIRED_FIELD;
    } else if (!tinycolor(attribute.color).isValid()) {
      errors.color = MESSAGE.INVALID_VALUE;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validate(data)) {
      return reject(data);
    }
    setLoading(true);
    postAttribute({ color: data.color, name: data.name, type: AttributeType.SALE })
      .then((attribute: Attribute) => {
        setAttributes([attribute, ...attributes]);
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [context, errors, attributes, validate]);

  const onUpdate = useCallback((data) => new Promise(
    (resolve, reject) => {
      if (!validate(data)) {
        return reject(data);
      }
      setLoading(true);
      putAttribute({
        id: data.id,
        color: data.color,
        name: data.name,
        type: AttributeType.SALE,
      }).then((response) => {
        setAttributes(attributes.map((item) => (item.id === response.id ? response : item)));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, errors, attributes, validate]);

  const onDelete = useCallback(({ id }) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteAttribute(id)
        .then((response) => {
          setAttributes((items) => items.filter((item) => item.id !== response.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [context]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const editable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowDelete: onDelete,
    onRowUpdate: onUpdate,
  }), [onAdd, onDelete, onUpdate]);

  const memoizedOptions = useMemo(() => ({
    columnsButton: false,
  }), []);

  return (
    <div className="table-container">
      <StyledTable
        onRowEditing={handleRowEditing}
        editable={editable}
        loading={loading}
        data={rows}
        columns={memoizedColumns}
        options={memoizedOptions}
      />
    </div>
  );
};

export default AttributeTable;
