import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import PriceFormationType from '@/interfaces/PriceFormationType';
import ID from '@/utils/id';
import { Column } from 'material-table';
import useNameColumn from '@/hook/columns/useNameColumn';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';
import {
  deletePriceFormationType,
  getPriceFormationTypes,
  postPriceFormationType,
  putPriceFormationType,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import { Errors } from '@/components/organisms/PriceFormationTypeTable/types';
import SnackbarContext from '@/app/snackbarContext';
import { PriceFormationTypeActions } from '@/redux/actions/priceFormationTypeActions';
import selectCustomPriceFormationTypes from '@/redux/selectors/priceFormationTypeSelector';
import { StoreState } from '@/redux/stores/store';

const PriceFormationTypeTable: FC = () => {
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({} as Errors);

  const userId = useSelector(selectSelectedUserId);
  const priceFormationTypes = useSelector<StoreState, PriceFormationType[]>(
    selectCustomPriceFormationTypes,
  );

  const nameColumn = useNameColumn(errors.name, null, true);

  useEffect(() => {
    setLoading(true);
    getPriceFormationTypes(userId)
      .then((response) => (
        dispatch(PriceFormationTypeActions.set(response))
      ))
      .finally(() => {
        setLoading(false);
      });
  }, [userId, dispatch]);

  const memoizedColumns = useMemo<Column<PriceFormationType>[]>(() => ([
    nameColumn,
  ]), [nameColumn]);

  const onAdd = useCallback((data) => new Promise<void>(
    (resolve, reject) => {
      setLoading(true);
      postPriceFormationType(data)
        .then((response) => {
          dispatch(PriceFormationTypeActions.add(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [context, errors, dispatch]);

  const onUpdate = useCallback((data) => new Promise<void>(
    (resolve, reject) => {
      setLoading(true);
      putPriceFormationType(data)
        .then((response) => {
          dispatch(PriceFormationTypeActions.update(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [context, errors, dispatch]);

  const onDelete = useCallback(({ id }) => new Promise<void>(
    (resolve, reject) => {
      setLoading(true);
      deletePriceFormationType(id)
        .then((response) => {
          dispatch(PriceFormationTypeActions.remove(response.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch]);

  const editable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowUpdate: onUpdate,
    onRowDelete: onDelete,
  }), [onAdd, onDelete, onUpdate]);

  const memoizedOptions = useMemo(() => ({
    columnsButton: false,
  }), []);

  return (
    <StyledTable<PriceFormationType>
      id={ID.PRICE_FORMATION_TYPE}
      loading={loading}
      data={priceFormationTypes}
      columns={memoizedColumns}
      options={memoizedOptions}
      editable={editable}
    />
  );
};

export default PriceFormationTypeTable;
