import { useCallback, useState } from 'react';
import { round } from '@/utils/Utils';

function useCalculateMaxCount(
  enableMaxCount,
  initialData,
) {
  const [initialRowData] = useState(initialData);

  return useCallback((item) => {
    if (enableMaxCount && item.supplierOrderItemId) {
      return !item.preorder
        ? round(item.storedCount - item.reservedCount + (
          initialRowData && initialRowData.supplierOrderItemId === item.supplierOrderItemId
            ? initialRowData.count
            : 0
        ), 4)
        : round(item.storedCount - item.reservedCount, 4);
    }
    return undefined;
  }, [enableMaxCount, initialRowData]);
}

export default useCalculateMaxCount;
