import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Box, Paper } from '@material-ui/core';
import { TEXT } from '@/utils/Text';
import moment from 'moment';
import { useStyles } from '@/components/organisms/IncomeSubTab/incomeSubTab.styles';
import Button from '@/components/atoms/Button';
import { putClientSaleDistribution } from '@/utils/fetch';
import { buildCurrencyHeader, dateTimeFormat } from '@/app/applicationSettings';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@/components/atoms/Typography';
import KeyboardDateTimePicker
  from '@/components/atoms/KeyboardDateTimePicker/KeyboardDateTimePicker';
import { Pie, PieDatum } from '@nivo/pie';
import { ClientSaleDistribution } from '@/interfaces/ClientSaleDistribution';
import { round } from '@/utils/Utils';
import { MailOutline, Phone } from '@material-ui/icons';
import Divider from '@/components/atoms/Divider/Divider';
import { ClientSaleDistributionResponse } from '@/interfaces/response/ClientSaleDistributionResponse';
import NumberFormat from 'react-number-format';
import ReportHeaderItem from '@/components/molecules/ReportHeaderItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';

const emptyChartData: PieDatum[] = [
  {
    id: '1',
    label: TEXT.NO_RECORDS,
    value: 100,
    sum: 0,
    vatSum: 0,
    sumNoVat: 0,
    incomeSum: 0,
    percentageSum: 0,
    percentageVatSum: 0,
    percentageSumNoVat: 0,
    percentageIncomeSum: 0,
  },
];

export enum ChartType {
  INCOME = 0,
  TURNOVER = 1,
  VAT_SUM = 2,
}

const IncomeSubTab: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState<moment.Moment | null>(
    moment().startOf('month'),
  );
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    moment().endOf('month'),
  );
  const [clientSaleDistributions, setClientSaleDistributions] = useState<ClientSaleDistribution[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(emptyChartData);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalTurnover, setTotalTurnover] = useState<number>(0);
  const [totalVatSum, setTotalVatSum] = useState<number>(0);
  const [smallGroups, setSmallGroups] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(
    ChartType.INCOME,
  );
  const selectedUserId = useSelector(selectSelectedUserId);

  useEffect(() => {
    dispatch(CompanyUserInfoActions.setSelectionEnabled(true));
    return () => {
      dispatch(CompanyUserInfoActions.setSelectionEnabled(false));
    };
  }, []);

  useEffect(() => {
    const items = clientSaleDistributions.filter((item) => {
      switch (selectedChartType) {
        case ChartType.INCOME:
          return !!item.incomeSum;
        case ChartType.TURNOVER:
          return !!item.sum;
        case ChartType.VAT_SUM:
          return !!item.vatSum;
        default:
          return !!item.incomeSum;
      }
    });
    if (items.length) {
      const groups = items.map((item) => {
        let value;
        switch (selectedChartType) {
          case ChartType.INCOME:
            value = item.percentageIncomeSum;
            break;
          case ChartType.TURNOVER:
            value = item.percentageSum;
            break;
          case ChartType.VAT_SUM:
            value = item.percentageVatSum;
            break;
          default:
            value = item.percentageIncomeSum;
        }
        return {
          ...item,
          label: item.id === '0' ? 'Не указано' : item.name,
          value,
        };
      }).sort((
        a,
        b,
      ) => b.value - a.value);
      let bigGroups = groups;
      if (groups.length > 10) {
        bigGroups = groups.slice(0, 10);
        const smallGroups = groups.slice(10, groups.length);
        bigGroups.push({
          id: '-1',
          label: TEXT.OTHERS,
          value: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.value, 0)),
          sum: round(smallGroups.reduce((
            previousValue: number,
            item,
          ) => previousValue + item.sum, 0)),
          vatSum: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.vatSum, 0)),
          sumNoVat: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.sumNoVat, 0)),
          incomeSum: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.incomeSum, 0)),
          percentageSum: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.percentageSum, 0)),
          percentageVatSum: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.percentageVatSum, 0)),
          percentageSumNoVat: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.percentageSumNoVat, 0)),
          percentageIncomeSum: round(smallGroups.reduce((
            previousValue,
            item,
          ) => previousValue + item.percentageIncomeSum, 0)),
        });
        setSmallGroups(smallGroups);
      }
      setData(bigGroups);
    } else {
      setData(emptyChartData);
      setSmallGroups([]);
    }
  }, [clientSaleDistributions, selectedChartType]);

  const handleSearchClick = useCallback(() => {
    if (!startDate || !endDate) return;

    setLoading(true);
    putClientSaleDistribution(
      moment(startDate).format(dateTimeFormat),
      moment(endDate).format(dateTimeFormat),
      selectedUserId,
    ).then((response: ClientSaleDistributionResponse) => {
      setClientSaleDistributions(response.clientSaleDistributions);
      setTotalIncome(response.totalIncomeSum);
      setTotalTurnover(response.totalSum);
      setTotalVatSum(response.totalVatSum);
    }).finally(() => setLoading(false));
  }, [startDate, endDate, selectedUserId]);

  const handleStartDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setStartDate(moment(value));
    } else {
      setStartDate(value);
    }
  }, []);

  const handleEndDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setEndDate(moment(value));
    } else {
      setEndDate(value);
    }
  }, []);

  const handleTypeChange = useCallback((type: ChartType) => {
    setSelectedChartType(type);
  }, []);

  return (
    <Box>
      <Box className={classes.tabHeader}>
        <Paper className={classes.paper}>
          <Box
            className={classes.leftHeaderBox}
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              startIcon={
                <SearchIcon />
              }
              disabled={!startDate?.isValid() || !endDate?.isValid()
              || loading}
              onClick={handleSearchClick}
              text={TEXT.SEARCH}
            />
            <KeyboardDateTimePicker
              classes={{ root: classes.dateInput }}
              label={TEXT.START_DATE}
              onChange={handleStartDateChange}
              value={startDate}
            />
            <KeyboardDateTimePicker
              classes={{ root: classes.dateInput }}
              label={TEXT.END_DATE}
              onChange={handleEndDateChange}
              value={endDate}
            />
          </Box>
        </Paper>
        <Paper className={classes.balancePaper}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <ReportHeaderItem
              header={buildCurrencyHeader(TEXT.INCOME)}
              value={(
                <NumberFormat
                  value={totalIncome}
                  thousandSeparator
                  displayType="text"
                />
              )}
              type={ChartType.INCOME}
              onChange={handleTypeChange}
              selected={selectedChartType}
            />
            <ReportHeaderItem
              header={buildCurrencyHeader(TEXT.TURNOVER)}
              value={(
                <NumberFormat
                  value={totalTurnover}
                  thousandSeparator
                  displayType="text"
                />
              )}
              type={ChartType.TURNOVER}
              onChange={handleTypeChange}
              selected={selectedChartType}
            />
            <ReportHeaderItem
              header={buildCurrencyHeader(TEXT.COLUMN.HEADER.VAT_SUM)}
              value={(
                <NumberFormat
                  value={totalVatSum}
                  thousandSeparator
                  displayType="text"
                />
              )}
              type={ChartType.VAT_SUM}
              onChange={handleTypeChange}
              selected={selectedChartType}
            />
          </Box>
        </Paper>
      </Box>
      <Paper>
        <div className="full flex-center">
          <Pie
            height={450}
            width={1100}
            sortByValue
            data={data}
            margin={{
              top: 40,
              right: 80,
              bottom: 80,
              left: 80,
            }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'red_yellow_blue' }}
            borderWidth={1}
            radialLabel="label"
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor={{ theme: 'labels.text.fill' }}
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={15}
            radialLabelsLinkHorizontalLength={25}
            radialLabelsLinkStrokeWidth={1}
            // borderColor={'black'}
            radialLabelsLinkColor={{
              from: 'color',
              modifiers: [],
            }}
            slicesLabelsSkipAngle={15}
            slicesLabelsTextColor="black"
            animate
            motionStiffness={90}
            motionDamping={15}
            sliceLabel={(element: PieDatum) => `${element.value}%`}
            tooltip={(element: PieDatum) => {
              const {
                value,
                email,
                phoneNumber,
                label,
                color,
                sum,
                incomeSum,
                vatSum,
              } = element;
              return (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  p={1}
                >
                  <Box display="flex" mb={1} className="full-width">
                    <div
                      className={classes.colorTip}
                      style={{ background: color }}
                    />
                    <Typography
                      variant="h3"
                    >
                      {`– ${label} (${value}%)`}
                    </Typography>
                  </Box>
                  {email && (
                    <Box display="flex" mb={0.5} className="full-width">
                      <Box pr={1} display="flex" alignItems="center">
                        <MailOutline color="secondary" />
                      </Box>
                      <div className="contact-info">
                        {email}
                      </div>
                    </Box>
                  )}
                  {phoneNumber && (
                    <Box display="flex" mb={0.5} className="full-width">
                      <Box pr={1} display="flex" alignItems="center">
                        <Phone color="secondary" />
                      </Box>
                      <div className="contact-info">
                        {phoneNumber}
                      </div>
                    </Box>
                  )}
                  <Divider horizontal />
                  <Box display="flex" mb={0.5} className="full-width">
                    <Box pr={1} display="flex" alignItems="center">
                      <Typography
                        className={classes.header}
                      >
                        {`${TEXT.INCOME}:`}
                      </Typography>
                      <NumberFormat
                        value={incomeSum}
                        displayType="text"
                        thousandSeparator
                      />
                    </Box>
                  </Box>
                  <Box display="flex" mb={0.5} className="full-width">
                    <Box pr={1} display="flex" alignItems="center">
                      <Typography
                        className={classes.header}
                      >
                        {`${TEXT.TURNOVER}: `}
                      </Typography>
                      <NumberFormat
                        value={sum}
                        thousandSeparator
                        displayType="text"
                      />
                    </Box>
                  </Box>
                  <Box display="flex" mb={0.5} className="full-width">
                    <Box pr={1} display="flex" alignItems="center">
                      <Typography
                        className={classes.header}
                      >
                        {`${TEXT.COLUMN.HEADER.VAT_SUM}: `}
                      </Typography>
                      <NumberFormat
                        value={vatSum}
                        thousandSeparator
                        displayType="text"
                      />
                    </Box>
                  </Box>
                </Box>
              );
            }}
            legends={[
              {
                anchor: 'top-right',
                direction: 'column',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 21,
                itemTextColor: 'black',
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default IncomeSubTab;
