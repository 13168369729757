import { compareByField } from '@/utils/comparator';
import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import ProductInfoEditInput from '@/components/molecules/ProductInfoEditInput';
import { getProductInfos, getStoreItemInfosByStoreId } from '@/utils/fetch';
import { productToOption } from '@/utils/toOption';
import ProductInfo, { ProductInfoOptionData } from '@/interfaces/ProductInfo';
import { useSelector } from 'react-redux';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';
import { selectCurrentStoreId } from '@/redux/selectors/applicationSelector';
import equalsIfPresent from '@/utils/stringUtils';
import byName from '@/service/filterAndsearch';
import { Column } from 'material-table';
import { round } from '@/utils/Utils';
import { Box, Tooltip } from '@material-ui/core';
import {
  LocalShipping, Lock, LockOpen, ShoppingCart, Store,
} from '@material-ui/icons';
import StoreItemInfoOption from '@/components/molecules/StoreItemInfoOption/StoreItemInfoOption';
import SaleItemInfo from '@/interfaces/SaleItemInfo';
import { PaginatedResponse } from '@/interfaces/PaginatedResponse';
import StoreItemInfo from '@/interfaces/StoreItemInfo';
import ProductOptionLabel from '@/components/atoms/ProductOptionLabel';

function useProductColumn(
  priceFormationTypeId,
  error: boolean,
  actionIcon?: boolean,
): Column<SaleItemInfo> {
  const storeEnabled = useSelector(isStoreEnabled);
  const userId = useSelector(selectSelectedUserId);
  const storeId = useSelector(selectCurrentStoreId);

  const loadOptions = useCallback(async (value) => {
    const responses = await Promise.all([
      getProductInfos(priceFormationTypeId, value),
      storeEnabled && storeId
        ? getStoreItemInfosByStoreId(priceFormationTypeId, storeId, userId, value)
        : undefined,
    ]);

    const [productInfosPage, storeItemInfosPage]: [
      PaginatedResponse<ProductInfo>,
      PaginatedResponse<StoreItemInfo> | undefined
    ] = responses;

    const productInfos = productInfosPage.records;

    const options: ProductInfoOptionData[] = [];
    productInfos.forEach((product) => {
      options.push({
        ...product,
        storedCount: undefined,
        reservedCount: undefined,
        storeItemId: undefined,
        supplierOrderItemId: undefined,
        producingDate: undefined,
      });
      if (storeItemInfosPage) {
        storeItemInfosPage.records
          .filter((storeItem) => storeItem.productId === product.id
          || storeItem.productId === product.synchronizingProductId)
          .forEach(({
            storedCount, reservedCount, id, supplierOrderItemId, producingDate,
          }) => {
            options.push({
              ...product,
              storedCount,
              reservedCount,
              storeItemId: id,
              supplierOrderItemId,
              producingDate,
            });
          });
      }
    });

    return [options.map(productToOption), productInfosPage.totalElementsCount];
  }, [
    priceFormationTypeId,
    storeEnabled,
    storeId,
    userId,
  ]);

  const renderOptionWithTooltip = useCallback(({ data: item }) => {
    const leftCount = round(item.storedCount - item.reservedCount, 4);
    const shouldShowBalanceDetails = storeEnabled && typeof item.storedCount === 'number';
    return (
      <Box className="flex full">
        {storeEnabled && item.storeItemId && !!leftCount && !item.reservedCount && (
          <Tooltip title="Товар не в резерве">
            <Box ml={1} mr={0.5}>
              <Store fontSize="small" color="primary" />
            </Box>
          </Tooltip>
        )}
        {storeEnabled && item.storeItemId && !!leftCount && !!item.reservedCount && (
          <Tooltip title="Товар частично в разерве">
            <Box ml={1} mr={0.5}>
              <LockOpen fontSize="small" color="primary" />
            </Box>
          </Tooltip>
        )}
        {storeEnabled && item.storeItemId && !leftCount && (
          <Tooltip title="Товар полностью в резерве">
            <Box ml={1} mr={0.5}>
              <Lock fontSize="small" color="primary" />
            </Box>
          </Tooltip>
        )}
        {storeEnabled && !item.storeItemId && (
          <Tooltip title="Предзаказ товара">
            <Box mr={0.5}>
              <ShoppingCart fontSize="small" color="primary" />
            </Box>
          </Tooltip>
        )}
        <StoreItemInfoOption
          maxCount={leftCount}
          markupEnabled
          interactive
          item={item}
          storeEnabled={shouldShowBalanceDetails}
        >
          <ProductOptionLabel
            showCount={shouldShowBalanceDetails}
            supplierName={item.supplierName}
            category={item.category}
            count={leftCount}
            unitName={item.unitName}
            name={item.name}
            manufacturer={item.manufacturer}
          />
        </StoreItemInfoOption>
      </Box>
    );
  }, [storeEnabled]);

  const getOptionSelected = useCallback((option, value) => (
    !!value && equalsIfPresent(option.data.supplierOrderItemId, value.data.supplierOrderItemId)
    && value.value === option.value
  ), []);

  const handleEditComponent = useCallback(({ rowData, onRowDataChange }) => (
    <ProductInfoEditInput
      renderOptionWithTooltip={renderOptionWithTooltip}
      getOptionSelected={getOptionSelected}
      priceFormationTypeId={priceFormationTypeId}
      rowData={rowData}
      onRowDataChange={onRowDataChange}
      error={error}
      loadOptions={loadOptions}
      actionIcon={actionIcon}
    />
  ), [
    getOptionSelected,
    error,
    loadOptions,
    actionIcon,
    priceFormationTypeId,
    renderOptionWithTooltip,
  ]);

  return useMemo(() => (
    {
      removable: false,
      customSort: compareByField('name'),
      customFilterAndSearch: byName,
      title: TEXT.COLUMN.HEADER.NAME,
      field: 'productId',
      render: (item) => (
        <Box className="flex full" flexDirection="column">
          {!!item.supplierOrderItemId && !item.preorder && (
            <Tooltip title="Товар выбран со склада">
              <Box ml={1} mr={0.5}>
                <Store fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}
          {item.preorder && !item.supplierOrderItemId && (
            <Tooltip title="Предзаказ товара">
              <Box ml={1} mr={0.5}>
                <ShoppingCart fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}
          {item.preorder && !!item.supplierOrderItemId && (
            <Tooltip title="В пути">
              <Box ml={1} mr={0.5}>
                <LocalShipping fontSize="small" color="primary" />
              </Box>
            </Tooltip>
          )}
          <StoreItemInfoOption
            markupEnabled
            interactive
            placement="right-start"
            item={item}
            storeEnabled={false}
          >
            <Box className="flex" width="100%">
              {item.name}
            </Box>
          </StoreItemInfoOption>
        </Box>
      ),
      defaultSort: 'asc',
      editComponent: handleEditComponent,
    }
  ), [handleEditComponent]);
}

export default useProductColumn;
