export const MESSAGE = {
  CHOOSE_ONE_FILE: 'Выберите один файл',
  INVALID_VALUE: 'Невалидное значение',
  SUCCESS_COPY_TO_CLIPBOARD: 'Операция копирования в буфер обмена успешна завершена (используйте "Ctrl + V" для вставки)',
  SUCCESS_RESTITUTION_COMPLETE: 'Возврат успешно совершён',
  SUCCESS_OPERATION: 'Операция успешно завершена',
  SUCCESS_SUPPLIER_PRODUCTS_IMPORT: 'Операция успешна завершена: "{0}" товар(ов) были импортированы',
  SUCCESS_SALE_COMPLETE: 'Продажа успешно совершена',
  SUCCESS_SUPPLIER_ORDER_RECEIVED: 'Заказ поставщика "{0}" успешно добавлен на склад',
  SUCCESS_SUPPLIER_ORDER_PENDING: 'Ваш заказ успешно отправлен поставщику и теперь ожидает подтверждения от "{0}"',
  SUCCESS_SUPPLIER_ORDER_CANCEL: 'Ваш заказ был отменён у поставщика "{0}"',
  SUCCESS_SUPPLIER_ORDER_DECLINE: 'Заказ успешно отменён',
  SUCCESS_SUPPLIER_ORDER_APPROVE: 'Заказ успешно подтверждён. Продажа была автоматически оформлена',
  SUCCESS_SUPPLIER_ORDER_SEND: 'Продажа успешно завершена. Заказ был переведён в статус "Отправлено"',
  SUCCESS_STORE_ITEM_DELETE: 'Товар "{0}" успешно удалён со склада в количестве "{1}"',
  OOPS_SOMETHING_WENT_WRONG: 'Упс! Кажется что-то пошло не так. Пожалуйста, сообщите о проблеме администратору',
  OPERATION_NOT_SUPPORTED_BY_BROWSER: 'Вы используете браузер, в котором данное действие не поддерживается (рекомендуем Google Chrome для работы)',
  SESSION_EXPIRED: 'Время сессии истекло! Пожалуйста, выполните вход',
  SUCCESS_EMAIL_SENT: 'Ваше сообщение было успешно отправлено по адресу "{0}"',
  MAX_FILE_LIMIT: 'Превышен максимально допустимый размер файла - 5 MB!',
  MAX_PRODUCTS_LIMIT: 'Превышено максимально допустимое количество товаров',
  WARNING_FILL_PUBLIC_DATA: 'Пожалуйста, перед началом использования роли "Поставщик", заполните публичные данные. Благодарим Вас!',
  WARNING_FILL_REQUIRED_DATA: 'Пожалуйста, заполните все обязательные поля',
  WARNING_SUPPLIER_SHOULD_NOT_BE_EMPTY_FOR_ORDER: 'Для оформления заказа поле "Поставщик" должно быть заполнено',
  WARNING_NO_ARCHIVED_PRODUCTS_FOR_ORDER: 'Для оформления заказа продажа не должна включать архивированных товаров',
  ERROR_REQUIRED_FIELD: 'Обязательное поле',
  NO_INTERNET_CONNECTION: 'Ваше интернет-соединение в настоящее время отключено',
  INTERNET_CONNECTION_ESTABLISHED: 'Ваше интернет-соединение восстановлено',
};
