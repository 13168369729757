import * as Utils from './Utils';

export const compareDate = (a, b) => {
  if (!a) return 1;
  if (!b) return -1;
  const date1 = Utils.toDate(a).getTime();
  const date2 = Utils.toDate(b).getTime();
  if (date1 === date2) return 0;
  return date1 > date2 ? 1 : -1;
};

export const compareDateTime = (a, b) => {
  if (!a) return 1;
  if (!b) return -1;
  const date1 = Utils.toDateFromDateTimeStr(a).getTime();
  const date2 = Utils.toDateFromDateTimeStr(b).getTime();
  if (date1 === date2) return 0;
  return date1 > date2 ? 1 : -1;
};

export const compareByField = (field, order = 'asc') => (a, b) => {
  if (isNaN(a[field])) {
    a = (a[field] || '').toUpperCase();
  } else {
    a = a[field];
  }
  if (isNaN(b[field])) {
    b = (b[field] || '').toUpperCase();
  } else {
    b = b[field];
  }
  if (a === b) {
    return 0;
  }
  return order === 'desc'
    ? a < b
      ? -1 : 1
    : a > b
      ? 1 : -1;
};
