import { makeStyles } from '@material-ui/core';

export const useClasses = makeStyles(() => ({
  container: {
    width: 15,
    minWidth: 15,
    height: 15,
    minHeight: 15,
    margin: '5px 10px 5px 5px',
    borderRadius: 2,
    border: '1px solid gray',
  },
}));
