import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import ClientEditComponent from '@/components/molecules/ClientEditComponent';
import ClientTitleLabel from '@/components/atoms/ClientTitleLabel';
import { Column } from 'material-table';
import ClientBased from '@/interfaces/ClientBased';
import { compareByField } from '@/utils/comparator';

function useClientColumn<RowData extends ClientBased>(
  column,
  error?,
  editable?,
  creditIconEnabled = false,
  required = false,
): Column<RowData> {
  const handleClientComponent = useCallback(({ onRowDataChange, rowData }) => (
    <ClientEditComponent
      required={required}
      error={error}
      onRowDataChange={onRowDataChange}
      rowData={rowData}
    />
  ), [required, error]);

  const handleRenderer = useCallback((rowData) => (
    <ClientTitleLabel
      creditIconEnabled={creditIconEnabled}
      clientId={rowData.clientId}
      name={rowData.clientFirstName}
      phoneNumber={rowData.clientPhoneNumber}
    />
  ), [creditIconEnabled]);

  return useMemo(() => ({
    hidden: !!column?.hidden,
    title: TEXT.COLUMN.HEADER.CLIENT,
    field: 'clientId',
    customSort: compareByField('clientFirstName'),
    render: handleRenderer,
    editComponent: handleClientComponent,
    editable,
    customFilterAndSearch: (value, item) => (
      !!item.clientFirstName && !!item?.clientFirstName.toUpperCase()
        .includes(value.toUpperCase()))
      || (!!item.clientPhoneNumber && !!item?.clientPhoneNumber.toUpperCase()
        .includes(value.toUpperCase())),
  }), [editable, column, handleClientComponent, handleRenderer]);
}

export default useClientColumn;
