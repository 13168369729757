import React from 'react';
import { TEXT } from '@/utils/Text';
import { Tooltip } from '@material-ui/core';
import TooltipLine from '@/components/organisms/ProductImportModal/TooltipLine';
import Box from '@material-ui/core/Box';

const renderProperty = (property) => property || '—';

const renderCount = (property, unitName) => (property ? `${property} ${unitName || ''}` : '—');

const StoreItemInfoOption = ({
  children,
  item,
  markupEnabled = false,
  storeEnabled,
  maxCount,
  maxCountEnabled,
  ...other
}) => {
  const {
    name,
    supplierName,
    manufacturer,
    category,
    price,
    markup,
    unitName,
    storedCount,
  } = item;
  return (
    <Tooltip
      {...other}
      interactive
      arrow
      placement="right"
      title={(
        <Box display="flex" flexDirection="column" maxWidth={250}>
          <TooltipLine header={TEXT.COLUMN.HEADER.NAME} text={renderProperty(name)} />
          <TooltipLine header={TEXT.COLUMN.HEADER.MANUFACTURER} text={renderProperty(manufacturer)} />
          <TooltipLine header={TEXT.COLUMN.HEADER.SUPPLIER} text={renderProperty(supplierName)} />
          <TooltipLine header={TEXT.COLUMN.HEADER.CATEGORY} text={renderProperty(category)} />

          {markupEnabled && (
            <TooltipLine header={`${TEXT.COLUMN.HEADER.MARKUP} (по умолчанию)`} text={renderProperty(markup)} />
          )}
          <TooltipLine header={TEXT.COLUMN.HEADER.PRICE_WITH_VAT} text={renderProperty(price)} />
          {storeEnabled && (
            <>
              <TooltipLine header="На складе" text={renderCount(storedCount, unitName)} />
              <TooltipLine header="Остаток" text={renderCount(maxCount, unitName)} />
            </>
          )}
          {maxCountEnabled && (
            <TooltipLine header="Остаток" text={renderCount(maxCount, unitName)} />
          )}
        </Box>
      )}
    >
      {children}
    </Tooltip>
  );
};

export default StoreItemInfoOption;
