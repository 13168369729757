enum ErrorType {
  DUPLICATED_EMAIL = 'duplicated.email',
  EXPIRED_TOKEN = 'expired.token',
  PRODUCTS_SHOULD_BE_SYNCHRONIZED = 'unsync.products',
  NO_PREORDER_SALE_ITEMS = 'no.preorder.sale.items',
  PREORDER_PRODUCT_SHOULD_HAVE_SUPPLIER = 'preorder.product.should.have.supplier',
  ARCHIVED_PRODUCT = 'archived.product',
}

export default ErrorType;
