import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { TEXT } from '@/utils/Text';
import { PreOrderItemInfoState } from '@/enum/PreOrderItemInfoState';
import { PreorderItemInfo } from '@/interfaces/PreOrderItemInfo';
import useStyles from './styles';

export const renderPreOrderItemStateText = ({
  state,
}: PreorderItemInfo) => {
  switch (state) {
    case PreOrderItemInfoState.ORDERED:
      return TEXT.ORDERED;
    case PreOrderItemInfoState.PARTLY_ORDERED:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {TEXT.PARTLY_ORDERED}
        </Box>
      );
    case PreOrderItemInfoState.OVER_ORDERED:
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          {TEXT.OVER_ORDERED}
        </Box>
      );
    case PreOrderItemInfoState.NOT_ORDERED:
      return TEXT.NOT_ORDERED;
    default:
      return '';
  }
};

export const getTextByState = (
  state: PreOrderItemInfoState,
) => {
  switch (state) {
    case PreOrderItemInfoState.ORDERED:
      return TEXT.ORDERED;
    case PreOrderItemInfoState.PARTLY_ORDERED:
      return TEXT.PARTLY_ORDERED;
    case PreOrderItemInfoState.NOT_ORDERED:
      return TEXT.NOT_ORDERED;
    case PreOrderItemInfoState.OVER_ORDERED:
      return TEXT.OVER_ORDERED;
    default:
      return '';
  }
};

interface Props {
  preorderItemInfo: PreorderItemInfo;
}

const PreOrderItemStateChip: React.FC<Props> = ({
  preorderItemInfo,
}) => {
  const classes = useStyles();

  return (
    <Box
      className="full-width"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      px={1}
    >
      <Chip
        classes={{ root: classes.root }}
        className={classes[preorderItemInfo.state.toLowerCase()]}
        label={renderPreOrderItemStateText(preorderItemInfo)}
      />
    </Box>
  );
};

export default React.memo(PreOrderItemStateChip);
