import { Reducer } from 'redux';
import { USER_ANONYMOUS } from '@/constants/action';
import combinedReducer from '@/redux/reducer/combinedReducer';

const rootReducer: Reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case USER_ANONYMOUS:
      return combinedReducer(undefined, action);
    default:
      return combinedReducer(state, action);
  }
};

export default rootReducer;
