import { ClientInfo } from '@/interfaces/ClientInfo';

export enum ClientInfoActionType {
  SET = 'SET_CLIENT_INFOS',
  UPDATE_ONE = 'UPDATE_ONE_CLIENT_INFO',
  DELETE = 'DELETE_CLIENT_INFO'
}

export function createUpdateClientInfo(
  clientInfo: ClientInfo,
) {
  return {
    type: ClientInfoActionType.UPDATE_ONE,
    payload: clientInfo,
  };
}

export function createSetClientInfosAction(
  clientInfos: Array<ClientInfo>,
) {
  return {
    type: ClientInfoActionType.SET,
    payload: clientInfos,
  };
}

export function createDeleteClientInfoAction(
  id: string,
) {
  return {
    type: ClientInfoActionType.DELETE,
    payload: id,
  };
}
