import React, { useCallback, useMemo } from 'react';
import { TEXT } from '@/utils/Text';
import UnitEditComponent from '@/components/molecules/UnitEditComponent';

function useUnitColumn(
  error,
  column,
) {
  const handleEditComponent = useCallback(({ rowData, onRowDataChange }) => (
    <UnitEditComponent
      onRowDataChange={onRowDataChange}
      rowData={rowData}
      error={error}
    />
  ), [error]);

  return useMemo(() => ({
    cellStyle: { width: 100 },
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.UNIT,
    field: 'unitId',
    render: (item) => item.unitName,
    editComponent: handleEditComponent,
  }), [handleEditComponent, column]);
}

export default useUnitColumn;
