export const TEXT = {
  ACTIVE: 'Активен',
  ADD_CLIENT: 'Добавление клиента',
  ADD_ATTRIBUTE: 'Добавление атрибута',
  ADDRESS: 'Адрес',
  APPROVE: 'Подтвердить',
  APPROVED: 'Подтверждено',
  ATTRIBUTES: 'Атрибуты',
  BACK: 'Назад',
  BALANCE: 'Кредитовое сальдо',
  BALANCE_AMOUNT: 'Сумма остатка',
  SALE_PATTERN: 'Для продаж',
  CANCEL: 'Отмена',
  CHECK_BALANCE: 'Проверить баланс',
  CLEAR: 'Очистить',
  COLOR: 'Цвет',
  CONTACT_EMAIL: 'Контактный e-mail',
  COMMENT: 'Примечание',
  COMPANY_NAME: 'Название',
  COMPANY_USERS: 'Филиалы',
  COMPANY_USER_PENDING: 'Филиал ожидает подтверждения',
  COMPLETE: 'Завершить',
  CREDIT: 'Кредит',
  DEFAULT: 'По умолчанию',
  DEBIT: 'Дебет',
  DECLINE: 'Отказать',
  DECLINED: 'Отказано',
  DELETE_COMPANY_MESSAGE: 'Вы уверены, что хотите удалить компанию?',
  DESCRIPTION: 'Описание',
  DOCUMENT: 'Документ',
  EMPTY_FORMED_SALE_ITEMS: 'Нет оформленных продаж с выбранным товаром',
  END_DATE: 'Дата окончания',
  EXPECTED_BALANCE: 'Ожидаемый остаток',
  EXTERNAL_USER: 'Сотрудничество с пользователем подтверждено',
  FILTERS: 'Фильтры',
  FORM_NEW: 'Оформить новые',
  FORM_SALE: 'Оформить проадажу',
  FORMED_SUM: 'Оформлено на сумму',
  LEAVE_COMPANY: 'Выйти из компании',
  MY_STORE: 'Мой склад',
  NEXT: 'Далее',
  NOT_ORDERED: 'Не заказан',
  NOT_ENOUGH: 'Не хватает',
  ORDER_NEW_OR_UPDATE: 'Вы хотите оформить новые заказы поставщику или дополнить (если возможно) текущие?',
  ORDERED: 'Заказан',
  OVER_ORDERED: 'Заказан с запасом',
  PARTLY_ORDERED: 'Частично заказан',
  PERSONAL: 'Персональные',
  PHONE: 'Телефон',
  PHONE_NUMBER: 'Номер телефона',
  PREORDER: 'Предзаказ',
  PREPAYMENT_AMOUNT: 'Сумма предоплаты',
  PRICE_FORMATION_TYPE: 'Ценообразование',
  RELOAD: 'Перезагрузить',
  READY: 'Готово',
  SEND_REQUEST: 'Отправить запрос',
  SEND_TO_CLIENT: 'Отправить клиенту',
  STATE: 'Статус',
  SYNC_PRODUCTS_IF_WANT_TO_ADD_TO_STORE: 'Чтобы переместить заказ на скад, все товары должны быть в вашем ассортименте',
  UPDATE_CURRENT: 'Дополнить текущие',
  UPDATE_PRODUCT_LIST: 'Обновить ассортимент',
  OF: 'из',
  VENDOR: 'Продавец',
  LABEL: {
    EXCEL_FILE: 'Excel файл',
    TYPES: 'Типы',
  },
  HOLDER: {
    EMPTY: 'Введите текст...',
  },
  CLOSE: 'Закрыть',
  BUTTON: {
    TITLE: {
      ADD_ALL: 'Добавить всё',
      REMOVE: 'Удалить',
    },
    ADD: 'Добавить',
    ADD_TO_STORE: 'Добавить на склад',
    APPLY: 'Применить',
    CANCEL: 'Отменить',
    CHANGE: 'Изменить',
    UPDATE: 'Редактировать',
    CLOSE: 'Закрыть',
    DELETE: 'Удалить',
    IMPORT: 'Импортировать',
    NO: 'Нет',
    REFILL: 'Пополнить',
    SALE_CREATE: 'Добавить',
    SALE_EDIT: 'Изменить',
    SAVE: 'Сохранить',
    TAKE_OFF: 'Списать',
    PRINT: 'Печать',
    SELL: 'Продать',
    COMPLETED: 'Продано',
    YES: 'Да',
    LOADING: {
      SAVE: 'Сохранение...',
      DELETE: 'Удаление...',
    },
  },
  COMPLETED: 'Завершено',
  COPY: 'Копировать',
  EDIT: 'Редактировать',
  EXPORT: 'Экспортировать',
  FORMED: 'Оформлено',
  IMPORT_PRODUCTS: 'Импорт товаров',
  IMPORTING_PRODUCT_TITLE: 'Товар синхронизируется с поставщиком',
  INCOMING: 'Входящие',
  INCOME: 'Доход',
  INVALID_VALUE: 'Неверное значение',
  TAB: {
    CLIENTS: 'Клиенты',
    PATTERNS: 'Шаблоны',
    PRODUCTS: 'Товары',
    REPORTS: 'Отчёты',
    STORE: 'Склад',
    SUPPLIERS: 'Поставщики',
    SUPPLIER_ORDERS: 'Заказы',
    SALES: 'Продажи',
    COMPANY: 'Компания',
    VENDORS: 'Продавцы',
  },
  LOGIN_FORM: {
    REQUEST_PROCESSING: 'Запрос обрабатывается.',
    PLEASE_CONFIRM_EMAIL: 'Пожалуйста подтвердите вашу почту по указанному адресу:',
    BUTTON: {
      SIGN_IN: 'Войти',
      REGISTER: 'Регистрация',
      CONFIRM_REGISTER: 'Зарегистрироваться',
      RESTORE: 'Восстановить',
      BACK: 'Назад',
    },
    LABEL: {
      NAME: 'Имя',
      LAST_NAME: 'Фамилия',
      NEW_PASSWORD: 'Новый пароль',
      USERNAME: 'E-mail',
      PASSWORD: 'Пароль',
      CONFIRM_PASSWORD: 'Подтвердите пароль',
      FORGET_PASSWORD: 'Забыли пароль?',
    },
  },
  MARKUP_BY_DEFAULT: 'Наценка (по умолчанию), %',
  MY_COMPANY: 'Моя компания',
  MY_SALES: 'Мои продажи',
  REPORTS: {
    REPORT_TYPE: 'Тип отчёта: ',
    VENDOR: 'Продавец: ',
    FILTERS: 'Фильтры',
    FROM: 'С: ',
    UNTIL: 'До: ',
  },
  NOT_SAVED_DATA: 'Данные не сохранены!',
  SALES: {
    FORM: {
      ADD_PRODUCT_TO_ORDER: 'Товар для покупки: ',
      COMMON_COUNT: 'Общее количество: ',
      COMMON_SUM: 'Общая сумма: ',
      COUNT: 'Количество: ',
      CUSTOMER: 'Клиент: ',
      MARKUP: 'Наценка: ',
      PATTERNS: 'Шаблоны',
      PRICE: 'Цена: ',
      PROFIT: 'Прибыль: ',
      SUM: 'Сумма: ',
      SUM_VAT: 'Сумма НДС: ',
    },
    BALANCE: 'на складе / остаток / добавлено',
    STATE: {
      FORMED: 'FORMED',
      COMPLETED: 'COMPLETED',
    },
  },
  COLUMN: {
    HEADER: {
      N: '№',
      ALL_FORMED_LEFT: 'всего / оформлено / остаток',
      COUNT: 'Кол-во',
      CATEGORY: 'Категория',
      CLIENT: 'Клиент',
      DAYS_UNTIL: 'Дней до просрочки',
      FIO: 'ФИО',
      FIRST_NAME: 'Имя',
      LAST_NAME: 'Фамилия',
      SECOND_NAME: 'Отчество',
      EMAIL: 'E-mail',
      MANUFACTURER: 'Производитель',
      MARKUP: 'Наценка, %',
      NAME: 'Наименование',
      PHONE_NUMBER: 'Номер телефона',
      PRICE: 'Цена',
      PRICE_NO_VAT: 'Цена (без НДС)',
      PRICE_WITH_VAT: 'Цена c НДС',
      FORMING_DATE: 'Дата оформления',
      PRODUCING_DATE: 'Дата производства',
      RECEIVING_DATE: 'Дата получения',
      SALE_DATE: 'Дата продажи',
      SALE_TIME: 'Время продажи',
      SHELF_LIFE: 'Срок годности',
      SUPPLIER: 'Поставщик',
      UNIT: 'Ед. изм.',
      SUPPLIER_PRICE: 'Цена поставщика',
      STATE: 'Статус',
      SUBCATEGORY: 'Подкатегория',
      SUM_NO_VAT: 'Сумма (без НДС)',
      SUM: 'Сумма',
      SUM_WITH_VAT: 'Сумма с НДС',
      TYPE: 'Тип',
      VAT: 'НДС, %',
      VAT_SUM: 'Сумма НДС',
      VENDOR: 'Продавец',
      PRODUCT: 'Товар',
    },
  },
  TABLE: {
    HEADER: {
      PURCHASE: 'Покупка',
      SALES: 'Продажи',
      SUPPLIERS: 'Поставщики',
      SUPPLIER_ORDERS: 'Заказы поставщику',
      SUPPLIER_ORDER_ITEMS: 'Товары заказа поставщику',
    },
    NEXT: 'Следующая',
    PREVIOUS: 'Предыдущая',
    LOADING: 'Загрузка',
    NO_DATA: 'Нет данных',
    PAGE: 'Страница',
    OF: 'из',
    ROWS: 'строк',
  },
  CLIENT_FORM: {
    HEADER: 'Добавление покупателя',
  },
  ERROR: {
    DUPLICATED_EMAIL: 'Данный e-mail уже используется. Воспользуйтесь восстановлением пароля для доступа к аккаунту.',
  },
  PATTERN: {
    BUTTON_TITLE: {
      ADD_BUTTON_ENABLED: 'Добавить шаблон',
      DELETE_BUTTON_ENABLED: 'Удалить шаблон',
      DELETE_BUTTON_DISABLED: 'Шаблон не выбран',
    },
    BUTTON: {
    },
    DELETE_MODAL: {
      HEADER: 'Удаление шаблона клиента',
      MESSAGE: 'Вы уверены, что хотите удалить шаблон "{0}"?',
    },
    FORM: {
      ADD_PATTERN: 'Добавление шаблона',
      CLIENT: 'Клиент',
      PRODUCT: 'Товар',
      NAME: 'Название',
    },
    TYPE: {
      SUPPLIER_ORDER: 'Заказ поставщику',
      CLIENT_SALE: 'Продажа клиенту',
    },
  },
  PATTERN_ITEM: {
    ADD_BUTTON_ENABLED: 'Добавить товары в шаблон',
    ADD_BUTTON_DISABLED: 'Шаблон не выбран',
    DELETE_BUTTON_DISABLED: 'Шаблон не выбран',
    DELETE_BUTTON_ENABLED: 'Удалить товар из шаблона',
    FORM: {
      ADD_PATTERN_ITEM: 'Добавление товара в шаблон',
    },
    DELETE_MODAL: {
      HEADER: 'Удаление товара из шаблона клиента',
      MESSAGE: 'Вы уверены, что хотите удалить товар из шаблона "{0}"?',
    },
  },
  SALE_FORM: {
    HEADER: 'Оформление покупки',
  },
  STORE_SETTINGS: 'Настройки склада',
  SUPPLIER_FORM: {
    HEADER: {
      ADD: 'Добавление поставщика',
      EDIT: 'Изменение поставщика',
    },
  },
  SUPPLIER_ORDER: {
    FORM: {
      HEADER: 'Редактирование заказа поставщику',
    },
  },
  SUPPLIER_ORDER_FORM: {
    HEADER: 'Добавление заказа поставщику',
  },
  PRODUCT_FORM: {
    HEADER: 'Добавление товара',
    MARKUP: 'Наценка',
    VAT: 'НДС',
  },
  HEADER: {
    ADD_CLIENT: 'Добавление клиента',
    CREATE_COMPANY: 'Создание компании',
    CREATE_PRODUCT: 'Создание товара',
    UPDATE_COMPANY: 'Редактирование компании',
    UPDATE_PRODUCT: 'Редактирование товара',
    DELETE_COMPANY: 'Удаление компании',
    DATA_IMPORT: 'Импорт данных',
    GROUP_SELECTION: 'Групповое редактирование',
    ADDITIONAL_DATA: 'Дополнительная информация',
    PERSONAL_DATA: 'Личные данные',
    SETTINGS: 'Настройки',
    EXIT: 'Выход',
  },
  ORDER: 'Заказать',
  OUTCOMING: 'Исходящие',
  OTHERS: 'Другие',
  RECONCILIATION_ACT: 'Акт сверки',
  REGISTRATION: 'Регистрация',
  RESTITUTION: 'Возврат',
  RESTITUTIONS: 'Возвраты',
  SEARCH: 'Поиск',
  SEARCH_COMPANY: 'Поиск компании',
  SELECT: {
    HOLDER: '- Не выбрано -',
    NO_OPTIONS: 'Нет вариантов',
    EMPTY_LIST: 'Список пуст',
    EMPTY_STORE: 'Склад пуст',
  },
  TURNOVER: 'Оборот',
  LOADING: 'Загрузка...',
  NAME: 'Имя',
  NEW_PASSWORD_MESSAGE: 'Теперь вы можете выбрать пароль и выполнить вход.',
  NO_RECORDS: 'Нет записей',
  PENDING: 'Ожидается',
  PENDING_APPROVAL: 'Ожидается подтверждение',
  OPEN_YOUTUBE: 'Открыть YouTube',
  ORGANIZATION: 'Организация',
  RECEIVED: 'Доставлено',
  RESTITUTION_DATE: 'Дата возврата',
  SENT: 'Отправлено',
  START_DATE: 'Дата начала',
  UNP: 'УНП',
  RETAIL: 'Розничная',
  YOU: 'Вы',
  WHOLESALE: 'Оптовая',
};

export default TEXT;
