import React, {
  FC, memo, useCallback, useContext,
} from 'react';
import { Box, InputAdornment, TextField } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import ReplayIcon from '@material-ui/icons/Replay';
import IconButton from '@/components/atoms/IconButton';
import { TEXT } from '@/utils/Text';
import useStyles from './style';

interface Props {
  onClick(event: React.MouseEvent): void;
  onChange(file: File): void;
  fullWidth?: boolean;
  error?: boolean;
  label: string;
  required?: boolean;
  loading: boolean;
  file: File | null;
  limitSize: number;
}

const CustomExcelInput: FC<Props> = ({
  fullWidth,
  onChange,
  file,
  onClick,
  label,
  limitSize,
  loading,
}) => {
  const classes = useStyles();
  const context = useContext(SnackbarContext);

  const handleChange = useCallback((event) => {
    const { files } = event.target;
    if (files.length !== 1) {
      context({ message: MESSAGE.CHOOSE_ONE_FILE, variant: 'warning' });
    } else {
      const selectedFile = files[0];
      if (selectedFile.size > limitSize) {
        context({ message: MESSAGE.MAX_FILE_LIMIT, variant: 'warning' });
      } else {
        onChange(selectedFile);
      }
    }
  }, [context, onChange, limitSize]);

  return (
    <Box
      mb={2}
      display="flex"
      className={`${classes.container} ${fullWidth ? 'full-width' : ''}`}
    >
      <input
        onChange={handleChange}
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <label htmlFor="raised-button-file" className={classes.title}>
        <Button
          disabled={loading}
          startIcon={<FolderOpenIcon color="primary" />}
          variant="outlined"
          component="span"
          onClick={onClick}
        >
          {label}
        </Button>
      </label>
      <TextField
        fullWidth
        placeholder="Файл не выбран"
        value={file?.name || ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                title={TEXT.RELOAD}
                disabled={loading || !file}
                onClick={() => {
                  if (file instanceof File) {
                    onChange(file);
                  }
                }}
              >
                <ReplayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
export default memo(CustomExcelInput);
