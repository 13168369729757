import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  input: {
    fontSize: '16px',
    textAlign: 'center',
    border: 'none',
    borderBottom: 'solid 1px black',
    height: '32px',
    paddingTop: '12px',
  },
}));
