import React, { FC } from 'react';
import { Box, ListItem } from '@material-ui/core';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import HelpIconTooltip from '@/components/atoms/HelpIconTooltip';
import TooltipLine from '@/components/organisms/ProductImportModal/TooltipLine';
import { TEXT } from '@/utils/Text';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import IconButton from '@/components/atoms/IconButton';
import { Delete } from '@material-ui/icons';
import BigList from '@/components/molecules/BigList';
import { Product } from '@/interfaces/ProductInfo';
import useStyles from '@/components/organisms/ProductImportModal/style';

interface Props {
  products: Product[];
  onDelete(product: Product): void;
}

const ProductList: FC<Props> = ({
  products,
  onDelete,
}) => {
  const classes = useStyles();

  return (
    <BigList
      maxHeight={200}
    >
      {products.map((product, arrIndex) => (
        <ListItem
          className={classes.itemContainer}
          key={product.id || arrIndex}
        >
          <ListItemIcon>
            <HelpIconTooltip
              title={(
                <Box display="flex" flexDirection="column">
                  <TooltipLine text={product.name} header={TEXT.COLUMN.HEADER.NAME} />
                  <TooltipLine
                    text={product.supplierPrice}
                    header={TEXT.COLUMN.HEADER.SUPPLIER_PRICE}
                  />
                  <TooltipLine text={product.vat} header={TEXT.COLUMN.HEADER.VAT} />
                  <TooltipLine text={product.price} header={TEXT.COLUMN.HEADER.PRICE} />
                  <TooltipLine text={product.category} header={TEXT.COLUMN.HEADER.CATEGORY} />
                </Box>
            )}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className={classes.item}
            primary={product.name}
          />
          <ListItemIcon>
            <IconButton
              title={TEXT.BUTTON.DELETE}
              onClick={() => onDelete(product)}
            >
              <Delete />
            </IconButton>
          </ListItemIcon>
        </ListItem>
      ))}
    </BigList>
  );
};

export default ProductList;
