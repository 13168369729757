import React, { FC, memo } from 'react';
import cx from 'classnames';
import DatePicker from '@/components/organisms/StyledTable/DatePicker/DatePicker';
import { useStyles } from '@/components/molecules/CustomAutocomplete/tableInputStyle';

const CustomDatePickerInput: FC = ({
  fullWidth,
  warning,
  error,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${fullWidth ? 'full-width' : ''}`}>
      <DatePicker
        {...other}
        fullWidth={fullWidth}
        error={error}
        rootClasses={cx({
				  [classes.warning]: warning,
				  [classes.error]: !warning && error,
        })}
      />
    </div>
  );
};

export default memo(CustomDatePickerInput);
