import { Component } from 'react';
import moment from 'moment';
import { dateFormat, dateTimeFormat } from '@/app/applicationSettings';

export const getDateTimeNow = () => (
  moment(Date.now()).format(dateTimeFormat)
);

export const getDateNow = () => (
  moment(Date.now()).format(dateFormat)
);

export function clearQueryParams() {
  window.history.replaceState({}, '', '/');
}

export function isUndefined(value) {
  return typeof value === 'undefined';
}

export function round(number, precision = 2) {
  if (!number) return number;
  return Number(Number(number).toFixed(precision));
}

/**
 * @param string should be DD.MM.YYYY format
 */
export function toDate(string) {
  const parts = string.split('/');
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

export function toDateFromDateTimeStr(string) {
  const parts = string.split(' ');
  const date = parts[0];
  const time = parts[1];
  const dateParts = date.split('/');
  if (time) {
    const timeParts = time.split(':');
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2]);
  }
  return toDate(date);
}

export function currentDate() {
  return moment(Date.now()).format(dateFormat);
}

export function dateDifferenceInDays(since, until) {
  return (until - since) / 1000 / 60 / 60 / 24;
}

export const KB = 1024;
export const MB = KB * 1024;

class Utils extends Component {
  static DOMAIN = 'http://localhost:8080';

  static defaultDateFormat = 'ru-Ru';

  static formatDate(date, format) {
    if (!format) {
      format = Utils.defaultDateFormat;
    }
    return new Intl.DateTimeFormat(format).format(date);
  }

  static clone(object) {
    const result = {};
    for (const key in object) {
      result[key] = object[key];
    }
    return result;
  }

  static insertRecord(list, item) {
    const index = list.findIndex((sourceItem) => sourceItem.id === item.id);
    if (index > -1) {
      list[index] = item;
    } else {
      list.unshift(item);
    }
    return list;
  }

  static calculateTextWidth(text, fontSize) {
    const div = document.createElement('div');
    div.textContent = text;
    div.style.fontSize = `${fontSize}px`;
    document.body.appendChild(div);
    const textLength = div.clientWidth;
    document.body.removeChild(div);
    return textLength;
  }
}

export default Utils;
