import { connect } from 'react-redux';
import { USER } from '@/constants/action';
import * as ActionCreators from '@/redux/actions/actionCreator';

const mapStateToProps = (state, ownProps) => ({
  user: state.application[USER],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchSaveUser: (user) => dispatch(ActionCreators.saveUser(user)),
});

export const PersonalDataContainer = (component) => connect(mapStateToProps, mapDispatchToProps)(component);
