import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  not_ordered: {
    background: '#981818',
    color: 'white',
  },
  ordered: {
    background: '#135a02',
    color: 'white',
  },
  over_ordered: {
    background: '#135a02',
    color: 'white',
  },
  partly_ordered: {
    background: '#ebd847',
  },
  root: {
    minHeight: 35,
    height: 'auto',
    minWidth: 166,
    borderRadius: '25px',
    fontSize: 15,
    padding: 5,
  },
}));
