import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { SlaChip as SlaChipProps } from '@/components/atoms/SupplierOrderStateChip/supplierOrderStateChip.types';
import { useSlaChipStyles } from '@/components/atoms/SupplierOrderStateChip/supplierOrderStateChip.styles';
import { SupplierOrderState } from '@/enum/SupplierOrderState';
import { TEXT } from '@/utils/Text';

export const renderSupplierOrderStateText = (state: SupplierOrderState) => {
  switch (state) {
    case SupplierOrderState.APPROVED:
      return TEXT.APPROVED;
    case SupplierOrderState.DECLINED:
      return TEXT.DECLINED;
    case SupplierOrderState.FORMED:
      return TEXT.FORMED;
    case SupplierOrderState.PENDING:
      return TEXT.PENDING;
    case SupplierOrderState.SENT:
      return TEXT.SENT;
    case SupplierOrderState.RECEIVED:
      return TEXT.RECEIVED;
    default:
      return '';
  }
};

const SupplierOrderStateChip: React.FC<SlaChipProps> = ({
  state = SupplierOrderState.FORMED,
}: SlaChipProps) => {
  const classes = useSlaChipStyles();

  return (
    <Box
      className="full-width"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      px={1}
    >
      <Chip
        classes={{ root: classes.root }}
        className={classes[state.toLowerCase()]}
        label={renderSupplierOrderStateText(state)}
      />
    </Box>
  );
};

export default React.memo(SupplierOrderStateChip);
