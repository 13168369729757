import CustomizedMenu from '@/components/atoms/CustomizedMenu/CustomizedMenu';
import React, { useCallback, useContext } from 'react';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import { TEXT } from '@/utils/Text';
import { Sync } from '@material-ui/icons';
import { putSyncSupplierProducts } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import format from 'string-format';
import { SupplierProductsImportResponse } from '@/interfaces/response/SupplierProductsImportResponse';

function SupplierRowContextMenu({
  rowAnchor,
  onClose,
  rowData,
  setLoading,
}) {
  const context = useContext(SnackbarContext);

  const handleImportProductsClick = useCallback(() => {
    const { id } = rowData;
    onClose();
    setLoading(true);
    putSyncSupplierProducts(id)
      .then((response: SupplierProductsImportResponse) => {
        context({
          message: format(MESSAGE.SUCCESS_SUPPLIER_PRODUCTS_IMPORT, response.length),
          variant: 'success',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onClose, rowData, setLoading, context]);

  return (
    <CustomizedMenu
      onClose={onClose}
      anchorEl={rowAnchor}
    >
      <MenuItem
        onClick={handleImportProductsClick}
        disabled={!rowData.external}
      >
        <ListItemIcon>
          <Sync color="primary" />
        </ListItemIcon>
        <ListItemText primary={TEXT.IMPORT_PRODUCTS} />
      </MenuItem>
    </CustomizedMenu>
  );
}

export default SupplierRowContextMenu;
