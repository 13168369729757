import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@/components/atoms/Divider/Divider';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@/components/atoms/IconButton';
import cx from 'classnames';
import Box from '@material-ui/core/Box';
import styles from './modalWindowStyles';

class ModalWindow extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { isOpen, onOpen } = this.props;
    if (isOpen && typeof onOpen === 'function') {
      onOpen();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { isOpen, onOpen } = this.props;
    if (!prevProps.isOpen && isOpen && typeof onOpen === 'function') {
      onOpen();
    }
  }

  render() {
    const {
      isOpen,
      onOpen,
      wrapperClassName,
      classes,
      header,
      onClose,
      loading,
      disableBackdropClick,
      hideCloseIcon,
      ...other
    } = this.props;
    return (
      <Modal
        onClick={(event) => {
          event.stopPropagation();
        }}
        onContextMenu={(event) => {
          event.stopPropagation();
        }}
        open={isOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        className={classes.modal}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick={disableBackdropClick || loading || false}
        onClose={onClose}
        {...other}
      >
        <Fade in={isOpen}>
          <div
            className={cx(wrapperClassName, classes.paper)}
          >
            <Box className={classes.closeBtnWrapper}>
              {!hideCloseIcon && (
              <IconButton
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
              )}
            </Box>
            {!!header && (
              <>
                <Box component="span" px={2} className="form-header">
                  {header}
                </Box>
                <Divider horizontal />
              </>
            )}
            {this.props.children}
            {/* <OverlayLoader loading={true}/> */}
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withStyles(styles)(ModalWindow);
