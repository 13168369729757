import ColumnName from '@/enum/ColumnName';

export const DEFAULT_CHECKED_COLUMN = [
  ColumnName.NAME,
  ColumnName.MANUFACTURER,
  ColumnName.SUPPLIER_NAME,
  ColumnName.CATEGORY,
  ColumnName.PRICE,
  ColumnName.COUNT,
  ColumnName.SUM,
];

export const DEFAULT_COLUMN_CLIENT_PATTERN_ITEMS = [
  ColumnName.NAME,
  ColumnName.MANUFACTURER,
  ColumnName.CATEGORY,
  ColumnName.PRICE,
];

export const DEFAULT_COLUMN_SUPPLIER_ORDER_ITEMS = [
  ColumnName.NAME,
  ColumnName.UNIT,
  ColumnName.MANUFACTURER,
  ColumnName.COUNT,
  ColumnName.PRICE_NO_VAT,
  ColumnName.SUM_NO_VAT,
  ColumnName.VAT,
  ColumnName.PRICE,
  ColumnName.SUM,
  ColumnName.PRODUCING_DATE,
];

export const DEFAULT_COLUMN_STORE_ITEMS = [
  ColumnName.NAME,
  ColumnName.MANUFACTURER,
  ColumnName.SUPPLIER_NAME,
  ColumnName.CATEGORY,
  ColumnName.UNIT,
  ColumnName.STORED_COUNT,
  ColumnName.RESERVED_COUNT,
  ColumnName.LEFT_COUNT,
  ColumnName.COUNT_IN_TRANSIT,
  ColumnName.SUPPLIER_PRICE,
  ColumnName.PRODUCING_DATE,
  ColumnName.RECEIVING_DATE,
  ColumnName.SHELF_LIFE,
];

export const DEFAULT_COLUMN_PRODUCT_ITEMS = [
  ColumnName.NAME,
  ColumnName.MANUFACTURER,
  ColumnName.SUPPLIER_NAME,
  ColumnName.UNIT,
  ColumnName.CATEGORY,
  ColumnName.SHELF_LIFE,
  ColumnName.SUPPLIER_PRICE,
  ColumnName.PRICE_NO_VAT,
  ColumnName.VAT,
  ColumnName.PRICE,
];
