import React, {
  FC, memo, useCallback, useContext, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { TEXT } from '@/utils/Text';
import { Box } from '@material-ui/core';
import { postAttribute } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import {
  AddAttributeDialogProps,
  ValidationErrors,
} from '@/components/organisms/AddAttributeModal/addAttributeDialog.types';
import { Attribute, AttributeBase } from '@/interfaces/Attribute';
import { AttributeType } from '@/enum/AttributeType';
import ColorInput from '@/components/atoms/ColorInput';
import tinycolor from 'tinycolor2';
import Button from '@/components/atoms/Button';

const AddAttributeModal: FC<AddAttributeDialogProps> = ({
  isOpened,
  onClose,
  onSave,
}) => {
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const [color, setColor] = useState('');
  const [name, setName] = useState('');

  const handleNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handleColorChange = useCallback((color) => {
    setColor(color);
  }, []);

  const validate = useCallback((attribute) => {
    const errors: ValidationErrors = {};
    if (!attribute.name) {
      errors.name = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    if (!attribute.color) {
      errors.color = MESSAGE.ERROR_REQUIRED_FIELD;
    } else if (!tinycolor(attribute.color).isValid()) {
      errors.color = MESSAGE.INVALID_VALUE;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const handleSave = useCallback(() => {
    const data: AttributeBase = {
      name,
      color,
      type: AttributeType.SALE,
    };
    if (!validate(data)) {
      return;
    }
    setLoading(true);
    postAttribute(data)
      .then((response: Attribute) => {
        if (typeof onSave === 'function') {
          onSave(response);
        }
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        onClose();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    onClose,
    onSave,
    validate,
    name,
    color,
    context,
    errors,
  ]);

  return (
    <ModalWindow
      isOpen={isOpened}
      onClose={onClose}
      header={TEXT.ADD_ATTRIBUTE}
    >
      <Box m={2} minWidth={300}>
        <Box pb={2}>
          <CustomInput
            id="name"
            label={TEXT.COLUMN.HEADER.NAME}
            required
            fullWidth
            value={name}
            onChange={handleNameChange}
            error={!!errors.name && errors.name}
            maxLength={40}
          />
        </Box>
        <Box pb={2}>
          <ColorInput
            id="color"
            required
            color={color}
            onChange={handleColorChange}
            error={!!errors.color && errors.color}
          />
        </Box>
      </Box>
      <Box className="flex-center">
        <Button
          color="primary"
          disabled={loading}
          onClick={handleSave}
        >
          {TEXT.BUTTON.SAVE}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default memo(AddAttributeModal);
