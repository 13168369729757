import { TEXT } from '@/utils/Text';
import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

interface MarkupInputProps {
  error?: string;
}

type MarkupProps = MarkupInputProps & TextFieldProps;

const MarkupInput: React.FC<MarkupProps> = ({
  label = TEXT.COLUMN.HEADER.MARKUP,
  error,
  ...other
}) => (
  <CustomNumberInput
    {...other}
    error={error}
    id="markup"
    label={label}
    step={1}
    decimalScale={2}
    suffix=" %"
		/>
);

export default MarkupInput;
