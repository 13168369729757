import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  prepaymentInput: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    border: 'none',
    height: '22px',
  },
}));
