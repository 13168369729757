import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { Column } from 'material-table';

const useFirstNameColumn = (
  error?,
): Column<any> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomInput
      error={error}
      id="firstName"
      maxLength={40}
      label={TEXT.COLUMN.HEADER.FIRST_NAME}
      required
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error]);

  return useMemo(() => ({
    removable: false,
    title: TEXT.COLUMN.HEADER.FIRST_NAME,
    defaultSort: 'asc',
    field: 'firstName',
    editComponent: handleEditComponent,
  }), [handleEditComponent]);
};

export default useFirstNameColumn;
