import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import Utils from '@/utils/Utils';
import IconButton from '@/components/atoms/IconButton';
import { Clear } from '@material-ui/icons';
import { TEXT } from '@/utils/Text';
import { EditableDocumentInputProps } from '@/components/molecules/EditableDocumentInput/editableDocumentInput.types';

const EditableDocumentInput: FC<EditableDocumentInputProps> = ({
  initialValue = '',
  onChange,
  width,
  editing,
  label,
  placeholder = '_______',
  clearable,
  className = 'document-input',
  hidePlaceholder,
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [valueWidth, setValueWidth] = useState(0);

  useEffect(() => {
    setValue(initialValue);
    setValueWidth(Utils.calculateTextWidth(initialValue, 12));
  }, [initialValue]);

  const handleChange = useCallback(({ target: { value } }) => {
    setValue(value);
    setValueWidth(Utils.calculateTextWidth(value, 12));
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }, [onChange]);

  return (
    <div className="d-flex">
      {label && (
        <span className="mr-10 document-label">{label}</span>
      )}
      {editing
        ? (
          <>
            <input
              className={className}
              type="text"
              value={value}
              onChange={handleChange}
              width={width || valueWidth || Utils.calculateTextWidth(placeholder, 12)}
              style={{
                maxWidth: 800,
              }}
            />
            {clearable && (
              <IconButton
                title={TEXT.BUTTON.TITLE.REMOVE}
                size="small"
                onClick={() => handleChange({ target: { value: '' } })}
                edge="end"
              >
                <Clear
                  color="primary"
                />
              </IconButton>
            )}
          </>
        )
        : (
          <span className="editable-input">
            {value || (hidePlaceholder ? '' : placeholder)}
          </span>
        )}
    </div>
  );
};

export default EditableDocumentInput;
