import React from 'react';
import './App.css';
import 'react-dropdown/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/vi4-6.png';
import '../assets/vi4.png';
import theme from '@/style/theme';
import SnackBarRenderer from '@/components/organisms/SnackBarRenderer/SnackBarRenderer';
import ErrorBoundary from '@/app/ErrorBoundary';
import { Route, Switch } from 'react-router-dom';
import Path from '@/enum/Path';
import LoaderPage from '@/components/pages/LoaderPage';
import LoginPage from '@/components/pages/LoginPage';
import VendorPage from '@/components/pages/WorkPage';
import { ThemeProvider } from '@material-ui/core/styles';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackBarRenderer>
        <ErrorBoundary>
          <Switch>
            <Route
              path={Path.LOADING}
            >
              <LoaderPage />
            </Route>
            <Route
              path={Path.LOGIN}
            >
              <LoginPage />
            </Route>
            <Route
              exact
              path={Path.INDEX}
            >
              <VendorPage />
            </Route>
          </Switch>
        </ErrorBoundary>
      </SnackBarRenderer>
    </ThemeProvider>
  );
}

export default App;
