import { Role } from '@/enum/Role.ts';
import { RoleAction, RoleActionType } from '@/redux/actions/rolesActions';

export function rolesReducer(
  roles: Array<Role> = [],
  { type, payload }: RoleAction<Array<Role> | boolean>,
): Array<Role> {
  switch (type) {
    case RoleActionType.SET_ROLES:
      if (payload instanceof Array) {
        return payload;
      }
      return roles;

    case RoleActionType.STOREKEEPER_ROLE_UPDATE:
      if (payload) {
        return [...roles, Role.STOREKEEPER];
      }
      return roles.filter((role) => role !== Role.STOREKEEPER);

    case RoleActionType.SUPPLIER_ROLE_UPDATE:
      if (payload) {
        return [...roles, Role.SUPPLIER];
      }
      return roles.filter((role) => role !== Role.SUPPLIER);

    default:
      return roles;
  }
}
