import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';

export enum SupplierPartnerRequestInfoActionType {
  SET = 'SET_SUPPLIER_PARTNER_REQUEST_INFO',
  UPDATE_ONE = 'UPDATE_ONE_SUPPLIER_PARTNER_REQUEST_INFO'
}

export function createUpdateSupplierPartnerRequestInfo(
  supplierPartnerRequestInfo: UserPartnerRequestInfo,
) {
  return {
    type: SupplierPartnerRequestInfoActionType.UPDATE_ONE,
    payload: supplierPartnerRequestInfo,
  };
}

export function createSetSupplierPartnerRequestInfosAction(
  supplierPartnerRequestInfos: Array<UserPartnerRequestInfo>,
) {
  return {
    type: SupplierPartnerRequestInfoActionType.SET,
    payload: supplierPartnerRequestInfos,
  };
}
