import React, {
  FC, memo, useCallback, useContext, useState,
} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import format from 'string-format';
import {
  Assignment, Email, Print, Send,
} from '@material-ui/icons';
import ReactToPrint from 'react-to-print';
import { getClientInfoById, postDocumentByEmail } from '@/utils/fetch';
import moment from 'moment';
import ApplicationContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import InputField from '@/components/atoms/TextField/InputField';
import IconButton from '@/components/atoms/IconButton';
import { validateEmail } from '@/service/validate';
import TEXT from '@/utils/Text';
import useStyles from './documentOutputMenu.styles';

interface Props {
  tableRef: any;
  selectedSale?: any;
  selectedDocument: any;
  disableEmail?: boolean;
  loading?: boolean;
  disabledCopyToClipboard?: boolean;
}

const DocumentOutputMenu: FC<Props> = ({
  tableRef,
  selectedSale,
  selectedDocument,
  disableEmail,
  loading,
  disabledCopyToClipboard,
}: Props) => {
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  const [emailModalOpen, setOpened] = useState<boolean>(false);
  const [stateLoading, setLoading] = useState<boolean>(false);

  const context = useContext(ApplicationContext);
  const isLoading = loading || stateLoading;

  const handleOpen = useCallback(() => {
    if (selectedSale && selectedSale.clientId) {
      setLoading(true);
      getClientInfoById(selectedSale.clientId)
        .then((clientInfo) => {
          setEmail(clientInfo.contactEmail);
        })
        .finally(() => setLoading(false));
    } else {
      setEmail('');
    }
  }, [selectedSale]);

  const handlePrintClick = useCallback(() => {
    window.print();
  }, []);

  const handleEmailClick = useCallback(() => {
    setOpened(true);
  }, []);

  const handleEmailSend = useCallback(() => {
    setLoading(true);
    const now = moment(Date.now()).format('DD_MM_YYYY HH_mm_ss');
    postDocumentByEmail(selectedDocument.id, selectedSale.id, email, now)
      .then(() => {
        context({
          message: format(MESSAGE.SUCCESS_EMAIL_SENT, email),
          variant: 'success',
        });
      })
      .finally(() => setLoading(false));
    setOpened(false);
  }, [context, email, selectedDocument, selectedSale]);

  const handleChange = useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);

  const handleCopyToClipboard = useCallback(() => {
    // @ts-ignore
    if (!navigator.clipboard || typeof navigator.clipboard.write !== 'function') {
      context({
        message: format(MESSAGE.OPERATION_NOT_SUPPORTED_BY_BROWSER),
        variant: 'warning',
      });
      return;
    }
    // @ts-ignore
    navigator.clipboard.write([
      new ClipboardItem({ 'text/html': new Blob([tableRef.current.innerHTML], { type: 'text/html' }) }),
    ]);
    context({
      message: format(MESSAGE.SUCCESS_COPY_TO_CLIPBOARD),
      variant: 'success',
    });
  }, [context, tableRef]);

  return (
    <List className={classes.root}>
      <ReactToPrint
        trigger={() => (
          <ListItem
            disabled={!selectedDocument || isLoading}
            dense
            button
            onClick={handlePrintClick}
          >
            <ListItemIcon>
              <Print
                className={classes.icon}
                color="primary"
              />
            </ListItemIcon>
            <ListItemText
              title="Печать"
              className={classes.listItem}
              primary="Печать"
            />
          </ListItem>
        )}
        content={() => tableRef.current}
      />
      {!disableEmail && (
      <ListItem
        disabled={!selectedDocument || selectedDocument.isDefault || isLoading}
        dense
        button
        onClick={handleEmailClick}
      >
        <ListItemIcon>
          <Email
            className={classes.icon}
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          title="E-mail"
          className={classes.listItem}
          primary="E-mail"
        />
        <ModalWindow
          onOpen={handleOpen}
          isOpen={emailModalOpen}
          onClose={(event) => {
            event.stopPropagation();
            setOpened(false);
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          header="Отправка документа на e-mail"
        >
          <div className="d-flex m-10 align-items-center">
            <InputField
              fullWidth
              label="E-mail"
              required
              id="email"
              onChange={handleChange}
              value={email}
            />
            <IconButton
              id="searchName"
              className="ml-10"
              disabled={!validateEmail(email) || isLoading}
              onClick={handleEmailSend}
              edge="end"
              title="Отправить"
            >
              <Send
                className={classes.sendIcon}
                color="primary"
              />
            </IconButton>
          </div>
        </ModalWindow>
      </ListItem>
      )}
      <ListItem
        disabled={isLoading || disabledCopyToClipboard}
        dense
        button
        onClick={handleCopyToClipboard}
      >
        <ListItemIcon>
          <Assignment
            className={classes.icon}
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          title="E-mail"
          className={classes.listItem}
          primary={TEXT.COPY}
        />
      </ListItem>
    </List>
  );
};

export default memo(DocumentOutputMenu);
