import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';

export enum IncomingSupplierOrderInfoActions {
  SET = 'SET_INCOMING_SUPPLIER_ORDER_INFOS',
  UPDATE_ONE = 'UPDATE_ONE_INCOMING_SUPPLIER_ORDER_INFOS'
}

export function createUpdateIncomingSupplierOrderInfoAction(
  supplierOrderInfo: IncomingSupplierOrderInfo,
) {
  return {
    type: IncomingSupplierOrderInfoActions.UPDATE_ONE,
    payload: supplierOrderInfo,
  };
}

export function createSetIncomingSupplierOrderInfoAction(
  supplierInfos: Array<IncomingSupplierOrderInfo>,
) {
  return {
    type: IncomingSupplierOrderInfoActions.SET,
    payload: supplierInfos,
  };
}
