import { compareByField } from '@/utils/comparator';
import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import ProductInfoEditInput from '@/components/molecules/ProductInfoEditInput';
import { getProductInfoForOrderBySupplierId } from '@/utils/fetch';
import { productToOption } from '@/utils/toOption';
import { round } from '@/utils/Utils';
import { Box } from '@material-ui/core';
import StoreItemInfoOption from '@/components/molecules/StoreItemInfoOption/StoreItemInfoOption';
import ProductOptionLabel from '@/components/atoms/ProductOptionLabel';

const useProductForOrderColumn = (
  storeEnabled,
  supplierId,
  error,
) => {
  const loadOptions = useCallback(async (value) => {
    const response = await getProductInfoForOrderBySupplierId(supplierId, value);
    return [response.records.map((item) => productToOption(item)), response.totalElementsCount];
  }, [supplierId]);

  const renderOptionWithTooltip = useCallback(({ data: item }) => {
    const leftCount = round(item.storedCount - item.reservedCount, 4);
    const shouldShowBalanceDetails = storeEnabled && typeof item.storedCount === 'number';
    return (
      <Box className="flex full">
        <StoreItemInfoOption
          maxCount={leftCount}
          markupEnabled={false}
          interactive
          placement="right-start"
          item={item}
          storeEnabled={shouldShowBalanceDetails}
        >
          <ProductOptionLabel
            supplierName={item.supplierName}
            category={item.category}
            showCount={shouldShowBalanceDetails}
            count={leftCount}
            unitName={item.unitName}
            name={item.name}
            manufacturer={item.manufacturer}
          />
        </StoreItemInfoOption>
      </Box>
    );
  }, [storeEnabled]);

  const handleEditComponent = useCallback(({ rowData, onRowDataChange }) => (
    <ProductInfoEditInput
      renderOptionWithTooltip={renderOptionWithTooltip}
      rowData={rowData}
      onRowDataChange={onRowDataChange}
      error={error}
      loadOptions={loadOptions}
      priceFormationTypeId={null}
    />
  ), [loadOptions, error, renderOptionWithTooltip]);

  return useMemo(() => (
    {
      removable: false,
      customSort: compareByField('name'),
      customFilterAndSearch: (value, item) => item.name.toUpperCase().includes(value.toUpperCase()),
      title: TEXT.COLUMN.HEADER.NAME,
      field: 'productId',
      render: (item) => item.name,
      defaultSort: 'asc',
      editComponent: handleEditComponent,
    }
  ), [handleEditComponent]);
};

export default useProductForOrderColumn;
