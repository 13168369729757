import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { Supplier } from '@/interfaces/SupplierInfo';
import { Product } from '@/interfaces/ProductInfo';
import Unit from '@/model/unit/Unit';
import Button from '@/components/atoms/Button';
import { TEXT } from '@/utils/Text';
import { Box, Paper } from '@material-ui/core';
import { postProductsParsedFromExcel, postSupplier, postUnit } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import {
  SupplierInfoActions,
} from '@/redux/actions/supplierInfoActions';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { useDispatch } from 'react-redux';
import SnackbarContext from '@/app/snackbarContext';
import LinearLabeledProgress from '@/components/molecules/LinearLabeledProgress';
import arrayIntoChunks from '@/utils/arrayIntoChunks';
import ProductInfoActions from '@/redux/actions/ProductInfoInfoActions';
import useStyles from '../style';

interface Props {
  products: Product[];
  suppliers: Supplier[];
  units: Unit[];
  onClose(): void;
  onBack(): void;
  priceFormationTypeId: string | undefined;
}

const MAX_ENTITIES_PER_REQUEST = 200;

const ExcelImporterStep: FC<Props> = ({
  products,
  suppliers,
  units,
  onClose,
  onBack,
  priceFormationTypeId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    setLoading(true);

    let productsToSave = [...products];

    const productsRequests = Math.ceil(productsToSave.length / MAX_ENTITIES_PER_REQUEST);

    const totalRequests = suppliers.length + units.length + productsRequests;
    const oneRequestValue = 100 / totalRequests;

    const suppliersPromise = suppliers.reduce((previousPromise, supplier) => (
      previousPromise
        .then(() => postSupplier({ organization: supplier.name })
          .then((response) => {
            setProgressValue((prevState) => prevState + oneRequestValue);
            dispatch(SupplierInfoActions.add(response));
            productsToSave = productsToSave.map((product) => {
              if (product.supplierId === supplier.id) {
                return { ...product, supplierId: response.id };
              }
              return { ...product };
            });
            return Promise.resolve();
          }))
    ), Promise.resolve());

    const unitsPromise = units.reduce((previousPromise, unit) => (
      previousPromise
        .then(() => postUnit(unit)
          .then((response) => {
            setProgressValue((prevState) => prevState + oneRequestValue);
            dispatch(ActionCreators.saveUnit(response));
            productsToSave = productsToSave.map((product) => {
              if (product.unitId === unit.id) {
                return { ...product, unitId: response.id };
              }
              return { ...product };
            });
            return Promise.resolve();
          }))
    ), Promise.resolve());

    Promise.all([
      suppliersPromise,
      unitsPromise,
    ]).then(() => {
      const productChunks = arrayIntoChunks(productsToSave, MAX_ENTITIES_PER_REQUEST);
      productChunks.reduce((previousPromise, chunk, index) => (
        previousPromise
          .then(() => postProductsParsedFromExcel({
            products: chunk,
            priceFormationTypeId,
          }).then((response) => {
            setProgressValue((prevState) => prevState + oneRequestValue);
            if (index === productChunks.length - 1) {
              dispatch(ProductInfoActions.updateAll(response));
              context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
              setLoading(false);
            }
            return Promise.resolve();
          }))
      ), Promise.resolve());
    });
  }, [dispatch, context, products, suppliers, units, onClose, priceFormationTypeId]);

  return (
    <Paper className={classes.paperImport}>
      <Box width="100%" px={2} pt={5} pb={2}>
        <LinearLabeledProgress value={progressValue} />
      </Box>
      <Box className={classes.actionPanel}>
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={onBack}
        >
          {TEXT.BACK}
        </Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={onClose}
        >
          {TEXT.READY}
        </Button>
      </Box>
    </Paper>
  );
};

export default ExcelImporterStep;
