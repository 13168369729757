import React, {
  FC, useCallback, useContext, useEffect, useState,
} from 'react';
import { Box, Chip } from '@material-ui/core';
import { CompanyUserState } from '@/enum/CompanyUserState';
import { TEXT } from '@/utils/Text';
import ConnectRequestLink from '@/components/molecules/ConnectRequestLink/ConnectRequestLink';
import { deleteCompanyUser, getCompanyUserById } from '@/utils/fetch';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import { MESSAGE } from '@/utils/message';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContext from '@/app/snackbarContext';
import { getUserId } from '@/redux/selectors/applicationSelector';
import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { selectCompanyUserInfoByUserId } from '@/redux/selectors/companySelector';
import useClasses from './styles';

interface Props {
  companyInfo: CompanyInfo;
}

const MyCompanyCardUserBottomPanel: FC<Props> = ({
  companyInfo,
}) => {
  const dispatch = useDispatch();
  const classes = useClasses();
  const context = useContext(SnackbarContext);

  const userId = useSelector(getUserId);
  const [companyUserInfo, setCompanyUserInfo] = useState<CompanyUserInfo | null>(
    useSelector(selectCompanyUserInfoByUserId(userId)),
  );
  const [loading, setLoading] = useState(false);

  const state = companyUserInfo?.state;

  useEffect(() => {
    getCompanyUserById(userId)
      .then((response) => {
        setCompanyUserInfo(response);
      })
      .catch((reason) => {
        if (reason.status === 403) {
          dispatch(CompanyInfoActions.remove(companyInfo.id));
          return Promise.resolve();
        }
        return Promise.reject(reason);
      });
  }, [
    dispatch,
    userId,
    companyInfo.id,
  ]);

  const handleLeaveCompanyClick = useCallback(() => {
    setLoading(true);

    const body = {
      userId,
      companyId: companyInfo.id,
    };

    deleteCompanyUser(body)
      .then(({ id }) => {
        dispatch(CompanyInfoActions.remove(id));
        context({
          message: MESSAGE.SUCCESS_OPERATION,
          variant: 'success',
        });
      })
      .finally(() => setLoading(false));
  }, [companyInfo, context, dispatch, userId]);

  const statusClasses = clsx({
    [classes.green]: state === CompanyUserState.ACTIVE,
    [classes.yellow]: state === CompanyUserState.PENDING,
  }, classes.status);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <Chip
        className={statusClasses}
        size="medium"
        label={state === CompanyUserState.ACTIVE
          ? TEXT.ACTIVE
          : TEXT.PENDING_APPROVAL}
      />
      <ConnectRequestLink
        onClick={handleLeaveCompanyClick}
        disabled={loading}
        label={TEXT.LEAVE_COMPANY}
      />
    </Box>
  );
};
export default MyCompanyCardUserBottomPanel;
