import React, { FC, memo } from 'react';
import cx from 'classnames';
import NumberInput from '@/components/atoms/NumberInput/NumberInput';
import { useStyles } from '../../../molecules/CustomAutocomplete/tableInputStyle';

const CustomNumberInput: FC = ({
  fullWidth,
  inputProps,
  warning,
  error,
  width,
  disabled,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.container} ${fullWidth ? 'full-width' : ''}`}
      style={{ width }}
    >
      <NumberInput
        {...other}
        disabled={disabled}
        fullWidth={fullWidth}
        inputProps={inputProps}
        error={error}
        variant="standard"
        rootClasses={cx({
          [classes.warning]: warning,
          [classes.error]: !warning && error,
        })}
      />
    </div>
  );
};

export default memo(CustomNumberInput);
