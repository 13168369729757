import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import {
  deleteSupplierOrderItem,
  getSupplierOrderItems,
  postSupplierOrderItem,
  putCancelSendSupplierOrder,
  putPendingOrderToSupplier,
  putSupplierOrderItem,
} from '@/utils/fetch';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import format from 'string-format';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import Button from '@/components/atoms/Button';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomTableLoading, selectSupplierOrderItemTableColumns } from '@/redux/selectors/customTableSelector';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { SupplierOrderState } from '@/enum/SupplierOrderState';
import { SupplierOrderItemInfo } from '@/interfaces/SupplierOrderItemInfo';
import { Block, ShoppingCart } from '@material-ui/icons';
import SupplierOrderInfo from '@/interfaces/SupplierOrderInfo';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import useOrderItemColumns from '@/hook/columns/useOrderItemColumns';
import AddOrderToStoreButton from '@/components/organisms/SupplierOrderItemsTable/AddOrderToStoreButton';
import { DEFAULT_COLUMN_SUPPLIER_ORDER_ITEMS } from '@/constants/DefaultCheckedColumn';
import ExportIcon from '@/components/atoms/ExportIcon';

interface Props {
  selectedOrder: SupplierOrderInfo
}

function SupplierOrderItemsTable({
  selectedOrder,
}: Props) {
  const { supplierId, type, external } = selectedOrder;

  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [orderItems, setOrderItems] = useState<SupplierOrderItemInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectSupplierOrderItemTableColumns);
  const supplierInfos = useSelector(selectSupplierInfos);

  const supplierInfo = useMemo(() => (
    supplierInfos.find((item) => item.id === supplierId)
  ), [supplierInfos, supplierId]);

  const orderItemColumns = useOrderItemColumns(
    supplierId,
    columns,
    supplierInfo ? supplierInfo.storeEnabled : false,
    type,
    errors,
    external,
  );

  useEffect(() => {
    if (selectedOrder) {
      setLoading(true);
      getSupplierOrderItems(selectedOrder.id)
        .then((response) => {
          setOrderItems(response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOrderItems([]);
    }
  }, [selectedOrder]);

  const validateSupplierOrderItem = useCallback(({
    productId, supplierPrice, producingDate, count,
  }) => {
    const errors = {};
    if (!productId) {
      errors.productId = true;
    }
    if (!supplierPrice) {
      errors.supplierPrice = true;
    }
    if (!producingDate) {
      errors.producingDate = true;
    }
    if (!count) {
      errors.count = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateSupplierOrderItem(data)) {
      return reject(data);
    }
    setLoading(true);
    postSupplierOrderItem({ ...data, supplierPrice: data.priceNoVat, orderId: selectedOrder.id })
      .then(({ supplierOrderInfo, supplierOrderItemInfo }) => {
        dispatch(ActionCreators.saveSupplierOrder(supplierOrderInfo));
        setOrderItems([...orderItems, supplierOrderItemInfo]);
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [validateSupplierOrderItem, context, dispatch, errors, orderItems, selectedOrder]);

  const onUpdate = useCallback((data) => new Promise(
    (resolve, reject) => {
      if (!validateSupplierOrderItem(data)) {
        reject();
        return;
      }
      setLoading(true);
      putSupplierOrderItem({ ...data, supplierPrice: data.priceNoVat })
        .then(({ supplierOrderInfo, supplierOrderItemInfo }) => {
          dispatch(ActionCreators.saveSupplierOrder(supplierOrderInfo));
          setOrderItems(orderItems.map((item) => {
            if (item.id === supplierOrderItemInfo.id) {
              return supplierOrderItemInfo;
            }
            return item;
          }));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatch, errors, orderItems, validateSupplierOrderItem]);

  const onDelete = useCallback((oldData) => new Promise(
    (resolve, reject) => {
      setLoading(false);
      deleteSupplierOrderItem(selectedOrder.id, oldData.id)
        .then(({ supplierOrderInfo, deleteResponse }) => {
          dispatch(ActionCreators.saveSupplierOrder(supplierOrderInfo));
          setOrderItems(orderItems.filter((item) => item.id !== deleteResponse.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({ message: response.message, variant: 'warning' });
            return Promise.resolve();
          }
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch, orderItems, selectedOrder]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const globalLoading = customTableLoading || loading;

  const rightButtonBar = useMemo(() => {
    const { state, external: isExternal, id } = selectedOrder;

    const handleOrderPending = () => {
      setLoading(true);
      putPendingOrderToSupplier(id)
        .then((supplierOrderInfo: SupplierOrderInfo) => {
          context({
            message: format(MESSAGE.SUCCESS_SUPPLIER_ORDER_PENDING, supplierOrderInfo.supplierName),
            variant: 'success',
          });
          dispatch(ActionCreators.saveSupplierOrder(supplierOrderInfo));
        })
        .finally(() => setLoading(false));
    };

    const handleCancel = () => {
      setLoading(true);
      putCancelSendSupplierOrder(id)
        .then((supplierOrderInfo: SupplierOrderInfo) => {
          context({
            message: format(MESSAGE.SUCCESS_SUPPLIER_ORDER_CANCEL, supplierOrderInfo.supplierName),
            variant: 'success',
          });
          dispatch(ActionCreators.saveSupplierOrder(supplierOrderInfo));
        })
        .finally(() => setLoading(false));
    };

    const hasAnyItems = !!orderItems.length && !!orderItems.find((item) => !!item.count);

    const cancelAvailable = isExternal && (
      state === SupplierOrderState.PENDING || state === SupplierOrderState.DECLINED
    );

    return (
      <div className="right-button-bar">
        <ExportIcon
          items={orderItems}
          columnNames={DEFAULT_COLUMN_SUPPLIER_ORDER_ITEMS}
          disabled={!orderItems.length}
        />
        {isExternal && state === SupplierOrderState.FORMED && (
          <Button
            variant="outlined"
            text={TEXT.ORDER}
            onClick={handleOrderPending}
            disabled={state !== SupplierOrderState.FORMED || !hasAnyItems}
            startIcon={<ShoppingCart />}
          />
        )}
        {cancelAvailable && (
          <Button
            variant="outlined"
            text={TEXT.BUTTON.CANCEL}
            onClick={handleCancel}
            disabled={!cancelAvailable}
            startIcon={<Block />}
          />
        )}
        <AddOrderToStoreButton
          selectedOrder={selectedOrder}
          orderItems={orderItems}
        />
      </div>
    );
  }, [
    selectedOrder,
    orderItems,
    dispatch,
    context,
  ]);

  const memoizedEditable = useMemo(() => {
    const editable = selectedOrder && selectedOrder.state === SupplierOrderState.FORMED;
    return {
      onRowAdd: editable ? onAdd : null,
      onRowDelete: onDelete,
      onRowUpdate: onUpdate,
      isDeletable: () => editable,
      isEditable: () => editable,
    };
  }, [onAdd, selectedOrder, onDelete, onUpdate]);

  const memoizedOptions = useMemo(() => (
    {
      paging: false,
      minBodyHeight: null,
      maxBodyHeight: 800,
    }
  ), []);

  return (
    <div className="table-container">
      <StyledTable
        className="sub-table"
        tableKey={ID.SUPPLIER_ORDER_ITEM_TABLE}
        onRowEditing={onRowEditing}
        loading={globalLoading}
        rightButtonBar={rightButtonBar}
        editable={memoizedEditable}
        columns={orderItemColumns}
        data={orderItems}
        options={memoizedOptions}
      />
    </div>
  );
}

export default SupplierOrderItemsTable;
