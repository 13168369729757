import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { deleteSaleRestitution, getSaleRestitutions, putRestitution } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import useSaleTypeColumn from '@/hook/columns/useSaleTypeColumn';
import useClientColumn from '@/hook/columns/useClientColumn';
import useDocumentNameColumn from '@/hook/columns/useDocumentNameColumn';
import useSaleStateColumn from '@/hook/columns/useSaleStateColumn';
import useFormTimeColumn from '@/hook/columns/useFormTimeColumn';
import useSumColumn from '@/hook/columns/useSumColumn';
import useSaleTimeColumn from '@/hook/columns/useSaleTimeColumn';
import { RestitutionInfo } from '@/interfaces/RestitutionInfo';
import RestitutionItemTable from '@/components/organisms/RestitutionItemTable';
import { isCustomTableLoading, selectRestitutionTableColumns } from '@/redux/selectors/customTableSelector';
import {
  createDeleteRestitutionInfoById,
  createSetRestitutionInfosAction,
  createUpdateRestitutionInfo,
} from '@/redux/actions/restitutionInfoActions';
import { selectRestitutionInfos } from '@/redux/selectors/restitutionInfoSelector';
import useRestitutionTimeColumn from '@/hook/columns/useRestitutionTimeColumn';
import useCommentColumn from '@/hook/columns/useCommentColumn';
import { isSelfSelected, selectSelectedUserId } from '@/redux/selectors/companySelector';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import useVendorColumn from '@/hook/columns/useVendorColumn';

interface EditErrors {
  documentName?: string;
}

function RestitutionTable() {
  const context = useContext(ApplicationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<EditErrors>({});

  const restitutionInfos = useSelector(selectRestitutionInfos);
  const columns = useSelector(selectRestitutionTableColumns);
  const customTableLoading = useSelector(isCustomTableLoading);
  const isEditable = useSelector(isSelfSelected);
  const selectedUserId = useSelector(selectSelectedUserId);

  const saleTypeColumn = useSaleTypeColumn('never');
  const documentColumn = useDocumentNameColumn(columns.documentName, errors.documentName);
  const clientColumn = useClientColumn(columns.clientFirstName, null, 'never');
  const saleStateColumn = useSaleStateColumn();
  const formTimeColumn = useFormTimeColumn(columns.formTime);
  const sumColumn = useSumColumn(columns.sum);
  const saleTimeColumn = useSaleTimeColumn();
  const restitutionColumn = useRestitutionTimeColumn(columns.restitutionTime);
  const commentColumn = useCommentColumn(columns.comment);
  const vendorColumn = useVendorColumn(columns.vendorId, errors.vendorId);

  const isLoading = loading || customTableLoading;

  useEffect(() => {
    dispatch(CompanyUserInfoActions.setSelectionEnabled(true));
    return () => {
      dispatch(CompanyUserInfoActions.setSelectionEnabled(false));
    };
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    getSaleRestitutions(selectedUserId)
      .then((response) => {
        dispatch(createSetRestitutionInfosAction(response));
      })
      .finally(() => setLoading(false));
  }, [dispatch, selectedUserId]);

  const memoizedColumns = useMemo(() => ([
    saleStateColumn,
    saleTypeColumn,
    formTimeColumn,
    clientColumn,
    sumColumn,
    documentColumn,
    saleTimeColumn,
    restitutionColumn,
    commentColumn,
    vendorColumn,
  ]), [
    saleStateColumn,
    saleTypeColumn,
    clientColumn,
    documentColumn,
    formTimeColumn,
    sumColumn,
    saleTimeColumn,
    restitutionColumn,
    commentColumn,
    vendorColumn,
  ]);

  const onUpdate = useCallback(({
    id, documentName, comment, vendorId,
  }) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      putRestitution({
        id, documentName, comment, vendorId,
      })
        .then((response) => {
          dispatch(createUpdateRestitutionInfo(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [errors, context, dispatch]);

  const onDelete = useCallback((item) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteSaleRestitution(item.id)
        .then((response) => {
          dispatch(createDeleteRestitutionInfoById(response.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({ message: response.message, variant: 'warning' });
            return undefined;
          }
          return Promise.reject(response);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatch]);

  const detailPanel = useCallback((rowData: RestitutionInfo) => {
    if (!rowData || !rowData.id) {
      return null;
    }
    return (
      <RestitutionItemTable
        restitution={rowData}
      />
    );
  }, []);

  const editable = useMemo(() => (isEditable
    ? {
      onRowDelete: onDelete,
      onRowUpdate: onUpdate,
    }
    : {}
  ), [onUpdate, onDelete, isEditable]);

  return (
    <div className="table-container">
      <StyledTable
        tableKey={ID.RESTITUTION_TABLE}
        editable={editable}
        loading={isLoading}
        columns={memoizedColumns}
        data={restitutionInfos}
        detailPanel={detailPanel}
      />
    </div>
  );
}

export default RestitutionTable;
