import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RequestState } from '@/enum/RequestState';
import UserCard from '@/components/molecules/UserCard/UserCard';
import ConnectRequestLink from '@/components/molecules/ConnectRequestLink/ConnectRequestLink';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { putToggleRequestConfirmationBySenderId } from '@/utils/fetch';
import { Box } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import { createUpdateClientRequestInfo } from '@/redux/actions/clientRequestInfoActions';
import { Cancel, Home } from '@material-ui/icons';

interface ClientCardProps {
  clientRequestInfo: UserPartnerRequestInfo
}

function ClientCard({
  clientRequestInfo,
}: ClientCardProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    id,
    phoneNumber,
    contactEmail,
    address,
    organization,
    description,
    requestState,
  } = clientRequestInfo;

  const handleConfirmation = useCallback(() => {
    setLoading(true);
    putToggleRequestConfirmationBySenderId(id, true)
      .then((response: UserPartnerRequestInfo) => {
        dispatch(createUpdateClientRequestInfo(response));
      })
      .finally(() => setLoading(false));
  }, [dispatch, id]);

  const handleDenial = useCallback(() => {
    setLoading(true);
    putToggleRequestConfirmationBySenderId(id, false)
      .then((response: UserPartnerRequestInfo) => {
        dispatch(createUpdateClientRequestInfo(response));
      })
      .finally(() => setLoading(false));
  }, [dispatch, id]);

  function getLinkLabel() {
    switch (requestState) {
      case RequestState.CONFIRMED:
        return 'Запрос подтверждён';
      case RequestState.PENDING:
        return 'Клиент ожидает вашего подтверждения';
      case RequestState.DECLINED:
        return 'Вы отклонили запрос';
      default:
        return 'Запрос подтверждён';
    }
  }

  return (
    <UserCard
      address={address}
      phoneNumber={phoneNumber}
      organization={organization}
      description={description}
      contactEmail={contactEmail}
      bottom={(
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className="full-width"
        >
          {requestState !== RequestState.PENDING && (
          <Box className="full-width" justifyContent="center" display="flex">
            <ConnectRequestLink
              disabled
              onClick={handleConfirmation}
              state={requestState}
              label={getLinkLabel()}
            />
          </Box>
          )}
          {requestState === RequestState.PENDING && (
          <Box
            mt={2}
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
            className="full-width"
          >
            <Button
              variant="outlined"
              startIcon={<Home color="primary" />}
              disabled={loading}
              onClick={handleConfirmation}
              text={'Добавить в "Мои клиенты"'}
              size="small"
            />

            <Button
              variant="outlined"
              size="small"
              startIcon={<Cancel color="primary" />}
              disabled={loading}
              onClick={handleDenial}
              text="Отклонить"
            />

          </Box>
          )}
        </Box>
  )}
    />
  );
}

export default ClientCard;
