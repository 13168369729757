import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import { Column } from 'material-table';
import { compareByField } from '@/utils/comparator';
import VendorEditComponent from '@/components/molecules/VendorEditComponent';

const useVendorColumn = (
  column?,
  error?,
): Column<any> => {
  const handleEditComponent = useCallback(({ onRowDataChange, rowData }) => (
    <VendorEditComponent
      error={error}
      rowData={rowData}
      onRowDataChange={onRowDataChange}
    />
  ), [error]);

  const handleRender = useCallback((rowData) => rowData.vendorName, []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.VENDOR,
    customFilterAndSearch: (value, item) => (
      item.vendorName
        ? item.vendorName.toUpperCase().includes(value.toUpperCase())
        : false
    ),
    customSort: compareByField('vendorName'),
    defaultSort: 'asc',
    render: handleRender,
    field: 'vendorId',
    editComponent: handleEditComponent,
  }), [handleRender, handleEditComponent, column]);
};

export default useVendorColumn;
