import { TEXT } from '@/utils/Text';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { Column } from 'material-table';
import { Box, Tooltip } from '@material-ui/core';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import { HowToReg } from '@material-ui/icons';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';

const useOrganizationColumn = (
  error?,
  fullWidth?,
  cellStyle = {} as CSSProperties,
): Column<any> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomInput
      fullWidth={fullWidth}
      error={error}
      id="organization"
      label={`${TEXT.ORGANIZATION} / ${TEXT.NAME}`}
      maxLength={40}
      required
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error, fullWidth]);

  const memoizedCellStyles = useMemo(() => ({
    ...cellStyle,
    width: fullWidth ? '100%' : cellStyle.width,
  }), [cellStyle, fullWidth]);

  const handleRender = useCallback((rowData) => (
    <Box
      display="flex"
      alignItems="center"
    >
      {!!rowData.external && (
      <Tooltip
        title={TEXT.EXTERNAL_USER}
        arrow
      >
        <ListItemIcon>
          <HowToReg fontSize="small" color="primary" />
        </ListItemIcon>
      </Tooltip>
      )}
      <ListItemText primary={rowData.organization} />
    </Box>
  ), []);

  return useMemo(() => ({
    cellStyle: memoizedCellStyles,
    removable: false,
    render: handleRender,
    title: `${TEXT.ORGANIZATION} / ${TEXT.NAME}`,
    field: 'organization',
    defaultSort: 'asc',
    editComponent: handleEditComponent,
  }), [handleEditComponent, memoizedCellStyles, handleRender]);
};

export default useOrganizationColumn;
