import React, {
  FC, useCallback, useState,
} from 'react';
import AttributeAutocomplete from '@/components/molecules/AttributeAutocomplete';
import { AttributeOptionI, attributeToOption } from '@/utils/toOption';
import { Attribute } from '@/interfaces/Attribute';

interface Props {
  value: Attribute[];
  onChange(attributes: Attribute[]): void;
  addButtonEnabled?: boolean;
}

const AttributeEditComponent: FC<Props> = ({
  value,
  onChange,
  addButtonEnabled,
}) => {
  const [selectedValue, setSelectedValue] = useState(value ? value.map(attributeToOption) : []);

  const handleChange = useCallback((options: AttributeOptionI[]) => {
    setSelectedValue(options);
    onChange(options.map((option) => option.data));
  }, [onChange]);

  return (
    <AttributeAutocomplete
      addButtonEnabled={addButtonEnabled}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

export default AttributeEditComponent;
