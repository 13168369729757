import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiIconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';

const IconButton: FC<IconButtonProps> = ({
  children,
  className,
  title = '',
  disabled,
  edge,
  onClick,
  onMouseDown,
  size,
  color,
  id,
  classes,
}) => (
  <Tooltip
    arrow
    title={disabled ? '' : title}
  >
    <MuiIconButton
      id={id}
      classes={classes}
      color={color}
      size={size}
      disabled={disabled}
      className={`${className || ''}`}
      edge={edge}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {children}
    </MuiIconButton>
  </Tooltip>
);

export default withStyles(() => ({
  root: {
    padding: '4px',
  },
}))(IconButton);
