import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import ProductAccordion
  from '@/components/organisms/ProductImportModal/ExcelParseResult/ProductAccordion';
import BigList from '@/components/molecules/BigList';
import { Box, ListItem, Paper } from '@material-ui/core';
import { Product } from '@/interfaces/ProductInfo';
import { Supplier } from '@/interfaces/SupplierInfo';
import Unit from '@/model/unit/Unit';
import { ExcelImportError } from '@/interfaces/ExcelImportError';
import AccordionResultItems
  from '@/components/organisms/ProductImportModal/ExcelParseResult/AccordionResultItems';
import SupplierAccordion
  from '@/components/organisms/ProductImportModal/ExcelParseResult/SupplierAccordion';
import ExcelParseResultSkeleton
  from '@/components/organisms/ProductImportModal/ExcelParseResult/ExcelParseResultSkeleton';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import HelpIconTooltip from '@/components/atoms/HelpIconTooltip';
import TooltipLine from '@/components/organisms/ProductImportModal/TooltipLine';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import Button from '@/components/atoms/Button';
import { TEXT } from '@/utils/Text';
import useClasses from '../style';

interface Props {
  loading: boolean;
  products: Product[];
  suppliers: Supplier[];
  units: Unit[];
  errors: ExcelImportError[];
  onNext(): void;
  onBack(): void;
  onDelete(product: Product): void;
}

const ExcelParseResult: FC<Props> = ({
  errors,
  products,
  units,
  suppliers,
  loading,
  onDelete,
  onBack,
  onNext,
}) => {
  const classes = useClasses();

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleExpanded = useCallback((index) => {
    setSelectedIndex(index);
  }, []);

  useEffect(() => {
    if (!errors.length && products.length) {
      setSelectedIndex(0);
    }
  }, [errors, products]);

  useEffect(() => {
    if (errors.length) {
      setSelectedIndex(3);
    }
  }, [errors]);

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column">
        {!loading ? (
          <Box className={classes.paperContent}>
            <ProductAccordion
              onDelete={onDelete}
              onChange={handleExpanded}
              index={0}
              selectedIndex={selectedIndex}
              products={products}
            />
            <SupplierAccordion
              suppliers={suppliers}
              onChange={handleExpanded}
              index={1}
              selectedIndex={selectedIndex}
            />
            <AccordionResultItems
              disabled={!units.length}
              header={`Единиц измерения (${units.length})`}
              onChange={handleExpanded}
              selectedIndex={selectedIndex}
              index={2}
            >
              <Box className={classes.expandablePanel}>
                {units.map((unit, index) => (
                  <ListItem
                    className={classes.itemContainer}
                    key={unit.id || index}
                  >
                    <ListItemText
                      disableTypography
                      className={classes.item}
                      primary={unit.name}
                    />
                  </ListItem>
                ))}
              </Box>
            </AccordionResultItems>
            <AccordionResultItems
              disabled={!errors.length}
              header={`Ошибки (${errors.length})`}
              onChange={handleExpanded}
              selectedIndex={selectedIndex}
              index={3}
            >
              <Box className={classes.expandablePanel}>
                <BigList
                  maxHeight={200}
                >
                  {errors.map(({ row, description, field }, index) => (
                    <ListItem
                      className={classes.itemContainer}
                      key={index}
                    >
                      <ListItemIcon>
                        <HelpIconTooltip
                          title={(
                            <Box display="flex" flexDirection="column">
                              <TooltipLine
                                text={description}
                                header={`Строка ${row}`}
                              />
                              {field && (
                                <TooltipLine
                                  text={field}
                                  header="Значение"
                                />
                              )}
                            </Box>
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        className={classes.item}
                        primary={`Строка ${row}: ${description.toLowerCase()}`}
                      />
                    </ListItem>
                  ))}
                </BigList>
              </Box>
            </AccordionResultItems>
          </Box>
        ) : <ExcelParseResultSkeleton />}

        <Box className={classes.actionPanel}>
          <Button
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={onBack}
          >
            {TEXT.BACK}
          </Button>
          <Button
            color="primary"
            disabled={loading || !products.length}
            onClick={onNext}
          >
            {TEXT.BUTTON.IMPORT}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default ExcelParseResult;
