import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  select: {
    minWidth: '180px',
    paddingLeft: '8px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderBottom: '1px solid white',
    '&:after': {
      borderBottom: '1px solid white',
    },
  },
  icon: {
    fill: 'white',
  },
  item: {
    paddingLeft: '30px',
  },
  popper: {
    top: '-40px !important',
  },
}));
