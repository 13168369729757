import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import SupplierOrderInfo from '@/interfaces/SupplierOrderInfo';
import { compareDate, compareDateTime } from '@/utils/comparator';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteSupplierOrderById, getSupplierOrderInfos, postSupplierOrder, putSupplierOrder,
} from '@/utils/fetch';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import { supplierToOption } from '@/utils/toOption';
import tableStyles from '@/components/organisms/StyledTable/styledTableStyles';
import ID from '@/utils/id';
import SupplierOrderItemsTable from '@/components/organisms/SupplierOrderItemsTable/SupplierOrderItemsTable';
import { buildCurrencyHeader } from '@/app/applicationSettings';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { SUPPLIER_ORDERS } from '@/constants/action';
import { useDispatch, useSelector } from 'react-redux';
import { selectSupplierOrders } from '@/redux/selectors/applicationSelector';
import { isCustomTableLoading, selectSupplierOrderTableColumns } from '@/redux/selectors/customTableSelector';
import { SupplierInfo } from '@/interfaces/SupplierInfo';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import SupplierOrderStateChip, { renderSupplierOrderStateText } from '@/components/atoms/SupplierOrderStateChip';
import { SupplierOrderState } from '@/enum/SupplierOrderState';
import useSaleTypeColumn from '@/hook/columns/useSaleTypeColumn';
import SupplierOrderRowContextMenu from '@/components/organisms/SupplierOrderRowContextMenu';
import { getDateTimeNow } from '@/utils/Utils';
import { PaginatedResponse } from '@/interfaces/PaginatedResponse';
import useCommentColumn from '@/hook/columns/useCommentColumn';
import { Tooltip } from '@material-ui/core';
import Typography from '@/components/atoms/Typography';

function SupplierOrderTable() {
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [rowAnchor, setRowAnchor] = useState(null);
  const [contextMenuRowData, setContextMenuRowData] = useState(null);

  const suppliers: SupplierInfo[] = useSelector(selectSupplierInfos);
  const columns = useSelector(selectSupplierOrderTableColumns);
  const supplierOrders: SupplierOrderInfo[] = useSelector(selectSupplierOrders);
  const customTableLoading = useSelector(isCustomTableLoading);

  const saleTypeColumn = useSaleTypeColumn();
  const commentColumn = useCommentColumn(columns.comment);

  useEffect(() => {
    setLoading(true);
    getSupplierOrderInfos()
      .then((response: PaginatedResponse<SupplierOrderInfo>) => {
        dispatch(ActionCreators.setSupplierOrders(response.records));
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const supplierOptions = useMemo(() => suppliers.map(supplierToOption), [suppliers]);

  const memoizedColumns = useMemo(() => (
    [
      {
        removable: false,
        title: TEXT.COLUMN.HEADER.FORMING_DATE,
        field: 'formingDate',
        width: 200,
        customSort: (a, b) => compareDateTime(a.formingDate, b.formingDate),
        defaultSort: 'desc',
        editable: 'never',
      },
      {
        removable: false,
        title: TEXT.COLUMN.HEADER.SUPPLIER,
        field: 'supplierId',
        cellStyle: tableStyles.cell.h1,
        editable: 'onAdd',
        customFilterAndSearch: (value, item) => (
          item.supplierName
            ? item.supplierName.toUpperCase().includes(value.toUpperCase())
            : true
        ),
        render: ({ supplierName }) => (
          <Tooltip title={supplierName}>
            <Typography variant="ellipsis">
              {supplierName}
            </Typography>
          </Tooltip>
        ),
        editComponent: ({ rowData, onRowDataChange }) => (
          <InputSelect
            error={errors.supplierId}
            id="supplierId"
            required
            label={TEXT.COLUMN.HEADER.SUPPLIER}
            defaultOptions={supplierOptions}
            value={supplierOptions.find((supplierOption) => (
              rowData.supplierId === supplierOption.value
            ))}
            onChange={(option) => (
              onRowDataChange({
                ...rowData,
                supplierId: option ? option.value : null,
                external: option ? option.data.external : false,
              })
            )}
          />
        ),
      },
      saleTypeColumn,
      {
        removable: false,
        title: TEXT.COLUMN.HEADER.STATE,
        field: 'state',
        customFilterAndSearch: (value, item) => renderSupplierOrderStateText(item.state)
          .toUpperCase()
          .includes(value.toUpperCase()),
        render: (item) => (
          <SupplierOrderStateChip
            state={item ? item.state : undefined}
          />
        ),
        defaultSort: 'asc',
        editable: 'never',
      },
      {
        hidden: columns.count ? columns.count.hidden : false,
        width: 80,
        title: TEXT.COLUMN.HEADER.COUNT,
        field: 'count',
        editable: 'never',
      },
      {
        hidden: columns.sum ? columns.sum.hidden : false,
        title: buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM_WITH_VAT),
        field: 'sum',
        editable: 'never',
      },
      {
        hidden: columns.receivingDate ? columns.receivingDate.hidden : false,
        title: TEXT.COLUMN.HEADER.RECEIVING_DATE,
        field: 'receivingDate',
        customSort: (a, b) => compareDate(a.receivingDate, b.receivingDate),
        defaultSort: 'desc',
        editable: 'never',
        width: 200,
      },
      commentColumn,
    ]
  ), [supplierOptions, saleTypeColumn, errors, columns, commentColumn]);

  const validateSupplierOrder = ({ supplierId }) => {
    const errors = {};
    if (!supplierId) {
      errors.supplierId = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onDelete = useCallback((item) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteSupplierOrderById(item.id)
        .then((response) => {
          dispatch(ActionCreators.deleteRecord(response.id, SUPPLIER_ORDERS));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({ message: response.message, variant: 'warning' });
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch]);

  const onUpdate = useCallback((data) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      const { id, comment } = data;
      putSupplierOrder({ id, comment })
        .then((response) => {
          dispatch(ActionCreators.saveSupplierOrder(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch, errors]);

  const onAdd = useCallback((data) => new Promise(
    (resolve, reject) => {
      if (!validateSupplierOrder(data)) {
        return reject(data);
      }
      setLoading(true);
      postSupplierOrder({ ...data, formingDate: getDateTimeNow() })
        .then((response) => {
          dispatch(ActionCreators.saveSupplierOrder(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch, errors]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const memoizedEditable = useMemo(() => (
    {
      onRowUpdate: onUpdate,
      onRowAdd: onAdd,
      onRowDelete: onDelete,
      isDeletable: (rowData) => rowData.state === SupplierOrderState.FORMED,
    }
  ), [onDelete, onAdd, onUpdate]);

  const detailPanel = useCallback((rowData) => (
    <SupplierOrderItemsTable
      selectedOrder={rowData}
    />
  ), []);

  const handleRowContextMenu = useCallback((event, rowData) => {
    setRowAnchor(event.target);
    setContextMenuRowData(rowData);
  }, []);

  const handleRowContextMenuClose = useCallback(() => {
    setRowAnchor(null);
    setContextMenuRowData(null);
  }, []);

  return (
    <div className="table-container">
      <StyledTable
        onRowContextMenu={handleRowContextMenu}
        tableKey={ID.SUPPLIER_ORDER_TABLE}
        onRowEditing={onRowEditing}
        columns={memoizedColumns}
        data={supplierOrders}
        editable={memoizedEditable}
        detailPanel={detailPanel}
        loading={loading || customTableLoading}
      />
      {!!contextMenuRowData && (
        <SupplierOrderRowContextMenu
          setLoading={setLoading}
          onClose={handleRowContextMenuClose}
          rowAnchor={rowAnchor}
          rowData={contextMenuRowData}
        />
      )}
    </div>
  );
}

export default SupplierOrderTable;
