import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import SaleStateChip, { renderSaleStateText } from '@/components/atoms/SaleStateChip';
import { SaleState } from '@/enum/SaleState';

const useSaleStateColumn = (
) => {
  const handleRender = useCallback((item) => (
    <SaleStateChip
      state={item ? item.state : SaleState.FORMED}
    />
  ), []);

  return useMemo(() => ({
    width: 120,
    removable: false,
    title: TEXT.COLUMN.HEADER.STATE,
    field: 'state',
    render: handleRender,
    editable: 'never',
    customFilterAndSearch: (value, item) => renderSaleStateText(item.state)
      .toUpperCase()
      .includes(value.toUpperCase()),
  }), [handleRender]);
};

export default useSaleStateColumn;
