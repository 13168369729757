import { useMemo } from 'react';

function useQuery() {
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useParamQuery() {
  const query = useQuery();

  return useMemo(() => ({
    token: query.get('token'),
    email: query.get('email'),
  }), [query]);
}

export default useQuery;
