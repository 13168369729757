import StoreItemInfo from '@/interfaces/StoreItemInfo';
import { StoreItemActions } from '@/redux/actions/storeItemActions';

export function storeItemReducer(
  storeItemInfos: StoreItemInfo[] = [],
  { type, payload },
): Array<StoreItemInfo> {
  switch (type) {
    case StoreItemActions.DELETE:
      return storeItemInfos.filter((item) => item.id !== payload);
    case StoreItemActions.SET:
      return payload;
    case StoreItemActions.UPDATE_ONE:
      const index = storeItemInfos.findIndex(
        (supplierOrderInfo) => supplierOrderInfo.id === payload.id,
      );
      if (index > -1) {
        const newInfos = [...storeItemInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return [payload, ...storeItemInfos];

    default:
      return storeItemInfos;
  }
}
