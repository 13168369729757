import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  status: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '30px 0',
  },
  green: {
    background: '#108d15',
    color: 'white',
  },
  yellow: {
    background: '#ebd847',
    color: '#4e4a4a',
  },
}));
