import React, { forwardRef, PropsWithChildren } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import MuiTypography, { TypographyProps } from '@material-ui/core/Typography';
import typographyStyle from './style';

const Typography = forwardRef((props: PropsWithChildren<TypographyProps>, ref) => {
  const {
    classes,
    variant,
    children,
    className,
    ...rest
  } = props;
  const txtComponent = cx({
    [classes[variant]]: variant,
  });
  return (
    <MuiTypography
      {...rest}
      className={`${className} ${txtComponent}`}
      ref={ref}
    >
      {children}
    </MuiTypography>
  );
});

export default withStyles(typographyStyle)(Typography);
