import React, { useCallback, useState } from 'react';
import { TEXT } from '@/utils/Text';
import { registration } from '@/utils/fetch';
import { TextField } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import ErrorType from '@/enum/ErrorType';
import Path from '@/enum/Path';
import EmailConfirmingText from '@/app/EmailConfirmingText';
import RegExpPattern from '@/constants/RegExp';
import { useHistory } from 'react-router';

function RegistrationForm() {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [thankYouEnabled, setThankYouEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const onRegister = useCallback(() => {
    setLoading(true);
    setErrors({});
    registration({ email, name })
      .then(() => {
        setThankYouEnabled(true);
      })
      .catch((error) => {
        if (error.error === ErrorType.DUPLICATED_EMAIL) {
          setMessage(TEXT.ERROR.DUPLICATED_EMAIL);
          return Promise.resolve();
        }
        if (error && error.field) {
          setErrors((state) => ({
            ...state,
            [error.field]: error.message,
          }));
          return Promise.resolve();
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email, name]);

  const onUsernameChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const onRegistrationCancel = useCallback(() => {
    history.push(Path.LOGIN);
  }, [history]);

  return (
    <div className="">
      {thankYouEnabled
        ? (
          <div className="login-form">
            <EmailConfirmingText email={email} />
            <div className="login-page-btn-bar">
              <Button
                className="login-page-btn"
                onClick={onRegistrationCancel}
              >
                {TEXT.LOGIN_FORM.BUTTON.SIGN_IN}
              </Button>
            </div>
          </div>
        )
        : (
          <div className="login-form">
            <div className="login-page-top-container">
              <TextField
                required
                label={TEXT.LOGIN_FORM.LABEL.USERNAME}
                className={!email || !RegExpPattern.E_MAIL.test(email) ? 'not-filled-input' : ''}
                type="e-mail"
                onChange={onUsernameChange}
                name="username"
                maxLength={40}
                helperText={errors.email}
                error={!!errors.email}
                value={email}
              />
              <TextField
                required
                label={TEXT.LOGIN_FORM.LABEL.NAME}
                className={!name ? 'not-filled-input' : ''}
                maxLength={40}
                onChange={onNameChange}
                name="name"
                helperText={errors.name}
                error={!!errors.name}
                value={name}
              />
            </div>
            {message ? <span className="invalid-input-message">{message}</span> : ''}
            <div className="login-page-btn-bar">
              <Button
                onClick={onRegister}
                disabled={loading || !email || !RegExpPattern.E_MAIL.test(email) || !name}
                className="login-page-btn"
              >
                {TEXT.LOGIN_FORM.BUTTON.CONFIRM_REGISTER}
              </Button>
              <Button
                className="login-page-btn"
                onClick={onRegistrationCancel}
              >
                {TEXT.LOGIN_FORM.BUTTON.BACK}
              </Button>
            </div>
          </div>
        )}
    </div>
  );
}

export default RegistrationForm;
