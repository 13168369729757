import React, { FC, useCallback, useState } from 'react';
import ProductTable from '@/components/organisms/ProductTable/ProductTable';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import HomeIcon from '@material-ui/icons/Home';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import PriceFormationTypeTable from '@/components/organisms/PriceFormationTypeTable';
import { LocalAtm, Money } from '@material-ui/icons';

const ProductTab:FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Мои товары"
          icon={<HomeIcon fontSize="small" />}
          selectedIndex={selectedTabIndex}
          index={0}
          onChange={handleChange}
        />
        <StyledBreadcrumb
          icon={<LocalAtm fontSize="small" />}
          label="Ценообразование"
          selectedIndex={selectedTabIndex}
          index={1}
          onChange={handleChange}
        />
      </Breadcrumbs>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <ProductTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <PriceFormationTypeTable />
      </SubTabPanel>
    </div>
  );
};

export default ProductTab;
