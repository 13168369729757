export const isLeftArrow = (code) => code === 37;

export const isRightArrow = (code) => code === 39;

export const isUpArrow = (code) => code === 38;

export const isDownArrow = (code) => code === 40;

export const doIfEnter = (code, action) => {
  if (code === 13 && typeof action === 'function') {
    action();
  }
};
