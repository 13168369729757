class HttpClient {
  static DOMAIN = '';

  static SECURE = '/secure';

  static URL = {
    LOGIN: `${HttpClient.DOMAIN}/api/v1/login`,
    LOGOUT: `${HttpClient.DOMAIN}/logout`,
    REGISTER: `${HttpClient.DOMAIN}/register`,
    CUSTOM_TABLE: `${HttpClient.DOMAIN + HttpClient.SECURE}/customTable`,
    CLIENT: `${HttpClient.DOMAIN + HttpClient.SECURE}/client`,
    CLIENT_PAGE: `${HttpClient.DOMAIN + HttpClient.SECURE}/client` + '/page',
    PRODUCT: `${HttpClient.DOMAIN + HttpClient.SECURE}/product`,
    PRODUCT_GROUP: `${HttpClient.DOMAIN + HttpClient.SECURE}/product/group`,
    EXCEL_PRODUCT: `${HttpClient.DOMAIN + HttpClient.SECURE}/excel/product`,
    PRODUCT_EXCEL: `${HttpClient.DOMAIN + HttpClient.SECURE}/product/excel`,
    STORE: `${HttpClient.DOMAIN + HttpClient.SECURE}/store`,
    STORE_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/store/item`,
    STORE_ITEM_INFO: `${HttpClient.DOMAIN + HttpClient.SECURE}/storeItemInfo`,
    USER: `${HttpClient.DOMAIN + HttpClient.SECURE}/user`,

    // supplier
    SUPPLIER: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplier`,
    SUPPLIER_ORDER: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplier` + '/order',
    SUPPLIER_ORDER_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplier` + '/order' + '/item',
    SUPPLIER_ORDER_RECEIVE: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplier` + '/order' + '/receive',
    // sale
    SALE: `${HttpClient.DOMAIN + HttpClient.SECURE}/sale`,
    SALE_COMPLETE: `${HttpClient.DOMAIN + HttpClient.SECURE}/sale/complete`,
    SALE_COPY: `${HttpClient.DOMAIN + HttpClient.SECURE}/sale/copy`,

    // saleInfo
    SALE_INFO: `${HttpClient.DOMAIN + HttpClient.SECURE}/saleInfo`,

    SALE_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/saleItem`,

    // saleItemInfo
    SALE_ITEM_INFO: `${HttpClient.DOMAIN + HttpClient.SECURE}/saleItemInfo`,
    SALE_ITEM_INFO_PATTERN: `${HttpClient.DOMAIN + HttpClient.SECURE}/saleItemInfo/pattern`,

    SALE_DISTRIBUTION: `${HttpClient.DOMAIN + HttpClient.SECURE}/saleDistribution`,

    // RESTITUTION
    RESTITUTION: `${HttpClient.DOMAIN + HttpClient.SECURE}/restitution`,

    // patterns
    CLIENT_PATTERN: `${HttpClient.DOMAIN + HttpClient.SECURE}/clientPattern`,
    CLIENT_PATTERN_INFO: `${HttpClient.DOMAIN + HttpClient.SECURE}/clientPatternInfo`,
    CLIENT_PATTERN_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/clientPatternItem`,
    SUPPLIER_PATTERN: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplierPattern`,
    SUPPLIER_PATTERN_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/supplierPatternItem`,

    // document
    DOCUMENT: `${HttpClient.DOMAIN + HttpClient.SECURE}/document`,
    SEND_CUSTOM_DOCUMENT: `${HttpClient.DOMAIN + HttpClient.SECURE}/document` + '/sendCustom',
    CUSTOM_DOCUMENT: `${HttpClient.DOMAIN + HttpClient.SECURE}/document` + '/custom',
    DOCUMENT_SETTINGS: `${HttpClient.DOMAIN + HttpClient.SECURE}/documentSettings`,

    // unit
    UNIT: `${HttpClient.DOMAIN + HttpClient.SECURE}/unit`,

    // company
    COMPANY: `${HttpClient.DOMAIN + HttpClient.SECURE}/company`,
    COMPANY_USER: `${HttpClient.DOMAIN + HttpClient.SECURE}/company/user`,

    ROLE_TOGGLE: `${HttpClient.DOMAIN + HttpClient.SECURE}/role/toggle`,

    REQUEST_SUPPLIER: `${HttpClient.DOMAIN + HttpClient.SECURE}/request/receiver`,
    REQUEST_CLIENT: `${HttpClient.DOMAIN + HttpClient.SECURE}/request/sender`,

    // attribute
    ATTRIBUTE: `${HttpClient.DOMAIN + HttpClient.SECURE}/attribute`,

    // price formation type
    PRICE_FORMATION_TYPE: `${HttpClient.DOMAIN + HttpClient.SECURE}/price-formation-type`,

    PREORDER_ITEM: `${HttpClient.DOMAIN + HttpClient.SECURE}/preorder-item`,

    VENDOR: `${HttpClient.DOMAIN + HttpClient.SECURE}/vendor`,
  };
}

export default HttpClient;
