import { SaleStateOptionI, saleStateToOption } from '@/utils/toOption';
import CustomAutocomplete from '@/components/molecules/CustomAutocomplete';
import { TEXT } from '@/utils/Text';
import React, { useCallback, useState } from 'react';
import { SaleState } from '@/enum/SaleState';
import SaleStateOption from '@/components/molecules/SaleStateOption';
import SaleStateChip from '@/components/atoms/SaleStateChip';

interface Props {
  value: SaleState[];
  onChange (value: SaleState[]): void;
  classes?: any;
}

function SaleStateAutocomplete({
  onChange,
  value,
  classes,
}: Props) {
  const [selectedValue, setSelectedValue] = useState<SaleStateOptionI[]>(
    value.map(saleStateToOption),
  );
  const [options] = useState<SaleStateOptionI[]>(
    Object.values(SaleState).map(saleStateToOption),
  );

  const handleChange = useCallback((newOptions: SaleStateOptionI[]) => {
    setSelectedValue(newOptions);
    onChange(newOptions.map((option) => option.value));
  }, [onChange]);

  const memoizedRenderTags = useCallback((tags: SaleStateOptionI[], getTagProps) => (
    tags.map((tag, index) => (
      <SaleStateChip
        size="small"
        state={tag.value}
        {...getTagProps({ index })}
      />
    ))), []);

  const renderOption = useCallback(({ value: saleState }, { selected }) => (
    <SaleStateOption
      saleState={saleState}
      selected={selected}
    />
  ), []);

  return (
    <CustomAutocomplete
      disableCloseOnSelect
      renderTags={memoizedRenderTags}
      multiple
      classes={classes}
      label={TEXT.COLUMN.HEADER.STATE}
      value={selectedValue}
      onChange={handleChange}
      defaultOptions={options}
      renderOption={renderOption}
    />
  );
}

export default React.memo(SaleStateAutocomplete);
