import { Role } from '@/enum/Role';
import { createStore, Store } from 'redux';
import { SupplierInfo } from '@/interfaces/SupplierInfo';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { ClientInfo } from '@/interfaces/ClientInfo';
import { ApplicationState } from '@/interfaces/ApplicationState';
import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';
import StoreItemInfo from '@/interfaces/StoreItemInfo';
import { CreditInfo } from '@/interfaces/CreditInfo';
import { RestitutionInfo } from '@/interfaces/RestitutionInfo';
import rootReducer from '@/redux/reducer/rootReducer';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { CompanyUserInfoState } from '@/interfaces/CompanyUserInfoState';
import { PriceFormationTypeState } from '@/redux/reducer/priceFomationTypeReducer';
import { PreOrderItemInfosState } from '@/redux/reducer/preOrderItemInfoReducer';
import Vendor from '@/interfaces/Vendor';
import ProductInfo from '@/interfaces/ProductInfo';
import SaleTableConfig from '@/interfaces/SaleTableConfig';

export interface StoreState {
  roles: Role[],
  customTable: any,
  application: ApplicationState,
  supplierInfos: SupplierInfo[],
  clientRequestInfos: UserPartnerRequestInfo[],
  supplierPartnerRequestInfos: UserPartnerRequestInfo[],
  clientInfos: ClientInfo[],
  incomingSupplierOrderInfos: IncomingSupplierOrderInfo[],
  storeItems: StoreItemInfo[],
  creditInfos: CreditInfo[],
  restitutionInfos: RestitutionInfo[],
  companyInfo: CompanyInfo | null,
  companyUserInfoState: CompanyUserInfoState,
  priceFormationTypes: PriceFormationTypeState,
  preOrderItemInfoState: PreOrderItemInfosState,
  vendors: Vendor[],
  products: ProductInfo[],
  saleTableConfig: SaleTableConfig,
}

export const store: Store<StoreState> = createStore(rootReducer);
