import React from 'react';
import footerImage from 'assets/vi4-6.png';
import Path from '@/enum/Path';
import { Route, Switch } from 'react-router-dom';
import Footer from '@/app/Footer';
import RegistrationForm from './forms/RegistrationForm';
import LoginForm from './forms/LoginForm';
import ForgetPasswordForm from './forms/ForgetPasswordForm';
import NewPasswordForm from './forms/NewPasswordForm';

function LoginPage() {
  return (
    <div className="login-page-wrapper">
      <div className="login-form-wrapper">
        <img className="footer-image" alt="Loading..." src={footerImage} />
        <Switch>
          <Route
            exact
            path={Path.LOGIN}
            component={LoginForm}
          />
          <Route
            path={`${Path.NEW_PASSWORD}/:token/:email`}
            component={NewPasswordForm}
          />
          <Route
            path={Path.FORGET_PASSWORD}
            component={ForgetPasswordForm}
          />
          <Route
            path={Path.REGISTRATION}
            component={RegistrationForm}
          />
        </Switch>
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default LoginPage;
