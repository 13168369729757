import React, { useCallback, useState } from 'react';
import SaleInfoTable from '@/components/organisms/SaleTable/SaleTable';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import HomeIcon from '@material-ui/icons/Home';
import { PostAdd, RestorePage } from '@material-ui/icons';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import { TEXT } from '@/utils/Text';
import RestitutionTable from '@/components/organisms/RestitutionTable';
import AttributeTable from '@/components/organisms/AttributeTable';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import ClientPatternSubTab from '@/components/organisms/ClientPatternSubTab';
import { Box } from '@material-ui/core';
import OutsideSaleToolBar from '@/components/molecules/OutsideSaleToolBar';

const SaleTab = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Box display="flex" justifyContent="space-between" position="relative">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            selectedIndex={selectedTabIndex}
            index={0}
            label={TEXT.MY_SALES}
            icon={<HomeIcon fontSize="small" />}
            onChange={handleChange}
          />
          <StyledBreadcrumb
            selectedIndex={selectedTabIndex}
            index={1}
            icon={<RestorePage fontSize="small" />}
            label={TEXT.RESTITUTIONS}
            onChange={handleChange}
          />
          <StyledBreadcrumb
            selectedIndex={selectedTabIndex}
            index={2}
            icon={<BubbleChartIcon fontSize="small" />}
            label={TEXT.ATTRIBUTES}
            onChange={handleChange}
          />
          <StyledBreadcrumb
            label={TEXT.TAB.PATTERNS}
            icon={<PostAdd fontSize="small" />}
            selectedIndex={selectedTabIndex}
            index={3}
            onChange={handleChange}
          />
        </Breadcrumbs>
        <OutsideSaleToolBar />
      </Box>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <SaleInfoTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <RestitutionTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={2}
      >
        <AttributeTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={3}
      >
        <ClientPatternSubTab />
      </SubTabPanel>
    </div>
  );
};

export default React.memo(SaleTab);
