import { combineReducers, Reducer } from 'redux';
import applicationReducer from '@/redux/reducer/applicationReducer';
import tableReducer from '@/redux/reducer/tableReducer';
import { rolesReducer } from '@/redux/reducer/rolesReducer';
import { clientRequestInfoReducer } from '@/redux/reducer/clientRequestInfoReducer';
import { clientInfoReducer } from '@/redux/reducer/clientInfoReducer';
import { supplierPartnerRequestInfoReducer } from '@/redux/reducer/supplierPartnerRequestInfoReducer';
import { incomingSupplierOrderInfoReducer } from '@/redux/reducer/incomingSupplierOrderInfoReducer';
import { storeItemReducer } from '@/redux/reducer/storeItemReducer';
import { creditInfoReducer } from '@/redux/reducer/creditInfoReducer';
import { restitutionInfoReducer } from '@/redux/reducer/restitutionInfoReducer';
import { StoreState } from '@/redux/stores/store';
import priceFormationTypeReducer from '@/redux/reducer/priceFomationTypeReducer';
import preOrderItemInfoReducer from '@/redux/reducer/preOrderItemInfoReducer';
import supplierInfoReducer from '@/redux/reducer/supplierInfoReducer';
import vendorReducer from '@/redux/reducer/vendorReducer';
import productInfoReducer from '@/redux/reducer/productnfoReducer';
import saleTableConfigReducer from '@/redux/reducer/saleTableConfigReducer';
import companyInfoReducer from './companyInfoReducer';
import companyUserInfoReducer from './companyUserReducer';

const combinedReducer: Reducer = combineReducers<StoreState>({
  application: applicationReducer,
  customTable: tableReducer,
  roles: rolesReducer,
  supplierInfos: supplierInfoReducer,
  clientRequestInfos: clientRequestInfoReducer,
  clientInfos: clientInfoReducer,
  supplierPartnerRequestInfos: supplierPartnerRequestInfoReducer,
  incomingSupplierOrderInfos: incomingSupplierOrderInfoReducer,
  storeItems: storeItemReducer,
  creditInfos: creditInfoReducer,
  restitutionInfos: restitutionInfoReducer,
  companyInfo: companyInfoReducer,
  companyUserInfoState: companyUserInfoReducer,
  priceFormationTypes: priceFormationTypeReducer,
  preOrderItemInfoState: preOrderItemInfoReducer,
  vendors: vendorReducer,
  products: productInfoReducer,
  saleTableConfig: saleTableConfigReducer,
});

export default combinedReducer;
