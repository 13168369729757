import React, { Component } from 'react';
import { TEXT } from '@/utils/Text';
import youtubeIco from '../../assets/youtube.ico';

class Footer extends Component {
  render() {
    const { className } = this.props;
    return (
      <section className={className}>
        <nav>
          <span className="link">
            <a
              title={TEXT.OPEN_YOUTUBE}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UClvyH2AWQkTLs0KaIDx5tKw"
            >
              <span className="link-item">YouTube</span>
              <img alt="Loading..." src={youtubeIco} />
            </a>
          </span>
        </nav>
      </section>
    );
  }
}

export default Footer;
