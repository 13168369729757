import { TEXT } from '@/utils/Text';
import { SaleType } from '@/enum/SaleType';
import { useMemo } from 'react';

function useSaleTypeColumn(
  editable: string = 'onAdd',
) {
  return useMemo(() => (
    {
      width: 120,
      removable: false,
      title: TEXT.COLUMN.HEADER.TYPE,
      field: 'type',
      editable,
      render: (item) => renderSaleType(item.type),
      lookup: { [SaleType.RETAIL]: TEXT.RETAIL, [SaleType.WHOLESALE]: TEXT.WHOLESALE },
      initialEditValue: SaleType.RETAIL,
    }
  ), [editable]);
}

function renderSaleType(type: SaleType) {
  switch (type) {
    case SaleType.RETAIL:
      return TEXT.RETAIL;
    case SaleType.WHOLESALE:
      return TEXT.WHOLESALE;
  }
}

export default useSaleTypeColumn;
