import React, { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';

interface SaleReceiptFooterProps {
  writtenSum: string;
}

const SaleReceiptFooter: FC<SaleReceiptFooterProps> = ({
  writtenSum,
}: SaleReceiptFooterProps) => (
  <>
    <Box py={4} display="flex" flex="auto">
      <Typography style={{ paddingRight: '8px', minWidth: '218px' }}>
        <i>
          Всего отпущено на сумму:
        </i>
      </Typography>
      <Typography style={{ textDecoration: 'underline' }}>
        {writtenSum}
      </Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography>
        <i>
          Менеджер:
        </i>
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>
          ____________________
        </Typography>
        <Typography variant="caption">
          (Подпись)
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography>
          _________________________________________
        </Typography>
        <Typography variant="caption">
          (Ф.И.О.)
        </Typography>
      </Box>
    </Box>

  </>
);

export default memo(SaleReceiptFooter);
