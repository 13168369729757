import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import CompanySearchSubTab from '@/components/organisms/CompanySearchSubTab';
import MyCompanySubTab from '@/components/organisms/MyCompanySubTab';
import CompanyUserSubTab from '@/components/organisms/CompanyUserSubTab';
import { Box, Breadcrumbs } from '@material-ui/core';
import { Home, HomeWork, Search } from '@material-ui/icons';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { isCompanyOwner, selectCompanyInfo } from '@/redux/selectors/companySelector';
import { useSelector } from 'react-redux';
import { TEXT } from '@/utils/Text';

const CompanyTab: FC = () => {
  const company = useSelector(selectCompanyInfo);
  const isOwner = useSelector(isCompanyOwner);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (company && selectedTabIndex === 2) {
      setSelectedTabIndex(0);
    }
    if (!company) {
      setSelectedTabIndex(2);
    }
  }, [company, selectedTabIndex]);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        {!!company && (
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={0}
          label={TEXT.MY_COMPANY}
          icon={<Home fontSize="small" />}
          onChange={handleChange}
        />
        )}
        {!!company && isOwner && (
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={1}
          icon={<HomeWork fontSize="small" />}
          label={TEXT.COMPANY_USERS}
          onChange={handleChange}
        />
        )}
        {!company && (
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={2}
          icon={<Search fontSize="small" />}
          label={TEXT.SEARCH_COMPANY}
          onChange={handleChange}
        />
        )}
      </Breadcrumbs>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <MyCompanySubTab />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <CompanyUserSubTab />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={2}
      >
        <CompanySearchSubTab />
      </SubTabPanel>
    </Box>
  );
};

export default CompanyTab;
