import React, { FC, memo, useMemo } from 'react';
import TableBuilder from '@/components/organisms/TableBuilder';
import ColumnName from '@/enum/ColumnName';
import { columnToOption } from '@/utils/toOption';
import SaleReceiptFooter from '@/components/atoms/SaleReceiptFooter';
import SaleReceiptHeader from '@/components/atoms/SaleReceiptHeader';
import SaleItemInfo from '@/interfaces/SaleItemInfo';
import { SaleInfoBase } from '@/interfaces/SaleInfoBase';

interface SaleReceiptProps {
  saleItemInfos: SaleItemInfo[],
  saleInfo: SaleInfoBase,
}

const SaleReceipt: FC<SaleReceiptProps> = ({
  saleItemInfos,
  saleInfo,
}: SaleReceiptProps) => {
  const columnNames = useMemo(() => ([
    ColumnName.NAME,
    ColumnName.UNIT,
    ColumnName.COUNT,
    ColumnName.PRICE,
    ColumnName.SUM,
  ]), []);

  const columns = useMemo(() => (
    columnNames.map((name) => columnToOption(name, true))
  ), [columnNames]);

  return (
    <>
      <SaleReceiptHeader saleTime={saleInfo.saleTime} />
      <TableBuilder
        className="sale-receipt-table"
        rows={saleItemInfos}
        columns={columns}
        saleReceipt
      />
      <SaleReceiptFooter writtenSum={saleInfo.writtenSum} />
    </>
  );
};

export default memo(SaleReceipt);
