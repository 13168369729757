import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  icon: {
    margin: '10px',
    width: '25px',
    height: '25px',
  },
  searchInput: {
    flex: 'none',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    paddingRight: 20,
    overflow: 'visible',
  },
  toolbarRoot: {
    borderRadius: 25,
  },
  spacer: {
    display: 'none',
  },
  rooterPaper: {
    'sub-table': {
      border: '2px solid #1d5b03',
    },
  },
}));
