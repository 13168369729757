type ObjetWithId<Obj> = Obj & {id: string};

function replaceOneById<T>(
  array: ObjetWithId<T>[],
  itemToReplace: ObjetWithId<T>,
) {
  const index = array.findIndex((item) => item.id === itemToReplace.id);
  if (index > -1) {
    const newInfos = [...array];
    newInfos[index] = itemToReplace;
    return newInfos;
  }
  return array;
}

export function replaceAllById<T>(
  array: ObjetWithId<T>[],
  itemsToReplace: ObjetWithId<T>[],
) {
  const result: ObjetWithId<T>[] = [];
  for (let i = 0; i < array.length; i++) {
    const index = itemsToReplace.findIndex((item) => item.id === array[i].id);
    if (index > -1) {
      result.push(itemsToReplace[index]);
    } else {
      result.push(array[i]);
    }
  }
  return result;
}

export function replaceArrayById<T>(
  array: ObjetWithId<T>[],
  itemsToReplace: ObjetWithId<T>[],
) {
  const newInfos = [...array];
  let found = false;
  itemsToReplace.forEach((itemToReplace) => {
    const index = array.findIndex((item) => item.id === itemToReplace.id);
    if (index > -1) {
      found = true;
      newInfos[index] = itemToReplace;
    }
  });
  return found ? newInfos : array;
}

export function deleteById<T>(
  array: ObjetWithId<T>[],
  id: string,
) {
  return array.filter((item) => item.id !== id);
}

export default replaceOneById;
