import React, { FC, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { getCompanyById } from '@/utils/fetch';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import UserCard from '@/components/molecules/UserCard/UserCard';
import CompanyActionButton from '@/components/molecules/CompanyActionButton';
import { Create } from '@material-ui/icons';
import DeleteCompanyButton from '@/components/molecules/DeleteCompanyButton';
import { TEXT } from '@/utils/Text';
import MyCompanyCardUserBottomPanel
  from '@/components/molecules/MyCompanyCard/MyCompanyCardUserBottomPanel';
import { Box } from '@material-ui/core';

interface MyCompanyCardProps {
  company: CompanyInfo;
  isOwner?: boolean;
}

const MyCompanyCard: FC<MyCompanyCardProps> = ({
  company,
  isOwner,
}: MyCompanyCardProps) => {
  const dispatch = useDispatch();

  const {
    id, name, address, description, phoneNumber, contactEmail,
  } = company;

  useEffect(() => {
    getCompanyById(id)
      .then((companyInfo) => {
        dispatch(CompanyInfoActions.set(companyInfo));
      })
      .catch((reason) => {
        if (reason.status === 403) {
          dispatch(CompanyInfoActions.remove(id));
          return Promise.resolve();
        }
        return Promise.reject(reason);
      });
  }, [
    dispatch,
    id,
  ]);

  return (
    <UserCard
      address={address}
      phoneNumber={phoneNumber}
      organization={name}
      description={description}
      contactEmail={contactEmail}
      bottom={(
        <Box mt={3}>
          {isOwner
            ? (
              <>
                <CompanyActionButton
                  dialogTitle={TEXT.HEADER.UPDATE_COMPANY}
                  text={TEXT.BUTTON.UPDATE}
                  company={company}
                  startIcon={<Create />}
                />
                <DeleteCompanyButton
                  companyId={company.id}
                />
              </>
            )
            : (
              <MyCompanyCardUserBottomPanel
                companyInfo={company}
              />
            )}
        </Box>
      )}
    />
  );
};

MyCompanyCard.defaultProps = {
  isOwner: false,
};

export default memo(MyCompanyCard);
