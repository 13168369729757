import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import SearchInput from '@/components/molecules/SearchInput/SearchInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@/components/atoms/Typography';
import Container from '@material-ui/core/Container';
import CompanyActionButton from '@/components/molecules/CompanyActionButton';
import { AddCircle } from '@material-ui/icons';
import { TEXT } from '@/utils/Text';
import UserGrid from '@/components/templates/UserSearchView/UserGrid';

function UserSearchView({
  handleSearchChange,
  loading,
  cards,
  onCancel,
  companyTab,
}) {
  return (
    <Container maxWidth="xl">
      <Paper>
        <Box p={2} mb={3} display="flex" justifyContent="space-between">
          <SearchInput
            label="Введите название организации"
            onChange={handleSearchChange}
            onCancel={onCancel}
          />
          {companyTab && (
          <CompanyActionButton
            dialogTitle={TEXT.HEADER.CREATE_COMPANY}
            text="Не нашли вашу компанию? Создайте свою"
            startIcon={<AddCircle />}
          />
          )}
        </Box>
      </Paper>
      <Grid container spacing={5}>
        {loading
          ? (
            <Grid item xs={12}>
              <Paper>
                <Box p={6}>
                  <Typography align="center">
                    Загрузка...
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ) : (
            <UserGrid
              cards={cards}
            />
          )}
      </Grid>
    </Container>
  );
}

export default UserSearchView;
