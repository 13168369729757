import { handleActions } from 'redux-actions';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';

const processAction = (
  state,
  action,
) => action.payload;

const companyInfoReducer = handleActions({
  [CompanyInfoActions.Type.SET]: processAction,
  [CompanyInfoActions.Type.UPDATE]: processAction,
  [CompanyInfoActions.Type.DELETE]: () => null,
},
null);

export default companyInfoReducer;
