import React, { Component } from 'react';
import { TEXT } from '@/utils/Text';

class EmailConfirmingText extends Component {
  render() {
    const { email } = this.props;
    return (
      <div className="thank-you-text">
        <span>{TEXT.LOGIN_FORM.REQUEST_PROCESSING}</span>
        <span className="next-line">{TEXT.LOGIN_FORM.PLEASE_CONFIRM_EMAIL}</span>
        <span className="next-line thank-you-text-email">{email}</span>
      </div>
    );
  }
}

export default EmailConfirmingText;
