import { TEXT } from '@/utils/Text';
import React, { CSSProperties, useCallback, useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { Column } from 'material-table';

const useNameColumn = (
  error?,
  column?,
  fullWidth?,
  cellStyle = {} as CSSProperties,
): Column<any> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomInput
      fullWidth={fullWidth}
      error={error}
      id="name"
      maxLength={40}
      label={TEXT.COLUMN.HEADER.NAME}
      required
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error, fullWidth]);

  const memoizedCellStyles = useMemo(() => ({
    ...cellStyle,
    width: fullWidth ? '100%' : cellStyle.width,
  }), [cellStyle, fullWidth]);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.NAME,
    defaultSort: 'asc',
    field: 'name',
    editComponent: handleEditComponent,
    cellStyle: memoizedCellStyles,
  }), [column, handleEditComponent, memoizedCellStyles]);
};

export default useNameColumn;
