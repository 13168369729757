import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import React from 'react';

function TtnHeader({
  editable,
  senderName,
  receiverName,
  receiverAddress,
  senderAddress,
}) {
  return (
    <div className="flex-column-container mb-20">
      <div className="d-flex">
        <EditableDocumentInput
          initialValue={senderName}
          label="Грузоотправитель"
          placeholder="__________________________________________________"
          editing={editable}
        />
      </div>
      <div className="d-flex">
        <EditableDocumentInput
          initialValue={receiverName}
          label="Грузополучатель"
          placeholder="___________________________________________________"
          editing={editable}
        />
      </div>
      <div className="d-flex flex-wrap">
        <div className="d-flex mr-5">
          <EditableDocumentInput
            label="Основание отпуска"
            placeholder="____________"
            editing={editable}
          />
        </div>
        <div className="d-flex mr-5">
          <EditableDocumentInput
            initialValue={senderAddress}
            label="Пункт погрузки"
            placeholder="____________"
            editing={editable}
          />
        </div>
        <div className="d-flex">
          <EditableDocumentInput
            initialValue={receiverAddress}
            label="Пункт разгрузки"
            placeholder="____________"
            editing={editable}
          />
        </div>
      </div>
    </div>
  );
}

export default TtnHeader;
