import { IncomingSupplierOrderInfoActions } from '@/redux/actions/incomingSupplierOrderInfoActions';
import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';

export function incomingSupplierOrderInfoReducer(
  supplierOrderInfos: Array<IncomingSupplierOrderInfo> = [],
  { type, payload },
): Array<IncomingSupplierOrderInfo> {
  switch (type) {
    case IncomingSupplierOrderInfoActions.SET:
      return payload;
    case IncomingSupplierOrderInfoActions.UPDATE_ONE:
      const index = supplierOrderInfos.findIndex(
        (supplierOrderInfo) => supplierOrderInfo.id === payload.id,
      );
      if (index > -1) {
        const newInfos = [...supplierOrderInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return [payload, ...supplierOrderInfos];

    default:
      return supplierOrderInfos;
  }
}
