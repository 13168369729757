import React, { useCallback, useState } from 'react';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import MySuppliersSubTab from '@/components/organisms/MySuppliersSubTab/MySuppliersSubTab';
import NewSupplierSearchSubTab from '@/components/organisms/NewSupplierSearchSubTab/NewSupplierSearchSubTab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import { Search } from '@material-ui/icons';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';

function SupplierTab() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback(index => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={0}
          label="Мои поставщики"
          icon={<HomeIcon fontSize="small" />}
          onChange={handleChange}
        />
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={1}
          icon={<Search fontSize="small" />}
          label="Поиск нового поставщика"
          onChange={handleChange}
        />
      </Breadcrumbs>

      <SubTabPanel value={selectedTabIndex} index={0}>
        <MySuppliersSubTab />
      </SubTabPanel>
      <SubTabPanel value={selectedTabIndex} index={1}>
        <NewSupplierSearchSubTab />
      </SubTabPanel>
    </div>
  );
}

export default SupplierTab;
