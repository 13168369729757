import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
  container: {
    minWidth: 150,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
}));
