import { createAction } from 'redux-actions';
import PreorderItemInfo from '@/interfaces/PreOrderItemInfo';

enum Type {
  SET = 'SET_PREORDER_ITEM_INFO',
  UPDATE_ONE = 'UPDATE_PREORDER_ITEM_INFO_ONE',
  UPDATE = 'UPDATE_PREORDER_ITEM_INFO',
}

const set = createAction<PreorderItemInfo[]>(Type.SET);
const updateOne = createAction<PreorderItemInfo>(Type.UPDATE_ONE);
const update = createAction<PreorderItemInfo[]>(Type.UPDATE);

export const PreOrderItemInfoActions = {
  Type,
  set,
  updateOne,
  update,
};

export type PreOrderItemInfoActionsType = Omit<typeof PreOrderItemInfoActions, 'Type'>;
