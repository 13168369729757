import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  dateInput: {
    width: 210,
  },
  clientInput: {
  },
  typography: {
    fontSize: 24,
    fontFamily: 'Lato Regular',
    padding: '5px 15px',
  },
  paper: {
    width: 650,
    padding: '10px 0',
    marginRight: 10,
    display: 'flex',
  },
  leftHeaderBox: {
    width: '100%',
  },
  balancePaper: {
    padding: 10,
    flex: 1,
  },
  balanceItem: {
    fontSize: 15,
    padding: 5,
    margin: '0 10px',
    wordBreak: 'break-all',
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 5,
  },
  tabHeader: {
    display: 'flex',
    marginBottom: 10,
  },
  colorTip: {
    border: '1px solid black',
    height: 20,
    width: 40,
    marginRight: 10,
    borderRadius: 5,
  },
}));
