import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteCredit, getCreditInfos, postCredit, putCredit,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomTableLoading, selectCreditsTableColumns } from '@/redux/selectors/customTableSelector';
import useClientColumn from '@/hook/columns/useClientColumn';
import useDescriptionColumn from '@/hook/columns/useDescriptionColumn';
import useReceivingTimeColumn from '@/hook/columns/useReceivingTimeColumn';
import { getDateTimeNow } from '@/utils/Utils';
import { CreditInfo } from '@/interfaces/CreditInfo';
import { PaginatedResponse } from '@/interfaces/PaginatedResponse';
import {
  createDeleteCreditInfoById,
  createSetCreditInfosAction,
  createUpdateCreditInfo,
} from '@/redux/actions/creditInfoActions';
import { selectCreditInfos } from '@/redux/selectors/creditInfoSelector';
import useCreditSumColumn from '@/hook/columns/useCreditSumColumn';
import { CreditTableErrors } from '@/components/organisms/CreditTable/clientsTable.types';
import useDocumentNameColumn from '@/hook/columns/useDocumentNameColumn';

function CreditTable() {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<CreditTableErrors>({});

  const [rows, setRows] = useState<CreditInfo[]>([]);
  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectCreditsTableColumns);
  const creditInfos = useSelector(selectCreditInfos);

  const clientColumn = useClientColumn(
    columns.clientFirstName,
    errors.clientId,
    undefined,
    false,
    true,
  );
  const sumColumn = useCreditSumColumn(errors.sum);
  const descriptionColumn = useDescriptionColumn(columns.description, errors.description);
  const receivingTimeColumn = useReceivingTimeColumn();
  const documentColumn = useDocumentNameColumn(columns.documentName);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getCreditInfos()
      .then((response: PaginatedResponse<CreditInfo>) => (
        dispatch(createSetCreditInfosAction(response.records))
      )).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(creditInfos.map((clientInfo) => ({ ...clientInfo })));
  }, [creditInfos]);

  const memoizedColumns = useMemo(() => (
    [
      receivingTimeColumn,
      clientColumn,
      sumColumn,
      descriptionColumn,
      documentColumn,
    ]
  ), [receivingTimeColumn, clientColumn, descriptionColumn, sumColumn, documentColumn]);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    setLoading(true);
    postCredit({ ...data, receivingTime: getDateTimeNow() })
      .then((response: CreditInfo) => {
        dispatch(createUpdateCreditInfo(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, dispatch, context]);

  const onUpdate = useCallback((data) => new Promise((resolve, reject) => {
    setLoading(true);
    putCredit(data)
      .then((response: CreditInfo) => {
        dispatch(createUpdateCreditInfo(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, context, dispatch]);

  const onDelete = useCallback((oldData) => new Promise((resolve, reject) => {
    setLoading(true);
    const { id } = oldData;
    deleteCredit(id)
      .then(() => {
        dispatch(createDeleteCreditInfoById(id));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((response) => {
        reject();
        return Promise.reject(response);
      })
      .finally(() => setLoading(false));
  }), [dispatch, context]);

  const memoizedEditable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowUpdate: onUpdate,
    onRowDelete: onDelete,
  }), [onAdd, onUpdate, onDelete]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const isLoading = loading || customTableLoading;

  return (
    <StyledTable
      tableKey={ID.CREDIT_TABLE}
      onRowEditing={handleRowEditing}
      editable={memoizedEditable}
      loading={isLoading}
      columns={memoizedColumns}
      data={rows}
    />
  );
}

export default CreditTable;
