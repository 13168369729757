import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';

function PasswordInput({
  name = 'password',
  ...other
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const memoizedInputProps = useMemo(() => ({
    endAdornment:
  <InputAdornment position="end">
    <IconButton
      title={showPassword ? 'Скрыть пароль' : 'Показать пароль'}
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>,
  }), [showPassword]);

  return (
    <TextField
      maxLength={40}
      type={showPassword ? 'text' : 'password'}
      name={name}
      InputProps={memoizedInputProps}
      {...other}
    />
  );
}

export default PasswordInput;
