import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import { Action, handleActions } from 'redux-actions';
import { CompanyUserInfoState } from '@/interfaces/CompanyUserInfoState';
import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';

const processSetUsers = (
  state: CompanyUserInfoState,
  action: Action<CompanyUserInfo[]>,
): CompanyUserInfoState => ({
  ...state,
  companyUserInfos: action.payload,
});

const processUpdateOne = (
  state: CompanyUserInfoState,
  { payload }: Action<CompanyUserInfo>,
): CompanyUserInfoState => {
  const { companyUserInfos } = state;
  const index = companyUserInfos.findIndex((userInfo) => userInfo.id === payload.id);
  const newInfos = [...companyUserInfos];
  if (index > -1) {
    newInfos[index] = payload;
    return { ...state, companyUserInfos: newInfos };
  }
  return {
    ...state,
    companyUserInfos: [
      payload,
      ...newInfos,
    ],
  };
};

const processDeleteOne = (
  state: CompanyUserInfoState,
  { payload }: Action<string>,
): CompanyUserInfoState => ({
  ...state,
  companyUserInfos: state.companyUserInfos.filter((userInfo) => userInfo.id !== payload),
});

const processRemoveSelected = (
  state: CompanyUserInfoState,
): CompanyUserInfoState => ({
  ...state,
  currentCompanyUserInfo: null,
});

const processSetSelectionEnabled = (
  state: CompanyUserInfoState,
  { payload }: Action<boolean>,
): CompanyUserInfoState => ({
  ...state,
  selectionEnabled: payload,
});

const processSetSelected = (
  state: CompanyUserInfoState,
  { payload }: Action<CompanyUserInfo>,
): CompanyUserInfoState => ({
  ...state,
  currentCompanyUserInfo: payload,
});

const initialState: CompanyUserInfoState = {
  companyUserInfos: [],
  selectionEnabled: false,
  currentCompanyUserInfo: null,
};

const companyUserInfoReducer = handleActions<CompanyUserInfoState, CompanyUserReducerPayload>({
  [CompanyUserInfoActions.Type.SET_USERS]: processSetUsers,
  [CompanyUserInfoActions.Type.UPDATE_ONE]: processUpdateOne,
  [CompanyUserInfoActions.Type.DELETE_ONE]: processDeleteOne,
  [CompanyUserInfoActions.Type.SET_SELECTED]: processSetSelected,
  [CompanyUserInfoActions.Type.REMOVE_SELECTED]: processRemoveSelected,
  [CompanyUserInfoActions.Type.SET_SELECTION_ENABLED]: processSetSelectionEnabled,
  [CompanyUserInfoActions.Type.DELETE_ALL]: () => initialState,
},
initialState);

type CompanyUserReducerPayload = CompanyUserInfo & CompanyUserInfo[] & string & boolean;

export default companyUserInfoReducer;
