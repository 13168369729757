import React from 'react';
import { Box, Chip } from '@material-ui/core';
import { useSlaChipStyles } from '@/components/atoms/SaleStateChip/saleStateChip.styles';
import { SaleStateProps } from '@/components/atoms/SaleStateChip/saleStateChip.types';
import { TEXT } from '@/utils/Text';
import { SaleState } from '@/enum/SaleState';

export const renderSaleStateText = (state: SaleState) => {
  switch (state) {
    case SaleState.FORMED:
      return TEXT.FORMED;
    case SaleState.COMPLETED:
      return TEXT.COMPLETED;
    default:
      return '';
  }
};

const SaleStateChip: React.FC<SaleStateProps> = ({
  state = SaleState.FORMED,
  size,
  onDelete,
}: SaleStateProps) => {
  const classes = useSlaChipStyles();

  return (
    <Chip
      onDelete={onDelete}
      size={size}
      classes={classes}
      className={classes[state.toLowerCase()]}
      label={renderSaleStateText(state)}
    />
  );
};

export default React.memo(SaleStateChip);
