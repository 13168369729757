import React from 'react';
import ClientPatternTable from '@/components/organisms/ClientPatternTable';

function ClientPatternSubTab() {
  return (
    <div className="table-container">
      <ClientPatternTable />
    </div>
  );
}

export default ClientPatternSubTab;
