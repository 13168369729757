import User from './User';

class UserService {
  static parse(
    {
      id,
      name,
      authorized,
      email,
      unp,
      organization,
      address,
      contactEmail,
      phoneNumber,
      description,
    },
  ) {
    return new User(
      id,
      name,
      authorized,
      email,
      unp,
      organization,
      address,
      contactEmail,
      phoneNumber,
      description,
    );
  }
}

export default UserService;
