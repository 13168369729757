import React, { useCallback, useState } from 'react';
import SnackBar from '@/components/molecules/SnackBar/Snackbar';
import SnackbarContext from '@/app/snackbarContext';

export interface Message {
  closeable?: boolean;
  variant: MessageType;
  message: string;
}

export enum MessageType {
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}

const SnackBarRenderer = props => {
  const [message, setMessage] = useState<Message | null>(null);

  const notCloseable =
    typeof message?.closeable === 'boolean' && !message?.closeable;

  const handleClose = useCallback(() => {
    if (notCloseable) {
      return;
    }
    setMessage(null);
  }, [notCloseable]);

  const handleSetMessage = useCallback(
    (newMessage: Message) => {
      if (notCloseable) {
        return;
      }
      setMessage(newMessage);
    },
    [notCloseable]
  );

  return (
    <>
      <SnackbarContext.Provider value={handleSetMessage}>
        {props.children}
      </SnackbarContext.Provider>
      {message && <SnackBar handleClose={handleClose} message={message} />}
    </>
  );
};

export default SnackBarRenderer;
