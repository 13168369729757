import React from 'react';
import useClasses from '@/components/atoms/ClientTitleLabel/clientTitlteLabel.styles';
import { Box } from '@material-ui/core';
import BalanceIcon from '@/components/molecules/BalanceIcon';
import Typography from '@/components/atoms/Typography';
import Tooltip from '@material-ui/core/Tooltip';

interface Props {
  clientId: string;
  name: string;
  phoneNumber: string;
  creditIconEnabled?: boolean;
}

const ClientTitleLabel: React.FC<Props> = ({
  clientId,
  name,
  phoneNumber,
  creditIconEnabled = false,
}) => {
  const classes = useClasses();

  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center">
        {clientId && creditIconEnabled && (
          <BalanceIcon
            clientId={clientId}
            name={name}
          />
        )}
        {name && (
          <Box mr={0.5}>
            <Tooltip
              arrow
              title={name || ''}
            >
              <Typography variant="ellipsis">{name}</Typography>
            </Tooltip>
          </Box>
        )}
      </Box>
      {phoneNumber && (
        <div>
          {phoneNumber}
        </div>
      )}
    </Box>
  );
};

export default ClientTitleLabel;
