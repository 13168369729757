import { StoreState } from '@/redux/stores/store';
import { createSelector } from 'reselect';
import User from '@/model/user/User';
import ProductInfo from '@/interfaces/ProductInfo';
import Unit from '@/model/unit/Unit';
import SupplierOrderInfo from '@/interfaces/SupplierOrderInfo';
import { Store } from '@/interfaces/Store';
import { ClientPatternInfo } from '@/interfaces/ClientPatternInfo';

export const selectProducts = (state: StoreState): ProductInfo[] => state.products;

export const selectNotArchivedProducts = createSelector(
  selectProducts,
  (products: ProductInfo[]) => products.filter((value) => !value.archived),
);

export const selectSupplierOrders = (
  state: StoreState,
): SupplierOrderInfo[] => state.application.supplierOrders;

export const selectUnits = (state: StoreState): Array<Unit> => state.application.units;

export const selectUser = (state: StoreState): User => state.application.user;

export const selectCurrentStore = (state: StoreState): Store | null => state.application.currentStore;

export const selectCurrentStoreId = createSelector(
  selectCurrentStore,
  (currentStore: Store | null): string | null => (currentStore ? currentStore.id : null),
);

export const selectClientPatterns = (state: StoreState): ClientPatternInfo[] => state.application.clientPatterns;

export const getUserId = createSelector(
  selectUser,
  (user: User): string => user.id,
);

export const getSupplierProducts = (supplierId) => createSelector(
  selectProducts,
  (productInfos: Array<ProductInfo>) => productInfos.filter((product) => product.supplierId === supplierId),
);
