import { CreditInfo } from '@/interfaces/CreditInfo';

export enum CreditInfoActionType {
  SET = 'SET_CREDIT_INFO',
  UPDATE_ONE = 'UPDATE_ONE_CREDIT_INFO',
  DELETE_ONE = 'DELETE_ONE_CREDIT_INFO'
}

export function createDeleteCreditInfoById(
  id: string,
) {
  return {
    type: CreditInfoActionType.DELETE_ONE,
    payload: id,
  };
}

export function createUpdateCreditInfo(
  creditInfo: CreditInfo,
) {
  return {
    type: CreditInfoActionType.UPDATE_ONE,
    payload: creditInfo,
  };
}

export function createSetCreditInfosAction(
  creditInfos: CreditInfo[],
) {
  return {
    type: CreditInfoActionType.SET,
    payload: creditInfos,
  };
}
