class User {
  constructor(
    id,
    name,
    authorized,
    email,
    unp,
    organization,
    address,
    contactEmail,
    phoneNumber,
    description,
  ) {
    this.id = id;
    this.name = name;
    this.authorized = !!authorized;
    this.email = email;
    this.unp = unp;
    this.organization = organization;
    this.address = address;
    this.contactEmail = contactEmail;
    this.phoneNumber = phoneNumber;
    this.description = description;
  }

  getUserId() {
    return this.id;
  }
}

export default User;
