import { Action, handleActions } from 'redux-actions';
import SaleTableConfig, { SaleTableFilter } from '@/interfaces/SaleTableConfig';
import SaleTableConfigActions from '@/redux/actions/saleTableConfigActions';
import moment from 'moment';
import { SaleState } from '@/enum/SaleState';

const initialState: SaleTableConfig = {
  filter: {
    endDate: moment().endOf('d'),
    startDate: moment().startOf('d').subtract(14, 'd'),
    states: Object.values(SaleState),
    clients: [],
    attributes: [],
  },
};

const processSetFilter = (
  state: SaleTableConfig,
  action: Action<SaleTableFilter>,
): SaleTableConfig => ({ ...state, filter: action.payload });

const saleTableConfigReducer = handleActions<SaleTableConfig, Payload>(
  {
    [SaleTableConfigActions.Type.SET_FILTER]: processSetFilter,
  },
  initialState,
);

type Payload = SaleTableFilter;

export default saleTableConfigReducer;
