import { ClientInfo } from '@/interfaces/ClientInfo';
import { ClientInfoActionType } from '@/redux/actions/clientInfoActions';

export function clientInfoReducer(
  clientInfos: Array<ClientInfo> = [],
  { type, payload },
): Array<ClientInfo> {
  switch (type) {
    case ClientInfoActionType.SET:
      return payload;
    case ClientInfoActionType.UPDATE_ONE:
      const index = clientInfos.findIndex((supplierInfo) => supplierInfo.id === payload.id);
      if (index > -1) {
        const newInfos = [...clientInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return [...clientInfos, payload];

    case ClientInfoActionType.DELETE:
      return clientInfos.filter((clientInfo) => clientInfo.id !== payload);
    default:
      return clientInfos;
  }
}
