import Chip from '@material-ui/core/Chip';
import React, { useCallback } from 'react';
import { Box } from '@material-ui/core';
import useStyles from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb.style';
import cx from 'classnames';

function StyledBreadcrumb({
  selectedIndex,
  label,
  onChange,
  index,
  icon,
}) {
  const styles = useStyles();

  const handleClick = useCallback(() => {
    if (index !== selectedIndex) {
      onChange(index);
    }
  }, [selectedIndex, index, onChange]);

  return (
    <Chip
      icon={icon}
      onClick={handleClick}
      className={cx({
			  [styles.selected]: selectedIndex === index,
      }, styles.root)}
      color="primary"
      clickable
      label={(
        <Box pr={1}>
          {label}
        </Box>
  )}
    />
  );
}

export default StyledBreadcrumb;
