import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import { round } from '@/utils/Utils';
import PriceService from '@/service/PriceService';

const useVatColumn = (
  column,
  error,
  disabled = false,
) => {
  const handleEditComponent = useCallback(({ value, rowData, onRowDataChange }) => {
    const {
      priceNoVat,
      synchronizingProductId,
    } = rowData;
    return (
      <CustomNumberInput
        disabled={!!synchronizingProductId}
        error={error}
        id="vat"
        label={TEXT.COLUMN.HEADER.VAT}
        onChange={(vat) => {
          onRowDataChange({
            ...rowData,
            vat,
            price: round(priceNoVat * (vat / 100 + 1)),
          });
        }}
        value={value}
        min={0}
        step={1}
        decimalScale={2}
        suffix=" %"
      />
    );
  }, [error]);

  return useMemo(() => ({
    width: 80,
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.VAT,
    initialEditValue: 0,
    field: 'vat',
    editComponent: handleEditComponent,
    editable: disabled ? 'never' : undefined,
    cellStyle: {
      width: 80,
    },
  }), [disabled, handleEditComponent, column]);
};

export default useVatColumn;
