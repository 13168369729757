import React, { FC, useCallback, useState } from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { TEXT } from '@/utils/Text';
import { postExcelFileWithProducts } from '@/utils/fetch';
import {
  Box, Step, StepLabel, Stepper,
} from '@material-ui/core';
import ExcelParseResult from '@/components/organisms/ProductImportModal/ExcelParseResult';
import { ExcelImportError } from '@/interfaces/ExcelImportError';
import { Product } from '@/interfaces/ProductInfo';
import { Supplier } from '@/interfaces/SupplierInfo';
import Unit from '@/model/unit/Unit';
import ExcelFileSelectStep from '@/components/organisms/ProductImportModal/ExcelFileSelectStep';
import TabPanel from '@/components/molecules/TabPanel';
import ExcelImporterStep from '@/components/organisms/ProductImportModal/ExcelImporterStep';
import { useSelector } from 'react-redux';
import { selectCurrentPriceFormationTypeId } from '@/redux/selectors/priceFormationTypeSelector';
import useClasses from './style';

const steps = ['Выберите Excel файл', 'Результат обработки файла', 'Импорт данных'];

interface Props {
  isOpen: boolean;
  onClose(): void;
}

const ProductImportModal: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const classes = useClasses();

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [units, setUnits] = useState<Unit[]>([]);
  const [errors, setErrors] = useState<ExcelImportError[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [file, setFile] = useState(null);

  const priceFormationTypeId = useSelector(selectCurrentPriceFormationTypeId);

  const handleFileChange = useCallback((newFIle) => {
    setActiveStep(1);
    setFile(newFIle);
    setLoading(true);
    postExcelFileWithProducts(newFIle)
      .then((data) => {
        setProducts(data.products);
        setSuppliers(data.suppliers);
        setErrors(data.errors);
        setUnits(data.units);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleProductDelete = useCallback((product) => {
    setProducts((prevProducts) => (
      prevProducts.filter((item) => item !== product)
    ));
  }, []);

  const handleBackClick = useCallback(() => {
    setActiveStep((prevState) => prevState - 1);
  }, []);

  const handleNextClick = useCallback(() => {
    setActiveStep((prevState) => prevState + 1);
  }, []);

  return (
    <ModalWindow
      loading={loading}
      header={TEXT.HEADER.DATA_IMPORT}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box className={classes.container} minWidth={800}>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <TabPanel
          value={activeStep}
          index={0}
          p={0}
        >
          <ExcelFileSelectStep
            onNext={handleNextClick}
            loading={loading}
            file={file}
            onFileChange={handleFileChange}
          />
        </TabPanel>
        <TabPanel
          value={activeStep}
          index={1}
          p={0}
        >
          <ExcelParseResult
            onBack={handleBackClick}
            onNext={handleNextClick}
            onDelete={handleProductDelete}
            loading={loading}
            errors={errors}
            products={products}
            suppliers={suppliers}
            units={units}
          />
        </TabPanel>
        <TabPanel
          value={activeStep}
          index={2}
          p={0}
        >
          <ExcelImporterStep
            priceFormationTypeId={priceFormationTypeId}
            onBack={handleBackClick}
            onClose={onClose}
            products={products}
            suppliers={suppliers}
            units={units}
          />
        </TabPanel>
      </Box>
    </ModalWindow>
  );
};

export default ProductImportModal;
