import React, { Component } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsIcon from '@material-ui/icons/Settings';
import { logout } from '@/utils/fetch';
import { TEXT } from '@/utils/Text';
import Divider from '@/components/atoms/Divider/Divider';
import SettingsModal from '@/components/organisms/SettingsModal/SettingsModal';
import ProductImportModal from '@/components/organisms/ProductImportModal';
import CloudDownload from '@material-ui/icons/CloudDownload';
import PersonalData from '@/components/organisms/PersonalData/PersonalData';
import { withStyles } from '@material-ui/core/styles';
import Path from '@/enum/Path';
import { withRouter } from 'react-router';
import { HeaderMenuContainer } from './HeaderMenuContainer';
import MenuItem from '../MenuItem/MenuItem';
import ListItemText from '../ListItemText/ListItemText';
import ListItemIcon from '../ListItemIcon/ListItemIcon';
import IconButton from '../IconButton';
import CustomizedMenu from '../CustomizedMenu/CustomizedMenu';

class HeaderMenu extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      isMounted: false,
      personalDataOpened: false,
      settingsOpened: false,
      productImportModalOpened: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onExit = () => {
    this.setState({ loading: true });
    logout()
      .then(() => {
        this.props.dispatchAnonymousUser();
        this.props.history.push(Path.LOGIN);
      })
      .catch((error) => {
        this.setState({ loading: false });
        return Promise.reject(error);
      });
  };

  onSettingsOpen = () => {
    this.handleClose();
    this.setState({ settingsOpened: true });
  };

  onSettingsClose = () => {
    this.setState({ settingsOpened: false });
  };

  onPersonalDataOpen = () => {
    this.handleClose();
    this.setState({ personalDataOpened: true });
  };

  onPersonalDataClose = () => {
    this.setState({ personalDataOpened: false });
  };

  onImportButtonClick = () => {
    this.handleClose();
    this.setState({ productImportModalOpened: true });
  };

  onProductImportModalClose = (event) => {
    this.setState({ productImportModalOpened: false });
  };

  render() {
    const { classes } = this.props;
    const {
      anchorEl,
      loading,
      personalDataOpened,
      settingsOpened,
      productImportModalOpened,
    } = this.state;
    return (
      <div>
        <IconButton
          onClick={this.handleClick}
        >
          <MenuIcon
            classes={{ root: classes.iconRoot }}
            fontSize="large"
          />
        </IconButton>
        <CustomizedMenu
          anchorEl={anchorEl}
          onClose={this.handleClose}
        >
          <MenuItem
            onClick={this.onPersonalDataOpen}
          >
            <ListItemIcon>
              <PermIdentityIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={TEXT.HEADER.PERSONAL_DATA} />
          </MenuItem>
          <MenuItem
            onClick={this.onImportButtonClick}
          >
            <ListItemIcon>
              <CloudDownload fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={TEXT.HEADER.DATA_IMPORT} />
          </MenuItem>
          <MenuItem
            onClick={this.onSettingsOpen}
          >
            <ListItemIcon>
              <SettingsIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={TEXT.HEADER.SETTINGS} />
          </MenuItem>
          <Divider
            className="header-menu-divider"
            horizontal
          />
          <MenuItem
            disabled={loading}
            onClick={this.onExit}
          >
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText primary={TEXT.HEADER.EXIT} />
          </MenuItem>
        </CustomizedMenu>
        {personalDataOpened
        && (
          <PersonalData
            onClose={this.onPersonalDataClose}
            isOpen={personalDataOpened}
          />
        )}
        {settingsOpened
        && (
          <SettingsModal
            onClose={this.onSettingsClose}
            isOpen={settingsOpened}
          />
        )}
        {productImportModalOpened
        && (
          <ProductImportModal
            onClose={this.onProductImportModalClose}
            isOpen={productImportModalOpened}
          />
        )}
      </div>
    );
  }
}

export default withStyles((theme) => ({
  iconRoot: {
    color: '#FFFFFF',
  },
}))(HeaderMenuContainer(withRouter(HeaderMenu)));
