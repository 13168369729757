import React, {
  FC, memo, useCallback, useState,
} from 'react';
import IconButton from '@/components/atoms/IconButton';
import { TEXT } from '@/utils/Text';
import { AddCircle, Create } from '@material-ui/icons';
import ProductActionModal from '@/components/organisms/ProductActionModal';
import { ProductActionIconProps } from '@/components/atoms/ProductActionIcon/productActionIcon.types';

const ProductActionIcon: FC<ProductActionIconProps> = ({
  disabled,
  productId,
  onAction,
  priceFormationTypeId,
}) => {
  const [isDialogOpened, setDialogOpened] = useState(false);

  const handleActionClick = useCallback(() => {
    setDialogOpened(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogOpened(false);
  }, []);

  return (
    <>
      <div className="flex-bottom mr-10">
        {productId
          ? (
            <IconButton
              onClick={handleActionClick}
              title={TEXT.BUTTON.UPDATE}
              disabled={disabled}
            >
              <Create color="primary" />
            </IconButton>
          )
          : (
            <IconButton
              onClick={handleActionClick}
              title={TEXT.BUTTON.ADD}
              disabled={disabled}
            >
              <AddCircle color="primary" />
            </IconButton>
          )}
      </div>
      {isDialogOpened && (
        <ProductActionModal
          isOpened={isDialogOpened}
          onClose={handleDialogClose}
          productId={productId}
          onAction={onAction}
          priceFormationTypeId={priceFormationTypeId}
        />
      )}
    </>
  );
};

export default memo(ProductActionIcon);
