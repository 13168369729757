import { Action, handleActions } from 'redux-actions';
import replaceOneById, { deleteById } from '@/utils/arrayUtils';
import { VendorActions } from '@/redux/actions/vendorActions';
import Vendor from '@/interfaces/Vendor';

const initialState: Vendor[] = [];

const processSet = (
  state: Vendor[],
  action: Action<Vendor[]>,
): Vendor[] => action.payload;

const processUpdateOne = (
  state: Vendor[],
  action: Action<Vendor>,
): Vendor[] => replaceOneById<Vendor>(state, action.payload);

const processDelete = (
  state: Vendor[],
  action: Action<string>,
): Vendor[] => deleteById(state, action.payload);

const processAdd = (
  state: Vendor[],
  action: Action<Vendor>,
): Vendor[] => [action.payload, ...state];

const vendorReducer = handleActions<Vendor[], VendorReducerPayload>({
  [VendorActions.Type.SET]: processSet,
  [VendorActions.Type.UPDATE_ONE]: processUpdateOne,
  [VendorActions.Type.DELETE_ONE]: processDelete,
  [VendorActions.Type.ADD]: processAdd,
},
initialState);

type VendorReducerPayload = Vendor & Vendor[] & string;

export default vendorReducer;
