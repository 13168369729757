import React, { FC, MouseEventHandler } from 'react';
import IconButton from '@/components/atoms/IconButton';
import { TEXT } from '@/utils/Text';
import { ShoppingCart } from '@material-ui/icons';
import OrderActionModal from '@/components/atoms/OrderActionIcon/OrderActionModal';

interface Props {
  disabled: boolean;
  loading: boolean;
  onFormNew(): void;
  onUpdateCurrent(): void;
  isOpened: boolean;
  onOpen(event: MouseEventHandler): void;
  onClose(): void;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
}

const OrderActionIcon: FC<Props> = ({
  disabled,
  onFormNew,
  loading,
  onUpdateCurrent,
  isOpened,
  onOpen,
  onClose,
  fontSize,
}) => (
  <>
    <IconButton
      onClick={onOpen}
      title={TEXT.ORDER}
      disabled={disabled}
    >
      <ShoppingCart
        fontSize={fontSize}
      />
    </IconButton>
    <OrderActionModal
      onClose={onClose}
      isOpened={isOpened}
      onUpdateCurrent={onUpdateCurrent}
      onFormNew={onFormNew}
      loading={loading}
    />
  </>
);

export default OrderActionIcon;
