import React, { FC, useCallback, useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import { TEXT } from '@/utils/Text';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import ReconciliationActSubTab from '@/components/organisms/ReconciliationActSubTab';
import { AssignmentInd, MonetizationOn } from '@material-ui/icons';
import IncomeSubTab from '@/components/organisms/IncomeSubTab';

const ReportTab: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          onChange={onChange}
          selectedIndex={selectedTabIndex}
          index={0}
          label={TEXT.INCOME}
          icon={<MonetizationOn fontSize="small" />}
        />
        <StyledBreadcrumb
          onChange={onChange}
          selectedIndex={selectedTabIndex}
          index={1}
          label={TEXT.RECONCILIATION_ACT}
          icon={<AssignmentInd fontSize="small" />}
        />
      </Breadcrumbs>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <IncomeSubTab />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <ReconciliationActSubTab />
      </SubTabPanel>
    </div>
  );
};

export default ReportTab;
