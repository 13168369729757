import { TEXT } from '@/utils/Text';
import React, { useMemo } from 'react';
import PhoneField from '@/components/atoms/PhoneField/PhoneField';
import { Column } from 'material-table';

const usePhoneNumberColumn = (
  column?,
  error?,
  removable?,
  required = false,
): Column<any> => useMemo(() => ({
  removable,
  width: 300,
  hidden: !!column?.hidden,
  title: TEXT.COLUMN.HEADER.PHONE_NUMBER,
  field: 'phoneNumber',
  editComponent: ({ value, onChange }) => (
    <PhoneField
      inputExtraProps={{
        error: !!error,
        required,
        helperText: error,
      }}
      value={value || ''}
      onChange={(val) => onChange(val)}
    />
  ),

}), [required, removable, column, error]);

export default usePhoneNumberColumn;
