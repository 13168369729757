import { Popper } from '@material-ui/core';
import React, { FC } from 'react';

const AutocompletePopper: FC = ({
  anchorEl,
  minWidth = 400,
  ...other
}) => (
  <Popper
    {...other}
    anchorEl={anchorEl}
    style={{ width: anchorEl && anchorEl.offsetWidth < minWidth ? minWidth : anchorEl.offsetWidth }}
    placement="bottom-start"
		/>
);

export default AutocompletePopper;
