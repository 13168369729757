import useProductForOrderColumn from '@/hook/columns/useProductForOrderColumn';
import useWholeSaleColumns from '@/hook/columns/useWholeSaleColumns';
import useCountColumn from '@/hook/columns/useCountColumn';
import usePriceColumn from '@/hook/columns/usePriceColumn';
import useSumColumn from '@/hook/columns/useSumColumn';
import useProducingDateColumn from '@/hook/columns/useProducingDateColumn';
import { useMemo } from 'react';

const useOrderItemColumns = (
  supplierId,
  columns,
  storeEnabled,
  saleType,
  errors = {},
  external,
) => {
  const productColumn = useProductForOrderColumn(storeEnabled, supplierId, errors.productId);
  const countColumn = useCountColumn(false, columns.count, errors.count, saleType);
  const wholeSaleColumns = useWholeSaleColumns(columns, errors, saleType, external);
  const priceColumn = usePriceColumn(columns.price, errors.price, saleType, external);
  const sumColumn = useSumColumn(columns.sum);
  const producingDateColumn = useProducingDateColumn(columns.producingDate, errors.producingDate, external);

  return useMemo(() => [
    productColumn,
    countColumn,
    ...wholeSaleColumns,
    priceColumn,
    sumColumn,
    producingDateColumn,
  ], [
    productColumn,
    countColumn,
    wholeSaleColumns,
    priceColumn,
    sumColumn,
    producingDateColumn,
  ]);
};

export default useOrderItemColumns;
