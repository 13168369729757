import React from 'react';
import cx from 'classnames';

const Divider = ({
  horizontal = false,
  noIndents = false,
  className = undefined,
}) => (
  <hr
    className={cx({
			  'horizontal-divider': horizontal,
			  'vertical-divider': !horizontal,
			  'm-0': noIndents,
    }, className)}
		/>
);

export default Divider;
