import React from 'react';

function HeaderLogo() {
  return (
    <svg
      style={{ width: 185, height: 60 }}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1521.000000pt"
      height="500.000000pt"
      viewBox="0 0 1521.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
    >

      <g // rgb(171, 200, 216)
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill="#FFFFFF"
        stroke="none"
      >
        <path d="M2205 4988 c-105 -12 -298 -51 -409 -83 -400 -116 -765 -333 -1066
-635 -407 -406 -658 -928 -719 -1492 -14 -125 -14 -431 0 -556 61 -564 312
-1086 719 -1492 406 -407 928 -658 1492 -719 125 -14 431 -14 556 0 564 61
1086 312 1492 719 407 406 658 928 719 1492 16 142 13 444 -4 588 -69 561
-312 1056 -715 1460 -405 406 -928 658 -1492 719 -115 13 -459 12 -573 -1z
m420 -373 c196 -50 359 -209 410 -401 21 -76 21 -222 0 -298 -52 -194 -218
-354 -416 -401 -186 -43 -376 12 -514 150 -116 116 -166 236 -166 400 0 165
50 285 166 401 140 139 337 196 520 149z m-676 -1963 c295 -415 540 -755 545
-755 14 -3 818 1133 813 1147 -3 8 -60 91 -127 186 -67 94 -120 174 -117 176
2 2 360 3 794 2 l791 -3 -1071 -1511 c-590 -832 -1076 -1512 -1082 -1512 -7 0
-1847 2583 -2127 2986 l-29 42 537 -2 537 -3 536 -753z"
        />
        <path d="M12561 4640 c-271 -38 -437 -182 -513 -445 l-23 -80 0 -905 0 -905
23 -80 c36 -124 79 -202 152 -275 125 -126 258 -173 490 -173 232 0 365 47
490 173 73 73 116 151 152 275 l23 80 0 905 0 905 -23 80 c-36 124 -79 202
-152 275 -76 76 -157 122 -266 150 -89 23 -261 33 -353 20z m233 -427 c45 -22
65 -43 90 -88 l21 -40 0 -875 c0 -836 -1 -877 -19 -910 -43 -81 -100 -114
-196 -114 -96 0 -153 33 -196 114 -18 33 -19 74 -19 910 0 836 1 877 19 910
33 63 72 93 141 111 40 11 121 1 159 -18z"
        />
        <path d="M5530 4605 c0 -10 420 -2740 426 -2768 l6 -27 331 0 332 0 216 1400
215 1400 -207 0 -206 0 -6 -27 c-2 -16 -76 -515 -163 -1111 -88 -603 -162
-1075 -166 -1065 -4 10 -77 502 -163 1093 -86 591 -159 1083 -161 1093 -5 15
-25 17 -230 17 -123 0 -224 -2 -224 -5z"
        />
        <path d="M7240 3210 l0 -1400 605 0 605 0 0 200 0 200 -380 0 -380 0 0 405 0
405 305 0 305 0 0 205 0 205 -305 0 -305 0 0 385 0 385 380 0 380 0 0 205 0
205 -605 0 -605 0 0 -1400z"
        />
        <path d="M8710 3210 l0 -1400 205 0 205 0 2 976 3 975 254 -943 c140 -519 258
-958 263 -975 l9 -33 229 0 230 0 0 1400 0 1400 -200 0 -200 0 -2 -804 -3
-804 -216 801 -215 802 -282 3 -282 2 0 -1400z"
        />
        <path d="M10420 3210 l0 -1400 313 -2 c411 -3 507 0 597 19 244 51 385 204
436 471 21 108 21 1716 0 1824 -51 266 -189 416 -433 472 -60 13 -140 16 -492
16 l-421 0 0 -1400z m793 976 c43 -17 93 -76 106 -123 7 -25 11 -315 11 -853
0 -538 -4 -828 -11 -853 -13 -48 -64 -106 -109 -125 -23 -10 -86 -16 -187 -20
l-153 -5 0 997 0 996 155 0 c108 0 166 -4 188 -14z"
        />
        <path d="M13630 3205 l0 -1405 225 0 225 0 0 571 0 571 133 -4 c144 -4 168
-11 225 -69 62 -64 65 -81 72 -534 5 -358 9 -418 25 -474 l18 -64 126 7 c69 3
173 6 230 6 94 0 103 1 96 18 -34 82 -37 123 -45 532 -7 375 -9 423 -29 498
-37 145 -93 230 -190 287 -28 17 -51 33 -51 36 0 3 11 11 25 17 39 18 110 88
145 143 78 120 105 286 98 583 -6 212 -21 301 -70 403 -40 86 -125 175 -201
213 -130 63 -140 64 -619 68 l-438 3 0 -1406z m770 973 c37 -19 51 -34 75 -83
l30 -60 0 -240 c0 -278 -6 -308 -69 -371 -51 -52 -89 -63 -238 -71 l-118 -6 0
426 0 427 138 0 c123 0 143 -2 182 -22z"
        />
        <path d="M13467 1586 c-61 -22 -104 -62 -134 -123 l-28 -58 -3 -403 c-2 -266
1 -422 8 -457 15 -74 59 -138 117 -172 46 -27 54 -28 168 -28 114 0 122 1 168
28 57 34 101 97 116 168 6 28 11 102 11 164 l0 115 -94 0 -94 0 -4 -117 c-3
-65 -9 -126 -13 -136 -4 -10 -21 -25 -37 -33 -39 -20 -88 -11 -119 22 l-24 26
-3 336 c-4 382 -1 431 33 470 32 37 94 38 131 0 25 -24 27 -35 32 -127 l5
-101 93 0 94 0 0 95 c0 170 -36 258 -127 312 -43 25 -58 28 -153 31 -71 1
-117 -2 -143 -12z"
        />
        <path d="M5960 970 l0 -620 100 0 100 0 0 620 0 620 -100 0 -100 0 0 -620z" />
        <path d="M6710 970 l0 -620 95 0 95 0 2 416 3 416 103 -379 c56 -208 107 -395
113 -416 l10 -37 104 0 105 0 0 620 0 620 -90 0 -90 0 -1 -347 -1 -348 -80
295 c-44 162 -86 319 -95 348 l-14 52 -130 0 -129 0 0 -620z"
        />
        <path d="M7840 1500 l0 -90 100 0 100 0 0 -530 0 -530 105 0 105 0 0 530 0
530 100 0 100 0 0 90 0 90 -305 0 -305 0 0 -90z"
        />
        <path d="M8950 970 l0 -620 270 0 270 0 0 90 0 90 -170 0 -170 0 0 175 0 175
135 0 135 0 0 95 0 95 -135 0 -135 0 0 170 0 170 170 0 170 0 0 90 0 90 -270
0 -270 0 0 -620z"
        />
        <path d="M10030 970 l0 -620 100 0 100 0 0 251 0 252 61 -6 c111 -10 129 -53
129 -319 0 -80 3 -153 6 -162 5 -13 25 -16 111 -16 96 0 104 1 97 18 -4 9 -11
111 -14 227 -5 131 -12 223 -20 245 -18 48 -44 83 -73 99 l-25 13 39 35 c61
55 74 104 74 273 0 141 -1 147 -30 205 -32 64 -71 97 -134 115 -20 5 -123 10
-228 10 l-193 0 0 -620z m356 406 c22 -25 24 -35 24 -151 0 -114 -2 -126 -22
-150 -23 -26 -77 -44 -130 -45 l-28 0 0 191 0 191 66 -4 c58 -4 70 -8 90 -32z"
        />
        <path d="M11170 970 l0 -620 100 0 100 0 0 250 0 250 125 0 125 0 0 95 0 95
-125 0 -125 0 0 185 0 185 160 0 160 0 0 90 0 90 -260 0 -260 0 0 -620z"
        />
        <path d="M12320 1584 c0 -10 -189 -1196 -194 -1217 -4 -16 4 -17 92 -15 l97 3
15 100 15 100 116 3 116 3 13 -95 c8 -53 16 -100 20 -106 4 -7 44 -10 106 -8
l100 3 -92 575 c-51 316 -95 594 -99 618 l-6 42 -150 0 c-82 0 -149 -3 -149
-6z m224 -811 l5 -33 -90 0 c-81 0 -90 2 -85 18 2 9 21 130 41 267 20 138 39
257 42 265 3 8 23 -97 44 -235 20 -137 40 -265 43 -282z"
        />
        <path d="M14430 965 l0 -625 270 0 270 0 0 95 0 95 -170 0 -170 0 0 175 0 175
135 0 135 0 0 95 0 95 -135 0 -135 0 0 170 0 170 170 0 170 0 0 90 0 90 -270
0 -270 0 0 -625z"
        />
      </g>
    </svg>
  );
}

export default HeaderLogo;
