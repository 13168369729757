import React, {
  FC, memo, useCallback, useContext, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { TEXT } from '@/utils/Text';
import PhoneField from '@/components/atoms/PhoneField/PhoneField';
import TextArea from '@/components/atoms/TextArea/TextArea';
import { Box, Button } from '@material-ui/core';
import { postClient } from '@/utils/fetch';
import { ClientInfo } from '@/interfaces/ClientInfo';
import { useDispatch } from 'react-redux';
import { createUpdateClientInfo } from '@/redux/actions/clientInfoActions';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import { ClientFieldsBase } from '@/interfaces/ClientFieldsBase';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { ClientRowData } from '@/utils/toOption';
import PriceFormationTypeSelect from '@/components/molecules/PriceFormationTypeSelect';
import { ClientTableErrors } from '../ClientsTable/clientsTable.types';

interface Props {
  isOpened: boolean;

  onClose(): void;

  onAction(newData: ClientRowData): void;
}

const AddClientModal: FC<Props> = ({
  isOpened,
  onClose,
  onAction,
}) => {
  const context = useContext(ApplicationContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState('');
  const [unp, setUnp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [errors, setErrors] = useState<ClientTableErrors>({});
  const [priceFormationTypeId, setPriceFormationTypeId] = useState<string | undefined>();

  const handleOrganizationChange = useCallback((event) => {
    setOrganization(event.target.value);
  }, []);

  const handleUnpChange = useCallback((event) => {
    setUnp(event.target.value);
  }, []);

  const handlePhoneNumberChange = useCallback((number) => {
    setPhoneNumber(number);
  }, []);

  const handleEmailChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const handleAddressChange = useCallback((event) => {
    setAddress(event.target.value);
  }, []);

  const validateClient = useCallback((clientInfo) => {
    const errors: ClientTableErrors = {};
    if (!clientInfo.organization) {
      errors.organization = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onSave = useCallback(() => {
    const data: ClientFieldsBase = {
      organization,
      phoneNumber,
      unp,
      contactEmail: email,
      address,
      priceFormationTypeId,
    };
    if (!validateClient(data)) {
      return;
    }
    setLoading(true);
    postClient(data)
      .then((response: ClientInfo) => {
        dispatch(createUpdateClientInfo(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        onAction!({
          clientFirstName: response.organization,
          clientId: response.id,
          external: response.external,
          priceFormationTypeId: response.priceFormationTypeId,
        });
        onClose();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    onClose,
    priceFormationTypeId,
    validateClient,
    organization,
    phoneNumber,
    unp,
    email,
    address,
    dispatch,
    context,
    errors,
    onAction,
  ]);

  const handlePriceFormationTypeChange = useCallback((value) => {
    setPriceFormationTypeId(value);
  }, []);

  return (
    <ModalWindow
      isOpen={isOpened}
      onClose={onClose}
      header={TEXT.ADD_CLIENT}
    >
      <Box m={2} minWidth={300}>
        <Box pb={2}>
          <CustomInput
            id="name"
            label={`${TEXT.ORGANIZATION} / ${TEXT.NAME}`}
            required
            fullWidth
            value={organization}
            onChange={handleOrganizationChange}
            error={!!errors.organization && errors.organization}
          />
        </Box>
        <Box pb={2}>
          <CustomInput
            id="unp"
            label={TEXT.UNP}
            fullWidth
            value={unp}
            onChange={handleUnpChange}
            error={!!errors.unp && errors.unp}
          />
        </Box>
        <Box pb={2}>
          <PhoneField
            inputExtraProps={{
              error: !!errors.phoneNumber && errors.phoneNumber,
            }}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </Box>
        <Box pb={2}>
          <CustomInput
            id="contactEmail"
            maxLength={40}
            fullWidth
            label={TEXT.CONTACT_EMAIL}
            value={email}
            onChange={handleEmailChange}
            error={!!errors.contactEmail && errors.contactEmail}
          />
        </Box>
        <Box pb={2}>
          <TextArea
            fullWidth
            rows={2}
            label={TEXT.ADDRESS}
            value={address}
            onChange={handleAddressChange}
            error={!!errors.address && errors.address}
          />
        </Box>
        <Box>
          <PriceFormationTypeSelect
            fullWidth
            selectedValue={priceFormationTypeId}
            onChange={handlePriceFormationTypeChange}
          />
        </Box>
      </Box>
      <Box className="flex-center">
        <Button
          disabled={loading}
          onClick={onSave}
        >
          {TEXT.BUTTON.SAVE}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default memo(AddClientModal);
