import { round } from '@/utils/Utils';

class PriceService {
  static calculatePriceNoVat(supplierPrice, markup = 0) {
    return round(supplierPrice * (markup / 100 + 1)) || 0;
  }

  static calculateMarkup(supplierPrice, price, vat) {
    const priceNoVat = round(price / (vat / 100 + 1));
    const markup = priceNoVat / supplierPrice;
    return round((markup - 1) * 100) || 0;
  }

  static calculatePrice(supplierPrice, markup, vat) {
    const priceNoVat = PriceService.calculatePriceNoVat(supplierPrice, markup);
    return round(priceNoVat * (vat / 100 + 1)) || 0;
  }

  static calculateSumNoVat(supplierPrice, markup, count) {
    return round(PriceService.calculatePriceNoVat(supplierPrice, markup) * count) || 0;
  }

  static calculatePriceNoMarkup(supplierPrice, vat) {
    return round(supplierPrice * (vat / 100 + 1)) || 0;
  }

  static calculateSumNoVatAndNoMarkup(supplierPrice, count) {
    return round(supplierPrice * count) || 0;
  }

  static calculateSum(supplierPrice, markup, count, vat) {
    return round(PriceService.calculateSumNoVat(supplierPrice, markup, count) * (vat / 100 + 1)) || 0;
  }

  static calculateSumNorMarkup(supplierPrice, count, vat) {
    return round(PriceService.calculateSumNoVatAndNoMarkup(supplierPrice, count) * (vat / 100 + 1)) || 0;
  }

  static calculateVatSum(supplierPrice, markup, count, vat) {
    return round(PriceService.calculateSum(supplierPrice, markup, count, vat) - PriceService.calculateSumNoVat(supplierPrice, markup, count)) || 0;
  }

  static calculateVatSumNoMarkup(supplierPrice, count, vat) {
    return round(PriceService.calculateSumNorMarkup(supplierPrice, count, vat) - PriceService.calculateSumNoVatAndNoMarkup(supplierPrice, count)) || 0;
  }

  static calculateSumBySale(sale) {
    return round(sale.items.reduce((prev, next) => prev + next.sum, 0), 2);
  }

  static calculate(supplierPrice, vat, markup) {
    const price = PriceService.calculatePriceNoVat(supplierPrice, markup);
    const _vat = vat / 100 + 1;
    return round(price * _vat, 2);
  }

  static calculateSupplierOrderItemSum(count, supplierPrice, vat) {
    return round(round(count * supplierPrice, 2) * (vat / 100 + 1), 2);
  }
}

export default PriceService;
