import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Button from '@/components/atoms/Button';
import { TEXT } from '@/utils/Text';
import { FilterList } from '@material-ui/icons';
import OutsideToolBar from '@/components/atoms/OutsideToolBar';
import PaperPopover from '@/components/atoms/PaperPopper';
import { Box, DialogActions, Grid } from '@material-ui/core';
import KeyboardDateTimePicker from '@/components/atoms/KeyboardDateTimePicker/KeyboardDateTimePicker';
import moment, { Moment } from 'moment';
import ClientAutocomplete from '@/components/molecules/ClientInput';
import { useSelector, useDispatch } from 'react-redux';
import { selectSaleTableFilter } from '@/redux/selectors/saleTableConfigSelector';
import { ClientInfo } from '@/interfaces/ClientInfo';
import { AutocompleteOption, toOption } from '@/utils/toOption';
import SaleStateAutocomplete from '@/components/molecules/SaleStateAutocomplete';
import { SaleState } from '@/enum/SaleState';
import { Attribute } from '@/interfaces/Attribute';
import AttributeEditComponent from '@/components/molecules/AttributeEditComponent';
import SaleTableConfigActions from '@/redux/actions/saleTableConfigActions';
import useStyles from './outsideSaleToolBar.styles';

function OutsideSaleToolBar() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef(null);

  const filter = useSelector(selectSaleTableFilter);

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Moment>(filter.startDate);
  const [endDate, setEndDate] = useState<Moment>(filter.endDate);
  const [clientOptions, setClientOptions] = useState<AutocompleteOption<ClientInfo>[]>(
    filter.clients.map((client) => toOption(client.organization, client, client.id)!),
  );
  const [saleStates, setSaleStates] = useState<SaleState[]>(filter.states);
  const [attributes, setAttributes] = useState<Attribute[]>(filter.attributes);

  useEffect(() => {
    if (open) {
      return;
    }
    setStartDate(filter.startDate);
    setEndDate(filter.endDate);
    setClientOptions(
      filter.clients.map((client) => toOption(client.organization, client, client.id)!),
    );
    setSaleStates(filter.states);
    setAttributes(filter.attributes);
  }, [filter, open]);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleApplyClick = useCallback(() => {
    setOpen(false);
    dispatch(SaleTableConfigActions.setFilter({
      attributes,
      states: saleStates,
      clients: clientOptions.map((clientOption) => clientOption.data),
      startDate,
      endDate,
    }));
  }, [attributes, saleStates, clientOptions, startDate, endDate, dispatch]);

  const handleStartDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setStartDate(moment(value));
    } else {
      setStartDate(value);
    }
  }, []);

  const handleEndDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setEndDate(moment(value));
    } else {
      setEndDate(value);
    }
  }, []);

  return (
    <OutsideToolBar>
      <Button
        refElement={ref}
        onClick={handleClick}
        startIcon={<FilterList />}
        text={TEXT.FILTERS}
        variant={open ? 'contained' : 'outlined'}
        color="primary"
      />
      <PaperPopover
        className={classes.paper}
        onClose={handleClose}
        open={open}
        anchorEl={ref.current}
      >
        <Box
          m={3}
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          flexDirection="column"
        >
          <Grid
            container
            spacing={2}
            direction="column"
          >
            <Grid item>
              <Box display="flex">
                <Box mr={2}>
                  <KeyboardDateTimePicker
                    classes={{ root: classes.dateInput }}
                    label={TEXT.START_DATE}
                    onChange={handleStartDateChange}
                    value={startDate}
                  />
                </Box>
                <KeyboardDateTimePicker
                  classes={{ root: classes.dateInput }}
                  label={TEXT.END_DATE}
                  onChange={handleEndDateChange}
                  value={endDate}
                />
              </Box>
            </Grid>
            <Grid item>
              <SaleStateAutocomplete
                value={saleStates}
                onChange={setSaleStates}
              />
            </Grid>
            <Grid item>
              <AttributeEditComponent
                value={attributes}
                onChange={setAttributes}
              />
            </Grid>
            <Grid item>
              <ClientAutocomplete
                multiple
                value={clientOptions}
                // @ts-ignore
                onChange={setClientOptions}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button
            onClick={handleClose}
            text={TEXT.CANCEL}
            color="primary"
            size="small"
            variant="outlined"
          />
          <Button
            onClick={handleApplyClick}
            size="small"
            color="primary"
            text={TEXT.BUTTON.APPLY}
          />
        </DialogActions>
      </PaperPopover>
    </OutsideToolBar>
  );
}

export default OutsideSaleToolBar;
