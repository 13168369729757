import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useClasses } from '@/components/atoms/OptionChipColor/optionChipColor.styles';

const OptionChipColor: FC<OptionChipColorProps> = ({
  color,
}) => {
  const classes = useClasses();

  return (
    <Box
      className={classes.container}
      style={{ backgroundColor: color }}
    />
  );
};

interface OptionChipColorProps {
  color: string;
}

export default OptionChipColor;
