import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import {
  Add, Clear, Delete, Edit, Search,
} from '@material-ui/icons';
import { TEXT } from '@/utils/Text';
import InputField from '../TextField/InputField';

class EditableList extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      newDocName: '',
      searchName: '',
    };
  }

  onChange = ({ target: { id, value } }) => {
    this.setState({ [id]: value });
  };

  onAdd = () => {
    this.setState({ newDocName: '' });
    this.props.onAdd(this.state.newDocName);
  };

  getFilteredList = () => {
    const { searchName } = this.state;
    const { list } = this.props;
    return list.filter((item) => item.label.toUpperCase().includes(searchName.toUpperCase()));
  };

  render() {
    const {
      classes,
      onSelect,
      onDelete,
      loading,
      selectedDocument,
    } = this.props;
    const {
      searchName,
      newDocName,
    } = this.state;
    return (
      <div className={classes.wrapper}>
        <div className="d-flex flex-center">
          <Search
            className={classes.icon}
            color="primary"
          />
          <ListItemText
            primary={(
              <>
                <InputField
                  id="searchName"
                  onChange={this.onChange}
                  value={searchName}
                  placeholder="Найти"
                />
                <IconButton
                  onClick={() => this.setState({ searchName: '' })}
                  edge="end"
                  title={TEXT.CLEAR}
                >
                  <Clear
                    color="primary"
                  />
                </IconButton>
              </>
     )}
          />
        </div>
        <List className={classes.root}>
          {this.getFilteredList().map((item, i) => {
					  const { label, value } = item;
					  return (
  <ListItem
    key={`document-item-${i}`}
    dense
    button
    selected={selectedDocument === value}
    onClick={() => onSelect(value, item)}
  >
    <ListItemText
      title={label}
      className={classes.listItem}
      primary={label}
    />

    {!value.isDefault && (
    <>
      <ListItemSecondaryAction>
        {/* <IconButton edge="end" aria-label="edit"> */}
        {/*	<Edit */}
        {/*		color={"action"} */}
        {/*	/> */}
        {/* </IconButton> */}
        <IconButton
          onClick={() => onDelete(value.id)}
          disabled={loading}
          edge="end"
          title={TEXT.BUTTON.DELETE}
        >
          <Delete
            color="primary"
          />
        </IconButton>
      </ListItemSecondaryAction>
    </>
    )}
  </ListItem>
					  );
          })}
        </List>
        <div className="d-flex flex-center">
          <ListItemIcon>
            <IconButton
              onClick={this.onAdd}
              disabled={!newDocName || loading}
              edge="end"
              title={TEXT.BUTTON.ADD}
            >
              <Add
                color="primary"
              />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={(
              <InputField
                id="newDocName"
                value={newDocName}
                onChange={this.onChange}
                placeholder="Новый"
              />
     )}
          />
        </div>
      </div>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    height: 180,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    margin: '10px',
    width: '25px',
    height: '25px',
  },
  wrapper: {
    width: 280,
  },
  listItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginRight: 60,
  },
}))(EditableList);
