import React, { Component } from 'react';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { TEXT } from '@/utils/Text';
import IconButton from '../IconButton';

class ExportButton extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      ...props
    } = this.props;
    return (
      <IconButton
        title={TEXT.EXPORT}
        className="mr-10"
        {...props}
      >
        <CloudUpload
          fontSize="large"
        />
      </IconButton>
    );
  }
}

export default ExportButton;
