import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    right: 5,
    padding: '5px 5px 5px 10px',
    zIndex: 5,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    paddingTop: 15,
  },
}));
