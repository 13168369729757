import { COLUMN_VISIBILITY_CHANGE, COLUMN_VISIBILITY_CHANGING, SET_CUSTOM_TABLES } from '@/constants/action';
import ID from '@/utils/id';
import Column from '@/model/column/Column';
import ColumnService from '@/model/column/ColumnService';

const handleColumnVisibilityChange = (state, action) => {
  const { payload } = action;
  const { tableId, columnField, hidden } = payload;
  const columns = { ...state[tableId] };
  const column = columns[columnField];
  if (!column) {
    columns[columnField] = new Column(columnField, hidden);
  } else {
    columns[columnField] = ColumnService.parse({ ...column, hidden });
  }
  return { ...state, [tableId]: columns };
};

function handleSetCustomTable(state, action) {
  const customTables = action.payload.customTables
    .reduce(
      (accumulator, customTable) => {
        accumulator[customTable.field] = customTable.columns
          .reduce(
            (accumulatorColumn, column) => {
              accumulatorColumn[column.field] = column;
              return accumulatorColumn;
            }, {},
          );
        return accumulator;
      }, {},
    );
  return {
    ...state,
    ...customTables,
  };
}

export default function tableReducer(state = {
  [ID.SUPPLIER_TABLE]: [],
  [ID.PRODUCT_TABLE]: [],
  [ID.SUPPLIER_ORDER_TABLE]: [],
  [ID.SUPPLIER_ORDER_ITEM_TABLE]: [],
  [ID.STORE_TABLE]: [],
  [ID.SALE_TABLE]: [],
  [ID.SALE_ITEM_TABLE]: [],
  [ID.CLIENT_TABLE]: [],
  [ID.CLIENT_PATTERN_TABLE]: [],
  [ID.CLIENT_PATTERN_ITEM_TABLE]: [],
  creditTable: [],
  [ID.RESTITUTION_TABLE]: [],
  [ID.RESTITUTION_ITEMS_TABLE]: [],
  vendorTable: [],
}, action) {
  const { type } = action;
  switch (type) {
    case COLUMN_VISIBILITY_CHANGE:
      return handleColumnVisibilityChange(state, action);
    case COLUMN_VISIBILITY_CHANGING:
      return { ...state, loading: action.payload.loading };
    case SET_CUSTOM_TABLES:
      return handleSetCustomTable(state, action);
    default:
      return state;
  }
}
