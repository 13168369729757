import { TEXT } from '@/utils/Text';
import { compareDate } from '@/utils/comparator';
import moment from 'moment';
import { dateFormat } from '@/app/applicationSettings';
import React, { useCallback, useMemo } from 'react';
import CustomDatePickerInput from '@/components/organisms/StyledTable/Input/CustomDatePickerInput';

export const useProducingDateColumn = (
  column,
  error,
  disabled = false,
) => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomDatePickerInput
      error={error}
      label={TEXT.COLUMN.HEADER.PRODUCING_DATE}
      onChange={(date) => {
        onChange(moment(date).format(dateFormat));
      }}
      value={value ? moment(value, dateFormat) : moment(Date.now())}
    />
  ), [error]);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.PRODUCING_DATE,
    field: 'producingDate',
    customSort: (a, b) => compareDate(a.producingDate, b.producingDate),
    initialEditValue: moment(Date.now()).format(dateFormat),
    editComponent: handleEditComponent,
    width: 150,
    editable: disabled ? 'never' : undefined,
  }), [column, disabled, handleEditComponent]);
};

export default useProducingDateColumn;
