import { RequestState } from '@/enum/RequestState';
import React, { ReactElement } from 'react';
import CheckIcon from '@/components/atoms/CheckIcon/CheckIcon';
import Button from '@/components/atoms/Button';

interface ConnectRequestLinkProps {
  state?: RequestState,
  onClick: Function,
  disabled: boolean,
  label: string | ReactElement
}

function ConnectRequestLink({
  state,
  onClick,
  disabled,
  label,
}: ConnectRequestLinkProps) {
  function getIconColor() {
    if (!state) {
      return 'action';
    }
    switch (state) {
      case RequestState.CONFIRMED:
        return 'primary';
      case RequestState.PENDING:
        return 'secondary';
      case RequestState.DECLINED:
        return 'error';
      default:
        return 'action';
    }
  }

  return (
    <Button
      variant="outlined"
      startIcon={(
        <CheckIcon
          color={getIconColor()}
        />
  )}
      disabled={disabled}
      onClick={onClick}
      text={label}
    />
  );
}

export default ConnectRequestLink;
