import { StoreState } from '@/redux/stores/store';
import PriceFormationType from '@/interfaces/PriceFormationType';
import { createSelector } from 'reselect';

export const selectCustomPriceFormationTypes = (state: StoreState): PriceFormationType[] => (
  state.priceFormationTypes.customPriceFormationTypes
);

export const selectCurrentPriceFormationType = (
  state: StoreState,
): PriceFormationType => (
  state.priceFormationTypes.currentPriceFormationType
);

export const selectCurrentPriceFormationTypeId = createSelector(
  selectCurrentPriceFormationType,
  (state: PriceFormationType): string => state.id,
);

export const selectPriceFormationTypeLoaded = (
  state: StoreState,
): boolean => (
  state.priceFormationTypes.loaded
);

export default selectCustomPriceFormationTypes;
