import React, { FC } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import useStyles from './styles';

interface Props {
  value: number;
}

const LinearLabeledProgress: FC<Props> = ({
  value,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress
          value={value}
          classes={classes}
          color="primary"
          variant="determinate"
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearLabeledProgress;
