import { SupplierInfoActions } from '@/redux/actions/supplierInfoActions';
import { SupplierInfo } from '@/interfaces/SupplierInfo';

import { Action, handleActions } from 'redux-actions';
import replaceOneById, { deleteById } from '@/utils/arrayUtils';

const initialState: SupplierInfo[] = [];

const processSet = (
  state: SupplierInfo[],
  action: Action<SupplierInfo[]>,
): SupplierInfo[] => action.payload;

const processUpdateOne = (
  state: SupplierInfo[],
  action: Action<SupplierInfo>,
): SupplierInfo[] => replaceOneById<SupplierInfo>(state, action.payload);

const processDelete = (
  state: SupplierInfo[],
  action: Action<string>,
): SupplierInfo[] => deleteById(state, action.payload);

const processAdd = (
  state: SupplierInfo[],
  action: Action<SupplierInfo>,
): SupplierInfo[] => [action.payload, ...state];

const supplierInfoReducer = handleActions<SupplierInfo[], SupplierInfoReducerPayload>({
  [SupplierInfoActions.Type.SET]: processSet,
  [SupplierInfoActions.Type.UPDATE_ONE]: processUpdateOne,
  [SupplierInfoActions.Type.DELETE_ONE]: processDelete,
  [SupplierInfoActions.Type.ADD]: processAdd,
},
initialState);

type SupplierInfoReducerPayload = SupplierInfo & SupplierInfo[] & string;

export default supplierInfoReducer;
