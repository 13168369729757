enum Path {
  INDEX = '/',
  LOADING = '/loading',
  LOGIN = '/login',
  NEW_PASSWORD = '/login/new-password',
  FORGET_PASSWORD = '/login/forget-password',
  REGISTRATION = '/login/registration',
}

export default Path;
