import TextArea from '@/components/atoms/TextArea/TextArea';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { CompanyInfo, CompanyInfoBase } from '@/interfaces/CompanyInfo';
import { Box } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from '@/redux/selectors/applicationSelector';
import { MESSAGE } from '@/utils/message';
import { postCompany, putCompany } from '@/utils/fetch';
import ApplicationContext from '@/app/snackbarContext';
import { TEXT } from '@/utils/Text';
import PhoneField from '@/components/atoms/PhoneField/PhoneField';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { CompanyDialogProps, CompanyInfoErrors } from './addCompanyDialog.types';

const CompanyActionDialog: FC<CompanyDialogProps> = ({
  title,
  isOpened,
  onClose,
  company,
}: CompanyDialogProps) => {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(company ? company.name : '');
  const [phoneNumber, setPhoneNumber] = useState(company ? company.phoneNumber : '');
  const [contactEmail, setContactEmail] = useState(company ? company.contactEmail : '');
  const [address, setAddress] = useState(company ? company.address : '');
  const [description, setDescription] = useState(company ? company.description : '');
  const [errors, setErrors] = useState<CompanyInfoErrors>({});
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  const handleNameChange = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const handlePhoneNumberChange = useCallback((number) => {
    setPhoneNumber(number);
  }, []);

  const handleEmailChange = useCallback((event) => {
    setContactEmail(event.target.value);
  }, []);

  const handleAddressChange = useCallback((event) => {
    setAddress(event.target.value);
  }, []);

  const handleDescriptionChange = useCallback((event) => {
    setDescription(event.target.value);
  }, []);

  const validateCompany = useCallback((companyInfo) => {
    const companyInfoErrors: CompanyInfoErrors = {};
    if (!companyInfo.name) {
      companyInfoErrors.name = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    setErrors(companyInfoErrors);
    return Object.keys(companyInfoErrors).length === 0;
  }, []);

  const onSave = useCallback(() => {
    const data: CompanyInfoBase = {
      name,
      phoneNumber,
      contactEmail,
      address,
      description,
      ownerUserId: userId,
    };
    if (!validateCompany(data)) {
      return;
    }
    setLoading(true);
    postCompany(data)
      .then((response: CompanyInfo) => {
        dispatch(CompanyInfoActions.set(response));
        context({
          message: MESSAGE.SUCCESS_OPERATION,
          variant: 'success',
        });
        onClose();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    validateCompany,
    onClose,
    userId,
    name,
    phoneNumber,
    contactEmail,
    address,
    description,
    dispatch,
    context,
    errors,
  ]);

  const onUpdate = useCallback(() => {
    const data: CompanyInfo = {
      id: company.id,
      name,
      phoneNumber,
      contactEmail,
      address,
      description,
      ownerUserId: userId,
    };
    if (!validateCompany(data)) {
      return;
    }
    setLoading(true);
    putCompany(data)
      .then((response: CompanyInfo) => {
        dispatch(CompanyInfoActions.update(response));
        context({
          message: MESSAGE.SUCCESS_OPERATION,
          variant: 'success',
        });
        onClose();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    name,
    phoneNumber,
    contactEmail,
    address,
    description,
    dispatch,
    context,
    errors,
    validateCompany,
    userId,
    company,
    onClose,
  ]);

  return (
    <ModalWindow
      isOpen={isOpened}
      onClose={onClose}
      header={title}
    >
      <Box m={2} minWidth={400}>
        <Box pb={2}>
          <CustomInput
            id="name"
            label={TEXT.COMPANY_NAME}
            required
            fullWidth
            value={name}
            onChange={handleNameChange}
            error={!!errors.name && errors.name}
          />
        </Box>
        <Box pb={2}>
          <PhoneField
            inputExtraProps={{
              error: !!errors.phoneNumber && errors.phoneNumber,
            }}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </Box>
        <Box pb={2}>
          <CustomInput
            id="contactEmail"
            maxLength={40}
            fullWidth
            label={TEXT.CONTACT_EMAIL}
            value={contactEmail}
            onChange={handleEmailChange}
            error={!!errors.contactEmail && errors.contactEmail}
          />
        </Box>
        <Box pb={2}>
          <TextArea
            inputProps={{
              maxLength: 127,
            }}
            fullWidth
            rows={2}
            label={TEXT.ADDRESS}
            value={address}
            onChange={handleAddressChange}
            error={!!errors.address && errors.address}
          />
        </Box>
        <TextArea
          inputProps={{
            maxLength: 127,
          }}
          fullWidth
          rows={2}
          label={TEXT.DESCRIPTION}
          value={description}
          onChange={handleDescriptionChange}
          error={!!errors.description && errors.description}
        />
      </Box>
      <Box className="flex-center">
        <Button
          disabled={loading}
          onClick={company ? onUpdate : onSave}
        >
          {TEXT.BUTTON.SAVE}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default CompanyActionDialog;
