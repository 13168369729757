import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  balanceItem: {
    fontSize: 15,
    padding: '5px 10px',
    margin: '0 10px',
    wordBreak: 'break-all',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid #eaeaea',
    boxShadow: theme.shadows[1],
    paddingLeft: theme.spacing(1),
    '&:focus': {
      borderRadius: 10,
      border: '2px solid #1b3d58',
    },
    '&:hover': {
      borderRadius: 10,
      border: '2px solid #1b3d58',
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 600,
    marginRight: 5,
  },
  selected: {
    fontSize: 15,
    borderRadius: 10,
    border: '2px solid #1b3d58',
    boxShadow: `${theme.shadows[5]} !important`,
  },
}));
