import { TEXT } from '@/utils/Text';
import React, { useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';

const useDescriptionColumn = (
  column = null,
  error = null,
) => useMemo(() => ({
  hidden: column ? column.hidden : false,
  title: TEXT.DESCRIPTION,
  field: 'description',
  editComponent: (props) => (
    <CustomInput
      error={error}
      maxLength={127}
      id="description"
      label={TEXT.DESCRIPTION}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
    />
  ),
}), [error, column]);

export default useDescriptionColumn;
