import React, { useCallback, useEffect, useState } from 'react';
import StoreItemsTable from '@/components/organisms/StoreItemsTable';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import { TEXT } from '@/utils/Text';
import HomeIcon from '@material-ui/icons/Home';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import PreorderTable from '@/components/organisms/PreorderTable';
import { ShoppingCart } from '@material-ui/icons';

function StoreTab() {
  const dispatch = useDispatch();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  useEffect(() => {
    dispatch(CompanyUserInfoActions.setSelectionEnabled(true));
    return () => {
      dispatch(CompanyUserInfoActions.setSelectionEnabled(false));
    };
  }, [dispatch]);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={0}
          label={TEXT.MY_STORE}
          icon={<HomeIcon fontSize="small" />}
          onChange={handleChange}
        />
        <StyledBreadcrumb
          selectedIndex={selectedTabIndex}
          index={1}
          icon={<ShoppingCart fontSize="small" />}
          label={TEXT.PREORDER}
          onChange={handleChange}
        />
      </Breadcrumbs>
      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <StoreItemsTable />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <PreorderTable />
      </SubTabPanel>
    </Box>
  );
}

export default StoreTab;
