import { TEXT } from '@/utils/Text';
import { useCallback, useMemo } from 'react';
import { compareDateTime } from '@/utils/comparator';

const useRestitutionTimeColumn = (
  column,
  title = TEXT.RESTITUTION_DATE,
) => {
  const handleCustomSort = useCallback((a, b) => (
    compareDateTime(a.restitutionTime, b.restitutionTime)
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title,
    field: 'restitutionTime',
    editable: 'never',
    customSort: handleCustomSort,
    defaultSort: 'desc',
    width: 200,
  }), [title, column, handleCustomSort]);
};

export default useRestitutionTimeColumn;
