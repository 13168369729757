import React, {
  FC, memo, useCallback, useContext, useState,
} from 'react';
import OrderActionIcon from '@/components/atoms/OrderActionIcon';
import ErrorType from '@/enum/ErrorType';
import { postPreordersBySaleIdIn } from '@/utils/fetch';
import { getDateTimeNow } from '@/utils/Utils';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import ProductActionModal from '@/components/organisms/ProductActionModal';

interface Props {
  saleIds: string[];
  disabled?: boolean;
  onAction?(): void;
}

const OrderBySaleActionIcon: FC<Props> = ({
  saleIds,
  onAction,
  disabled,
}) => {
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isEditProductOpened, setEditProductOpened] = useState(false);
  const [editingProductId, setEditingProductId] = useState<null | string>(null);

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const sendOrderBySaleRequest = useCallback((
    fillingExistingOrder: boolean,
  ) => {
    setLoading(true);
    postPreordersBySaleIdIn({
      fillingExistingOrder,
      formingDate: getDateTimeNow(),
      saleIds,
    }).then(() => {
      context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      handleClose();
      if (typeof onAction === 'function') {
        onAction();
      }
    }).catch((error) => {
      if (error.type === ErrorType.PRODUCTS_SHOULD_BE_SYNCHRONIZED
        || error.type === ErrorType.NO_PREORDER_SALE_ITEMS
      ) {
        context({ message: error.message, variant: 'warning' });
        return Promise.resolve();
      }
      if (error.type === ErrorType.PREORDER_PRODUCT_SHOULD_HAVE_SUPPLIER) {
        context({
          message: MESSAGE.WARNING_SUPPLIER_SHOULD_NOT_BE_EMPTY_FOR_ORDER,
          variant: 'warning',
        });
        setEditProductOpened(true);
        setEditingProductId(error.detail);
        return Promise.resolve();
      }
      if (error.type === ErrorType.ARCHIVED_PRODUCT) {
        context({
          message: MESSAGE.WARNING_NO_ARCHIVED_PRODUCTS_FOR_ORDER,
          variant: 'warning',
        });
        return Promise.resolve();
      }
      return Promise.reject(error);
    }).finally(() => {
      setLoading(false);
    });
  }, [handleClose, saleIds, context, onAction]);

  const handleFormNew = useCallback(() => {
    sendOrderBySaleRequest(false);
  }, [sendOrderBySaleRequest]);

  const handleUpdateCurrent = useCallback(() => {
    sendOrderBySaleRequest(true);
  }, [sendOrderBySaleRequest]);

  const handleDialogClose = useCallback(() => {
    setEditProductOpened(false);
  }, []);

  return (
    <>
      {isEditProductOpened && (
        <ProductActionModal
          onAction={onAction}
          isOpened={isEditProductOpened}
          onClose={handleDialogClose}
          productId={editingProductId!}
          defaultErrors={{ supplierId: MESSAGE.ERROR_REQUIRED_FIELD }}
        />
      )}
      <OrderActionIcon
        disabled={!!disabled}
        loading={loading}
        onFormNew={handleFormNew}
        onUpdateCurrent={handleUpdateCurrent}
        isOpened={opened}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </>
  );
};

export default memo(OrderBySaleActionIcon);
