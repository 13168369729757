import { TEXT } from '@/utils/Text';
import React, { useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';

const useUnpColumn = (
  column = null,
  error,
) => useMemo(() => ({
  hidden: !!column?.hidden,
  title: TEXT.UNP,
  field: 'unp',
  editComponent: (props) => (
    <CustomInput
      error={error}
      maxLength={40}
      id="unp"
      label={TEXT.UNP}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
    />
  ),
}), [error, column]);

export default useUnpColumn;
