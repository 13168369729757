import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
  fullscreenBtn: {
    position: 'absolute',
    zIndex: 10,
    top: 25,
    right: 25,
  },
  preview: {
    width: 1000,
    overflowY: 'auto',
    cursor: 'pointer',
    position: 'relative',
    padding: 10,
  },
  withColumnsPreview: {
    height: 715,
  },
  container: {
    display: 'flex',
    maxHeight: 805,
    maxWidth: 1625,
  },
  paper: {
    outline: 'none',
    padding: 15,
    background: 'rgb(255, 255, 255)',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    maxHeight: '100%',
    overflow: 'auto',
    margin: 10,
    borderRadius: 4,
  },
  title: {
    color: 'black !important',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingBottom: '5px',
    textAlign: 'center',
  },
}));
