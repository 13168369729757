import { StoreState } from '@/redux/stores/store';
import { createSelector } from 'reselect';
import { CustomTable } from '@/interfaces/CustomTable';
import { CustomColumn, CustomColumns } from '@/interfaces/CustomColumn';
import ID from '@/utils/id';

export const getCustomTable = (state: StoreState): CustomTable => state.customTable;

export const selectCustomColumnsById = (id: ID | undefined) => createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumns => (id ? customTable[id] : {} as CustomColumns),
);

export const isCustomTableLoading = createSelector(
  getCustomTable,
  (customTable: CustomTable): boolean => customTable.loading,
);

export const selectSupplierCustomTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.supplierTable,
);

export const selectProductTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.productTable,
);

export const selectClientsTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.clientTable,
);

export const selectSupplierOrderTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.supplierOrderTable,
);

export const selectSupplierOrderItemTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.supplierOrderItemTable,
);

export const selectClientPatternItemTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.clientPatternItemTable,
);

export const selectCreditsTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.creditTable,
);

export const selectStoreTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.storeTable,
);

export const selectSaleItemsTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.saleItemTable,
);

export const selectSaleTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.saleTable,
);

export const selectRestitutionTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.restitutionTable,
);

export const selectRestitutionItemTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.restitutionItemTable,
);

export const selectClientPatternTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.clientPatternTable,
);

export const selectVendorTableColumns = createSelector(
  getCustomTable,
  (customTable: CustomTable): CustomColumn[] => customTable.vendorTable,
);
