import CompanyCard from '@/components/molecules/CompanyCard';
import UserSearchView from '@/components/templates/UserSearchView/UserSearchView';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { getCompanies } from '@/utils/fetch';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';

const CompanySearchSubTab: FC = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [companies, setCompanies] = useState<CompanyInfo[]>([]);
  const [searchedCompanies, setSearchedCompanies] = useState<CompanyInfo[]>([]);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    getCompanies()
      .then((companyInfos) => {
        if (!isMounted) return;
        setCompanies(companyInfos);
        setSearchedCompanies(companyInfos);
      })
      .finally(() => {
        if (!isMounted) return;
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setSearchedCompanies(companies.filter(
      (company) => (company.name.trim().toLowerCase().includes(searchValue.trim().toLowerCase())),
    ));
  }, [companies, searchValue]);

  const handleCancel = useCallback(() => setSearchValue(''), []);

  return (
    <UserSearchView
      companyTab
      onCancel={handleCancel}
      loading={loading}
      handleSearchChange={setSearchValue}
      cards={searchedCompanies.map((company) => (
        <CompanyCard
          key={company.id}
          company={company}
        />
      ))}
    />
  );
};

export default CompanySearchSubTab;
