import React, { useCallback, useContext, useState } from 'react';
import { postRequestToSupplier } from '@/utils/fetch';
import { currentDate } from '@/utils/Utils';

import { RequestSupplier } from '@/interfaces/RequestSupplier';
import { RequestState } from '@/enum/RequestState';
import { getUserId, selectUser } from '@/redux/selectors/applicationSelector';
import UserCard from '@/components/molecules/UserCard/UserCard';
import ConnectRequestLink from '@/components/molecules/ConnectRequestLink/ConnectRequestLink';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { createUpdateSupplierPartnerRequestInfo } from '@/redux/actions/supplierPatnerRequestInfoActions';
import { useDispatch, useSelector } from 'react-redux';
import { MESSAGE } from '@/utils/message';
import PersonalData from '@/components/organisms/PersonalData/PersonalData';
import ApplicationContext from '@/app/snackbarContext';
import User from '@/model/user/User';

interface SupplierCardProps {
  supplier: UserPartnerRequestInfo
}

function SupplierCard({
  supplier,
}: SupplierCardProps) {
  const context = useContext(ApplicationContext);
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const [loading, setLoading] = useState(false);
  const [personalDataOpened, setPersonalDataOpened] = useState(false);
  const [personalDataErrors, setPersonalDataErrors] = useState({});

  const user = useSelector<User>(selectUser);

  const {
    address,
    phoneNumber,
    contactEmail,
    organization,
    description,
    requestState,
  } = supplier;

  const onPersonalDataClose = useCallback(() => {
    setPersonalDataOpened(false);
  }, []);

  const handleAddSupplierClick = useCallback(() => {
    const {
      organization, contactEmail, description, phoneNumber,
    } = user;
    if (!organization || !contactEmail || !description || !phoneNumber || !phoneNumber || phoneNumber.length < 5) {
      const errors = {
        organization: !organization,
        contactEmail: !contactEmail,
        description: !description,
        phoneNumber: !phoneNumber || phoneNumber.length < 5,
      };
      setPersonalDataErrors(errors);
      setPersonalDataOpened(true);
      context({ message: MESSAGE.WARNING_FILL_PUBLIC_DATA, variant: 'warning' });
      return;
    }

    setLoading(true);
    postRequestToSupplier(supplier.id, currentDate())
      .then((requestSupplier: RequestSupplier) => {
        dispatch(createUpdateSupplierPartnerRequestInfo({ ...supplier, requestState: requestSupplier.state }));
      })
      .finally(() => setLoading(false));
  }, [context, user, dispatch, supplier]);

  function getLinkLabel() {
    if (!requestState) {
      return 'Добавить в "Мои поставщики"';
    }
    switch (requestState) {
      case RequestState.CONFIRMED:
        return 'Уже добавлен в ваш список поставщиков';
      case RequestState.PENDING:
        return 'Запрос отправлен, ожидается подтверждение';
      case RequestState.DECLINED:
        return 'Запрос отклонён, но вы можете его повторить';
      default:
        return 'Добавить в "Мои поставщики"';
    }
  }

  return (
    <>
      <UserCard
        address={address}
        phoneNumber={phoneNumber}
        organization={organization}
        description={description}
        contactEmail={contactEmail}
        bottom={(
          <ConnectRequestLink
            disabled={!!requestState && requestState !== RequestState.DECLINED || loading || supplier.id === userId}
            onClick={handleAddSupplierClick}
            state={requestState}
            label={getLinkLabel()}
          />
   )}
      />
      {personalDataOpened && (
      <PersonalData
        onClose={onPersonalDataClose}
        isOpen={personalDataOpened}
        externalErrors={personalDataErrors}
      />
      )}
    </>
  );
}

export default SupplierCard;
