import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => createStyles({
  root: {
    top: 15,
    right: 10,
  },
  dateInput: {
    width: 210,
  },
  paper: {
    width: 520,
    '& .MuiInputLabel-shrink': {
      fontWeight: 'bold',
      color: 'black',
    },
  },
}));
