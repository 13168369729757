import { createAction } from 'redux-actions';
import ProductInfo from '@/interfaces/ProductInfo';

enum Type {
  ADD = 'ADD_PRODUCT_INFO',
  SET = 'SET_PRODUCT_INFO',
  UPDATE_ONE = 'UPDATE_ONE_PRODUCT_INFO',
  UPDATE_ALL = 'UPDATE_ALL_PRODUCT_INFO',
  DELETE_ONE = 'DELETE_ONE_PRODUCT_INFO'
}

const set = createAction<ProductInfo[]>(Type.SET);
const updateOne = createAction<ProductInfo>(Type.UPDATE_ONE);
const updateAll = createAction<ProductInfo[]>(Type.UPDATE_ALL);
const remove = createAction<string>(Type.DELETE_ONE);
const add = createAction<ProductInfo>(Type.ADD);

const ProductInfoActions = {
  Type,
  set,
  updateOne,
  updateAll,
  remove,
  add,
};

export default ProductInfoActions;
