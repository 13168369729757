import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import OrderActionIcon from '@/components/atoms/OrderActionIcon';
import { PreorderItemInfo } from '@/interfaces/PreOrderItemInfo';
import { postPreordersByProductIds } from '@/utils/fetch';
import { getDateTimeNow } from '@/utils/Utils';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import ErrorType from '@/enum/ErrorType';
import { PreOrderItemInfoActions } from '@/redux/actions/preOrderItemInfoActions';
import { useDispatch } from 'react-redux';
import ProductActionModal from '@/components/organisms/ProductActionModal';
import { NewProductRowData } from '@/components/organisms/ProductActionModal/productActionDialog.types';

interface Props {
  preorderItemInfos: PreorderItemInfo[];
}

const OrderByProductsActionIcon: FC<Props> = ({
  preorderItemInfos,
}) => {
  const dispatch = useDispatch();
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [isEditProductOpened, setEditProductOpened] = useState(false);
  const [editingProductId, setEditingProductId] = useState<null | string>(null);

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const postOrders = useCallback((fillingExistingOrder) => {
    setLoading(true);
    postPreordersByProductIds({
      preorderItems: preorderItemInfos
        .map((item) => ({ productId: item.id, count: item.leftCount })),
      formingDate: getDateTimeNow(),
      fillingExistingOrder,
    })
      .then((response) => {
        dispatch(PreOrderItemInfoActions.update(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        handleClose();
      })
      .catch((error) => {
        if (error.type === ErrorType.PRODUCTS_SHOULD_BE_SYNCHRONIZED
        || error.type === ErrorType.NO_PREORDER_SALE_ITEMS
        ) {
          context({ message: error.message, variant: 'warning' });
          return Promise.resolve();
        }
        if (error.type === ErrorType.PREORDER_PRODUCT_SHOULD_HAVE_SUPPLIER) {
          context({
            message: MESSAGE.WARNING_SUPPLIER_SHOULD_NOT_BE_EMPTY_FOR_ORDER,
            variant: 'warning',
          });
          setEditProductOpened(true);
          setEditingProductId(error.detail);
          return Promise.resolve();
        }
        if (error.type === ErrorType.ARCHIVED_PRODUCT) {
          context({
            message: MESSAGE.WARNING_NO_ARCHIVED_PRODUCTS_FOR_ORDER,
            variant: 'warning',
          });
          return Promise.resolve();
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [context, preorderItemInfos, handleClose, dispatch]);

  const handleFormNew = useCallback(() => {
    postOrders(false);
  }, [postOrders]);

  const handleUpdateCurrent = useCallback(() => {
    postOrders(true);
  }, [postOrders]);

  const handleDialogClose = useCallback(() => {
    setEditProductOpened(false);
  }, []);

  const handleProductAction = useCallback((newData: NewProductRowData) => {
    const preorderItemInfo = preorderItemInfos.find((item) => item.id === newData.productId);
    if (preorderItemInfo) {
      dispatch(PreOrderItemInfoActions.updateOne({
        ...preorderItemInfo,
        supplierId: newData.supplierId,
        supplierName: newData.supplierName,
      }));
    }
  }, [preorderItemInfos, dispatch]);

  return (
    <>
      {isEditProductOpened && (
        <ProductActionModal
          onAction={handleProductAction}
          isOpened={isEditProductOpened}
          onClose={handleDialogClose}
          productId={editingProductId!}
          defaultErrors={{ supplierId: MESSAGE.ERROR_REQUIRED_FIELD }}
        />
      )}
      <OrderActionIcon
        disabled={!preorderItemInfos.length}
        loading={loading}
        onFormNew={handleFormNew}
        onUpdateCurrent={handleUpdateCurrent}
        isOpened={opened}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default OrderByProductsActionIcon;
