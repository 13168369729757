import { Action, handleActions } from 'redux-actions';
import PriceFormationType from '@/interfaces/PriceFormationType';
import { PriceFormationTypeActions } from '@/redux/actions/priceFormationTypeActions';
import priceFormationDefaultType from '@/service/builder/priceFormationDefaultType';

export interface PriceFormationTypeState {
  customPriceFormationTypes: PriceFormationType[],
  currentPriceFormationType: PriceFormationType,
  loaded: boolean,
}

const initialState: PriceFormationTypeState = {
  customPriceFormationTypes: [],
  currentPriceFormationType: priceFormationDefaultType,
  loaded: false,
};

const processSetCustom = (
  state: PriceFormationTypeState,
  action: Action<PriceFormationType[]>,
) => ({ ...state, customPriceFormationTypes: action.payload });

const processSetDefault = (
  state: PriceFormationTypeState,
  action: Action<PriceFormationType[]>,
) => ({
  ...state,
  defaultPriceFormationTypes: action.payload,
  currentPriceFormationType: action.payload[0],
});

const processAdd = (
  state: PriceFormationTypeState,
  action: Action<PriceFormationType>,
) => ({
  ...state,
  customPriceFormationTypes: [action.payload, ...state.customPriceFormationTypes],
});

const processUpdate = (
  state: PriceFormationTypeState,
  action: Action<PriceFormationType>,
) => {
  const { payload } = action;
  const index = state.customPriceFormationTypes.findIndex((userInfo) => userInfo.id === payload.id);
  const newItems = [...state.customPriceFormationTypes];
  if (index > -1) {
    newItems[index] = payload;
    return { ...state, customPriceFormationTypes: newItems };
  }
  return state;
};

const processDelete = (
  state: PriceFormationTypeState,
  action: Action<string>,
) => ({
  ...state,
  customPriceFormationTypes: state.customPriceFormationTypes.filter(
    (item) => item.id !== action.payload,
  ),
});

const processSetCurrentById = (
  state: PriceFormationTypeState,
  action: Action<string>,
) => ({
  ...state,
  currentPriceFormationType: state.customPriceFormationTypes
    .find((item) => item.id === action.payload) || priceFormationDefaultType,
});

const processSetLoaded = (
  state: PriceFormationTypeState,
  action: Action<boolean>,
) => ({
  ...state,
  loaded: action.payload,
});

const priceFormationTypeReducer = handleActions<PriceFormationTypeState,
PriceFormationTypeReducerPayload>({
  [PriceFormationTypeActions.Type.SET_CUSTOM]: processSetCustom,
  [PriceFormationTypeActions.Type.SET_DEFAULT]: processSetDefault,
  [PriceFormationTypeActions.Type.UPDATE]: processUpdate,
  [PriceFormationTypeActions.Type.DELETE]: processDelete,
  [PriceFormationTypeActions.Type.ADD]: processAdd,
  [PriceFormationTypeActions.Type.SET_CURRENT_BY_ID]: processSetCurrentById,
  [PriceFormationTypeActions.Type.SET_LOADED]: processSetLoaded,
},
initialState);

type PriceFormationTypeReducerPayload =
  PriceFormationType
  & PriceFormationType[]
  & string
  & boolean;

export default priceFormationTypeReducer;
