export enum ID {
  STORE_TABLE = 'storeTable',
  PRODUCT_TABLE = 'productTable',
  SUPPLIER_TABLE = 'supplierTable',
  SUPPLIER_ORDER_TABLE = 'supplierOrderTable',
  SUPPLIER_ORDER_ITEM_TABLE = 'supplierOrderItemTable',
  SALE_TABLE = 'saleTable',
  SALE_ITEM_TABLE = 'saleItemTable',
  CLIENT_TABLE = 'clientTable',
  CLIENT_PATTERN_TABLE = 'clientPatternTable',
  CLIENT_PATTERN_ITEM_TABLE = 'clientPatternItemTable',
  CREDIT_TABLE = 'creditTable',
  RECONCILIATION_TABLE = 'reconciliationTable',
  RESTITUTION_TABLE = 'restitutionTable',
  RESTITUTION_ITEMS_TABLE = 'restitutionItemTable',
  PRICE_FORMATION_TYPE = 'priceFormationType',
  VENDOR_TABLE = 'vendorTable',
}

export default ID;
