import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';

export default withStyles((theme) => ({
  root: {
  },
  primary: {
    fontSize: '14px',
  },
}))(ListItemText);
