import React, { memo } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

export function renderBigListRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef(({ style, ...other }, ref) => {
  const { maxHeight, ...otherOuterProps } = React.useContext(OuterElementContext);
  return (
    <div
      ref={ref}
      style={{ ...style, maxHeight }}
      {...other}
      {...otherOuterProps}
    />
  );
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const BigList = React.forwardRef((props, ref) => {
  const { children, renderOption, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 40 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderOption || renderBigListRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default memo(BigList);
