import React, { useEffect } from 'react';
import VendorInterfaceLogo from '@/components/atoms/VendorInterfaceLogo';
import { Box } from '@material-ui/core';
import { getUser } from '@/utils/fetch';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { createSetRolesAction } from '@/redux/actions/rolesActions';
import UserService from '@/model/user/UserService';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import Path from '@/enum/Path';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useParamQuery } from '@/hook/useQuery';
import useClasses from './loaderPage.styles';

const LoaderPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useParamQuery();

  const {
    wrapper, spinner, logoWrapper, logoImage,
  } = useClasses();

  useEffect(() => {
    if (query.email && query.token) {
      history.push(`${Path.NEW_PASSWORD}/${query.token}/${query.email}`);
    } else {
      getUser()
        .then((response) => {
          dispatch(ActionCreators.setStores(response.stores));
          dispatch(ActionCreators.setUnits(response.units));
          dispatch(ActionCreators.setCustomTables(response.customTables));
          dispatch(createSetRolesAction(response.roles));
          dispatch(ActionCreators.saveUser(UserService.parse({ ...response, authorized: true })));
          dispatch(CompanyInfoActions.set(response.company));
          dispatch(CompanyUserInfoActions.setUsers(response.companyUserInfos));
          history.push(Path.INDEX);
        })
        .catch((response) => {
          history.push(Path.LOGIN);
          dispatch(ActionCreators.setAnonymousUser());
          if (response.status === 401) {
            return Promise.resolve();
          }
          return Promise.reject(response);
        });
    }
  }, [history, query, dispatch]);

  return (
    <Box
      className={wrapper}
      id="loader-wrapper"
    >
      <Box
        className={spinner}
        id="loader-spinner"
      />
      <Box
        className={logoWrapper}
        id="logo-wrapper"
      >
        <VendorInterfaceLogo
          className={logoImage}
        />
      </Box>
    </Box>
  );
};

export default React.memo(LoaderPage);
