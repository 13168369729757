function equalsIfPresent(
  str1: string | undefined | null,
  str2: string | undefined | null,
) {
  if (!!str1 && !!str2) {
    return str1 === str2;
  }
  if ((!!str1 && !str2) || (!!str2 && !str1)) {
    return false;
  }
  return true;
}

export function formatPhoneNumberStr(str) {
  if (!str || !str.length) return str;
  let newStr = '';
  for (let i = 0; i < str.length; i += 1) {
    const chr = str[i];
    if (chr !== ' ' && chr !== '+' && chr !== '(' && chr !== ')') {
      newStr += chr;
    }
  }
  return newStr;
}

export default equalsIfPresent;
