import React, { PureComponent } from 'react';
import { MESSAGE } from '@/utils/message';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Path from '@/enum/Path';
import SnackbarContext from './snackbarContext';

class ErrorBoundary extends PureComponent {
  static contextType = SnackbarContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      criticalError: null,
    };
  }

  componentDidCatch(error, info) {
    let message = MESSAGE.OOPS_SOMETHING_WENT_WRONG;
    if (REACT_APP_DEVELOPMENT) {
      console.error(error, info);
      message = error.message;
    }
    this.context({
      message,
      variant: 'error',
    });
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    window.addEventListener('unhandledrejection', this.handleRejection);
  }

  componentWillUnmount() {
    window.removeEventListener('unhandledrejection', this.handleRejection);
  }

  componentDidUpdate(prevProps, prevState) {
    const { message } = this.state;
    if (prevState.message !== message) {
      this.context({
        message,
        variant: 'success',
      });
    }
  }

  handleRejection = (event) => {
    event.promise.catch((error) => {
      if (REACT_APP_DEVELOPMENT) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      if (error && error.status === 401) {
        this.props.history.push(Path.LOGIN);
        this.props.dispatch(ActionCreators.setAnonymousUser());
        this.context({
          message: MESSAGE.SESSION_EXPIRED,
          variant: 'warning',
        });
      } else {
        const message = error && error.message && error.status && error.timeStamp
          ? error.message
          : MESSAGE.OOPS_SOMETHING_WENT_WRONG;
        const { variant = 'error' } = error;
        this.context({
          message,
          variant,
        });
      }
    });
    event.stopPropagation();
    event.preventDefault();
  };

  render() {
    return (
      <>
        {this.props.children}
      </>
    );
  }
}

export default connect(null, (dispatch) => ({
  dispatch,
}))(withRouter(ErrorBoundary));
