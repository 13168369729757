import CustomizedMenu from '@/components/atoms/CustomizedMenu/CustomizedMenu';
import React, { useCallback, useContext } from 'react';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import { TEXT } from '@/utils/Text';
import { FileCopy } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { copySupplierOrder } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import * as ActionCreators from '@/redux/actions/actionCreator';

function SupplierOrderRowContextMenu({
  rowAnchor,
  onClose,
  rowData,
  setLoading,
}) {
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const handleCopyClick = useCallback(() => {
    const { id } = rowData;
    onClose();
    setLoading(true);
    copySupplierOrder(id)
      .then((response) => {
        dispatch(ActionCreators.saveSupplierOrder(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onClose, rowData, setLoading, context, dispatch]);

  return (
    <CustomizedMenu
      onClose={onClose}
      anchorEl={rowAnchor}
    >
      <MenuItem
        onClick={handleCopyClick}
      >
        <ListItemIcon>
          <FileCopy fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary={TEXT.COPY} />
      </MenuItem>
    </CustomizedMenu>
  );
}

export default SupplierOrderRowContextMenu;
