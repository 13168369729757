import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  title: {
    width: 200,
  },
  container: {
    position: 'relative',
    minWidth: 55,
    marginRight: 5,
  },
}));
