import React, { FC, memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import { useSelector } from 'react-redux';
import { selectUser } from '@/redux/selectors/applicationSelector';
import TEXT from '@/utils/Text';
import useStyles from './saleReseiptHeader.styles';

interface SaleReceiptHeaderProps {
  saleTime: string | undefined;
}

const SaleReceiptHeader: FC<SaleReceiptHeaderProps> = ({
  saleTime,
}: SaleReceiptHeaderProps) => {
  const classes = useStyles();
  const user = useSelector(selectUser);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
      minHeight="140px"
      py={2}
    >
      <Typography variant="h5">
        {user.organization || user.name}
      </Typography>
      {user.unp && (
        <Typography>
          {`${TEXT.UNP}: ${user.unp}`}
        </Typography>
      )}
      {user.address && (
        <Typography>
          {`${TEXT.ADDRESS}: ${user.address}`}
        </Typography>
      )}
      {user.phoneNumber && (
        <Typography>
          {`${TEXT.PHONE}: ${user.phoneNumber}`}
        </Typography>
      )}
      <Box display="flex">
        <Typography style={{ padding: '8px' }}>
          Товарный чек №
        </Typography>
        <EditableDocumentInput
          className={classes.input}
          editing
        />
        <Typography style={{ padding: '8px' }}>
          от
        </Typography>
        <EditableDocumentInput
          className={classes.input}
          initialValue={saleTime ? saleTime.substr(0, 10) : ''}
          editing
        />
      </Box>
    </Box>
  );
};

export default memo(SaleReceiptHeader);
