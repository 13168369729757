import React, { FC } from 'react';
import OptionChipColor from '@/components/atoms/OptionChipColor';
import { AttributeOptionProps } from '@/components/molecules/AttributeOption/attributeOption.types';
import MultipleAutocompleteOption from '@/components/molecules/MultipleAutocompleteOption';

const AttributeOption: FC<AttributeOptionProps> = ({
  selected,
  attribute,
}) => {
  const { color, name } = attribute;

  return (
    <MultipleAutocompleteOption
      prefix={(
        <OptionChipColor color={color} />
      )}
      name={name}
      selected={selected}
    />
  );
};

export default AttributeOption;
