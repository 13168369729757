import { Action, handleActions } from 'redux-actions';
import { PreOrderItemInfoActions } from '@/redux/actions/preOrderItemInfoActions';
import PreorderItemInfo from '@/interfaces/PreOrderItemInfo';
import replaceOneById, { replaceArrayById } from '@/utils/arrayUtils';

const initialState: PreOrderItemInfosState = {
  preOrderItems: [],
};

export interface PreOrderItemInfosState {
  preOrderItems: PreorderItemInfo[];
}

const processSet = (
  state: PreOrderItemInfosState,
  action: Action<PreorderItemInfo[]>,
): PreOrderItemInfosState => ({ preOrderItems: action.payload });

const processUpdateOne = (
  state: PreOrderItemInfosState,
  action: Action<PreorderItemInfo>,
): PreOrderItemInfosState => ({
  ...state,
  preOrderItems: replaceOneById<PreorderItemInfo>(state.preOrderItems, action.payload),
});

const processUpdate = (
  state: PreOrderItemInfosState,
  action: Action<PreorderItemInfo[]>,
): PreOrderItemInfosState => ({
  ...state,
  preOrderItems: replaceArrayById(state.preOrderItems, action.payload),
});

const preOrderItemInfoReducer = handleActions<PreOrderItemInfosState, PreOrderItemReducerPayload>({
  [PreOrderItemInfoActions.Type.SET]: processSet,
  [PreOrderItemInfoActions.Type.UPDATE_ONE]: processUpdateOne,
  [PreOrderItemInfoActions.Type.UPDATE]: processUpdate,
},
initialState);

type PreOrderItemReducerPayload = PreorderItemInfo & PreorderItemInfo[];

export default preOrderItemInfoReducer;
