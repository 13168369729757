import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  root: {
    backgroundColor: 'transparent !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexContainerVertical: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));
