import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';
import { createAction } from 'redux-actions';

enum Type {
  SET_SELECTION_ENABLED = 'SET_SELECTION_ENABLED',
  SET_USERS = 'SET_COMPANY_USER_INFOS',
  SET_SELECTED = 'SET_SELECTED_COMPANY_USER',
  REMOVE_SELECTED = 'REMOVE_SELECTED_COMPANY_USER',
  UPDATE_ONE = 'UPDATE_ONE_COMPANY_USER_INFO',
  DELETE_ONE = 'DELETE_ONE_COMPANY_USER_INFO',
  DELETE_ALL = 'DELETE_COMPANY_USER_INFOS',
}

const setUsers = createAction<CompanyUserInfo[]>(Type.SET_USERS);
const updateOne = createAction<CompanyUserInfo>(Type.UPDATE_ONE);
const removeOne = createAction<string>(Type.DELETE_ONE);
const removeAll = createAction(Type.DELETE_ALL);
const removeSelected = createAction<void>(Type.REMOVE_SELECTED);
const setSelected = createAction(Type.SET_SELECTED);
const setSelectionEnabled = createAction(Type.SET_SELECTION_ENABLED);

export const CompanyUserInfoActions = {
  Type,
  setUsers,
  updateOne,
  removeOne,
  removeAll,
  setSelected,
  removeSelected,
  setSelectionEnabled,
};

export type CompanyUserInfoActionsType = Omit<typeof CompanyUserInfoActions, 'Type'>;
