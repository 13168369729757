import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  deleteClientPatternById, getClientPatternInfos, postClientPattern, putClientPattern,
} from '@/utils/fetch';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import ClientPatternItemTable from '@/components/organisms/ClientPatternItemTable/ClientPatternItemTable';
import { deleteRecord, saveClientPattern, setClientPatterns } from '@/redux/actions/actionCreator';
import { CLIENT_PATTERNS } from '@/constants/action';
import { isCustomTableLoading, selectClientPatternTableColumns } from '@/redux/selectors/customTableSelector';
import { selectClientPatterns } from '@/redux/selectors/applicationSelector';
import { ClientPatternInfo } from '@/interfaces/ClientPatternInfo';
import { useDispatch, useSelector } from 'react-redux';
import useClientColumn from '@/hook/columns/useClientColumn';
import useNameColumn from '@/hook/columns/useNameColumn';

const ClientPatternTable: FC = () => {
  const dispatch = useDispatch();
  const context = useContext(SnackbarContext);

  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const clientPatternInfos: ClientPatternInfo[] = useSelector(selectClientPatterns);
  const columns = useSelector(selectClientPatternTableColumns);
  const customTableLoading = useSelector(isCustomTableLoading);

  const clientColumn = useClientColumn(columns.clientFirstName, errors.clientId);
  const nameColumn = useNameColumn(errors.name, columns.name);

  useEffect(() => {
    setLoading(true);
    getClientPatternInfos()
      .then((patterns: ClientPatternInfo[]) => dispatch(setClientPatterns(patterns)))
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(clientPatternInfos.map((item) => ({ ...item })));
  }, [clientPatternInfos]);

  const memoizedColumns = useMemo(() => (
    [
      nameColumn,
      clientColumn,
    ]
  ), [clientColumn, nameColumn]);

  const validateClientPattern = useCallback(({ clientId, name }) => {
    const errors = {};
    if (!clientId) {
      errors.clientId = true;
    }
    if (!name) {
      errors.name = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onDelete = useCallback((data) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteClientPatternById(data.id)
        .then((response) => {
          dispatch(deleteRecord(response.id, CLIENT_PATTERNS));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [context, dispatch]);

  const onAdd = useCallback((data) => new Promise(
    (resolve, reject) => {
      if (!validateClientPattern(data)) {
        return reject(data);
      }
      setLoading(true);
      postClientPattern(data)
        .then((response) => {
          dispatch(saveClientPattern(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [validateClientPattern, dispatch, context, errors]);

  const onUpdate = useCallback((data) => new Promise(
    (resolve, reject) => {
      if (!validateClientPattern(data)) {
        return reject(data);
      }
      setLoading(true);
      putClientPattern(data)
        .then((response) => {
          dispatch(saveClientPattern(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => setLoading(false));
    },
  ), [validateClientPattern, dispatch, context, errors]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const editable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowDelete: onDelete,
    onRowUpdate: onUpdate,
  }), [onAdd, onDelete, onUpdate]);

  const options = useMemo(() => ({
    columnsButton: false,
  }), []);

  const handleDetailPanel = useCallback((rowData) => (
    <ClientPatternItemTable selectedClientPattern={rowData} />
  ), []);

  const isLoading = loading || customTableLoading;
  return (
    <div className="table-container">
      <StyledTable
        tableKey={ID.CLIENT_PATTERN_TABLE}
        onRowEditing={handleRowEditing}
        editable={editable}
        loading={isLoading}
        data={rows}
        columns={memoizedColumns}
        options={options}
        detailPanel={handleDetailPanel}
      />
    </div>
  );
};

export default ClientPatternTable;
