import React, { useCallback, useState } from 'react';
import 'react-dropdown/style.css';
import SubTabPanel from '@/components/atoms/SubTabPanel/SubTabPanel';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import MyClientsSubTab from '@/components/organisms/MyClientsSubTab/MyClientsSubTab';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import StyledBreadcrumb from '@/components/atoms/StyledBreadcrumb/StyledBreadcrumb';
import ClientRequestsSubTab from '@/components/organisms/ClientRequestsSubTab/ClientRequestsSubTab';
import CreditCard from '@material-ui/icons/CreditCard';
import CreditTable from '@/components/organisms/CreditTable';

function ClientsTab() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = useCallback((index) => {
    setSelectedTabIndex(index);
  }, []);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <StyledBreadcrumb
          label="Мои клиенты"
          icon={<HomeIcon fontSize="small" />}
          selectedIndex={selectedTabIndex}
          index={0}
          onChange={handleChange}
        />
        <StyledBreadcrumb
          icon={<EmojiPeopleIcon fontSize="small" />}
          label="Сотрудничество"
          selectedIndex={selectedTabIndex}
          index={1}
          onChange={handleChange}
        />
        <StyledBreadcrumb
          icon={<CreditCard fontSize="small" />}
          label="Кредит"
          selectedIndex={selectedTabIndex}
          index={2}
          onChange={handleChange}
        />
      </Breadcrumbs>

      <SubTabPanel
        value={selectedTabIndex}
        index={0}
      >
        <MyClientsSubTab />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={1}
      >
        <ClientRequestsSubTab />
      </SubTabPanel>
      <SubTabPanel
        value={selectedTabIndex}
        index={2}
      >
        <CreditTable />
      </SubTabPanel>
    </div>
  );
}

export default ClientsTab;
