import { Popover, PopoverProps } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

function PaperPopover({
  anchorEl,
  children,
  onClose,
  open,
  className,
}: PropsWithChildren<PopoverProps>) {
  return (
    <Popover
      className={className}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionProps={{
        timeout: 500,
      }}
    >
      {children}
    </Popover>
  );
}

export default PaperPopover;
