import { Box, ListItem } from '@material-ui/core';
import BigList from '@/components/molecules/BigList';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import TooltipLine from '@/components/organisms/ProductImportModal/TooltipLine';
import AccordionResultItems
  from '@/components/organisms/ProductImportModal/ExcelParseResult/AccordionResultItems';
import { Supplier } from '@/interfaces/SupplierInfo';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import HelpIconTooltip from '@/components/atoms/HelpIconTooltip';
import useStyles from '../../style';

interface Props {
  suppliers: Supplier[];

  onChange(index: number): void;

  index: number;
  selectedIndex: number | null;
}

const SupplierAccordion: FC<Props> = ({
  suppliers,
  index,
  selectedIndex,
  onChange,
}) => {
  const classes = useStyles();

  const [displayedSuppliers, setDisplayedSuppliers] = useState<Supplier[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setDisplayedSuppliers(
      suppliers.filter((supplier) => supplier.name.toLowerCase()
        .includes(searchValue.toLowerCase())),
    );
  }, [suppliers, searchValue]);

  const handleSearchCancel = useCallback(() => {
    setSearchValue('');
  }, []);

  return (
    <AccordionResultItems
      onSearchCancel={handleSearchCancel}
      onSearchChange={setSearchValue}
      header={searchValue
        ? `Поставщики (${displayedSuppliers.length} ${TEXT.OF} ${suppliers.length})`
        : `Поставщики (${suppliers.length})`}
      disabled={!suppliers.length}
      index={index}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Box className={classes.expandablePanel}>
        <BigList
          maxHeight={200}
        >
          {displayedSuppliers.map((supplier, arrIndex) => (
            <ListItem
              className={classes.itemContainer}
              key={supplier.id || arrIndex}
            >
              <ListItemIcon>
                <HelpIconTooltip
                  title={(
                    <Box display="flex" flexDirection="column">
                      <TooltipLine text={supplier.name} header={TEXT.COLUMN.HEADER.NAME} />
                    </Box>
                  )}
                />
              </ListItemIcon>
              <ListItemText
                disableTypography
                className={classes.item}
                primary={supplier.name}
              />
            </ListItem>
          ))}
        </BigList>
      </Box>
    </AccordionResultItems>
  );
};

export default SupplierAccordion;
