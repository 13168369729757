import React, { FC, useCallback, useState } from 'react';
import IconButton from '@/components/atoms/IconButton';
import { TEXT } from '@/utils/Text';
import CreditCard from '@material-ui/icons/CreditCard';
import ClientBalanceModal from '@/components/molecules/BalanceIcon/ClientBalanceModal';

interface Props {
  clientId: string;
  name: string;
}

const BalanceIcon: FC<Props> = ({
  clientId,
  name,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    setIsOpen(true);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setIsOpen(false);
  }, []);

  return (
    <>
      <IconButton
        onClick={handleClick}
        title={TEXT.CHECK_BALANCE}
      >
        <CreditCard fontSize="small" />
      </IconButton>
      {isOpen && (
        <ClientBalanceModal
          clientId={clientId}
          name={name}
          isOpen={isOpen}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default BalanceIcon;
