import React, {
  FC, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import SnackbarContext from '@/app/snackbarContext';
import BigList from '@/components/molecules/BigList';
import { TEXT } from '@/utils/Text';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import AutocompletePopper from '@/components/molecules/AutocompletePopper';
import { Chip, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MultipleModeListbox } from '@/components/molecules/AttributeAutocomplete';

interface Props {
  label: string;
  error?: boolean | string;
  warning?: boolean;
  required?: boolean;
  onChange (value: any): void;
  loadOptions? (inputValue: string): Promise<any[]>;
  defaultOptions?: any[];
}

type CustomAutocompleteProps = Props | AutocompleteProps<any, any, any, any>;

const CustomAutocomplete: FC<CustomAutocompleteProps> = ({
  loadOptions,
  defaultOptions,
  value,
  disabled,
  onChange,
  onInputChange,
  filterOption,
  placeholder,
  inputProps,
  label,
  required,
  error,
  warning,
  classes,
  renderOption,
  autocompleteProps,
  containerRef,
  width = 200,
  getOptionSelected,
  filterOptions,
  multiple,
  freeSolo,
  disableCloseOnSelect,
  renderInput,
  renderTags,
  ...other
}) => {
  const context = useContext(SnackbarContext);

  const [opened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [allOptions, setOptions] = useState(defaultOptions || []);
  const [loading, setLoading] = useState(false);
  const [selectedOptionState, setSelectedOption] = useState(multiple ? [] : null);
  const [initialSelectedSet, setInitialSelectedSet] = useState(false);
  const [isSelectCallbackCalled, setIsSelectCallbackCalled] = useState(false);

  const [searchName, setSearchName] = useState(value ? value.label : '');

  const anchorEl = useRef(null);

  useEffect(() => {
    if (!defaultOptions) return;

    setOptions(defaultOptions);
  }, [defaultOptions]);

  useEffect(() => {
    if (multiple) {
      return;
    }
    const newInputValue = value ? value.label : '';
    setSearchName(newInputValue);
    setInputValue(newInputValue);
  }, [multiple, value]);

  useEffect(() => {
    if (initialSelectedSet || !defaultOptions) {
      return;
    }
    setInitialSelectedSet(true);
    setSelectedOption(value);
  }, [value, initialSelectedSet, defaultOptions]);

  const handleOpen = useCallback(() => {
    if (disabled || other.loading) {
      return;
    }
    setOpened(true);
  }, [disabled, other.loading]);

  const handleClose = useCallback((event, reason) => {
    if (reason !== 'blur') {
      setOpened(false);
    }
  }, []);

  const handleChange = useCallback((event, newValue) => {
    setSelectedOption(newValue);
    setOpened(false);
    if (!loadOptions) {
      setOptions(defaultOptions);
    }
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  }, [defaultOptions, onChange, loadOptions]);

  const memoizedGetOptionSelected = useCallback(getOptionSelected
    || ((option, value) => (
      !!value && option.value === value.value
    )), [getOptionSelected]);

  useEffect(() => {
    if (!loadOptions) return;

    let isMounted = true;

    const promise = loadOptions(searchName);
    if (!promise) {
      return;
    }
    setLoading(true);
    promise.then((response) => {
      if (!isMounted) return;

      const [newOptions] = response;
      setOptions(newOptions);
      setLoading(false);
    }).catch((response) => {
      if (!isMounted) return;
      context({ message: response ? response.message : '', variant: 'error' });
    }).finally(() => {
      if (!isMounted) return;
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  }, [context, loadOptions, searchName]);

  useEffect(() => {
    let newSelectedOption;

    if (multiple) {
      newSelectedOption = value;
    } else {
      newSelectedOption = allOptions
        .find((item) => memoizedGetOptionSelected(item, value));
    }

    if (!newSelectedOption) {
      setSelectedOption(multiple ? [] : null);
      return;
    }
    if (loading) {
      return;
    }
    setSelectedOption(newSelectedOption);
    if (!isSelectCallbackCalled && typeof onChange === 'function') {
      setIsSelectCallbackCalled(true);
      onChange(newSelectedOption);
    }
  }, [
    multiple,
    isSelectCallbackCalled,
    onChange,
    memoizedGetOptionSelected,
    value,
    allOptions,
    loading,
  ]);

  const handleInputChange = useCallback((event, value, reason) => {
    setInputValue(value);
    if (typeof onInputChange === 'function') {
      onInputChange(value);
    }
    if (reason === 'reset') {
      setOpened(false);
    } else if (multiple) {
      setSearchName(value);
    } else {
      setOpened(true);
      setSelectedOption(null);
      if (typeof onChange === 'function') {
        onChange(null);
      }
      if (typeof loadOptions !== 'function') {
        const defaultFilter = ({ label }) => label.toUpperCase().includes(value.toUpperCase());
        setOptions(defaultOptions.filter(filterOption || defaultFilter));
      } else {
        setSearchName(value);
      }
    }
  }, [defaultOptions, onChange, filterOption, loadOptions, onInputChange, multiple]);

  const getOptionLabel = useCallback((option) => option.label, []);

  const renderOptions = useMemo(() => (
    renderOption || ((option) => (
      <Box
        key={option.value}
        title={option.label}
        className="table-input-list-item"
      >
        {option.label}
      </Box>
    ))
  ), [renderOption]);

  const isDisabled = other.loading || disabled;
  const memoizedRenderInput = useCallback((params) => (
    renderInput || (
      <CustomInput
        {...params}
        inputProps={{ ...params.inputProps, ...inputProps }}
        disabled={isDisabled}
        error={error}
        warning={warning}
        label={label}
        required={!!required}
        placeholder={placeholder}
        inputRef={anchorEl}
      />
    )
  ), [renderInput, inputProps, isDisabled, error, warning, label, required, placeholder, anchorEl]);

  const memoizedRenderTags = useMemo(() => (
    renderTags || (
      (tags, getTagProps) => tags.map((tag, index) => (
        <Chip
          color="primary"
          label={tag.title}
          size="small"
          {...getTagProps({ index })}
        />
      ))
    )
  ), [renderTags]);

  const styles = useMemo(() => (
    { minWidth: width, width: '100%' }
  ), [width]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.container}>
        <Autocomplete
          disableCloseOnSelect={disableCloseOnSelect}
          freeSolo={freeSolo}
          filterOptions={filterOptions}
          forcePopupIcon={false}
          clearOnBlur={false}
          disabled={isDisabled}
          ref={containerRef}
          onClose={handleClose}
          style={styles}
          disableListWrap
          autoComplete
          clearOnEscape
          noOptionsText={TEXT.NO_RECORDS}
          loadingText={TEXT.LOADING}
          inputValue={inputValue}
          open={opened}
          loading={other.loading || loading}
          options={allOptions}
          value={selectedOptionState}
          onInputChange={handleInputChange}
          onOpen={handleOpen}
          getOptionLabel={getOptionLabel}
          getOptionSelected={memoizedGetOptionSelected}
          onChange={handleChange}
          ListboxComponent={multiple ? MultipleModeListbox : BigList}
          renderOption={renderOptions}
          renderInput={memoizedRenderInput}
          PopperComponent={AutocompletePopper}
          renderTags={memoizedRenderTags}
          clearText=""
          closeIcon={(
            <Tooltip
              arrow
              title={TEXT.CLEAR}
            >
              <Close fontSize="small" />
            </Tooltip>
          )}
          multiple={multiple}
          {...autocompleteProps}
        />
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(withStyles(() => ({
  menuList: {
    maxHeight: 250,
    overflow: 'auto',
    maxWidth: 500,
    padding: 0,
  },
  paper: {
    padding: '15px 0',
    borderRadius: 15,
    margin: '5px 0',
  },
  popper: {
    zIndex: 2000,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },

}))(CustomAutocomplete));
