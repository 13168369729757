export const CLIENT_PATTERNS = 'clientPatterns';
export const SALES = 'sales';
export const SALE_INFOS = 'saleInfos';
export const STORES = 'stores';
export const SUPPLIERS = 'suppliers';
export const SUPPLIER_ORDERS = 'supplierOrders';
export const SUPPLIER_ORDER_ITEMS = 'supplierOrderItems';
export const UNITS = 'units';
export const USER = 'user';
export const CURRENT_STORE = 'currentStore';

export const SAVE_RECORD = 'saveRecord';
export const SET_RECORDS = 'setRecords';
export const SAVE_STORE = 'SAVE_STORE';

export const DELETE_RECORD = 'deleteRecord';
export const DELETE_SUPPLIER_ORDER_ITEM = 'deleteSupplierOrderItem';

export const USER_AUTHORIZED = 'userAuthorized';
export const USER_ANONYMOUS = 'userAnonymous';

export const SET_CUSTOM_TABLES = 'setCustomTables';
export const COLUMN_VISIBILITY_CHANGE = 'columnVisibilityChange';
export const COLUMN_VISIBILITY_CHANGING = 'columnVisibilityChanging';
