import React, {
  FC, memo, useCallback, useContext, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { createCompanyUser } from '@/utils/fetch';
import { CompanyInfoActions } from '@/redux/actions/companyInfoActions';
import ApplicationContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import UserCard from '@/components/molecules/UserCard/UserCard';
import ConnectRequestLink from '@/components/molecules/ConnectRequestLink/ConnectRequestLink';
import { TEXT } from '@/utils/Text';
import { Box } from '@material-ui/core';

interface CompanyCardProps {
  company: CompanyInfo;
}

const CompanyCard: FC<CompanyCardProps> = ({
  company,
}: CompanyCardProps) => {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);

  const {
    name, address, description, phoneNumber, contactEmail,
  } = company;

  const handleSendRequestClick = useCallback(() => {
    setLoading(true);
    createCompanyUser(company.id)
      .then((response) => {
        dispatch(CompanyInfoActions.set(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      })
      .finally(() => setLoading(false));
  }, [company, context, dispatch]);

  return (
    <UserCard
      address={address}
      phoneNumber={phoneNumber}
      organization={name}
      description={description}
      contactEmail={contactEmail}
      bottom={(
        <Box mt={3}>
          <ConnectRequestLink
            onClick={handleSendRequestClick}
            disabled={loading}
            label={TEXT.SEND_REQUEST}
          />
        </Box>
      )}
    />
  );
};

export default memo(CompanyCard);
