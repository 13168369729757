import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    backgroundColor: 'transparent',
  },
  tabPanel: {
    flex: 'auto',
  },
  productsPanel: {
    display: 'flex',
    flexGrow: 1,
  },
  expandablePanel: {
    width: '100%',
    fontSize: 12,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    margin: '0 0 5px 10px',
  },
  titleRed: {
    color: 'red',
  },
  item: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '330px !important',
    fontSize: 14,
    display: 'inline-block',
    overflow: 'hidden',
  },
  itemContainer: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 550,
  },
  paperImport: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  paperContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
  },
  actionPanel: {
    margin: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepper: {
    padding: 10,
  },
}));
