import { TEXT } from '@/utils/Text';
import { getSaleItemInfosSaleId } from '@/utils/fetch';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import StoreItemInfoOption from '@/components/molecules/StoreItemInfoOption/StoreItemInfoOption';
import { round } from '@/utils/Utils';
import { optionByIdAndName, optionBySaleItemIdAndName } from '@/utils/toOption';
import { useSelector } from 'react-redux';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';
import ProductOptionLabel from '@/components/atoms/ProductOptionLabel';

const header = (
  <MenuItem disabled>
    <div className="bold flex flex-space-between align-items-center full-width">
      <span className="text-overflow-ellipsis f-1 mr-10">
        {TEXT.COLUMN.HEADER.NAME}
      </span>
      <span className="f-1 fs-12">
        {TEXT.SALES.BALANCE}
      </span>
    </div>
  </MenuItem>
);

function RestitutionItemEditInput({
  rowData,
  onRowDataChange,
  saleId,
  error,
}) {
  const [initialData] = useState(() => rowData);

  const selectedUserId = useSelector(selectSelectedUserId);

  const selectedOption = useMemo(() => optionBySaleItemIdAndName(rowData), [rowData]);

  const loadOptions = useCallback(async () => {
    const response = await getSaleItemInfosSaleId(saleId, selectedUserId);
    return [response.map((item) => optionByIdAndName(item))];
  }, [saleId, selectedUserId]);

  const calculateMaxCount = useCallback((item) => round(
    item.count + (
      initialData && initialData.saleItemId === item.saleItemId
        ? initialData.count
        : 0
    ), 4,
  ), [initialData]);

  const renderOptionWithTooltip = useCallback(({ data: item }) => (
    <StoreItemInfoOption
      storeEnabled={false}
      maxCountEnabled
      maxCount={calculateMaxCount(item)}
      interactive
      placement="right-start"
      item={item}
    >
      <ProductOptionLabel
        showCount
        count={calculateMaxCount(item)}
        supplierName={item.supplierName}
        category={item.category}
        unitName={item.unitName}
        name={item.name}
        manufacturer={item.manufacturer}
      />
    </StoreItemInfoOption>
  ), [calculateMaxCount]);

  const handleSelect = useCallback((option) => {
    if (option) {
      const maxCount = calculateMaxCount(option.data);
      const {
        vat,
        priceNoVat,
        supplierPrice,
        markup,
        price,
        name,
        id,
      } = option.data;
      onRowDataChange({
        ...rowData,
        supplierPrice,
        priceNoVat,
        vat,
        markup,
        price,
        name,
        count: maxCount < rowData.count ? maxCount : rowData.count,
        maxCount,
        saleItemId: id,
      });
    } else {
      onRowDataChange({
        ...rowData,
        saleItemId: null,
        name: '',
        supplierPrice: 0,
        vat: 0,
        markup: 0,
        price: 0,
        sum: 0,
        maxCount: null,
      });
    }
  }, [calculateMaxCount, rowData, onRowDataChange]);

  return (
    <InputSelect
      value={selectedOption}
      paperHeader={header}
      error={error}
      required
      renderOption={renderOptionWithTooltip}
      label={TEXT.COLUMN.HEADER.NAME}
      loadOptions={loadOptions}
      onChange={handleSelect}
    />
  );
}

export default React.memo(RestitutionItemEditInput);
