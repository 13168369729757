import { TEXT } from '@/utils/Text';
import React, { useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';

const useDocumentNameColumn = (
  column = null,
  error: string | null = null,
) => useMemo(() => ({
  hidden: column ? column.hidden : false,
  title: TEXT.DOCUMENT,
  field: 'documentName',
  editComponent: ({ onChange, value }) => (
    <CustomInput
      error={error}
      maxLength={127}
      id="documentName"
      label={TEXT.DOCUMENT}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ),
}), [error, column]);

export default useDocumentNameColumn;
