import React, { FC } from 'react';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import useStyles from './style';

interface Props {
  title: NonNullable<React.ReactNode>;
  fontSize?: 'inherit' | 'default' | 'small' | 'large';
}

const HelpIconTooltip: FC<Props> = ({
  title,
  fontSize = 'small',
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      interactive
      arrow
      title={title}
    >
      <HelpIcon
        classes={classes}
        fontSize={fontSize}
        color="primary"
      />
    </Tooltip>
  );
};
export default HelpIconTooltip;
