import { ProductInfoErrors } from '@/components/organisms/ProductActionModal/productActionDialog.types';

export default ({ name, supplierPrice, price }) => {
  const errors = {} as ProductInfoErrors;
  if (!price) {
    errors.price = true;
  }
  if (!supplierPrice) {
    errors.supplierPrice = true;
  }
  if (!name) {
    errors.name = true;
  }
  return Object.keys(errors).length === 0 ? undefined : errors;
};
