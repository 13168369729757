import { Role } from '@/enum/Role';

export enum RoleActionType {
  SET_ROLES = 'SET_ROLES',
  STOREKEEPER_ROLE_UPDATE = 'STORE_KEEPER_ROLE_UPDATE',
  SUPPLIER_ROLE_UPDATE = 'SUPPLIER_ROLE_UPDATE'
}

export interface RoleAction<T> {
  type: RoleActionType,
  payload: T
}

interface SetRoleAction extends RoleAction<Array<Role>> {
}

interface RoleUpdateAction extends RoleAction<boolean> {
}

export function createSetRolesAction(
  roles: Array<Role>,
): SetRoleAction {
  return {
    type: RoleActionType.SET_ROLES,
    payload: roles,
  };
}

export function createStoreKeeperRoleUpdateAction(
  enabled: boolean,
): RoleUpdateAction {
  return {
    type: RoleActionType.STOREKEEPER_ROLE_UPDATE,
    payload: enabled,
  };
}

export function createSupplierRoleUpdateAction(
  enabled: boolean,
): RoleUpdateAction {
  return {
    type: RoleActionType.SUPPLIER_ROLE_UPDATE,
    payload: enabled,
  };
}
