import { selectCompanyUserInfos, selectSelectedUserId } from '@/redux/selectors/companySelector';
import { TEXT } from '@/utils/Text';
import { ListSubheader, Select } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '@/redux/selectors/applicationSelector';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';
import { CompanyUserState } from '@/enum/CompanyUserState';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import useStyles from './companyUserSelect.styles';

const CompanyUserSelect: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companyUsers = useSelector(selectCompanyUserInfos);
  const user = useSelector(selectUser);
  const selectedUserId = useSelector(selectSelectedUserId);

  const handleChange = useCallback(({ target: { value } }) => {
    if (user.id === value) {
      dispatch(CompanyUserInfoActions.setSelected(null));
    } else {
      dispatch(CompanyUserInfoActions.setSelected(
        companyUsers.find((item) => item.userId === value),
      ));
    }
  }, [dispatch, companyUsers, user]);

  return (
    <Select
      value={selectedUserId}
      className={classes.select}
      onChange={handleChange}
      inputProps={{
        classes: {
          icon: classes.icon,
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        getContentAnchorEl: null,
      }}
    >
      <ListSubheader>{TEXT.YOU}</ListSubheader>
      <MenuItem
        value={user.id}
      >
        {user.name}
      </MenuItem>
      <ListSubheader>{TEXT.COMPANY_USERS}</ListSubheader>
      {companyUsers.map((companyUser) => (
        <MenuItem
          className={classes.item}
          key={companyUser.id}
          value={companyUser.userId}
          disabled={companyUser.state === CompanyUserState.PENDING}
        >
          {companyUser.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CompanyUserSelect;
