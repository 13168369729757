import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteClientById,
  getAllClientInfos,
  postClient,
  putClient,
  putToggleRequestConfirmationBySenderId,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import ApplicationContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCustomTableLoading,
  selectClientsTableColumns,
} from '@/redux/selectors/customTableSelector';
import { ClientTableErrors } from '@/components/organisms/ClientsTable/clientsTable.types';
import { ClientInfo } from '@/interfaces/ClientInfo';
import {
  createDeleteClientInfoAction,
  createSetClientInfosAction,
  createUpdateClientInfo,
} from '@/redux/actions/clientInfoActions';
import { selectClientInfos } from '@/redux/selectors/clientInfoSelector';
import useAddressColumn from '@/hook/columns/useAddressColumn';
import useUnpColumn from '@/hook/columns/useUnpColumn';
import usePriceFormationTypeColumn from '@/hook/columns/usePriceFormationTypeColumn';
import useOrganizationColumn from '@/hook/columns/useOrganizationColumn';
import usePhoneNumberColumn from '@/hook/columns/usePhoheNumberColumn';

function ClientTable() {
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ClientTableErrors>({});
  const [rows, setRows] = useState([]);
  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectClientsTableColumns);
  const clientInfos = useSelector<Array<ClientInfo>>(selectClientInfos);

  const addressColumn = useAddressColumn(columns.address, errors.address);
  const unpColumn = useUnpColumn(columns.unp, errors.unp);
  const priceFormationTypeColumn = usePriceFormationTypeColumn(
    columns.priceFormationTypeId,
    errors.priceFormationTypeId,
  );
  const organizationColumn = useOrganizationColumn(errors.organization);
  const phoneNumber = usePhoneNumberColumn(columns.phoneNumber, errors.phoneNumber, true);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    getAllClientInfos()
      .then((clientInfos) => (
        dispatch(createSetClientInfosAction(clientInfos))
      )).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(clientInfos.map((clientInfo) => ({ ...clientInfo })));
  }, [clientInfos]);

  const memoizedColumns = useMemo(() => (
    [
      organizationColumn,
      phoneNumber,
      {
        hidden: columns.contactEmail ? columns.contactEmail.hidden : false,
        title: TEXT.COLUMN.HEADER.EMAIL,
        field: 'contactEmail',
        editComponent: (props) => (
          <CustomInput
            error={errors.contactEmail}
            id="contactEmail"
            label={TEXT.CONTACT_EMAIL}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
          />
        ),
      },
      addressColumn,
      unpColumn,
      priceFormationTypeColumn,
    ]
  ), [
    phoneNumber,
    organizationColumn,
    columns,
    errors,
    addressColumn,
    unpColumn,
    priceFormationTypeColumn,
  ]);

  const validateClient = useCallback(({ organization }) => {
    const errors: ClientTableErrors = {};
    if (!organization) {
      errors.organization = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateClient(data)) {
      return reject(data);
    }
    setLoading(true);
    postClient(data)
      .then((response: ClientInfo) => {
        dispatch(createUpdateClientInfo(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, dispatch, context, validateClient]);

  const onUpdate = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateClient(data)) {
      return reject(data);
    }
    setLoading(true);
    putClient({ ...data })
      .then((response: ClientInfo) => {
        dispatch(createUpdateClientInfo(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [errors, validateClient, context, dispatch]);

  const onDelete = useCallback((oldData) => new Promise((resolve, reject) => {
    setLoading(true);
    const { id, originalUserId, external } = oldData;

    if (external) {
      putToggleRequestConfirmationBySenderId(originalUserId, false)
        .then(() => {
          dispatch(createDeleteClientInfoAction(id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    } else {
      deleteClientById(id)
        .then(() => {
          dispatch(createDeleteClientInfoAction(id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    }
  }), [dispatch, context]);

  const memoizedEditable = useMemo(() => ({
    onRowAdd: onAdd,
    onRowUpdate: onUpdate,
    onRowDelete: onDelete,
  }), [onAdd, onUpdate, onDelete]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const isLoading = loading || customTableLoading;

  return (
    <StyledTable
      tableKey={ID.CLIENT_TABLE}
      onRowEditing={handleRowEditing}
      editable={memoizedEditable}
      loading={isLoading}
      columns={memoizedColumns}
      data={rows}
    />
  );
}

export default ClientTable;
