import { ClientInfo, ClientMinorInfo } from '@/interfaces/ClientInfo';
import { getAllClientInfos } from '@/utils/fetch';
import { AutocompleteOption, toOption } from '@/utils/toOption';
import CustomAutocomplete from '@/components/molecules/CustomAutocomplete';
import { TEXT } from '@/utils/Text';
import React, { useCallback } from 'react';
import { formatPhoneNumberStr } from '@/utils/stringUtils';
import MultipleAutocompleteOption from '@/components/molecules/MultipleAutocompleteOption';
import ClientInfoOption from '../ClientInfoOption';

type ValueType = AutocompleteOption<ClientMinorInfo> | AutocompleteOption<ClientMinorInfo>[] | null;

interface ClientInputProps {
  value: ValueType;

  onChange (value: ValueType): void;

  error?: string;
  classes?: any;
  required?: boolean;
  multiple?: boolean,
}

function ClientAutocomplete({
  error,
  onChange,
  value,
  classes,
  required,
  multiple,
}: ClientInputProps) {
  const loadClientsOptions = useCallback(async (inputValue) => {
    const response: Array<ClientInfo> = await getAllClientInfos(inputValue);
    return [
      response.map((clientInfo) => (
        toOption(clientInfo.organization, clientInfo, clientInfo.id))),
      response.length,
    ];
  }, []);

  const renderOptionWithTooltip = useCallback(({ data: item }, { selected }) => (
    multiple ? (
      <MultipleAutocompleteOption
        name={(
          <ClientInfoOption
            item={item}
          >
            <div className="flex flex-space-between full">
              <span className="text-overflow-ellipsis f-1 mr-5">
                {item.organization}
              </span>
              <span style={{ textAlign: 'end' }}>{item.phoneNumber || ''}</span>
            </div>
          </ClientInfoOption>
        )}
        selected={selected}
      />
    ) : (
      <ClientInfoOption
        item={item}
      >
        <div className="flex flex-space-between full">
          <span className="text-overflow-ellipsis f-1 mr-5">
            {item.organization}
          </span>
          <span style={{ textAlign: 'end' }}>{item.phoneNumber || ''}</span>
        </div>
      </ClientInfoOption>
    )
  ), [multiple]);

  const filterOptions = useCallback((
    options,
    { inputValue },
  ) => options.filter(({ data: { organization, phoneNumber } }) => (
    (phoneNumber && formatPhoneNumberStr(phoneNumber).includes(formatPhoneNumberStr(inputValue)))
    || (organization && organization.toLowerCase().includes(inputValue))
  )), []);

  return (
    <CustomAutocomplete
      disableCloseOnSelect={multiple}
      multiple={multiple}
      filterOptions={filterOptions}
      required={required}
      classes={classes}
      error={error}
      label={TEXT.COLUMN.HEADER.CLIENT}
      value={value}
      loadOptions={loadClientsOptions}
      onChange={onChange}
      renderOption={renderOptionWithTooltip}
    />
  );
}

export default React.memo(ClientAutocomplete);
