import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';

const ExcelParseResultSkeleton: FC = () => (
  <>
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="rect" height={200} />
    <Skeleton variant="text" />
    <Skeleton variant="rect" height={50} />
    <Skeleton variant="text" />
    <Skeleton variant="rect" height={50} />
    <Skeleton variant="text" />
    <Skeleton variant="rect" height={50} />
  </>
);

export default ExcelParseResultSkeleton;
