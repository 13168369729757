import { TEXT } from '@/utils/Text';
import { optionByProductId } from '@/utils/toOption';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import React, { useCallback, useMemo, useState } from 'react';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import { Box } from '@material-ui/core';
import ProductActionIcon from '@/components/atoms/ProductActionIcon';

const header = (
  <MenuItem disabled>
    <div className="bold flex flex-space-between align-items-center full-width">
      <span className="text-overflow-ellipsis f-1 mr-10">
        {TEXT.COLUMN.HEADER.NAME}
      </span>
      <span className="f-1 fs-12">
        {TEXT.SALES.BALANCE}
      </span>
    </div>
  </MenuItem>
);

function ProductInfoEditInput({
  rowData,
  onRowDataChange,
  loadOptions,
  error,
  renderOptionWithTooltip,
  actionIcon,
  priceFormationTypeId,
  getOptionSelected,
}) {
  const [isPreLoaded, setPreLoaded] = useState(false);

  const selectedOption = useMemo(() => optionByProductId(rowData), [rowData]);

  const handleSelect = useCallback((option) => {
    if (!option && !rowData.productId) return;

    if (option) {
      const {
        vat,
        priceNoVat,
        supplierPrice,
        markup,
        id,
        price,
        name,
        supplierOrderItemId,
        producingDate,
        storedCount,
        reservedCount,
      } = option.data;
      onRowDataChange({
        ...rowData,
        storedCount,
        reservedCount,
        supplierPrice: isPreLoaded ? supplierPrice : rowData.supplierPrice,
        priceNoVat: isPreLoaded ? priceNoVat : rowData.priceNoVat,
        vat: isPreLoaded ? vat : rowData.vat,
        markup: isPreLoaded ? markup : rowData.markup,
        price: isPreLoaded ? price : rowData.price,
        name,
        producingDate: producingDate || rowData.producingDate,
        supplierOrderItemId,
        productId: id,
      });
    } else {
      onRowDataChange({
        ...rowData,
        reservedCount: undefined,
        storedCount: undefined,
        productId: null,
        name: '',
        supplierOrderItemId: null,
        supplierPrice: 0,
        vat: 0,
        markup: 0,
        price: 0,
        sum: 0,
      });
    }
    setPreLoaded(true);
  }, [rowData, onRowDataChange, isPreLoaded]);

  const handleInputChange = useCallback((value) => {
    onRowDataChange({
      ...rowData,
      name: value,
    });
  }, [rowData, onRowDataChange]);

  const handleDialogAction = useCallback((newData) => {
    onRowDataChange({
      ...rowData,
      ...newData,
      storedCount: undefined,
      reservedCount: undefined,
    });
  }, [onRowDataChange, rowData]);

  return (
    <Box display="flex">
      <Box flex="auto">
        <InputSelect
          getOptionSelected={getOptionSelected}
          value={selectedOption}
          paperHeader={header}
          error={error}
          required
          renderOption={renderOptionWithTooltip}
          label={TEXT.COLUMN.HEADER.NAME}
          loadOptions={loadOptions}
          onChange={handleSelect}
          onInputChange={handleInputChange}
        />
      </Box>
      {actionIcon && (
        <ProductActionIcon
          productId={rowData.productId || ''}
          onAction={handleDialogAction}
          priceFormationTypeId={priceFormationTypeId}
        />
      )}
    </Box>
  );
}

export default React.memo(ProductInfoEditInput);
