import { TEXT } from '@/utils/Text';
import { useCallback, useMemo } from 'react';
import { compareDateTime } from '@/utils/comparator';

const useSaleTimeColumn = (
  title = TEXT.COLUMN.HEADER.SALE_DATE,
) => {
  const handleCustomSort = useCallback((a, b) => (
    compareDateTime(a.saleTime, b.saleTime)
  ), []);

  return useMemo(() => ({
    title,
    field: 'saleTime',
    editable: 'never',
    customSort: handleCustomSort,
    defaultSort: 'desc',
    width: 200,
  }), [title, handleCustomSort]);
};

export default useSaleTimeColumn;
