import Supplier from './Unit';

class UnitService {
  static parse({ id, name }) {
    return new Supplier(
      id,
      name,
    );
  }

  static parseItems(items) {
    return items.map((item) => UnitService.parse(item));
  }
}

export default UnitService;
