import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { SupplierPartnerRequestInfoActionType } from '@/redux/actions/supplierPatnerRequestInfoActions';

export function supplierPartnerRequestInfoReducer(
  supplierPartnerRequestInfos: Array<UserPartnerRequestInfo> = [],
  { type, payload },
): Array<UserPartnerRequestInfo> {
  switch (type) {
    case SupplierPartnerRequestInfoActionType.SET:
      return payload;
    case SupplierPartnerRequestInfoActionType.UPDATE_ONE:
      const index = supplierPartnerRequestInfos.findIndex((supplierInfo) => supplierInfo.id === payload.id);
      if (index > -1) {
        const newInfos = [...supplierPartnerRequestInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return supplierPartnerRequestInfos;

    default:
      return supplierPartnerRequestInfos;
  }
}
