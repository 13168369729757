import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TtnAnnexDocument from '@/components/organisms/TtnAnnexDocument/TtnAnnexDocument';
import CheckboxList from '@/components/atoms/CheckboxList/CheckboxList';
import TableBuilder from '@/components/organisms/TableBuilder';
import {
  getClientInfoById,
  getDocuments,
  getDocumentSettings,
  putDocument,
} from '@/utils/fetch';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import Typography from '@/components/atoms/Typography';
import { DefaultDocument } from '@/enum/DefaultDocument';
import TtnDocument from '@/components/organisms/TtnDocument/TtnDocument';
import { ClientInfo } from '@/interfaces/ClientInfo';
import SaleItemInfo from '@/interfaces/SaleItemInfo';
import { useStyles } from '@/components/organisms/SaleExportModal/saleExportModal.styles';
import SaleReceipt from '@/components/molecules/SaleReceipt';
import { columnToOption } from '@/utils/toOption';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@/components/atoms/IconButton';
import DocumentEditableList from './DocumentEditableList/DocumentEditableList';
import DocumentOutputMenu from './DocumentOutputMenu';
import SaleInfo from '@/interfaces/SaleInfo';

interface Props {
  saleItemInfos: SaleItemInfo[];
  selectedSale: SaleInfo;
  onClose: () => void;
  isOpen: boolean;
}

function SaleExportModal({
  saleItemInfos,
  selectedSale,
  onClose,
  isOpen,
}: Props) {
  const classes = useStyles();

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [formats, setFormats] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const [documentColumns, setDocumentColumns] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);

  const [selectedFormat, setSelectedFormat] = useState('');
  const [fullscreen, setFullscreen] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    if (!selectedDocument || selectedDocument.isDefault) return;

    setAllColumns(
      columnNames.map(name => {
        const column = documentColumns.find(
          documentColumn => name === documentColumn.name
        );
        return columnToOption(name, !!column);
      })
    );
  }, [columnNames, documentColumns, selectedDocument]);

  useEffect(() => {
    if (!selectedDocument) return;

    if (!selectedDocument.isDefault) {
      setDocumentColumns(selectedDocument.documentColumns);
    }
    setSelectedFormat(selectedDocument.format);
  }, [selectedDocument]);

  const onDocumentSelect = useCallback(doc => {
    setSelectedDocument(doc);
  }, []);

  const updateDocument = useCallback(
    (document, format, allColumns) => {
      setLoading(true);
      putDocument({
        id: document.id,
        name: document.name,
        format,
        documentColumns: allColumns
          .filter(column => column.checked)
          .map(column => ({ name: column.value })),
      })
        .then(newDocument => {
          if (selectedDocument && selectedDocument.id === document.id) {
            setSelectedDocument(newDocument);
          }
          const index = documents.findIndex(doc => doc.id === newDocument.id);
          const newDocuments = [...documents];
          newDocuments[index] = newDocument;
          setDocuments(newDocuments);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [selectedDocument, documents]
  );

  const { clientId, id: saleId } = selectedSale;

  const onOpen = useCallback(() => {
    setLoading(true);
    const promises = [
      getDocumentSettings().then(({ formats, documentColumnNames }) => {
        setFormats(formats);
        setColumnNames(documentColumnNames);
        setSelectedFormat(formats[0]);
      }),
      getDocuments(saleId).then(documents => {
        setDocuments(documents);
        setSelectedDocument(documents.find(doc => doc.isDefault));
      }),
    ];
    if (clientId) {
      promises.push(
        getClientInfoById(clientId).then(clientInfo =>
          setClientInfo(clientInfo)
        )
      );
    }
    Promise.all(promises).finally(() => {
      setLoading(false);
    });
  }, [clientId]);

  const documentPreview = useMemo(() => {
    if (!selectedDocument) return;

    const { isDefault, id } = selectedDocument;
    if (isDefault) {
      switch (id) {
        case DefaultDocument.SALE_RECEIPT: {
          return (
            <SaleReceipt
              saleItemInfos={saleItemInfos}
              saleInfo={selectedSale}
            />
          );
        }
        case DefaultDocument.TTN: {
          return (
            <TtnDocument
              editable={fullscreen}
              saleInfo={selectedSale}
              saleItemInfos={saleItemInfos}
              clientInfo={clientInfo}
            />
          );
        }
        case DefaultDocument.TTN_ANNEX: {
          return (
            <TtnAnnexDocument
              clientInfo={clientInfo}
              editable={fullscreen}
              saleInfo={selectedSale}
              saleItemInfos={saleItemInfos}
            />
          );
        }
        default: {
          return <span>This document is not supported</span>;
        }
      }
    } else {
      return (
        <TableBuilder
          rows={saleItemInfos}
          columns={allColumns.filter(option => option.checked)}
        />
      );
    }
  }, [
    clientInfo,
    selectedDocument,
    fullscreen,
    selectedSale,
    saleItemInfos,
    allColumns,
  ]);

  const onColumnChange = useCallback(
    value => {
      const newDocumentColumns = [...allColumns];
      const index = newDocumentColumns.findIndex(item => item.value === value);
      if (index > -1) {
        const oldItem = newDocumentColumns[index];
        newDocumentColumns[index] = { ...oldItem, checked: !oldItem.checked };
      }
      if (!selectedDocument.isDefault) {
        updateDocument(selectedDocument, selectedFormat, newDocumentColumns);
      }
    },
    [updateDocument, allColumns, selectedDocument, selectedFormat]
  );

  const onAdd = useCallback(
    document => {
      setDocuments([...documents, document]);
      setSelectedDocument(document);
    },
    [documents]
  );

  const onDelete = useCallback(
    id => {
      const newDocuments = documents.filter(doc => doc.id !== id);
      setDocuments(newDocuments);
      setSelectedDocument(newDocuments[0]);
    },
    [documents]
  );

  const onPreviewClick = useCallback(() => {
    setFullscreen(true);
  }, []);

  const isDefaultDocument = !!selectedDocument && selectedDocument.isDefault;

  const handleFullscreenClose = useCallback(event => {
    event.stopPropagation();
    setFullscreen(false);
  }, []);

  return (
    <ModalWindow
      disableBackdropClick={true}
      hideCloseIcon={fullscreen}
      onClose={onClose}
      isOpen={isOpen}
      header="Экспорт"
      onOpen={onOpen}
    >
      <Box className={classes.container}>
        <div className="flex-column-container">
          <DocumentEditableList
            classes={classes}
            onAdd={onAdd}
            onDelete={onDelete}
            setLoading={setLoading}
            selectedDocument={selectedDocument}
            documents={documents}
            onSelect={onDocumentSelect}
          />
          {!isDefaultDocument && (
            <div className={classes.paper}>
              <Typography className={classes.title} component="legend">
                Столбцы
              </Typography>
              <CheckboxList
                loading={loading}
                onChange={onColumnChange}
                disabled={!selectedDocument || selectedDocument.isDefault}
                list={allColumns}
              />
            </div>
          )}
          <div className={classes.paper}>
            <Typography className={classes.title} component="legend">
              Отправить
            </Typography>
            <DocumentOutputMenu
              loading={loading}
              selectedDocument={selectedDocument}
              selectedSale={selectedSale}
              tableRef={tableRef}
              disabledCopyToClipboard={isDefaultDocument}
            />
          </div>
        </div>
        <Box className={clsx('d-flex', classes.paper)} maxHeight={800}>
          <Box
            onClick={onPreviewClick}
            className={clsx({
              'full-size-preview': fullscreen,
              [classes.preview]: !fullscreen,
              [classes.withColumnsPreview]: !fullscreen && !isDefaultDocument,
            })}
          >
            {fullscreen && (
              <IconButton
                className={classes.fullscreenBtn}
                onClick={handleFullscreenClose}
              >
                <CloseIcon />
              </IconButton>
            )}
            <div
              className={clsx({ 'page-fullscreen': fullscreen })}
              size="A4"
              ref={tableRef}
            >
              {documentPreview}
            </div>
          </Box>
        </Box>
      </Box>
    </ModalWindow>
  );
}

export default SaleExportModal;
