import { StoreState } from '@/redux/stores/store';
import { SupplierInfo } from '@/interfaces/SupplierInfo';
import { createSelector } from 'reselect';

export const selectSupplierInfos = (state: StoreState): Array<SupplierInfo> => state.supplierInfos;

export const selectInternalSupplierInfos = createSelector(
  selectSupplierInfos,
  (supplierInfos: Array<SupplierInfo>): Array<SupplierInfo> => supplierInfos.filter((supplierInfo) => !supplierInfo.external),
);

export const selectExternalSupplierInfos = createSelector(
  selectSupplierInfos,
  (supplierInfos: Array<SupplierInfo>): Array<SupplierInfo> => supplierInfos.filter((supplierInfo) => supplierInfo.external),
);
