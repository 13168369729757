import React, { useCallback, useState } from 'react';
import Utils from '@/utils/Utils';
import { useValueWidth } from '@/hook';

interface Props {
  initialValue?: string;
  editable?: boolean;
  placeholder?: string;
  width?: number;
}

export function EditableTableCell({
  editable,
  placeholder,
  initialValue,
  width,
}: Props) {
  const [value, setValue] = useState(initialValue || '');

  const valueWidth = useValueWidth(value);

  const handleChange = useCallback(({ target: { value: newValue } }) => {
    setValue(newValue);
  }, []);

  return (
    <td>
      {editable ? (
        <>
          <input
            type="text"
            value={value}
            onChange={handleChange}
            width={
              width || valueWidth || Utils.calculateTextWidth(placeholder, 12)
            }
            style={{
              maxWidth: 800,
            }}
            placeholder={placeholder}
          />
        </>
      ) : (
        value
      )}
    </td>
  );
}
