enum ColumnName {
  CODE = 'CODE',
  NAME = 'NAME',
  CATEGORY = 'CATEGORY',
  MANUFACTURER = 'MANUFACTURER',
  SUPPLIER_NAME = 'SUPPLIER_NAME',
  UNIT = 'UNIT',
  PRODUCING_DATE = 'PRODUCING_DATE',
  SHELF_LIFE = 'SHELF_LIFE',
  PRICE_NO_VAT = 'PRICE_NO_VAT',
  SUM_NO_VAT = 'SUM_NO_VAT',
  VAT = 'VAT',
  PRICE = 'PRICE',
  VAT_SUM = 'VAT_SUM',
  SUM = 'SUM',
  COUNT = 'COUNT',
  NOTE = 'NOTE',
  STORED_COUNT = 'STORED_COUNT',
  RESERVED_COUNT = 'RESERVED_COUNT',
  LEFT_COUNT = 'LEFT_COUNT',
  COUNT_IN_TRANSIT = 'COUNT_IN_TRANSIT',
  SUPPLIER_PRICE = 'SUPPLIER_PRICE',
  RECEIVING_DATE = 'RECEIVING_DATE'
}

export default ColumnName;
