import { useContext, useEffect } from 'react';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';

const useOfflineMessage = () => {
  const context = useContext(SnackbarContext);

  useEffect(() => {
    const handleOffline = () => (
      context({ message: MESSAGE.NO_INTERNET_CONNECTION, variant: 'warning', closeable: false })
    );

    const handleOnline = () => (
      context({ message: MESSAGE.INTERNET_CONNECTION_ESTABLISHED, variant: 'success' })
    );

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
};

export default useOfflineMessage;
