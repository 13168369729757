import React, { FC, useCallback } from 'react';
import { Box, Paper } from '@material-ui/core';
import CustomExcelInput from '@/components/molecules/CustomExcelInput';
import { MB } from '@/utils/Utils';
import { TEXT } from '@/utils/Text';
import FileDescriptionPanel from '@/components/organisms/ProductImportModal/ExcelFileSelectStep/FileDescriptionPanel';
import Button from '@/components/atoms/Button';
import useStyles from '../style';

interface Props {
  onFileChange(file: File): void;
  loading: boolean;
  file: File | null;
  onNext(): void;
}

const ExcelFileSelectStep: FC<Props> = ({
  onFileChange,
  loading,
  file,
  onNext,
}) => {
  const classes = useStyles();

  const handleClick = useCallback((event) => event.stopPropagation(), []);

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column" p={2} mb={3}>
        <CustomExcelInput
          file={file}
          limitSize={MB * 5}
          onClick={handleClick}
          loading={loading}
          label={TEXT.LABEL.EXCEL_FILE}
          onChange={onFileChange}
        />
        <FileDescriptionPanel />
      </Box>
      <Box className={classes.actionPanel}>
        <Button
          color="primary"
          disabled={!file}
          onClick={onNext}
        >
          {TEXT.NEXT}
        </Button>
      </Box>
    </Paper>
  );
};

export default ExcelFileSelectStep;
