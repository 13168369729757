import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteProductById, getAllProductInfos, postProduct, putProduct,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import IconButton from '@/components/atoms/IconButton';
import Edit from '@material-ui/icons/Edit';
import ProductGroupEditModal from '@/components/organisms/ProductGroupEditModal';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotArchivedProducts } from '@/redux/selectors/applicationSelector';
import { isCustomTableLoading, selectProductTableColumns } from '@/redux/selectors/customTableSelector';
import useUnitColumn from '@/hook/columns/useUnitColumn';
import { round } from '@/utils/Utils';
import useVatColumn from '@/hook/columns/useVatColumn';
import { Box } from '@material-ui/core';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import validateProduct from '@/service/validator/validateProduct';
import useMarkupColumn from '@/hook/columns/useMarkupColumn';
import PriceFormationTypeSelect from '@/components/molecules/PriceFormationTypeSelect';
import ProductPostRequest from '@/interfaces/requests/ProductPostRequest';
import ProductUpdateRequest from '@/interfaces/requests/ProductUpdateRequest';
import usePriceNoVatColumn from '@/hook/columns/usePriceNoVatColumn';
import { PriceFormationTypeActions } from '@/redux/actions/priceFormationTypeActions';
import { selectCurrentPriceFormationTypeId } from '@/redux/selectors/priceFormationTypeSelector';
import useProductNameColumn from '@/hook/columns/useProductNameColumn';
import useSupplierColumn from '@/hook/columns/useSupplierColumn';
import ProductInfo from '@/interfaces/ProductInfo';
import { Column } from 'material-table';
import useManufacturerColumn from '@/hook/columns/useManufacturerColumn';
import ProductInfoActions from '@/redux/actions/ProductInfoInfoActions';
import { DEFAULT_COLUMN_PRODUCT_ITEMS } from '@/constants/DefaultCheckedColumn';
import ExportIcon from '@/components/atoms/ExportIcon';
import { useEffectDebugger } from '@/hook/useEffectDebugger';

function ProductTable() {
  const context = useContext(ApplicationContext);
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [groupEditModalOpened, setGroupEditModalOpened] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rows, setRows] = useState([]);
  const [editingRow, setEditingRow] = useState<boolean>(false);

  const products = useSelector(selectNotArchivedProducts);
  const columns = useSelector(selectProductTableColumns);
  const customTableLoading = useSelector(isCustomTableLoading);
  const priceFormationTypeId = useSelector(selectCurrentPriceFormationTypeId);

  const markupColumn = useMarkupColumn(columns.markup, true);
  const vatColumn = useVatColumn(columns.vat, errors.vat);
  const unitColumn = useUnitColumn(errors.unitId, columns.unitId);
  const priceNoVatColumn = usePriceNoVatColumn(errors.priceNoVat, columns.priceNoVat);
  const productNameColumn = useProductNameColumn(errors.name);
  const supplierColumn = useSupplierColumn('always', columns.supplierId, errors.supplierId);
  const manufacturerColumn = useManufacturerColumn(columns.manufacturer, errors.manufacturer);

  useEffect(() => {
    setLoading(true);
    getAllProductInfos(priceFormationTypeId)
      .then((productInfos) => dispatch(ProductInfoActions.set(productInfos)))
      .finally(() => setLoading(false));
  }, [dispatch, priceFormationTypeId]);

  useEffect(() => {
    setRows(products.map((product) => ({ ...product })));
  }, [products]);

  const onGroupUpdateSuccess = useCallback(() => {
    setSelectedProducts([]);
    setLoading(true);
    getAllProductInfos(priceFormationTypeId)
      .then((productInfos) => dispatch(ProductInfoActions.set(productInfos)))
      .finally(() => setLoading(false));
  }, []);

  const memoizedColumns: Column<ProductInfo>[] = useMemo(() => (
    [
      productNameColumn,
      supplierColumn,
      manufacturerColumn,
      unitColumn,
      {
        hidden: columns.category ? columns.category.hidden : false,
        title: TEXT.COLUMN.HEADER.CATEGORY,
        field: 'category',
        editComponent: (props) => (
          <CustomInput
            error={errors.category}
            id="category"
            label={TEXT.COLUMN.HEADER.CATEGORY}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
          />
        ),
      },
      {
        cellStyle: { width: 100 },
        hidden: columns.shelfLife ? columns.shelfLife.hidden : false,
        title: TEXT.COLUMN.HEADER.SHELF_LIFE,
        initialEditValue: 0,
        field: 'shelfLife',
        editComponent: (props) => (
          <CustomNumberInput
            error={errors.shelfLife}
            id="shelfLife"
            label={TEXT.COLUMN.HEADER.SHELF_LIFE}
            onChange={(value) => props.onChange(value)}
            value={props.value}
            min={0}
            step={1}
            decimalScale={0}
          />
        ),
      },
      {
        removable: false,
        title: buildCurrencyHeader(TEXT.COLUMN.HEADER.SUPPLIER_PRICE),
        initialEditValue: 0,
        field: 'supplierPrice',
        editComponent: ({ rowData, onRowDataChange, value }) => {
          const {
            vat = 0,
            price = 0,
            synchronizingProductId,
          } = rowData;
          return (
            <CustomNumberInput
              disabled={!!synchronizingProductId}
              error={errors.supplierPrice}
              id="supplierPrice"
              required
              label={TEXT.COLUMN.HEADER.SUPPLIER_PRICE}
              onChange={(supplierPrice) => {
                const priceNoVat = round(price / (vat / 100 + 1));
                onRowDataChange({
                  ...rowData,
                  supplierPrice,
                  priceNoVat,
                  markup: round((priceNoVat / supplierPrice - 1) * 100),
                });
              }}
              value={value}
              min={0}
              step={1}
              decimalScale={2}
              suffix={` ${currency}`}
            />
          );
        },
      },
      markupColumn,
      priceNoVatColumn,
      vatColumn,
      {
        cellStyle: { width: 100 },
        hidden: columns.price ? columns.price.hidden : false,
        title: buildCurrencyHeader(TEXT.COLUMN.HEADER.PRICE_WITH_VAT),
        field: 'price',
        initialEditValue: 0,
        editComponent: ({ rowData, onRowDataChange, value }) => {
          const {
            supplierPrice,
            vat = 0,
          } = rowData;
          return (
            <CustomNumberInput
              label={buildCurrencyHeader(TEXT.COLUMN.HEADER.PRICE_WITH_VAT)}
              error={errors.price}
              id="price"
              min={0}
              step={1}
              decimalScale={2}
              value={value}
              onChange={((price) => {
                const priceNoVat = round(price / (vat / 100 + 1));
                onRowDataChange({
                  ...rowData,
                  price,
                  priceNoVat,
                  markup: supplierPrice ? (priceNoVat / supplierPrice - 1) * 100 : 0,
                });
              })}
            />
          );
        },
      },
    ]
  ), [
    manufacturerColumn,
    productNameColumn,
    supplierColumn,
    vatColumn,
    priceNoVatColumn,
    unitColumn,
    markupColumn,
    columns,
    errors,
  ]);

  const onAdd: (newData: ProductInfo) => Promise<void> = useCallback(({
    category,
    manufacturer,
    markup,
    name,
    price,
    priceNoVat,
    shelfLife,
    supplierId,
    supplierPrice,
    unitId,
    vat,
  }) => new Promise((resolve, reject) => {
    const request: ProductPostRequest = {
      category,
      manufacturer,
      markup,
      name,
      price,
      priceNoVat,
      shelfLife,
      supplierId,
      supplierPrice,
      unitId,
      vat,
      priceFormationTypeId,
    };
    const validationErrors = validateProduct(request);
    if (validationErrors) {
      setErrors(validationErrors);
      reject(request);
      return;
    }
    setLoading(true);
    postProduct(request)
      .then((response) => {
        dispatch(ProductInfoActions.add(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [context, dispatch, errors, priceFormationTypeId]);

  const onUpdate: (newData: ProductInfo, oldData?: ProductInfo) => Promise<void> = useCallback(({
    id,
    category,
    manufacturer,
    markup,
    name,
    price,
    priceNoVat,
    shelfLife,
    supplierId,
    supplierPrice,
    unitId,
    vat,
  }) => new Promise(
    (resolve, reject) => {
      const data: ProductUpdateRequest = {
        id,
        category,
        manufacturer,
        markup,
        name,
        price,
        priceNoVat,
        shelfLife,
        supplierId,
        supplierPrice,
        unitId,
        vat,
        priceFormationTypeId,
      };
      const validationErrors = validateProduct(data);
      if (validationErrors) {
        setErrors(validationErrors);
        reject();
        return;
      }
      setLoading(true);
      putProduct(data)
        .then((response) => {
          dispatch(ProductInfoActions.updateOne(response));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatch, errors, priceFormationTypeId]);

  const onDelete: (oldData: ProductInfo) => Promise<void> = useCallback(
    (oldData) => new Promise((resolve, reject) => {
      setLoading(true);
      deleteProductById(oldData.id)
        .then((response) => {
          dispatch(ProductInfoActions.remove(response.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({ message: response.message, variant: 'warning' });
            return Promise.resolve(response);
          }
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    }), [context, dispatch],
  );

  const onRowEditing = useCallback(() => {
    setErrors({});
    setEditingRow(true);
  }, []);

  const onRowEditingEnd = useCallback(() => {
    setEditingRow(false);
  }, []);

  const onGroupEditModalOpen = useCallback(() => {
    setGroupEditModalOpened(true);
  }, []);

  const onGroupEditModalClose = useCallback(() => {
    setGroupEditModalOpened(false);
  }, []);

  const onSelectionChange = useCallback((selectedRows) => {
    setSelectedProducts(selectedRows);
  }, []);

  const editable: {
    isEditable?: (rowData: ProductInfo) => boolean;
    isDeletable?: (rowData: ProductInfo) => boolean;
    onRowAdd?: (newData: ProductInfo) => Promise<void>;
    onRowUpdate?: (newData: ProductInfo, oldData?: ProductInfo) => Promise<void>;
    onRowDelete?: (oldData: ProductInfo) => Promise<void>;
  } = useMemo(() => (
    {
      onRowAdd: onAdd,
      onRowUpdate: onUpdate,
      onRowDelete: onDelete,
    }
  ), [onAdd, onUpdate, onDelete]);

  const handlePriceFormationTypeChange = useCallback((value) => {
    dispatch(PriceFormationTypeActions.setCurrentById(value));
  }, [dispatch]);

  const rightButtonBar = useMemo(() => (
    <>
      <Box mr={4}>
        <PriceFormationTypeSelect
          disabled={editingRow}
          selectedValue={priceFormationTypeId}
          onChange={handlePriceFormationTypeChange}
        />
      </Box>
      <ExportIcon
        items={products}
        columnNames={DEFAULT_COLUMN_PRODUCT_ITEMS}
        disabled={!products.length || loading}
      />
    </>
  ), [editingRow, priceFormationTypeId, handlePriceFormationTypeChange, products, loading]);

  const rightButtonBarSelectionMode = useMemo(() => (
    <>
      <Box mr={4}>
        <PriceFormationTypeSelect
          selectedValue={priceFormationTypeId}
          onChange={handlePriceFormationTypeChange}
        />
      </Box>
      <IconButton
        disabled={!selectedProducts.length}
        className="mr-10"
        onClick={onGroupEditModalOpen}
        title={TEXT.EDIT}
      >
        <Edit
          color="primary"
        />
      </IconButton>
      <ProductGroupEditModal
        onSuccess={onGroupUpdateSuccess}
        selectedProducts={selectedProducts}
        isOpen={groupEditModalOpened}
        onClose={onGroupEditModalClose}
      />
    </>
  ), [
    onGroupUpdateSuccess,
    priceFormationTypeId,
    handlePriceFormationTypeChange,
    selectedProducts,
    groupEditModalOpened,
    onGroupEditModalOpen,
    onGroupEditModalClose,
  ]);

  useEffectDebugger(() => {

  }, [onGroupUpdateSuccess,
    priceFormationTypeId,
    handlePriceFormationTypeChange,
    selectedProducts,
    groupEditModalOpened,
    onGroupEditModalOpen,
    onGroupEditModalClose]);

  return (
    <StyledTable
      selection
      tableKey={ID.PRODUCT_TABLE}
      rightButtonBarSelectionMode={rightButtonBarSelectionMode}
      rightButtonBar={rightButtonBar}
      onRowEditing={onRowEditing}
      onRowEditingEnd={onRowEditingEnd}
      editable={editable}
      loading={loading || customTableLoading}
      columns={memoizedColumns}
      data={rows}
      onSelectionChange={onSelectionChange}
    />
  );
}

export default ProductTable;
