import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => createStyles({
  colorPrimary: {
    color: theme.palette.success.main,
  },
  colorSecondary: {
    color: theme.palette.warning.main,
  },
  colorAction: {
    color: theme.palette.info.main,
  },
}));
