import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { getAllPreorderItemInfos } from '@/utils/fetch';
import ID from '@/utils/id';
import { isCustomTableLoading } from '@/redux/selectors/customTableSelector';
import { useDispatch, useSelector } from 'react-redux';
import { isSelfSelected, selectSelectedUserId } from '@/redux/selectors/companySelector';
import { Column, Options } from 'material-table';
import useProductNameColumn from '@/hook/columns/useProductNameColumn';
import { PreorderItemInfo } from '@/interfaces/PreOrderItemInfo';
import PreorderSaleItemDetailPanel from '@/components/organisms/PreorderSaleItemDetailPanel';
import { Box } from '@material-ui/core';
import usePreorderStatusColumn from '@/hook/columns/usePreorderStatusColumn';
import HelpIconTooltip from '@/components/atoms/HelpIconTooltip';
import OrderByProductActionIcon
  from '@/components/organisms/PreorderTable/OrderByProductActionIcon';
import OrderByProductsActionIcon
  from '@/components/organisms/PreorderTable/OrderByProductsActionIcon';
import { PreOrderItemInfoState } from '@/enum/PreOrderItemInfoState';
import useSupplierColumn from '@/hook/columns/useSupplierColumn';
import { selectPreOrderItemInfos } from '@/redux/selectors/preOrderItemInfoSelector';
import { PreOrderItemInfoActions } from '@/redux/actions/preOrderItemInfoActions';

function PreorderTable() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [rows, setRows] = useState<PreorderItemInfo[]>([]);
  const [selectedRows, setSelectedRows] = useState<PreorderItemInfo[]>([]);

  const customTableLoading: boolean = useSelector(isCustomTableLoading);

  const selectedUserId = useSelector(selectSelectedUserId);
  const isEditable = useSelector(isSelfSelected);
  const preorderItemInfos = useSelector(selectPreOrderItemInfos);

  const productNameColumn = useProductNameColumn();
  const preorderStatusColumn = usePreorderStatusColumn();
  const supplierColumn = useSupplierColumn('never');

  useEffect(() => {
    setLoading(true);
    getAllPreorderItemInfos(selectedUserId)
      .then((response) => (
        dispatch(PreOrderItemInfoActions.set(response))
      ))
      .finally(() => setLoading(false));
  }, [selectedUserId, dispatch]);

  useEffect(() => {
    setRows(preorderItemInfos.map((value) => ({ ...value })));
  }, [preorderItemInfos]);

  const memoizedColumns = useMemo<Column<PreorderItemInfo>[]>(() => (
    [
      {
        hidden: !isEditable,
        field: 'actions',
        render: (rowData) => (
          <OrderByProductActionIcon
            preorderItemInfo={rowData}
          />
        ),
      },
      productNameColumn,
      preorderStatusColumn,
      supplierColumn,
      {
        cellStyle: { minWidth: 150 },
        title: TEXT.COLUMN.HEADER.CATEGORY,
        field: 'category',
        editable: 'never',
      },
      {
        cellStyle: { minWidth: 150 },
        editable: 'never',
        field: 'count',
        title: TEXT.COLUMN.HEADER.COUNT,
        render: ({
          count, countInTransit, unitName, leftCount,
        }) => (
          <Box display="flex">
            <Box mr={1} display="flex" alignItems="center">
              <HelpIconTooltip title={(
                <Box>
                  <Box m={1}>
                    {`В предзаказе: ${count}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`В пути: ${countInTransit}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`Необходимо заказать: ${leftCount > 0 ? leftCount : 0}`}
                  </Box>
                  <Box mx={1} mb={1}>
                    {`Запас: ${leftCount < 0 ? Math.abs(leftCount) : 0}`}
                  </Box>
                </Box>
            )}
              />
            </Box>
            <Box>
              {`${count} / ${countInTransit} / ${leftCount} ${unitName ? `(${unitName})` : ''}`}
            </Box>
          </Box>
        ),
      },
    ]
  ), [isEditable, preorderStatusColumn, productNameColumn, supplierColumn]);

  const handleSelectionChange = useCallback((items) => {
    function isSelected(item) {
      const found = items.find((currItem) => currItem.id === item.id);
      if (!found) return false;
      return found.state === PreOrderItemInfoState.NOT_ORDERED
        || found.state === PreOrderItemInfoState.PARTLY_ORDERED;
    }
    items.forEach((item) => {
      item.tableData.checked = isSelected(item);
    });

    setRows((prevState) => prevState.map((row) => ({
      ...row,
      tableData: {
        ...row.tableData,
        checked: isSelected(row),
      },
    })));
  }, []);

  useEffect(() => {
    setSelectedRows(rows.filter((row) => row.tableData && row.tableData.checked));
  }, [rows]);

  const rightButtonBarSelectionMode = useMemo(() => (
    <>
      <Box mr={1}>
        <OrderByProductsActionIcon
          preorderItemInfos={selectedRows}
        />
      </Box>
    </>
  ), [selectedRows]);

  const detailPanel = useCallback((rowData: PreorderItemInfo) => (
    <PreorderSaleItemDetailPanel
      productId={rowData.id}
    />
  ), []);

  const memoizedOptions: Options = useMemo(() => ({
    columnsButton: false,
    selectionProps: (rowData: PreorderItemInfo) => ({
      disabled: rowData.state === PreOrderItemInfoState.OVER_ORDERED
      || rowData.state === PreOrderItemInfoState.ORDERED,
    }),
  }), []);

  const isLoading = loading || customTableLoading;
  return (
    <div className="table-container">
      <StyledTable<PreorderItemInfo>
        detailPanel={detailPanel}
        rightButtonBarSelectionMode={rightButtonBarSelectionMode}
        tableKey={ID.STORE_TABLE}
        loading={isLoading}
        columns={memoizedColumns}
        data={rows}
        selection={isEditable}
        onSelectionChange={handleSelectionChange}
        options={memoizedOptions}
      />
    </div>
  );
}

export default PreorderTable;
