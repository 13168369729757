import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@/components/atoms/Typography';
import TextArea from '@/components/atoms/TextArea/TextArea';

const useCommentColumn = (
  column = null,
  error = null,
) => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <TextArea
      error={error}
      fullWidth
      id="comment"
      label={TEXT.COMMENT}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error]);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.COMMENT,
    field: 'comment',
    render: ({ comment }) => (
      <Tooltip
        arrow
        title={comment || ''}
      >
        <Typography variant="multilineEllipsis2">
          {comment}
        </Typography>
      </Tooltip>
    ),
    editComponent: handleEditComponent,
  }), [column, handleEditComponent]);
};

export default useCommentColumn;
