import StyledTable, { subTableOptions } from '@/components/organisms/StyledTable/StyledTable';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { ReservedSaleItemInfo } from '@/interfaces/SaleItemInfo';
import useFormTimeColumn from '@/hook/columns/useFormTimeColumn';
import useCountColumn from '@/hook/columns/useCountColumn';
import useClientColumn from '@/hook/columns/useClientColumn';
import { Column } from 'material-table';
import { TEXT } from '@/utils/Text';
import { getFormedSaleItemsByProductId } from '@/utils/fetch';
import { useSelector } from 'react-redux';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';

interface Props {
  productId: string;
}

const PreorderSaleItemDetailPanel: FC<Props> = ({
  productId,
}) => {
  const [loading, setLoading] = useState(false);
  const [reservedSaleItems, setReservedSaleItems] = useState<ReservedSaleItemInfo[]>([]);

  const userId = useSelector(selectSelectedUserId);

  const formTime = useFormTimeColumn<ReservedSaleItemInfo>();
  const count = useCountColumn<ReservedSaleItemInfo>();
  const clientColumn = useClientColumn<ReservedSaleItemInfo>(null, null, 'never', true);

  useEffect(() => {
    setLoading(true);
    getFormedSaleItemsByProductId(productId, userId)
      .then((response) => {
        setReservedSaleItems(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [productId, userId]);

  const memoizedColumns = useMemo<Column<ReservedSaleItemInfo>[]>(() => ([
    formTime,
    clientColumn,
    count,
  ]), [
    formTime,
    clientColumn,
    count,
  ]);

  return (
    <StyledTable<ReservedSaleItemInfo>
      emptyDataSourceMessage={TEXT.EMPTY_FORMED_SALE_ITEMS}
      className="sub-table"
      loading={loading}
      columns={memoizedColumns}
      data={reservedSaleItems}
      options={subTableOptions}
    />
  );
};

export default PreorderSaleItemDetailPanel;
