import { connect } from 'react-redux';
import { CURRENT_STORE, SALE_INFOS } from '@/constants/action';
import ID from '@/utils/id';
import * as ActionCreators from '../../redux/actions/actionCreator';

const mapStateToProps = (state, ownProps) => ({
  currentStore: state.application[CURRENT_STORE],
  saleInfos: state.application[SALE_INFOS],
  columns: state.customTable[ID.SALE_TABLE],
  customTableLoading: state.customTable.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchSetSaleInfos: (saleInfos) => dispatch(ActionCreators.setSaleInfos(saleInfos)),
  dispatchSaveSaleInfo: (sale) => dispatch(ActionCreators.saveSaleInfo(sale)),
  dispatchDeleteRecord: (id) => dispatch(ActionCreators.deleteRecord(id, SALE_INFOS)),
});

export const SaleContainer = (component) => connect(mapStateToProps, mapDispatchToProps)(component);
