import { toOption } from '@/utils/toOption';
import React, { useCallback, useMemo } from 'react';
import { TEXT } from '@/utils/Text';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import { getAllVendors } from '@/utils/fetch';
import Vendor from '@/interfaces/Vendor';
import { formatPhoneNumberStr } from '@/utils/stringUtils';

function VendorEditComponent({
  error,
  onRowDataChange,
  rowData,
  required = false,
}) {
  const selectedOption = useMemo(() => toOption(
    rowData.vendorName,
    { id: rowData.vendorId, firstName: rowData.vendorName },
    rowData.vendorId,
  ), [rowData]);

  const handleSelect = useCallback((option) => {
    if (option) {
      onRowDataChange({
        ...rowData,
        vendorId: option.data.id,
        vendorName: option.label,
      });
    } else {
      onRowDataChange({
        ...rowData,
        vendorId: null,
        vendorName: '',
      });
    }
  }, [rowData, onRowDataChange]);

  const loadVendorOptions = useCallback(async (inputValue) => {
    const response: Vendor[] = await getAllVendors(inputValue);
    return [
      response.map((vendor) => (
        toOption(vendor.firstName, vendor, vendor.id))),
      response.length,
    ];
  }, []);

  const filterOptions = useCallback((
    options,
    { inputValue },
  ) => options.filter(({ data: { firstName, phoneNumber, lastName } }) => (
    (firstName && firstName.toLowerCase().includes(inputValue))
    || (phoneNumber && formatPhoneNumberStr(phoneNumber).includes(formatPhoneNumberStr(inputValue)))
    || (lastName && lastName.toLowerCase().includes(inputValue))
  )), []);

  return (
    <div className="flex">
      <InputSelect
        filterOptions={filterOptions}
        maxLength={40}
        required={required}
        error={error}
        label={TEXT.VENDOR}
        value={selectedOption}
        loadOptions={loadVendorOptions}
        onChange={handleSelect}
      />
    </div>
  );
}

export default React.memo(VendorEditComponent);
