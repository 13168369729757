import React, { FC, useCallback, useState } from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import ClientAutocomplete from '@/components/molecules/ClientInput';
import { TEXT } from '@/utils/Text';
import moment from 'moment';
import { useStyles } from '@/components/organisms/ReconciliationActSubTab/reconciliationActSubTab.styles';
import ReconciliationTable from '@/components/organisms/ReconciliationTable';
import Button from '@/components/atoms/Button';
import { putReconciliationAct } from '@/utils/fetch';
import { buildCurrencyHeader, dateTimeFormat } from '@/app/applicationSettings';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@/components/atoms/Typography';
import KeyboardDateTimePicker from '@/components/atoms/KeyboardDateTimePicker/KeyboardDateTimePicker';

const ReconciliationActSubTab: FC = () => {
  const classes = useStyles();

  const [selectedClientOption, setSelectedClientOption] = useState<any>(null);
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment().startOf('month'));
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment().endOf('month'));
  const [debits, setDebits] = useState([]);
  const [credits, setCredits] = useState([]);
  const [startBalance, setStartBalance] = useState(0);
  const [endBalance, setEndBalance] = useState(0);
  const [periodBalance, setPeriodBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSearchClick = useCallback(() => {
    if (!startDate || !endDate) return;
    setLoading(true);
    putReconciliationAct(
      moment(startDate).format(dateTimeFormat),
      moment(endDate).format(dateTimeFormat),
      selectedClientOption.value,
    ).then((response) => {
      setStartBalance(response.startBalance);
      setEndBalance(response.endBalance);
      setPeriodBalance(response.periodBalance);
      setCredits(response.credits);
      setDebits(response.debits);
    }).finally(() => setLoading(false));
  }, [startDate, endDate, selectedClientOption]);

  const handleStartDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setStartDate(moment(value));
    } else {
      setStartDate(value);
    }
  }, []);

  const handleEndDateChange = useCallback((value) => {
    if (moment.isDate(value)) {
      setEndDate(moment(value));
    } else {
      setEndDate(value);
    }
  }, []);

  return (
    <Box>
      <Box className={classes.tabHeader}>
        <Paper className={classes.paper}>
          <Box
            className={classes.leftHeaderBox}
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Button
              variant="outlined"
              startIcon={
                <SearchIcon />
              }
              disabled={!startDate?.isValid() || !endDate?.isValid() || !selectedClientOption || loading}
              onClick={handleSearchClick}
              text={TEXT.SEARCH}
            />
            <ClientAutocomplete
              classes={{ container: classes.clientInput }}
              value={selectedClientOption}
              onChange={setSelectedClientOption}
            />
            <KeyboardDateTimePicker
              classes={{ root: classes.dateInput }}
              label={TEXT.START_DATE}
              onChange={handleStartDateChange}
              value={startDate}
            />
            <KeyboardDateTimePicker
              classes={{ root: classes.dateInput }}
              label={TEXT.END_DATE}
              onChange={handleEndDateChange}
              value={endDate}
            />
          </Box>
        </Paper>
        <Paper className={classes.balancePaper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.header}
              >
                {buildCurrencyHeader(TEXT.BALANCE)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.balanceItem}>
              {`Начальное: ${startBalance}`}
            </Grid>
            <Grid item xs={12} className={classes.balanceItem}>
              {`За период: ${periodBalance}`}
            </Grid>
            <Grid item xs={12} className={classes.balanceItem}>
              {`Конечное: ${endBalance}`}
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Paper>
        <ReconciliationTable
          loading={loading}
          debits={debits}
          credits={credits}
        />
      </Paper>

    </Box>
  );
};

export default ReconciliationActSubTab;
