import useCountColumn from '@/hook/columns/useCountColumn';
import usePriceColumn from '@/hook/columns/usePriceColumn';
import useMarkupColumn from '@/hook/columns/useMarkupColumn';
import useSumColumn from '@/hook/columns/useSumColumn';
import useProducingDateColumn from '@/hook/columns/useProducingDateColumn';
import { useMemo } from 'react';
import usePriceNoVatColumn from '@/hook/columns/usePriceNoVatColumn';
import useVatColumn from '@/hook/columns/useVatColumn';
import useVatSumColumn from '@/hook/columns/useVatSumColumn';
import useSumNoVatColumn from '@/hook/columns/useSumNoVatColumn';
import useProductColumn from '@/hook/columns/useProductColumn';

const useSaleItemColumns = (
  priceFormationTypeId,
  errors,
  columns,
  storeEnabled,
  saleType,
) => {
  const productColumn = useProductColumn(priceFormationTypeId, errors.productId, true);
  const priceNoVatColumn = usePriceNoVatColumn(columns.priceNoVat);
  const vatColumn = useVatColumn(columns.vat, errors.vat);
  const vatSumColumn = useVatSumColumn(columns.vatSum);
  const sumNoVatColumn = useSumNoVatColumn(columns.sumNoVat);
  const countColumn = useCountColumn(storeEnabled, columns.count, errors.count, saleType);
  const priceColumn = usePriceColumn(columns.price, errors.price, saleType);
  const markupColumn = useMarkupColumn(columns.markup, true);
  const sumColumn = useSumColumn(columns.sum);
  const producingColumn = useProducingDateColumn(columns.producingDate, errors.producingDate);

  return useMemo(() => [
    productColumn,
    countColumn,
    priceNoVatColumn,
    vatColumn,
    priceColumn,
    markupColumn,
    vatSumColumn,
    sumNoVatColumn,
    sumColumn,
    producingColumn,
  ], [
    priceNoVatColumn,
    vatColumn,
    vatSumColumn,
    sumNoVatColumn,
    productColumn,
    countColumn,
    priceColumn,
    markupColumn,
    sumColumn,
    producingColumn,
  ]);
};

export default useSaleItemColumns;
