import { createAction } from 'redux-actions';
import { CompanyInfo } from '@/interfaces/CompanyInfo';

enum Type {
  SET = 'SET_COMPANY_INFO',
  UPDATE = 'UPDATE_COMPANY_INFO',
  DELETE = 'DELETE_COMPANY_INFO',
}

const set = createAction<CompanyInfo>(Type.SET);
const update = createAction<CompanyInfo>(Type.UPDATE);
const remove = createAction<string>(Type.DELETE);

export const CompanyInfoActions = {
  Type,
  set,
  update,
  remove,
};

export type CompanyInfoActionsType = Omit<typeof CompanyInfoActions, 'Type'>;
