import { StoreState } from '@/redux/stores/store';
import SaleTableConfig from '@/interfaces/SaleTableConfig';
import { createSelector } from 'reselect';

export const selectSaleTableConfig = (state: StoreState): SaleTableConfig => state.saleTableConfig;

export const selectSaleTableFilter = createSelector(
  selectSaleTableConfig,
  (config: SaleTableConfig) => config.filter,
);
