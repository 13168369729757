import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import { doIfEnter } from '@/utils/keyCode';
import { TextField } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import Path from '@/enum/Path';
import { login } from '@/utils/fetch';
import SnackbarContext from '@/app/snackbarContext';
import RegExpPattern from '@/constants/RegExp';
import PasswordInput from '@/components/atoms/PasswordInput/PasswordInput';
import { useHistory } from 'react-router';

function LoginForm() {
  const context = useContext(SnackbarContext);
  const history = useHistory();
  const usernameRef = useRef<HTMLInputElement>();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState({});
  const [usernameError, setUsernameError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (usernameRef.current) {
      usernameRef.current.focus();
    }
  }, []);

  const handleLogin = useCallback(() => {
    let passError = {};
    let emailError = {};
    if (!email || (email && !RegExpPattern.E_MAIL.test(email))) {
      emailError = { error: true, helperText: 'Введите ваш e-mail' };
    }
    if (!password) {
      passError = { error: true, helperText: 'Введите ваш пароль' };
    } else if (password.length < 6) {
      passError = { error: true, helperText: 'Минимальная длина 6 символов' };
    }
    setPasswordError(passError);
    setUsernameError(emailError);
    if (emailError.error || passError.error) {
      return;
    }
    if (email && password) {
      setLoading(true);
      setErrorMessage('');
      login(email, password)
        .then(() => {
          history.push(Path.LOADING);
        })
        .catch((response) => {
          if (response.status === 401) {
            setErrorMessage(response.message);
          } else {
            context({
              message: response.message,
              variant: 'error',
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [history, email, password, context]);

  return (
    <div className="login-form">
      <div
        className="login-page-top-container"
      >
        <TextField
          inputRef={usernameRef}
          error={usernameError.error}
          helperText={usernameError.helperText}
          type="e-mail"
          label={TEXT.LOGIN_FORM.LABEL.USERNAME}
          onChange={(event) => {
            setUsernameError({});
            setEmail(event.target.value);
          }}
          onKeyDown={({ keyCode }) => doIfEnter(keyCode, handleLogin)}
          name="email"
          maxLength={40}
          value={email}
        />
        <PasswordInput
          error={passwordError.error}
          helperText={passwordError.helperText}
          onKeyDown={({ keyCode }) => doIfEnter(keyCode, handleLogin)}
          onChange={(event) => {
            setPasswordError({});
            setPassword(event.target.value);
          }}
          value={password}
          label={TEXT.LOGIN_FORM.LABEL.PASSWORD}
        />
        <span
          className="forget-password"
          onClick={() => {
            history.push(Path.FORGET_PASSWORD);
          }}
        >
          {TEXT.LOGIN_FORM.LABEL.FORGET_PASSWORD}
        </span>
      </div>
      {errorMessage && (
        <span className="invalid-input-message">{errorMessage}</span>
      )}
      <div className="login-page-btn-bar">
        <Button
          disabled={loading}
          className="login-page-btn"
          onClick={handleLogin}
        >
          {TEXT.LOGIN_FORM.BUTTON.SIGN_IN}
        </Button>
        <Button
          onClick={() => {
            history.push(Path.REGISTRATION);
          }}
          className="login-page-btn"
        >
          {TEXT.LOGIN_FORM.BUTTON.REGISTER}
        </Button>
      </div>
    </div>
  );
}

export default LoginForm;
