import React, { FC } from 'react';
import { Box } from '@material-ui/core';

interface Props {
  header: string;
  text: string | number;
}

const TooltipLine: FC<Props> = ({
  header,
  text,
}) => (
  <Box
    display="flex"
    flexDirection="column"
  >
    <Box
      component="span"
      fontSize={18}
      mb={0.5}
      pl={1}
      display="flex"
    >
      {header}
    </Box>
    <Box pl={3} component="span" mb={1} fontSize={16} pr={1}>
      {text}
    </Box>
  </Box>
);

export default TooltipLine;
