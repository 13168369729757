import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    fontWeight: 400,
  },
  error: {
    '& > div:before': {
      borderBottom: '2px solid #bd0101 !important',
    },
    '& > div:after': {
      borderBottom: '2px solid #bd0101 !important',
    },
    '& > div:hover:before': {
      borderBottom: '3px solid #bd0101 !important',
    },
  },
  warning: {
    '& > div:before': {
      borderBottom: '2px solid #fd7e14 !important',
    },
    '& > div:after': {
      borderBottom: '2px solid #fd7e14 !important',
    },
    '& > div:hover:before': {
      borderBottom: '3px solid #fd7e14 !important',
    },
  },
  container: {
    position: 'relative',
    minWidth: 55,
    marginRight: 5,
  },
}));
