import { AutocompleteOption, ClientRowData, toPreselectedClientOption } from '@/utils/toOption';
import React, { useCallback, useMemo, useState } from 'react';
import ClientAutocomplete from '@/components/molecules/ClientInput';
import { AddCircle } from '@material-ui/icons';
import { TEXT } from '@/utils/Text';
import AddClientDialog from '@/components/organisms/AddClientModal';
import IconButton from '@/components/atoms/IconButton';
import { ClientMinorInfo } from '@/interfaces/ClientInfo';

function ClientEditComponent({
  error,
  onRowDataChange,
  rowData,
  required,
  multiple,
}) {
  const [isDialogOpened, setDialogOpened] = useState(false);
  const [isInitializedFirst, setIsInitializedFirst] = useState(false);

  const selectedOption = useMemo<AutocompleteOption<ClientMinorInfo> | null>(() => (
    toPreselectedClientOption(rowData)
  ), [rowData]);

  const handleSelect = useCallback((option) => {
    if (option) {
      onRowDataChange({
        ...rowData,
        clientId: option.data.id,
        external: option.data.external,
        clientFirstName: option.label,
        priceFormationTypeId: isInitializedFirst
          ? option.data.priceFormationTypeId
          : rowData.priceFormationTypeId,
      });
    } else {
      onRowDataChange({
        ...rowData,
        clientId: null,
        external: false,
        clientFirstName: '',
      });
    }
    setIsInitializedFirst(true);
  }, [rowData, onRowDataChange, isInitializedFirst]);

  const handleAddClick = useCallback(() => (
    setDialogOpened(true)
  ), []);

  const handleDialogClose = useCallback(() => (
    setDialogOpened(false)
  ), []);

  const handleDialogAction = useCallback((newData: ClientRowData) => {
    onRowDataChange({
      ...rowData,
      ...newData,
    });
  }, [onRowDataChange, rowData]);

  return (
    <div className="flex">
      <ClientAutocomplete
        multiple={multiple}
        required={required}
        error={error}
        value={selectedOption}
        onChange={handleSelect}
      />
      <div className="flex-bottom mr-10">
        <IconButton
          onClick={handleAddClick}
          title={TEXT.BUTTON.ADD}
        >
          <AddCircle color="primary" />
        </IconButton>
      </div>
      <AddClientDialog
        onAction={handleDialogAction}
        isOpened={isDialogOpened}
        onClose={handleDialogClose}
      />
    </div>
  );
}

export default React.memo(ClientEditComponent);
