import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import {
  createRestitutionItem,
  deleteRestitutionItem,
  getRestitutionItemInfos,
  putRestitutionComplete,
  updateRestitutionItem,
} from '@/utils/fetch';
import { dateTimeFormat } from '@/app/applicationSettings';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import moment from 'moment';
import Button from '@/components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { SaleState } from '@/enum/SaleState';
import { RestorePageOutlined } from '@material-ui/icons';
import {
  isCustomTableLoading,
  selectRestitutionItemTableColumns,
} from '@/redux/selectors/customTableSelector';
import useCountColumn from '@/hook/columns/useCountColumn';
import usePriceColumn from '@/hook/columns/usePriceColumn';
import useMarkupColumn from '@/hook/columns/useMarkupColumn';
import useSumColumn from '@/hook/columns/useSumColumn';
import useProducingDateColumn from '@/hook/columns/useProducingDateColumn';
import { createUpdateRestitutionInfo } from '@/redux/actions/restitutionInfoActions';
import useRestitutionItemColumn from '@/hook/columns/useRestitutionItemColumn';
import { RestitutionItemInfo } from '@/interfaces/RestitutionItemInfo';
import {
  EditErrors,
  Props,
} from '@/components/organisms/RestitutionItemTable/restitutionItemTable.types';
import usePriceNoVatColumn from '@/hook/columns/usePriceNoVatColumn';
import useVatColumn from '@/hook/columns/useVatColumn';
import useVatSumColumn from '@/hook/columns/useVatSumColumn';
import useSumNoVatColumn from '@/hook/columns/useSumNoVatColumn';
import { isSelfSelected, selectSelectedUserId } from '@/redux/selectors/companySelector';

function RestitutionItemTable({
  restitution,
}: Props) {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<EditErrors>({});
  const [restitutionItemInfos, setRestitutionItemInfos] = useState<RestitutionItemInfo[]>([]);

  const columns = useSelector(selectRestitutionItemTableColumns);
  const customTableLoading = useSelector(isCustomTableLoading);
  const selectedUserId = useSelector(selectSelectedUserId);
  const isEditable = useSelector(isSelfSelected);

  const globalLoading = customTableLoading || loading;
  const { type, saleId } = restitution;
  const restitutionId = restitution.id;

  const restitutionItemColumn = useRestitutionItemColumn(saleId, errors.saleItemId);
  const priceNoVatColumn = usePriceNoVatColumn(columns.priceNoVat);
  const vatColumn = useVatColumn(columns.vat, errors.vat, true);
  const vatSumColumn = useVatSumColumn(columns.vatSum);
  const sumNoVatColumn = useSumNoVatColumn(columns.sumNoVat);
  const countColumn = useCountColumn(true, columns.count, errors.count, type);
  const priceColumn = usePriceColumn(columns.price, errors.price, type, true);
  const markupColumn = useMarkupColumn(columns.markup, true);
  const sumColumn = useSumColumn(columns.sum);
  const producingColumn = useProducingDateColumn(columns.producingDate, errors.producingDate, true);

  const memoizedColumns = useMemo(() => [
    restitutionItemColumn,
    countColumn,
    priceNoVatColumn,
    vatColumn,
    priceColumn,
    markupColumn,
    vatSumColumn,
    sumNoVatColumn,
    sumColumn,
    producingColumn,
  ], [
    restitutionItemColumn,
    countColumn,
    priceNoVatColumn,
    vatColumn,
    sumNoVatColumn,
    vatSumColumn,
    priceColumn,
    markupColumn,
    sumColumn,
    producingColumn,
  ]);

  useEffect(() => {
    let didCancel = false;
    if (restitutionId) {
      setLoading(true);
      getRestitutionItemInfos(restitutionId, selectedUserId)
        .then((response) => {
          if (didCancel) {
            return;
          }
          setRestitutionItemInfos(response);
        })
        .finally(() => {
          if (didCancel) {
            return;
          }
          setLoading(false);
        });
    } else {
      setRestitutionItemInfos([]);
    }
    return () => {
      didCancel = true;
    };
  }, [restitutionId]);

  const validateSaleItem = useCallback(({ saleItemId }) => {
    const errors: EditErrors = {};
    if (!saleItemId) {
      errors.saleItemId = MESSAGE.ERROR_REQUIRED_FIELD;
    }
    setErrors(errors);
    return Object.keys(errors).length ? Promise.reject() : Promise.resolve();
  }, []);

  const onAdd = useCallback(({
    saleItemId,
    count = 0,
    vatSum = 0,
    sumNoVat = 0,
    sum = 0,
  }) => new Promise((resolve, reject) => {
    setLoading(true);
    Promise.resolve()
      .then(() => validateSaleItem({
        count,
        vatSum,
        sumNoVat,
        sum,
        saleItemId,
      }))
      .then(() => createRestitutionItem({
        count,
        vatSum,
        sumNoVat,
        sum,
        saleItemId,
        restitutionId,
      }))
      .then(({ restitutionInfo, restitutionItemInfo }) => {
        dispatch(createUpdateRestitutionInfo(restitutionInfo));
        setRestitutionItemInfos([...restitutionItemInfos.filter((item) => item.id !== restitutionItemInfo.id), restitutionItemInfo]);
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error) {
          if (error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }), [
    restitutionId,
    dispatch,
    errors,
    context,
    restitutionItemInfos,
    setRestitutionItemInfos,
    validateSaleItem,
  ]);

  const onUpdate = useCallback(({
    id,
    saleItemId,
    count = 0,
    vatSum = 0,
    sumNoVat = 0,
    sum = 0,
  }) => new Promise((resolve, reject) => {
    setLoading(true);
    Promise.resolve()
      .then(() => validateSaleItem({
        count,
        vatSum,
        sumNoVat,
        sum,
        saleItemId,
      }))
      .then(() => updateRestitutionItem({
        id,
        count,
        vatSum,
        sumNoVat,
        sum,
        saleItemId,
        restitutionId,
      }))
      .then(({ restitutionInfo, restitutionItemInfo }) => {
        dispatch(createUpdateRestitutionInfo(restitutionInfo));
        setRestitutionItemInfos(restitutionItemInfos.map((item) => {
          if (item.id === restitutionItemInfo.id) {
            return restitutionItemInfo;
          }
          return item;
        }));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error) {
          if (error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }), [
    restitutionId,
    errors,
    restitutionItemInfos,
    context,
    dispatch,
    setRestitutionItemInfos,
    validateSaleItem,
  ]);

  const onDelete = useCallback((item) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteRestitutionItem(restitutionId, item.id)
        .then(({ restitutionItemInfo, restitutionInfo }) => {
          dispatch(createUpdateRestitutionInfo(restitutionInfo));
          setRestitutionItemInfos(restitutionItemInfos.filter((saleItemInfo) => restitutionItemInfo.id !== saleItemInfo.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({ message: response.message, variant: 'warning' });
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setLoading(false));
    },
  ), [
    restitutionId,
    dispatch,
    setRestitutionItemInfos,
    restitutionItemInfos,
    context,
  ]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const rightButtonBar = useMemo(() => {
    function handleRestitutionComplete() {
      const saleTime = moment(Date.now()).format(dateTimeFormat);
      setLoading(true);
      putRestitutionComplete({ id: restitutionId, saleTime })
        .then((completeSaleResponse) => {
          dispatch(createUpdateRestitutionInfo(completeSaleResponse.restitutionInfo));
          setRestitutionItemInfos(completeSaleResponse.restitutionItemInfos);
          context({ message: MESSAGE.SUCCESS_RESTITUTION_COMPLETE, variant: 'success' });
        })
        .finally(() => setLoading(false));
    }

    return (
      <div className="right-button-bar">
        <Button
          variant="outlined"
          endIcon={<RestorePageOutlined />}
          text={TEXT.COMPLETE}
          onClick={handleRestitutionComplete}
          disabled={!restitution || restitution.state === SaleState.COMPLETED || loading || globalLoading}
        />
      </div>
    );
  }, [
    restitutionId,
    loading,
    globalLoading,
    restitution,
    context,
    dispatch,
  ]);

  const editable = useMemo(() => {
    const hasWriteAccess = restitution
      && restitution.state !== SaleState.COMPLETED;
    return isEditable
      ? {
        onRowAdd: hasWriteAccess ? onAdd : null,
        onRowDelete: onDelete,
        onRowUpdate: onUpdate,
        isDeletable: () => hasWriteAccess,
        isEditable: () => hasWriteAccess,
      }
      : {};
  }, [isEditable, onDelete, onUpdate, onAdd, restitution]);

  const options = useMemo(() => ({
    paging: false,
    minBodyHeight: null,
    maxBodyHeight: 800,
  }), []);

  return (
    <div className="table-container">
      <StyledTable
        className="sub-table"
        tableKey={ID.RESTITUTION_ITEMS_TABLE}
        onRowEditing={onRowEditing}
        rightButtonBar={isEditable && rightButtonBar}
        editable={editable}
        loading={globalLoading}
        columns={memoizedColumns}
        data={restitutionItemInfos}
        options={options}
      />
    </div>
  );
}

export default RestitutionItemTable;
