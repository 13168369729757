import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPriceFormationTypes } from '@/utils/fetch';
import { PriceFormationTypeActions } from '@/redux/actions/priceFormationTypeActions';
import { selectSelectedUserId } from '@/redux/selectors/companySelector';
import selectCustomPriceFormationTypes, { selectPriceFormationTypeLoaded } from '@/redux/selectors/priceFormationTypeSelector';
import { TEXT } from '@/utils/Text';
import { ListSubheader, MenuItem } from '@material-ui/core';
import CustomSelect from '@/components/atoms/CustomSelect';
import priceFormationDefaultType from '@/service/builder/priceFormationDefaultType';
import useStyles from './styles';

interface Props {
  required?: boolean;
  error?: boolean;
  selectedValue: string | undefined;
  onChange?: (value: string | undefined) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const PriceFormationTypeSelect: FC<Props> = ({
  required,
  error,
  selectedValue = priceFormationDefaultType.id,
  onChange,
  fullWidth,
  disabled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<string>('');

  const userId = useSelector(selectSelectedUserId);

  const customPriceFormationTypes = useSelector(selectCustomPriceFormationTypes);
  const loaded = useSelector(selectPriceFormationTypeLoaded);

  useEffect(() => {
    if (loaded) {
      return;
    }
    setLoading(true);
    getPriceFormationTypes(userId)
      .then((response) => {
        dispatch(PriceFormationTypeActions.set(response));
        dispatch(PriceFormationTypeActions.setLoaded(true));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const exists = !!customPriceFormationTypes.find((item) => item.id === selectedValue)
      || selectedValue === priceFormationDefaultType.id;
    if (exists) {
      setSelected(selectedValue);
    } else if (typeof onChange === 'function') {
      onChange(undefined);
    }
  }, [onChange, selectedValue, loaded, customPriceFormationTypes]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    if (!value) {
      return;
    }
    if (typeof onChange === 'function') {
      onChange(value === priceFormationDefaultType.id ? undefined : value);
    } else {
      setSelected(value);
    }
  }, [onChange]);

  return (
    <CustomSelect
      fullWidth={fullWidth}
      required={required}
      error={error}
      classes={classes}
      disabled={loading || disabled}
      label={TEXT.PRICE_FORMATION_TYPE}
      value={selected}
      onChange={handleChange}
    >
      <ListSubheader>{TEXT.DEFAULT}</ListSubheader>
      <MenuItem
        key={priceFormationDefaultType.id}
        value={priceFormationDefaultType.id}
        divider
      >
        {priceFormationDefaultType.name}
      </MenuItem>
      {customPriceFormationTypes.length && (
        <ListSubheader>{TEXT.PERSONAL}</ListSubheader>
      )}
      {customPriceFormationTypes.map((item) => (
        <MenuItem
          key={item.id}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default PriceFormationTypeSelect;
