import { createAction } from 'redux-actions';
import Vendor from '@/interfaces/Vendor';

enum Type {
  ADD = 'ADD_VENDOR',
  SET = 'SET_VENDORS',
  UPDATE_ONE = 'UPDATE_VENDOR',
  DELETE_ONE = 'DELETE_VENDOR'
}

const set = createAction<Vendor[]>(Type.SET);
const update = createAction<Vendor>(Type.UPDATE_ONE);
const remove = createAction<string>(Type.DELETE_ONE);
const add = createAction<Vendor>(Type.ADD);

export const VendorActions = {
  Type,
  set,
  update,
  remove,
  add,
};
