import InputSelect from '@/components/molecules/CustomAutocomplete';
import { TEXT } from '@/utils/Text';
import IconButton from '@/components/atoms/IconButton';
import { postUnit } from '@/utils/fetch';
import * as ActionCreators from '@/redux/actions/actionCreator';
import AddCircle from '@material-ui/icons/AddCircle';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { selectUnits } from '@/redux/selectors/applicationSelector';
import { optionByIdAndName, unitableRowDataToOption } from '@/utils/toOption';
import { useDispatch, useSelector } from 'react-redux';

function UnitEditComponent({
  onRowDataChange,
  rowData,
  error,
}) {
  const dispatch = useDispatch();

  const units = useSelector(selectUnits);
  const [loading, setLoading] = useState(false);
  const [unitOptions, setUnitOptions] = useState(units.map(optionByIdAndName));

  const selectedOption = useMemo(
    () => unitableRowDataToOption(rowData),
    [rowData],
  );

  useEffect(() => (
    setUnitOptions(units.map(optionByIdAndName))
  ), [units]);

  const handleSelect = useCallback((option) => {
    if (option) {
      onRowDataChange({
        ...rowData,
        unitName: option ? option.label : null,
        unitId: option ? option.value : null,
      });
    }
  }, [rowData, onRowDataChange]);

  const handleInputChange = useCallback((value) => {
    const existingUnit = units.find((unit) => value.toUpperCase() === unit.name.toUpperCase());
    onRowDataChange({
      ...rowData,
      unitName: value,
      unitId: existingUnit ? existingUnit.id : null,
    });
  }, [units, rowData, onRowDataChange]);

  const handleAddClick = useCallback(() => {
    setLoading(true);
    postUnit({ name: rowData.unitName })
      .then((unit) => {
        onRowDataChange({
          ...rowData,
          unitName: unit.name,
          unitId: unit.id,
        });
        dispatch(ActionCreators.saveUnit(unit));
      })
      .finally(() => setLoading(false));
  }, [dispatch, onRowDataChange, rowData]);

  return (
    <div className="flex">
      <InputSelect
        width={70}
        error={error}
        id="unitId"
        label={TEXT.COLUMN.HEADER.UNIT}
        defaultOptions={unitOptions}
        value={selectedOption}
        onChange={handleSelect}
        onInputChange={handleInputChange}
      />
      <div className="flex-bottom mr-10">
        <IconButton
          onClick={handleAddClick}
          disabled={loading || !rowData || !rowData.unitName || !!selectedOption}
          title={TEXT.BUTTON.ADD}
        >
          <AddCircle color="primary" />
        </IconButton>
      </div>
    </div>
  );
}

export default React.memo(UnitEditComponent);
