import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@/components/atoms/Typography';

interface Props {
  cards: any,
}

const UserGrid: FC<Props> = ({
  cards,
}) => (
  cards.length
    ? cards.map((
      card,
      index,
    ) => (
      <Grid item xs={4} key={index}>
        {card}
      </Grid>
    )) : (
      <Grid item xs={12}>
        <Paper>
          <Box p={6}>
            <Typography align="center">
              Нет записей
            </Typography>
          </Box>
        </Paper>
      </Grid>
    )
);

export default UserGrid;
