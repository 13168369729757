import { makeStyles } from '@material-ui/core';

export const useSlaChipStyles = makeStyles(() => ({
  formed: {
    background: '#e3e3e3',
  },
  completed: {
    background: '#135a02',
    color: 'white',
  },
  root: {
    margin: 2,
  },
}));
