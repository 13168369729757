import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteSupplierById,
  getSupplierInfos,
  postSupplier,
  putSupplier,
  putToggleRequestConfirmationByReceiverId,
} from '@/utils/fetch';
import ApplicationContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import ID from '@/utils/id';
import { isCustomTableLoading, selectSupplierCustomTableColumns } from '@/redux/selectors/customTableSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  SupplierInfoActions,
} from '@/redux/actions/supplierInfoActions';
import { SupplierInfo } from '@/interfaces/SupplierInfo';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import useAddressColumn from '@/hook/columns/useAddressColumn';
import SupplierRowContextMenu from '@/components/organisms/SupplierRowContextMenu';
import { Box } from '@material-ui/core';
import useOrganizationColumn from '@/hook/columns/useOrganizationColumn';

function SupplierTable() {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rows, setRows] = useState([]);
  const [contextMenuRowData, setContextMenuRowData] = useState(null);
  const [rowAnchor, setRowAnchor] = useState(null);

  const suppliers = useSelector(selectSupplierInfos);
  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectSupplierCustomTableColumns);

  const organizationColumn = useOrganizationColumn(errors.organization);
  const addressColumn = useAddressColumn(columns.address, errors.address);

  useEffect(() => {
    setLoading(true);
    getSupplierInfos()
      .then((suppliers: SupplierInfo[]) => {
        dispatch(SupplierInfoActions.set(suppliers));
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setRows(suppliers.map((supplier) => ({ ...supplier })));
  }, [suppliers]);

  const memoizedColumns = useMemo(() => (
    [
      organizationColumn,
      {
        width: 300,
        hidden: columns.phoneNumber ? columns.phoneNumber.hidden : false,
        title: TEXT.COLUMN.HEADER.PHONE_NUMBER,
        field: 'phoneNumber',
        editComponent: (props) => (
          <CustomInput
            error={errors.phoneNumber}
            maxLength={40}
            id="phoneNumber"
            label={TEXT.COLUMN.HEADER.PHONE_NUMBER}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
          />
        ),
      },
      {
        width: 300,
        hidden: columns.contactEmail ? columns.contactEmail.hidden : false,
        title: TEXT.CONTACT_EMAIL,
        field: 'contactEmail',
        editComponent: (props) => (
          <CustomInput
            error={errors.contactEmail}
            maxLength={40}
            id="contactEmail"
            label={TEXT.CONTACT_EMAIL}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
          />
        ),
      },
      addressColumn,
      {
        hidden: columns.description ? columns.description.hidden : false,
        title: TEXT.DESCRIPTION,
        field: 'description',
        editComponent: (props) => (
          <CustomInput
            error={errors.description}
            maxLength={127}
            id="description"
            label={TEXT.DESCRIPTION}
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value || ''}
          />
        ),
      },
    ]
  ), [organizationColumn, errors, columns, addressColumn]);

  const validateSupplier = useCallback(({ organization }) => {
    const errors = {};
    if (!organization) {
      errors.organization = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateSupplier(data)) {
      return reject(data);
    }
    setLoading(true);
    postSupplier(data)
      .then((response) => {
        dispatch(SupplierInfoActions.add(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [validateSupplier, dispatch, context, errors]);

  const onUpdate = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateSupplier(data)) {
      return reject(data);
    }
    setLoading(true);
    putSupplier(data)
      .then((response) => {
        dispatch(SupplierInfoActions.update(response));
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [validateSupplier, dispatch, context, errors]);

  const onDelete = useCallback((oldData) => new Promise(
    (resolve, reject) => {
      setLoading(true);

      const { id, originalUserId } = oldData;
      if (oldData.external) {
        putToggleRequestConfirmationByReceiverId(originalUserId, false)
          .then(() => {
            dispatch(SupplierInfoActions.remove(id));
            context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
            resolve();
          })
          .catch((response) => {
            reject();
            return Promise.reject(response);
          })
          .finally(() => setLoading(false));
      } else {
        deleteSupplierById(id)
          .then(({ id }) => {
            dispatch(SupplierInfoActions.remove(id));
            context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
            resolve();
          })
          .catch((response) => {
            reject();
            if (response.status === 424) {
              context({ message: response.message, variant: 'warning' });
            } else {
              return Promise.reject(response);
            }
          })
          .finally(() => setLoading(false));
      }
    },
  ), [dispatch, context]);

  const editable = useMemo(() => (
    {
      onRowAdd: onAdd,
      onRowUpdate: onUpdate,
      onRowDelete: onDelete,
    }
  ), [onAdd, onUpdate, onDelete]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const isLoading = loading || customTableLoading;

  const onRowContextMenu = useCallback((event, rowData) => {
    setRowAnchor(event.target);
    setContextMenuRowData(rowData);
  }, []);

  const handleRowContextMenuClose = useCallback(() => {
    setRowAnchor(null);
    setContextMenuRowData(null);
  }, []);

  return (
    <Box
      className="table-container"
    >
      <StyledTable
        onRowContextMenu={onRowContextMenu}
        tableKey={ID.SUPPLIER_TABLE}
        onRowEditing={onRowEditing}
        loading={isLoading}
        editable={editable}
        columns={memoizedColumns}
        data={rows}
      />
      {!!contextMenuRowData && (
      <SupplierRowContextMenu
        setLoading={setLoading}
        onClose={handleRowContextMenuClose}
        rowAnchor={rowAnchor}
        rowData={contextMenuRowData}
      />
      )}
    </Box>
  );
}

export default SupplierTable;
