import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paperImport: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  actionPanel: {
    margin: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
