import { compareByField } from '@/utils/comparator';
import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import PriceFormationTypeSelect from '@/components/molecules/PriceFormationTypeSelect';
import { Column } from 'material-table';
import priceFormationDefaultType from '@/service/builder/priceFormationDefaultType';
import SaleInfo from '@/interfaces/SaleInfo';
import { CustomColumn } from '@/interfaces/CustomColumn';
import { SaleState } from '@/enum/SaleState';

const usePriceFormationTypeColumn = (
  column?: CustomColumn,
  error?: boolean,
): Column<SaleInfo> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <PriceFormationTypeSelect
      required
      error={error}
      selectedValue={value || priceFormationDefaultType.id}
      onChange={onChange}
    />
  ), [error]);

  return useMemo(() => (
    {
      editable: (columnDef, rowData) => (
        !rowData || rowData.state !== SaleState.COMPLETED
      ),
      cellStyle: {
        width: 70,
      },
      initialEditValue: undefined,
      customSort: compareByField('priceFormationTypeName'),
      customFilterAndSearch: (value, item) => !!item.priceFormationTypeName
        && item.priceFormationTypeName.toUpperCase().includes(value.toUpperCase()),
      title: TEXT.PRICE_FORMATION_TYPE,
      field: 'priceFormationTypeId',
      render: (rowData) => rowData.priceFormationTypeName || priceFormationDefaultType.name,
      defaultSort: 'asc',
      hidden: !!column?.hidden && false,
      editComponent: handleEditComponent,
    }
  ), [column, handleEditComponent]);
};

export default usePriceFormationTypeColumn;
