import { Role } from '@/enum/Role';
import { jsonToQueryString } from '@/utils/jsonToQueryString';
import { ClientInfo } from '@/interfaces/ClientInfo';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { SupplierInfo, SupplierPostRequest } from '@/interfaces/SupplierInfo';
import { PaginatedResponse } from '@/interfaces/PaginatedResponse';
import { ProductInfoForOrder } from '@/interfaces/ProductInfoForOrder';
import SupplierOrderInfo from '@/interfaces/SupplierOrderInfo';
import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';
import SupplierUpdateRequest from '@/interfaces/requests/SupplierUpdateRequest';
import { getDateTimeNow } from '@/utils/Utils';
import { CompleteSaleResponse } from '@/interfaces/response/CompleteSaleResponse';
import { DeleteResponse } from '@/interfaces/DeleteResponse';
import { CreditInfo } from '@/interfaces/CreditInfo';
import ProductInfo, { ProductGroupUpdateRequest } from '@/interfaces/ProductInfo';
import { Store } from '@/interfaces/Store';
import { OrderApprovalRequest } from '@/interfaces/requests/OrderApprovalRequest';
import { SalePostRequest } from '@/interfaces/requests/SalePostRequest';
import SaleInfo from '@/interfaces/SaleInfo';
import { SaleCopyRequest } from '@/interfaces/requests/SaleCopyRequest';
import { RestitutionPostRequest } from '@/interfaces/requests/RestitutionPostRequest';
import { RestitutionInfo } from '@/interfaces/RestitutionInfo';
import { SaleCompleteRequest } from '@/interfaces/requests/SaleCompleteRequest';
import { RestitutionCompleteResponse } from '@/interfaces/response/RestitutionCompleteResponse';
import { RestitutionUpdateResponse } from '@/interfaces/response/RestitutionUpdateResponse';
import { RestitutionItemUpdateRequest } from '@/interfaces/requests/RestitutionItemUpdateRequest';
import { RestitutionItemPostRequest } from '@/interfaces/requests/RestitutionItemPostRequest';
import { RestitutionItemInfo } from '@/interfaces/RestitutionItemInfo';
import { RestitutionUpdateRequest } from '@/interfaces/requests/RestitutionUpdateRequest';
import StoreItemInfo from '@/interfaces/StoreItemInfo';
import { ClientSaleDistributionResponse } from '@/interfaces/response/ClientSaleDistributionResponse';
import { ClientPatternInfo } from '@/interfaces/ClientPatternInfo';
import { SupplierProductsImportResponse } from '@/interfaces/response/SupplierProductsImportResponse';
import { SupplierOrderUpdateRequest } from '@/interfaces/requests/SupplierOrderUpdateRequest';
import { SaleUpdateRequest } from '@/interfaces/requests/SaleUpdateRequest';
import { Attribute, AttributePostRequest, AttributeUpdateRequest } from '@/interfaces/Attribute';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';
import { ProductExcelParseResult } from '@/interfaces/response/ProductExcelParseResult';
import ProductPostRequest from '@/interfaces/requests/ProductPostRequest';
import ProductUpdateRequest from '@/interfaces/requests/ProductUpdateRequest';
import PriceFormationType, {
  PriceFormationTypePostRequest,
  PriceFormationTypeUpdateRequest,
} from '@/interfaces/PriceFormationType';
import ProductImportRequest from '@/interfaces/requests/ProductImportRequest';
import ClientBalanceResponse from '@/interfaces/response/ClientBalanceResponse';
import CreditPostRequest from '@/interfaces/requests/CreditPostRequest';
import { ReservedSaleItemInfo } from '@/interfaces/SaleItemInfo';
import PreorderItemInfo, {
  PreorderBySaleIdInPostRequest,
  PreorderGroupByProductsPostRequest,
} from '@/interfaces/PreOrderItemInfo';
import Unit from '@/interfaces/Unit';
import { SupplierOrderItemInfo } from '@/interfaces/SupplierOrderItemInfo';
import Vendor, { VendorBase } from '@/interfaces/Vendor';
import { SaleState } from '@/enum/SaleState';
import { Moment } from 'moment';
import { dateTimeFormat } from '@/app/applicationSettings';
import HttpClient from './HttpClient';

// ---------------------------------------------------------- //
// ------------------------- COMMON ------------------------- //
// ---------------------------------------------------------- //

const defaultOptions = (method, body?) => ({
  method,
  body: body ? JSON.stringify(body) : null,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const processEmptyResponse = (response) => {
  if (response.ok) {
    return response;
  }
  return response.text().then((text) => {
    try {
      return Promise.reject(JSON.parse(text));
    } catch (err) {
      return Promise.reject({ message: text, status: response.status });
    }
  });
};

export const processJsonResponse = (response) => {
  if (response.ok) {
    return response.json();
  }
  return response.text().then((text) => {
    try {
      return Promise.reject(JSON.parse(text));
    } catch (err) {
      return Promise.reject({ message: text, status: response.status });
    }
  });
};

export const postFormData = (url, data) => {
  const formData = new FormData();

  for (const name in data) {
    formData.append(name, data[name]);
  }

  return fetch(url, {
    method: 'POST',
    body: formData,
  }).then(processJsonResponse);
};

export const postFile = (url, file) => postFormData(url, { file });

export const post = (url, body?) => fetch(url, defaultOptions('POST', body))
  .then(processJsonResponse);

export const postNoResponse = (url, body) => fetch(url, defaultOptions('POST', body))
  .then(processEmptyResponse);

export const put = (url, body?) => fetch(url, defaultOptions('PUT', body))
  .then(processJsonResponse);

export const putNoResponse = (url, body?) => fetch(url, defaultOptions('PUT', body))
  .then(processEmptyResponse);

export const get = (url) => fetch(url, defaultOptions('GET'))
  .then(processJsonResponse);

export const deleteById = (url) => fetch(url, defaultOptions('DELETE'))
  .then(processJsonResponse);

export const buildPageUrl = (
  url,
  page = 0,
  limit = 10,
  sort = 'name',
  order = 'ASC',
) => `${url}?page=${page}&limit=${limit}&sort=${sort}&order=${order}`;

export const buildPageAndNameUrl = (
  url,
  page = 0,
  name,
  limit = 10,
  sort = 'name',
  order = 'ASC',
) => `${url}?page=${page}&limit=${limit}&sort=${sort}&order=${order}&name=${name}`;

// ---------------------------------------------------------- //
// ------------------------- API ------------------------- //
// ---------------------------------------------------------- //

export const registration = (body) => post(HttpClient.URL.REGISTER, body);

export const recoverPassword = (email) => post(`${HttpClient.URL.REGISTER}/${email}`);

export const putNewPassword = (body) => put(HttpClient.URL.REGISTER, body);

export const login = (username, password) => fetch(
  HttpClient.URL.LOGIN,
  {
    method: 'POST',
    body: jsonToQueryString({ username, password }),
    headers: {
      Accept: 'application/json;charset=UTF-8',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
).then(processEmptyResponse);

export const logout = () => fetch(HttpClient.URL.LOGOUT, defaultOptions('POST'))
  .then(processEmptyResponse);

export const getUser = () => get(HttpClient.URL.USER);

export const putUser = (body) => putNoResponse(HttpClient.URL.USER, body);

export const postSupplier = (
  supplier: SupplierPostRequest,
): Promise<SupplierInfo> => post(HttpClient.URL.SUPPLIER, supplier);

export const putSupplier = (supplier: SupplierUpdateRequest) => put(
  HttpClient.URL.SUPPLIER, supplier,
);

export const deleteSupplierById = (id) => deleteById(`${HttpClient.URL.SUPPLIER}/${id}`);

export const postProduct = (
  product: ProductPostRequest,
): Promise<ProductInfo> => post(HttpClient.URL.PRODUCT, product);

export const putProduct = (
  product: ProductUpdateRequest,
): Promise<ProductInfo> => put(HttpClient.URL.PRODUCT, product);

export const putProductGroup = (
  request: ProductGroupUpdateRequest,
): Promise<ProductInfo[]> => put(
  HttpClient.URL.PRODUCT_GROUP,
  request,
);

export const deleteProductById = (id) => deleteById(`${HttpClient.URL.PRODUCT}?id=${id}`);

export const getSupplierOrderInfos = (
  page: number = 0,
  limit: number = 1000,
  sortBy: string = 'forming_date',
  order: string = 'ASC',
): Promise<PaginatedResponse<SupplierOrderInfo>> => get(buildPageUrl(
  `${HttpClient.URL.SUPPLIER_ORDER}/info`, page, limit, sortBy, order,
));

export const postSupplierOrder = (supplierOrder) => post(
  HttpClient.URL.SUPPLIER_ORDER, supplierOrder,
);

export const postPreordersByProductIds = (
  body: PreorderGroupByProductsPostRequest,
): Promise<PreorderItemInfo[]> => post(
  `${HttpClient.URL.PREORDER_ITEM}/product`, body,
);

export const postPreordersBySaleIdIn = (
  body: PreorderBySaleIdInPostRequest,
): Promise<PreorderItemInfo[]> => post(
  `${HttpClient.URL.PREORDER_ITEM}/sale`, body,
);

export const postPreordersBySaleItemIdIn = (
  body: PreorderBySaleIdInPostRequest,
): Promise<PreorderItemInfo[]> => post(
  `${HttpClient.URL.PREORDER_ITEM}/sale-item`, body,
);

export const putSupplierOrder = (
  supplierOrderUpdateRequest: SupplierOrderUpdateRequest,
) => put(HttpClient.URL.SUPPLIER_ORDER, supplierOrderUpdateRequest);

export const putSupplierOrderReceive = (receiveOrderToStoreRequest) => put(
  HttpClient.URL.SUPPLIER_ORDER_RECEIVE, receiveOrderToStoreRequest,
);

export const deleteSupplierOrderById = (id) => deleteById(
  `${HttpClient.URL.SUPPLIER_ORDER}?id=${id}`,
);

export const getSupplierOrderItems = (
  supplierOrderId,
): Promise<SupplierOrderItemInfo[]> => get(
  `${HttpClient.URL.SUPPLIER_ORDER_ITEM}?supplierOrderId=${supplierOrderId}`,
);

export const postSupplierOrderItem = (body) => post(HttpClient.URL.SUPPLIER_ORDER_ITEM, body);

export const putSupplierOrderItem = (body) => put(HttpClient.URL.SUPPLIER_ORDER_ITEM, body);

export const deleteSupplierOrderItem = (supplierOrderId, supplierOrderItemId) => deleteById(
  `${HttpClient.URL.SUPPLIER_ORDER_ITEM}?supplierOrderId=${supplierOrderId}&id=${supplierOrderItemId}`,
);

export const getAllStoreItemInfosByStoreId = (
  storeId,
  userId,
): Promise<StoreItemInfo[]> => (
  get(`${HttpClient.URL.STORE_ITEM_INFO}/all/${storeId}?userId=${userId}`)
);

export const getAllPreorderItemInfos = (
  userId,
): Promise<PreorderItemInfo[]> => (
  get(`${HttpClient.URL.PREORDER_ITEM}/user/${userId}`)
);

export const getStoreItemInfosByStoreId = (
  priceFormationTypeId: string | undefined = '',
  storeId: string,
  userId: string,
  name: string = '',
  page: number = 0,
  limit: number = 50,
  sortBy: string = 'id',
  order: string = 'ASC',
): Promise<PaginatedResponse<StoreItemInfo>> => (
  get(`${buildPageAndNameUrl(
    `${HttpClient.URL.STORE_ITEM_INFO}/${storeId}`,
    page,
    encodeURIComponent(name),
    limit,
    sortBy,
    order,
  )}&userId=${userId}&priceFormationTypeId=${priceFormationTypeId}`)
);

export const deleteStoreItem = (id, storeId): Promise<DeleteResponse> => deleteById(
  `${HttpClient.URL.STORE_ITEM_INFO}/${storeId}/${id}`,
);

export const getFormedSaleItemsByStoreItemId = (
  id: string,
  userId: string,
): Promise<ReservedSaleItemInfo[]> => (
  get(`${HttpClient.URL.SALE_ITEM_INFO}/reserved/user/${userId}/store-item/${id}`)
);

export const getFormedSaleItemsByProductId = (
  id: string,
  userId: string,
): Promise<ReservedSaleItemInfo[]> => (
  get(`${HttpClient.URL.SALE_ITEM_INFO}/reserved/user/${userId}/product/${id}`)
);

export const putPatternForSale = (saleId, patternId, storeId) => put(
  `${HttpClient.URL.SALE_ITEM_INFO_PATTERN}?saleId=${saleId}&patternId=${patternId}&storeId=${storeId}`,
);

export const getSaleItemInfosSaleId = (saleId, userId) => fetch(
  `${HttpClient.URL.SALE_ITEM_INFO}?saleId=${saleId}&userId=${userId}`,
)
  .then(processJsonResponse);

export const getSaleInfosByUserId = (
  userId: string,
  startDate: Moment,
  endDate: Moment,
  saleStates?: SaleState[],
  attributes?: Attribute[],
  clients?: ClientInfo[],
) => {
  let params = `userId=${userId}&startDate=${startDate.format(dateTimeFormat)}&endDate=${endDate.format(dateTimeFormat)}`;
  if (saleStates && saleStates.length) {
    params += `&saleStates=${saleStates.join(',')}`;
  }
  if (attributes && attributes.length) {
    params += `&attributeIds=${attributes.map((attribute) => attribute.id).join(',')}`;
  }
  if (clients && clients.length) {
    params += `&clientIds=${clients.map((client) => client.id).join(',')}`;
  }
  return get(`${HttpClient.URL.SALE_INFO}?${params}`);
};

export const deleteSaleItemById = (saleId, saleItemId) => deleteById(
  `${HttpClient.URL.SALE_ITEM_INFO}?saleId=${saleId}&saleItemId=${saleItemId}`,
);

export const postSaleItem = (body) => post(HttpClient.URL.SALE_ITEM_INFO, body);

export const putSaleItem = (body) => put(HttpClient.URL.SALE_ITEM_INFO, body);

export const getClientById = (id) => get(`${HttpClient.URL.CLIENT}/${id}`);

export const getAllClientInfos = (name = '') => get(`${HttpClient.URL.CLIENT}/info/all?name=${encodeURIComponent(name)}`);

export const getClientInfoById = (clientId): Promise<ClientInfo> => get(`${HttpClient.URL.CLIENT}/info?clientId=${clientId}`);

export const postClient = (client) => post(HttpClient.URL.CLIENT, client);

export const putClient = (client) => put(HttpClient.URL.CLIENT, client);

export const deleteClientById = (id) => deleteById(`${HttpClient.URL.CLIENT}?id=${id}`);

export const getClientsPage = (page, name = '', limit, sortBy, order) => get(
  buildPageAndNameUrl(HttpClient.URL.CLIENT_PAGE, page, encodeURIComponent(name), limit, sortBy, order),
);

export const postSale = (
  saleFormRequest: SalePostRequest,
): Promise<SaleInfo> => post(HttpClient.URL.SALE, saleFormRequest);

export const copySale = (
  request: SaleCopyRequest,
): Promise<SaleInfo> => post(`${HttpClient.URL.SALE}/copy`, request);

export const copySupplierOrder = (supplierOrderId) => post(`${HttpClient.URL.SUPPLIER_ORDER}/copy`,
  { supplierOrderId, formingDate: getDateTimeNow() });

export const putSale = (
  saleUpdateRequest: SaleUpdateRequest,
) => put(HttpClient.URL.SALE, saleUpdateRequest);

export const deleteSaleById = (id) => deleteById(`${HttpClient.URL.SALE}?id=${id}`);

export const putSaleComplete = (
  saleCompleteRequest,
): Promise<CompleteSaleResponse> => put(HttpClient.URL.SALE_COMPLETE, saleCompleteRequest);

export const getClientPatternInfos = (): Promise<ClientPatternInfo[]> => get(
  HttpClient.URL.CLIENT_PATTERN_INFO,
);

export const getClientPatternInfosByClientId = (clientId, external) => get(
  `${HttpClient.URL.CLIENT_PATTERN_INFO}/${clientId}?external=${external}`,
);

export const getClientPatternItems = (patternId) => get(
  `${HttpClient.URL.CLIENT_PATTERN_ITEM}?patternId=${patternId}`,
);

export const postClientPattern = (body) => post(HttpClient.URL.CLIENT_PATTERN, body);

export const putClientPattern = (body) => put(HttpClient.URL.CLIENT_PATTERN, body);

export const deleteClientPatternById = (patternId) => deleteById(
  `${HttpClient.URL.CLIENT_PATTERN}?patternId=${patternId}`,
);

export const postClientPatternItem = (data) => post(HttpClient.URL.CLIENT_PATTERN_ITEM, data);

export const putClientPatternItem = (data) => put(HttpClient.URL.CLIENT_PATTERN_ITEM, data);

export const deleteClientPatternItem = (patternId, id) => deleteById(
  `${HttpClient.URL.CLIENT_PATTERN_ITEM}?patternId=${patternId}&id=${id}`,
);

export const postDocument = (body) => post(HttpClient.URL.DOCUMENT, body);

export const postDocumentByEmail = (id, saleId, email, date) => postNoResponse(
  HttpClient.URL.SEND_CUSTOM_DOCUMENT,
  {
    id,
    saleId,
    email,
    date,
  },
);

export const putDocument = (body) => put(HttpClient.URL.DOCUMENT, body);

export const deleteDocumentById = (id) => deleteById(`${HttpClient.URL.DOCUMENT}?id=${id}`);

export const getDocuments = (saleId: string) => get(`${HttpClient.URL.DOCUMENT}?saleId=${saleId}`);

export const getCustomDocuments = () => get(HttpClient.URL.CUSTOM_DOCUMENT);

export const getDocumentSettings = () => get(HttpClient.URL.DOCUMENT_SETTINGS);

export const postUnit = (
  unit,
): Promise<Unit> => post(HttpClient.URL.UNIT, unit);

export const postExcelFileWithProducts = (
  file,
): Promise<ProductExcelParseResult> => (
  postFile(HttpClient.URL.EXCEL_PRODUCT, file)
);

export const postProductsParsedFromExcel = (
  body: ProductImportRequest,
): Promise<ProductInfo[]> => post(
  HttpClient.URL.PRODUCT_EXCEL,
  body,
);

export const putCustomTable = (customTable) => put(HttpClient.URL.CUSTOM_TABLE, customTable);

export const toggleRole = (
  role: Role,
  enabled: boolean,
): Promise<void> => putNoResponse(
  `${HttpClient.URL.ROLE_TOGGLE}?roleName=${role}&enabled=${enabled}`,
);

export const getSupplierPartnerRequests = (
  organizationName: string,
): Promise<Array<UserPartnerRequestInfo>> => get(
  `${HttpClient.URL.REQUEST_SUPPLIER}?organization=${encodeURIComponent(organizationName)}`,
);

export const postRequestToSupplier = (
  supplierId,
  requestDate,
) => post(HttpClient.URL.REQUEST_SUPPLIER, { requestDate, supplierId });

export const getClientInfoRequests = (
  organizationName: string,
) => get(`${HttpClient.URL.REQUEST_CLIENT}?organization=${encodeURIComponent(organizationName)}`);

export const putToggleRequestConfirmationBySenderId = (
  senderId: string,
  confirmed: boolean,
) => put(`${HttpClient.URL.REQUEST_CLIENT}?senderId=${senderId}&confirmed=${confirmed}`);

export const putToggleRequestConfirmationByReceiverId = (
  receiverId: string,
  confirmed: boolean,
) => put(`${HttpClient.URL.REQUEST_SUPPLIER}?receiverId=${receiverId}&confirmed=${confirmed}`);

export const getSupplierInfos = (): Promise<Array<SupplierInfo>> => get(`${HttpClient.URL.SUPPLIER}/info`);

export const getAllProductInfos = (
  priceFormationTypeId: string | undefined = '',
): Promise<ProductInfo[]> => get(
  `${HttpClient.URL.PRODUCT}/info/all?priceFormationTypeId=${priceFormationTypeId}`,
);

export const getProductInfos = (
  priceFormationTypeId: string | undefined = '',
  name: string = '',
  page: number = 0,
  limit: number = 50,
  sortBy: string = 'name',
  order: string = 'ASC',
): Promise<PaginatedResponse<ProductInfo>> => get(
  `${buildPageAndNameUrl(
    `${HttpClient.URL.PRODUCT}/info`,
    page,
    encodeURIComponent(name),
    limit,
    sortBy,
    order,
  )}&priceFormationTypeId=${priceFormationTypeId}`,
);

export const getProductById = (
  id: string,
  priceFormationTypeId: string | undefined = '',
): Promise<ProductInfo> => get(
  `${HttpClient.URL.PRODUCT}/info/${id}?priceFormationTypeId=${priceFormationTypeId}`,
);

export const getProductInfoForOrderBySupplierId = (
  supplierId: string,
  name: string = '',
  page: number = 0,
  limit: number = 500,
  sortBy: string = 'name',
  order: string = 'ASC',
): Promise<PaginatedResponse<ProductInfoForOrder>> => get(
  `${buildPageAndNameUrl(
    `${HttpClient.URL.PRODUCT}/info/order`,
    page,
    encodeURIComponent(name),
    limit,
    sortBy,
    order,
  )}&supplierId=${supplierId}`,
);

export const putPendingOrderToSupplier = (
  supplierOrderId: string,
): Promise<SupplierOrderInfo> => put(
  `${HttpClient.URL.SUPPLIER_ORDER}/pending`,
  { supplierOrderId, pendingDate: getDateTimeNow() },
);

export const putCancelSendSupplierOrder = (
  supplierOrderId: string,
): Promise<SupplierOrderInfo> => put(`${HttpClient.URL.SUPPLIER_ORDER}/cancel/${supplierOrderId}`);

export const putDeclineSendSupplierOrder = (
  supplierOrderId: string,
): Promise<IncomingSupplierOrderInfo> => put(`${HttpClient.URL.SUPPLIER_ORDER}/decline/${supplierOrderId}`);

export const putApproveSupplierOrder = (
  request: OrderApprovalRequest,
): Promise<IncomingSupplierOrderInfo> => put(`${HttpClient.URL.SUPPLIER_ORDER}/approve`, request);

export const putSendSaleToClient = (
  body,
): Promise<CompleteSaleResponse> => put(`${HttpClient.URL.SALE}/send`, body);

export const getIncomingSupplierOrderInfos = (
  page: number = 0,
  limit: number = 1000,
  sortBy: string = 'pending_date',
  order: string = 'ASC',
): Promise<PaginatedResponse<IncomingSupplierOrderInfo>> => get(`${HttpClient.URL.SUPPLIER_ORDER}/info/incoming?page=${page}&limit=${limit}&sort=${sortBy}&order=${order}`);

export const postCredit = (
  body: CreditPostRequest,
): Promise<CreditInfo> => post(`${HttpClient.SECURE}/credit`, body);

export const putCredit = (
  body,
): Promise<CreditInfo> => put(`${HttpClient.SECURE}/credit`, body);

export const deleteCredit = (
  id,
): Promise<DeleteResponse> => deleteById(`${HttpClient.SECURE}/credit/${id}`);

export const getCreditInfos = (
  page: number = 0,
  limit: number = 1000,
  sortBy: string = 'receiving_time',
  order: string = 'ASC',
): Promise<PaginatedResponse<CreditInfo>> => get(buildPageUrl(`${HttpClient.SECURE}/credit/page`, page, limit, sortBy, order));

export const putStore = (body): Promise<Store> => put(`${HttpClient.SECURE}/store`, body);

export const putReconciliationAct = (
  startDate,
  endDate,
  clientId,
) => put(`${HttpClient.SECURE}/act`, { startDate, endDate, clientId });

export const getReconciliationActByClientId = (
  clientId,
): Promise<ClientBalanceResponse> => (
  get(`${HttpClient.SECURE}/act/client/${clientId}`)
);

export const postSaleRestitution = (
  request: RestitutionPostRequest,
): Promise<RestitutionInfo> => post(HttpClient.URL.RESTITUTION, request);

export const getSaleRestitutions = (
  userId?: string,
): Promise<RestitutionInfo[]> => {
  const url = userId
    ? `${HttpClient.URL.RESTITUTION}?userId=${userId}`
    : HttpClient.URL.RESTITUTION;
  return get(url);
};

export const deleteSaleRestitution = (id): Promise<DeleteResponse> => deleteById(`${HttpClient.URL.RESTITUTION}?id=${id}`);

export const putRestitution = (
  request: RestitutionUpdateRequest,
): Promise<RestitutionInfo> => put(HttpClient.URL.RESTITUTION, request);

export const putRestitutionComplete = (
  body: SaleCompleteRequest,
): Promise<RestitutionCompleteResponse> => put(`${HttpClient.URL.RESTITUTION}/complete`, body);

export const createRestitutionItem = (
  request: RestitutionItemPostRequest,
): Promise<RestitutionUpdateResponse> => post(`${HttpClient.URL.RESTITUTION}/item`, request);

export const updateRestitutionItem = (
  request: RestitutionItemUpdateRequest,
): Promise<RestitutionUpdateResponse> => put(`${HttpClient.URL.RESTITUTION}/item`, request);

export const deleteRestitutionItem = (
  restitutionId,
  restitutionItemId,
): Promise<RestitutionUpdateResponse> => deleteById(`${HttpClient.URL.RESTITUTION}/item?restitutionId=${restitutionId}&restitutionItemId=${restitutionItemId}`);

export const getRestitutionItemInfos = (
  restitutionId: string,
  userId: string,
): Promise<RestitutionItemInfo[]> => get(`${HttpClient.URL.RESTITUTION}/item?restitutionId=${restitutionId}&userId=${userId}`);

export const putClientSaleDistribution = (
  startDate,
  endDate,
  userId,
): Promise<ClientSaleDistributionResponse> => put(`${HttpClient.URL.SALE_DISTRIBUTION}/client`, { startDate, endDate, userId });

export const putSyncSupplierProducts = (
  supplierId,
): Promise<SupplierProductsImportResponse> => put(`${HttpClient.URL.PRODUCT}/sync/supplier/${supplierId}`);

export const putSyncSupplierProductsByOrderId = (
  supplierId,
  supplierOrderId,
): Promise<SupplierProductsImportResponse> => put(
  `${HttpClient.URL.PRODUCT}/sync/supplier/${supplierId}/supplierOrder/${supplierOrderId}`,
);

export const getAttributes = (): Promise<Attribute[]> => get(HttpClient.URL.ATTRIBUTE);

export const postAttribute = (
  attributePostRequest: AttributePostRequest,
): Promise<Attribute> => post(HttpClient.URL.ATTRIBUTE, attributePostRequest);

export const putAttribute = (
  request: AttributeUpdateRequest,
): Promise<Attribute> => put(HttpClient.URL.ATTRIBUTE, request);

export const deleteAttribute = (id): Promise<DeleteResponse> => deleteById(`${HttpClient.URL.ATTRIBUTE}/${id}`);
export const getCompanies = (): Promise<CompanyInfo[]> => get(HttpClient.URL.COMPANY);

export const getCompanyById = (id): Promise<CompanyInfo> => get(`${HttpClient.URL.COMPANY}/${id}`);

export const postCompany = (body): Promise<CompanyInfo> => post(HttpClient.URL.COMPANY, body);

export const putCompany = (body): Promise<CompanyInfo> => put(HttpClient.URL.COMPANY, body);

export const deleteCompanyById = (id): Promise<DeleteResponse> => deleteById(`${HttpClient.URL.COMPANY}/${id}`);

export const createCompanyUser = (companyId): Promise<CompanyInfo> => post(`${HttpClient.URL.COMPANY_USER}?companyId=${companyId}`);

export const getCompanyUsers = (companyId): Promise<CompanyUserInfo[]> => get(`${HttpClient.URL.COMPANY_USER}/${companyId}`);

export const getCompanyUserById = (userId): Promise<CompanyUserInfo> => get(`${HttpClient.URL.COMPANY_USER}?userId=${userId}`);

export const toggleCompanyUser = ({
  userId,
  companyId,
  state,
}): Promise<CompanyUserInfo> => put(`${HttpClient.URL.COMPANY_USER}?userId=${userId}&companyId=${companyId}&state=${state}`);

export const deleteCompanyUser = ({ userId, companyId }): Promise<DeleteResponse> => deleteById(`${HttpClient.URL.COMPANY_USER}?userId=${userId}&companyId=${companyId}`);

export const getPriceFormationTypes = (
  userId: string,
): Promise<PriceFormationType[]> => (
  get(`${HttpClient.URL.PRICE_FORMATION_TYPE}/user/${userId}`)
);

export const postPriceFormationType = (
  body: PriceFormationTypePostRequest,
): Promise<PriceFormationType> => (
  post(HttpClient.URL.PRICE_FORMATION_TYPE, body)
);

export const putPriceFormationType = (
  body: PriceFormationTypeUpdateRequest,
): Promise<PriceFormationType> => (
  put(HttpClient.URL.PRICE_FORMATION_TYPE, body)
);

export const deletePriceFormationType = (
  id: string,
): Promise<DeleteResponse> => (
  deleteById(`${HttpClient.URL.PRICE_FORMATION_TYPE}/${id}`)
);

export const getAllVendors = (
  filter: string = '',
): Promise<Vendor[]> => (
  get(`${HttpClient.URL.VENDOR}?filter=${encodeURIComponent(filter)}`)
);

export const postVendor = (
  body: VendorBase,
): Promise<Vendor> => (
  post(HttpClient.URL.VENDOR, body)
);

export const putVendor = (
  body: Vendor,
): Promise<Vendor> => (
  put(HttpClient.URL.VENDOR, body)
);

export const deleteVendor = (
  id: string,
): Promise<DeleteResponse> => (
  deleteById(`${HttpClient.URL.VENDOR}/${id}`)
);
