import React, { useCallback, useEffect, useState } from 'react';
import { getClientInfoRequests } from '@/utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import UserSearchView from '@/components/templates/UserSearchView/UserSearchView';
import { getClientRequestInfos } from '@/redux/selectors/clientRequestInfoSelector';
import { createSetClientRequestInfosAction } from '@/redux/actions/clientRequestInfoActions';
import ClientCard from '@/components/molecules/ClientCard/ClientCard';

function ClientRequestsSubTab() {
  const dispatch = useDispatch();
  const clientRequestInfos = useSelector<Array<UserPartnerRequestInfo>>(getClientRequestInfos);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    getClientInfoRequests(searchValue)
      .then((clientInfoRequests: Array<UserPartnerRequestInfo>) => {
        if (!isMounted) return;
        dispatch(createSetClientRequestInfosAction(clientInfoRequests));
      })
      .finally(() => {
        if (!isMounted) return;
        setLoading(false);
      });
    return () => {
      isMounted = false;
    };
  }, [searchValue, dispatch]);

  const handleCancel = useCallback(() => setSearchValue(''), []);

  return (
    <UserSearchView
      onCancel={handleCancel}
      loading={loading}
      cards={
				clientRequestInfos.map((clientRequestInfo: UserPartnerRequestInfo) => (
  <ClientCard
    key={clientRequestInfo.id}
    clientRequestInfo={clientRequestInfo}
  />
				))
			}
      handleSearchChange={setSearchValue}
    />
  );
}

export default ClientRequestsSubTab;
