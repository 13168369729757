import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import { putNewPassword } from '@/utils/fetch';
import Button from '@/components/atoms/Button';
import Path from '@/enum/Path';
import { clearQueryParams } from '@/utils/Utils';
import { useParamQuery } from '@/hook/useQuery';
import { useHistory } from 'react-router';
import PasswordInput from '@/components/atoms/PasswordInput/PasswordInput';
import NewPasswordRequest from '@/app/request/NewPasswordRequest';

function NewPasswordForm() {
  const params = useParamQuery();
  const history = useHistory();

  const [token] = useState(params.token);
  const [email] = useState(params.email);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearQueryParams();
  }, []);

  const onSave = useCallback(() => {
    setLoading(true);
    putNewPassword(new NewPasswordRequest(email, password, token))
      .then(() => {
        history.push(Path.LOGIN);
      })
      .catch((response) => {
        setErrorMessage(response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email, password, token]);

  const isInvalidPass = useMemo(() => (
    !password || password !== confirmPassword || password.length < 6
  ), [password, confirmPassword]);

  const onPasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const onConfirmPasswordChange = useCallback((event) => {
    setConfirmPassword(event.target.value);
  }, []);

  const onNewPasswordComplete = useCallback(() => {
    history.push(Path.LOGIN);
  }, [history]);

  return (
    <div className="login-form">
      <div className="login-page-top-container">
        <PasswordInput
          label={TEXT.LOGIN_FORM.LABEL.NEW_PASSWORD}
          error={isInvalidPass}
          onChange={onPasswordChange}
          value={password}
        />
        <PasswordInput
          label={TEXT.LOGIN_FORM.LABEL.CONFIRM_PASSWORD}
          error={isInvalidPass}
          maxLength={40}
          onChange={onConfirmPasswordChange}
          name="confirm-password"
          value={confirmPassword}
        />
      </div>
      {errorMessage
        ? <span className="invalid-input-message">{errorMessage}</span>
        : <span className="thank-you-text">{TEXT.NEW_PASSWORD_MESSAGE}</span>}
      <div className="login-page-btn-bar">
        <Button
          className="login-page-btn"
          disabled={isInvalidPass || loading}
          onClick={onSave}
          text={TEXT.BUTTON.SAVE}
        />
        <Button
          className="login-page-btn"
          onClick={onNewPasswordComplete}
          text={TEXT.LOGIN_FORM.BUTTON.BACK}
        />
      </div>
    </div>
  );
}

export default NewPasswordForm;
