import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';

export enum ClientRequestInfoActionType {
  SET = 'SET_CLIENT_REQUESTS',
  UPDATE_ONE = 'UPDATE_ONE_CLIENT_REQUEST'
}

export function createUpdateClientRequestInfo(
  clientRequestInfo: UserPartnerRequestInfo,
) {
  return {
    type: ClientRequestInfoActionType.UPDATE_ONE,
    payload: clientRequestInfo,
  };
}

export function createSetClientRequestInfosAction(
  clientRequestInfos: Array<UserPartnerRequestInfo>,
) {
  return {
    type: ClientRequestInfoActionType.SET,
    payload: clientRequestInfos,
  };
}
