import CustomizedMenu from '@/components/atoms/CustomizedMenu/CustomizedMenu';
import React, { useCallback, useContext, useState } from 'react';
import MenuItem from '@/components/atoms/MenuItem/MenuItem';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';
import { TEXT } from '@/utils/Text';
import { FileCopy, RestorePage } from '@material-ui/icons';
import { connect } from 'react-redux';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { copySale, postSaleRestitution } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import moment from 'moment';
import { dateTimeFormat } from '@/app/applicationSettings';
import SaleInfo from '@/interfaces/SaleInfo';
import { SaleState } from '@/enum/SaleState';

function SaleRowContextMenu({
  rowAnchor,
  onClose,
  rowData,
  setLoading,
  dispatchSaveSaleInfo,
}) {
  const context = useContext(SnackbarContext);
  const [restitutionLoading, setRestitutionLoading] = useState(false);
  const {
    id,
    state,
  }: SaleInfo = rowData;

  const handleSaleCopyClick = useCallback(() => {
    onClose();
    setLoading(true);
    copySale({
      id,
      formTime: moment(Date.now()).format(dateTimeFormat),
    })
      .then((response) => {
        dispatchSaveSaleInfo(response);
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onClose, id, setLoading, context, dispatchSaveSaleInfo]);

  const handleRestitutionClick = useCallback(() => {
    setRestitutionLoading(true);
    postSaleRestitution({
      saleId: id,
      formTime: moment(Date.now()).format(dateTimeFormat),
    })
      .then((response) => {
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
      })
      .finally(() => {
        setRestitutionLoading(false);
        onClose();
      });
  }, [id, context, onClose]);

  return (
    <CustomizedMenu
      onClose={onClose}
      anchorEl={rowAnchor}
    >
      <MenuItem
        onClick={handleSaleCopyClick}
      >
        <ListItemIcon>
          <FileCopy fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary={TEXT.COPY} />
      </MenuItem>
      <MenuItem
        disabled={
					restitutionLoading
					|| state === SaleState.FORMED
				}
        onClick={handleRestitutionClick}
      >
        <ListItemIcon>
          <RestorePage fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary={TEXT.RESTITUTION} />
      </MenuItem>
    </CustomizedMenu>
  );
}

export default connect(null, (dispatch) => ({
  dispatchSaveSaleInfo: (sale) => dispatch(ActionCreators.saveSaleInfo(sale)),
}))(SaleRowContextMenu);
