import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    height: 'auto',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    width: '25px',
    height: '25px',
  },
  sendIcon: {
    margin: '10px',
    width: '25px',
    height: '25px',
  },
  wrapper: {
    width: 280,
  },
  listItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    marginRight: 60,
  },
}));
