import React, { useCallback, useMemo } from 'react';
import { TEXT } from '@/utils/Text';
import { SaleType } from '@/enum/SaleType';
import CountEditInput from '@/components/molecules/CountEditComponent';
import { CustomColumn } from '@/interfaces/CustomColumn';
import { Column } from 'material-table';

function useCountColumn<RowData extends object>(
  enableMaxCount = false,
  column?: CustomColumn,
  error = false,
  saleType?: SaleType,
): Column<RowData> {
  const handleEditComponent = useCallback(({ onRowDataChange, rowData, value }) => (
    <CountEditInput
      onRowDataChange={onRowDataChange}
      rowData={rowData}
      saleType={saleType}
      value={value}
      error={error}
      enableMaxCount={enableMaxCount}
    />
  ), [saleType, error, enableMaxCount]);

  return useMemo(() => ({
    hidden: !!column?.hidden,
    title: TEXT.COLUMN.HEADER.COUNT,
    field: 'count',
    render: (rowData) => `${rowData.count} ${rowData.unitName || ''}`,
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [handleEditComponent, column]);
}

export default useCountColumn;
