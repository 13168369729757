import * as TYPE from '@/constants/action';
import {
  CLIENT_PATTERNS,
  PRODUCTS,
  SALE_INFOS,
  STORES,
  SUPPLIER_ORDERS,
  UNITS,
  USER,
} from '@/constants/action';
import { compareByField } from '@/utils/comparator';
import UnitService from '@/model/unit/UnitService';

export function setStores(stores) {
  return {
    meta: STORES,
    type: TYPE.SET_RECORDS,
    payload: { items: stores },
  };
}

export function saveStore(store) {
  return {
    type: TYPE.SAVE_STORE,
    payload: store,
  };
}

export function setSaleInfos(saleInfos) {
  return {
    meta: SALE_INFOS,
    type: TYPE.SET_RECORDS,
    payload: { items: saleInfos },
  };
}

export function setSupplierOrders(supplierOrders) {
  return {
    meta: SUPPLIER_ORDERS,
    type: TYPE.SET_RECORDS,
    payload: { items: supplierOrders },
  };
}

export function saveRecord(record, entityName) {
  return {
    type: TYPE.SAVE_RECORD,
    payload: record,
    meta: entityName,
  };
}

export function deleteRecord(id, entityName) {
  return {
    type: TYPE.DELETE_RECORD,
    id,
    meta: entityName,
  };
}

export function deleteSupplierItemRecord(id, orderId) {
  return {
    type: TYPE.DELETE_SUPPLIER_ORDER_ITEM,
    payload: {
      id,
      orderId,
    },
  };
}

export function saveSupplierOrder(order) {
  return {
    meta: SUPPLIER_ORDERS,
    type: TYPE.SAVE_RECORD,
    payload: {
      item: order,
    },
  };
}

export function saveUser(user) {
  return {
    meta: USER,
    type: TYPE.USER_AUTHORIZED,
    payload: user,
  };
}

export function setAnonymousUser() {
  return {
    type: TYPE.USER_ANONYMOUS,
  };
}

export function saveSaleInfo(saleInfo) {
  return {
    meta: SALE_INFOS,
    type: TYPE.SAVE_RECORD,
    payload: {
      item: saleInfo,
    },
  };
}

export function setClientPatterns(clientPatterns) {
  return {
    meta: CLIENT_PATTERNS,
    type: TYPE.SET_RECORDS,
    payload: {
      items: clientPatterns,
    },
  };
}

export function saveClientPattern(clientPattern) {
  return {
    meta: CLIENT_PATTERNS,
    type: TYPE.SAVE_RECORD,
    payload: {
      item: clientPattern,
    },
  };
}

export function saveUnit(unit) {
  return {
    meta: UNITS,
    type: TYPE.SAVE_RECORD,
    payload: {
      item: unit,
      service: UnitService,
    },
  };
}

export function setUnits(units) {
  return {
    meta: UNITS,
    type: TYPE.SET_RECORDS,
    payload: {
      items: units,
      service: UnitService,
    },
  };
}

export function setCustomTables(customTables) {
  return {
    meta: UNITS,
    type: TYPE.SET_CUSTOM_TABLES,
    payload: {
      customTables,
    },
  };
}

export function columnVisibilityChange(tableId, columnField, hidden) {
  return {
    type: TYPE.COLUMN_VISIBILITY_CHANGE,
    payload: {
      tableId,
      columnField,
      hidden,
    },
  };
}

export function columnVisibilityChanging(loading) {
  return {
    type: TYPE.COLUMN_VISIBILITY_CHANGING,
    payload: {
      loading,
    },
  };
}
