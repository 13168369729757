import React, {
  FC, useCallback, useContext, useMemo, useState,
} from 'react';
import ReceiveOrderToStoreRequest from '@/model/order/item/request/ReceiveOrderToStoreRequest';
import { getDateTimeNow } from '@/utils/Utils';
import { putSupplierOrderReceive, putSyncSupplierProductsByOrderId } from '@/utils/fetch';
import * as ActionCreators from '@/redux/actions/actionCreator';
import { MESSAGE } from '@/utils/message';
import SupplierOrderInfo from '@/interfaces/SupplierOrderInfo';
import { TEXT } from '@/utils/Text';
import { SupplierOrderState } from '@/enum/SupplierOrderState';
import { Store } from '@material-ui/icons';
import Button from '@/components/atoms/Button';
import SnackbarContext from '@/app/snackbarContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentStore } from '@/redux/selectors/applicationSelector';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import format from 'string-format';
import AskToSyncProductsModal
  from '@/components/organisms/SupplierOrderItemsTable/AddOrderToStoreButton/AskToSyncProductsModal';
import { SupplierOrderItemInfo } from '@/interfaces/SupplierOrderItemInfo';
import ErrorType from '@/enum/ErrorType';

interface Props {
  selectedOrder: SupplierOrderInfo;
  orderItems: SupplierOrderItemInfo[];
}

const AddOrderToStoreButton: FC<Props> = ({
  selectedOrder,
  orderItems,
}) => {
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isOpened, setOpened] = useState(false);

  const currentStore = useSelector(selectCurrentStore);
  const storeEnabled = useSelector(isStoreEnabled);

  const hasAnyItems = useMemo(() => (
    !!orderItems.length && !!orderItems.find((item) => !!item.count)
  ), [orderItems]);

  const handleOrderReceived = useCallback(() => {
    setLoading(true);

    const request = new ReceiveOrderToStoreRequest(
      selectedOrder.supplierId,
      selectedOrder.id,
      currentStore!.id,
      getDateTimeNow(),
    );
    putSupplierOrderReceive(request)
      .then(({ order }) => {
        dispatch(ActionCreators.saveSupplierOrder(order));
        context({
          message: format(MESSAGE.SUCCESS_SUPPLIER_ORDER_RECEIVED, order.supplierName),
          variant: 'success',
        });
      })
      .catch((response) => {
        if (response && response.type === ErrorType.PRODUCTS_SHOULD_BE_SYNCHRONIZED) {
          setOpened(true);
          return Promise.resolve();
        }
        return Promise.reject(response);
      })
      .finally(() => setLoading(false));
  }, [dispatch, context, currentStore, selectedOrder]);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleSyncProducts = useCallback(() => {
    setLoading(true);
    putSyncSupplierProductsByOrderId(selectedOrder.supplierId, selectedOrder.id)
      .then((response) => {
        context({
          message: format(MESSAGE.SUCCESS_SUPPLIER_PRODUCTS_IMPORT, response.length),
          variant: 'success',
        });
        setOpened(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedOrder, context]);

  return (
    <>
      <AskToSyncProductsModal
        isOpened={isOpened}
        onClose={handleClose}
        loading={loading}
        onSyncProducts={handleSyncProducts}
      />
      <Button
        variant="outlined"
        text={TEXT.BUTTON.ADD_TO_STORE}
        onClick={handleOrderReceived}
        disabled={
          !storeEnabled
          || selectedOrder.state === SupplierOrderState.RECEIVED
          || (selectedOrder.external && selectedOrder.state !== SupplierOrderState.SENT)
          || !hasAnyItems
          || loading
        }
        startIcon={<Store />}
      />
    </>
  );
};

export default AddOrderToStoreButton;
