import React, { FC } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Slide } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Message } from '@/components/organisms/SnackBarRenderer/SnackBarRenderer';
import styles from './style';

interface Props {
  message: Message;
  handleClose(): void;
  classes: Record<any, any>;
}

const SnackBar: FC<Props> = ({ classes, message, handleClose }) => {
  const { variant, closeable = true, message: text } = message;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Snackbar
        TransitionComponent={Slide}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open
        className={classes[variant]}
        onExited={handleClose}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <Alert severity={variant} variant="filled">
            <span id="message-id">{text}</span>
          </Alert>
        }
        action={
          closeable && [
            <IconButton
              key="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]
        }
      />
    </ClickAwayListener>
  );
};

export default withStyles(styles)(SnackBar);
