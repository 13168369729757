import { CompanyUserInfo } from '@/interfaces/CompanyUserInfo';
import { createSelector } from 'reselect';
import { CompanyInfo } from '@/interfaces/CompanyInfo';
import { StoreState } from '@/redux/stores/store';
import { getUserId, selectCurrentStore } from './applicationSelector';

export const selectCompanyInfo = (state: StoreState): CompanyInfo | null => state.companyInfo;

export const selectCompanyUserInfos = (state: StoreState): CompanyUserInfo[] => (
  state.companyUserInfoState.companyUserInfos
);

export const selectCompanyUserInfoByUserId = (id) => createSelector(
  selectCompanyUserInfos,
  (companyUserInfos): CompanyUserInfo | null => (
    companyUserInfos.find((value) => value.userId === id) || null
  ),
);

export const selectCompanyUserSelectionEnabled = (state: StoreState): boolean => (
  state.companyUserInfoState.selectionEnabled
);

export const selectCurrentCompanyUserInfo = (state: StoreState): CompanyUserInfo | null => (
  state.companyUserInfoState.currentCompanyUserInfo
);

export const isCompanyOwner = createSelector(
  [
    selectCompanyInfo,
    getUserId,
  ],
  (
    company: CompanyInfo | null,
    userId: string,
  ): boolean => (!!company && userId === company.ownerUserId),
);

export const isDropdownEnabled = createSelector(
  [
    selectCompanyUserSelectionEnabled,
    isCompanyOwner,
  ],
  (
    isEnable: boolean,
    isOwner: boolean,
  ) => isOwner && isEnable,
);

export const isSelfSelected = createSelector(
  selectCurrentCompanyUserInfo,
  (
    selectedUser,
  ) => (selectedUser === null),
);

export const selectSelectedUserId = createSelector(
  [
    getUserId,
    selectCurrentCompanyUserInfo,
  ],
  (
    selfId: string,
    selectedUser: CompanyUserInfo | null,
  ) => (selectedUser?.userId || selfId),
);

export const selectUserStore = createSelector(
  [
    selectCurrentCompanyUserInfo,
    selectCurrentStore,
  ],
  (
    user,
    store,
  ) => (
    user
      ? user.stores[0]
      : store
  ),
);
