import { Role } from '@/enum/Role';
import { StoreState } from '@/redux/stores/store';
import { createSelector } from 'reselect';

const getRoles = (state: StoreState): Array<Role> => state.roles;

export const isStoreEnabled = createSelector(
  getRoles,
  (roles): boolean => roles.includes(Role.STOREKEEPER),
);

export const isSupplierEnabled = createSelector(
  getRoles,
  (roles): boolean => roles.includes(Role.SUPPLIER),
);
