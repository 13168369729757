import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import {
  deleteSaleItemById,
  getClientPatternInfosByClientId,
  getSaleItemInfosSaleId,
  postSaleItem,
  putPatternForSale,
  putSaleComplete,
  putSaleItem,
  putSendSaleToClient,
} from '@/utils/fetch';
import { dateTimeFormat } from '@/app/applicationSettings';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import { patternToOption } from '@/utils/toOption';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import IconButton from '@/components/atoms/IconButton';
import Check from '@material-ui/icons/Check';
import ExportButton from '@/components/atoms/ExportMenu/ExportButton';
import SaleExportModal from '@/components/organisms/SaleExportModal/SaleExportModal';
import ID from '@/utils/id';
import moment from 'moment';
import SaleCompleteRequest from '@/model/sale/request/SaleCompleteRequest';
import Button from '@/components/atoms/Button';
import { useDispatch, useSelector } from 'react-redux';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import { selectCurrentStore } from '@/redux/selectors/applicationSelector';
import { SaleState } from '@/enum/SaleState';
import SaleItemInfo from '@/interfaces/SaleItemInfo';
import { LocalShipping, ShoppingBasket } from '@material-ui/icons';
import useSaleItemColumns from '@/hook/columns/useSaleItemColumns';
import * as ActionCreators from '@/redux/actions/actionCreator';
import {
  isCustomTableLoading,
  selectSaleItemsTableColumns,
} from '@/redux/selectors/customTableSelector';
import SaleInfo from '@/interfaces/SaleInfo';
import { Box } from '@material-ui/core';
import { isSelfSelected, selectSelectedUserId } from '@/redux/selectors/companySelector';
import OrderBySaleActionIcon from '@/components/organisms/SaleItemTable/OrderBySaleActionIcon';

interface Props {
  selectedSale: SaleInfo,
}

function SaleItemTable({
  selectedSale,
}: Props) {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [patternOptions, setPatternOptions] = useState([]);
  const [patternsLoading, setPatternsLoading] = useState(false);
  const [selectedPatternOption, setSelectedPatternOption] = useState(null);
  const [saleExportModalOpen, setSaleExportModalOpen] = useState(false);
  const [saleItemInfos, setSaleItemInfos] = useState<Array<SaleItemInfo>>([]);

  const storeEnabled = useSelector(isStoreEnabled);
  const columns = useSelector(selectSaleItemsTableColumns);
  const currentStore = useSelector(selectCurrentStore);
  const customTableLoading = useSelector(isCustomTableLoading);
  const selectedUserId = useSelector(selectSelectedUserId);
  const isEditable = useSelector(isSelfSelected);

  const { priceFormationTypeId } = selectedSale;

  const saleItemColumns = useSaleItemColumns(
    priceFormationTypeId || undefined,
    errors,
    columns,
    storeEnabled,
    selectedSale.type,
  );

  const globalLoading = customTableLoading || loading;
  const saleId = selectedSale.id;

  const fetchItems = useCallback(() => {
    let didCancel = false;
    if (saleId) {
      setLoading(true);
      getSaleItemInfosSaleId(saleId, selectedUserId)
        .then((response) => {
          if (didCancel) {
            return;
          }
          setSaleItemInfos(response);
        })
        .finally(() => {
          if (didCancel) {
            return;
          }
          setLoading(false);
        });
    } else {
      setSaleItemInfos([]);
    }
    return () => {
      didCancel = true;
    };
  }, [selectedUserId, saleId]);

  useEffect(fetchItems, [fetchItems]);

  const clientId = selectedSale ? selectedSale.clientId : null;
  const external = selectedSale ? selectedSale.external : false;

  useEffect(() => {
    let didCancel = false;
    if (clientId && isEditable) {
      setPatternsLoading(true);
      getClientPatternInfosByClientId(clientId, external)
        .then((patterns) => {
          if (didCancel) {
            return;
          }
          setPatternOptions(patterns.map(patternToOption));
          setSelectedPatternOption(null);
        })
        .finally(() => {
          if (didCancel) {
            return;
          }
          setPatternsLoading(false);
        });
    } else {
      setPatternOptions([]);
      setSelectedPatternOption(null);
      setPatternsLoading(false);
    }
    return () => {
      didCancel = true;
    };
  }, [isEditable, clientId, external]);

  const validateSaleItem = useCallback(({ productId }) => {
    const errors = {};
    if (!productId) {
      errors.productId = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, []);

  const onAdd = useCallback(({
    supplierOrderItemId,
    name,
    count = 0,
    markup = 0,
    productId,
    vat = 0,
    supplierPrice = 0,
    price = 0,
    producingDate,
  }) => new Promise((
    resolve,
    reject,
  ) => {
    if (!validateSaleItem({
      supplierOrderItemId,
      name,
      count,
      markup,
      productId,
      vat,
      supplierPrice,
      price,
    })) {
      reject();
      return;
    }

    setLoading(true);
    Promise.resolve()
      .then(() => postSaleItem({
        supplierOrderItemId,
        name,
        count,
        markup,
        productId,
        vat,
        supplierPrice,
        price,
        saleId: selectedSale.id,
        producingDate,
      }))
      .then(({ saleInfo, saleItemInfo }) => {
        dispatch(ActionCreators.saveSaleInfo(saleInfo));
        setSaleItemInfos([
          ...saleItemInfos.filter((item) => item.id !== saleItemInfo.id),
          saleItemInfo,
        ]);
        context({
          message: MESSAGE.SUCCESS_OPERATION,
          variant: 'success',
        });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [dispatch, errors, context, saleItemInfos, selectedSale, setSaleItemInfos, validateSaleItem]);

  const onUpdate = useCallback(({
    id,
    supplierOrderItemId,
    name,
    count = 0,
    markup = 0,
    productId,
    vat = 0,
    supplierPrice = 0,
    price = 0,
    vatSum = 0,
    priceNoVat = 0,
    sumNoVat = 0,
    sum = 0,
    producingDate,
  }) => new Promise((
    resolve,
    reject,
  ) => {
    if (!validateSaleItem({
      supplierOrderItemId,
      name,
      count,
      markup,
      productId,
      vat,
      supplierPrice,
      price,
    })) {
      reject();
      return;
    }

    setLoading(true);
    Promise.resolve()
      .then(() => putSaleItem({
        id,
        supplierOrderItemId,
        count,
        markup,
        productId,
        vat,
        supplierPrice,
        price,
        vatSum,
        priceNoVat,
        sumNoVat,
        sum,
        saleId: selectedSale.id,
        producingDate,
      }))
      .then(({ saleInfo, saleItemInfo }) => {
        dispatch(ActionCreators.saveSaleInfo(saleInfo));
        setSaleItemInfos(saleItemInfos.map((item) => {
          if (item.id === saleItemInfo.id) {
            return saleItemInfo;
          }
          return item;
        }));
        context({
          message: MESSAGE.SUCCESS_OPERATION,
          variant: 'success',
        });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [selectedSale, errors, saleItemInfos, context, dispatch, setSaleItemInfos, validateSaleItem]);

  const onDelete = useCallback((item) => new Promise(
    (
      resolve,
      reject,
    ) => {
      setLoading(true);
      deleteSaleItemById(selectedSale.id, item.id)
        .then(({ saleInfo }) => {
          dispatch(ActionCreators.saveSaleInfo(saleInfo));
          setSaleItemInfos(saleItemInfos.filter((saleItemInfo) => item.id !== saleItemInfo.id));
          context({
            message: MESSAGE.SUCCESS_OPERATION,
            variant: 'success',
          });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({
              message: response.message,
              variant: 'warning',
            });
            return undefined;
          }
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [selectedSale, dispatch, setSaleItemInfos, saleItemInfos, context]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const rightButtonBar = useMemo(() => {
    const { supplierOrderId, state, id } = selectedSale;

    const handleSelectPatternOption = (option) => {
      setSelectedPatternOption(option);
    };

    const handleSaleExportModalClose = () => {
      setSaleExportModalOpen(false);
    };

    const handleSaleExportModalOpen = () => {
      setSaleExportModalOpen(true);
    };

    function handleSaleComplete() {
      const saleTime = moment(Date.now())
        .format(dateTimeFormat);
      setLoading(true);
      putSaleComplete(new SaleCompleteRequest(selectedSale.id, saleTime))
        .then((completeSaleResponse) => {
          dispatch(ActionCreators.saveSaleInfo(completeSaleResponse.saleInfo));
          setSaleItemInfos(completeSaleResponse.saleItemInfos);
          context({
            message: MESSAGE.SUCCESS_SALE_COMPLETE,
            variant: 'success',
          });
        })
        .finally(() => setLoading(false));
    }

    const onPatternApply = () => {
      if (!selectedPatternOption) {
        return;
      }
      setLoading(true);
      putPatternForSale(id, selectedPatternOption!.value, storeEnabled ? currentStore!.id : '')
        .then((response) => {
          context({
            message: MESSAGE.SUCCESS_OPERATION,
            variant: 'success',
          });
          setSaleItemInfos(response);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleSendToClient = () => {
      const saleTime = moment(Date.now())
        .format(dateTimeFormat);
      setLoading(true);
      putSendSaleToClient({
        id,
        supplierOrderId,
        saleTime,
      })
        .then((completeSaleResponse) => {
          dispatch(ActionCreators.saveSaleInfo(completeSaleResponse.saleInfo));
          setSaleItemInfos(completeSaleResponse.saleItemInfos);
          context({
            message: MESSAGE.SUCCESS_SUPPLIER_ORDER_SEND,
            variant: 'success',
          });
        })
        .finally(() => setLoading(false));
    };

    return (
      <div className="right-button-bar">
        <Box mr={1}>
          <OrderBySaleActionIcon
            onAction={fetchItems}
            saleIds={[selectedSale.id]}
            disabled={!saleItemInfos.find((item) => item.preorder && !item.supplierOrderItemId)}
          />
        </Box>
        <SaleExportModal
          selectedSale={selectedSale}
          onClose={handleSaleExportModalClose}
          isOpen={saleExportModalOpen}
          saleItemInfos={saleItemInfos}
        />
        <ExportButton
          onClick={handleSaleExportModalOpen}
          disabled={!saleItemInfos.length || globalLoading}
        />
        <Box mx={1} display="flex" height="100%">
          <InputSelect
            loading={patternsLoading}
            error={errors.patternId}
            label={TEXT.SALES.FORM.PATTERNS}
            defaultOptions={patternOptions}
            value={selectedPatternOption}
            onChange={handleSelectPatternOption}
            disabled={!selectedSale || !selectedSale.clientId
            || selectedSale.state === SaleState.COMPLETED}
          />
          <Box display="flex" alignItems="flex-end" mb={1}>
            <IconButton
              className="mr-10"
              title={TEXT.BUTTON.APPLY}
              onClick={onPatternApply}
              disabled={!selectedPatternOption}
            >
              <Check />
            </IconButton>
          </Box>
        </Box>
        {
          supplierOrderId
            ? (
              <Button
                variant="outlined"
                text={TEXT.SEND_TO_CLIENT}
                onClick={handleSendToClient}
                disabled={globalLoading || loading || SaleState.COMPLETED === state}
                startIcon={<LocalShipping />}
              />
            )
            : (
              <Button
                variant="outlined"
                startIcon={<ShoppingBasket />}
                text={
                  selectedSale && selectedSale.state === SaleState.COMPLETED
                    ? TEXT.BUTTON.COMPLETED
                    : TEXT.BUTTON.SELL
                }
                onClick={handleSaleComplete}
                disabled={!selectedSale || selectedSale.state === SaleState.COMPLETED
                || loading || globalLoading}
              />
            )
        }
      </div>
    );
  }, [
    fetchItems,
    currentStore,
    storeEnabled,
    errors,
    patternOptions,
    saleExportModalOpen,
    saleItemInfos,
    selectedPatternOption,
    patternsLoading,
    loading,
    globalLoading,
    selectedSale,
    context,
    dispatch,
  ]);

  const editable = useMemo(() => {
    const hasWriteAccess = selectedSale && selectedSale.state !== SaleState.COMPLETED;
    return isEditable
      ? {
        onRowAdd: hasWriteAccess ? onAdd : null,
        onRowDelete: onDelete,
        onRowUpdate: onUpdate,
        isDeletable: () => hasWriteAccess,
        isEditable: () => hasWriteAccess,
      }
      : {};
  }, [isEditable, onDelete, onUpdate, onAdd, selectedSale]);

  const options = useMemo(() => ({
    paging: false,
    minBodyHeight: null,
    maxBodyHeight: 800,
  }), []);

  return (
    <div className="table-container">
      <StyledTable
        className="sub-table"
        tableKey={ID.SALE_ITEM_TABLE}
        onRowEditing={onRowEditing}
        rightButtonBar={isEditable ? rightButtonBar : undefined}
        editable={editable}
        loading={globalLoading}
        columns={saleItemColumns}
        data={saleItemInfos}
        options={options}
      />
    </div>
  );
}

export default SaleItemTable;
