import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'rgb(27, 61, 89)',
      },
    },
    MuiBreadcrumbs: {
      ol: {
        padding: '0 16px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiList: {
      root: {
        width: '100%',
      },
    },
    MuiStepIcon: {
      text: {
        fill: 'white',
      },
    },
    MuiIconButton: {
      root: {
        color: 'rgb(27, 61, 88)',
      },
      colorPrimary: {
        color: 'rgb(27, 61, 88)',
      },
    },
    MuiButton: {
      root: {
        marginRight: '5px',
      },
      contained: {
        border: '1px solid rgb(27, 61, 88)',
        '&:disabled': {
          backgroundColor: 'inherit',
          color: 'rgb(27, 61, 88)',
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.12)',
        },
      },
      outlined: {
        color: 'rgb(27, 61, 88)',
        border: '1px solid rgb(27, 61, 88)',
        '&:disabled': {
          backgroundColor: 'inherit',
        },
      },
      containedPrimary: {
        color: 'white',
        border: '1px solid rgb(27, 61, 88)',
        '&:hover': {
          boxShadow: '0px 2px 4px -1px rgb(27, 61, 88), 0px 4px 5px 0px rgb(255, 255, 255), 0px 1px 10px 0px rgb(27, 61, 88)',
          backgroundColor: 'rgb(27, 61, 88)',
          border: '1px solid rgb(9, 21, 30)',
        },
        backgroundColor: 'rgb(27, 61, 88)',
      },
      outlinedPrimary: {
        border: '1px solid rgb(27, 61, 88)',
        color: 'rgb(27 61 88)',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'rgb(27, 61, 88)',
          color: 'white',
          border: '1px solid rgb(27, 61, 88)',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 15,
        maxWidth: 500,
        padding: 10,
        backgroundColor: 'rgb(4 21 40 / 90%)',
      },
      arrow: {
        color: 'rgb(4 21 40 / 90%)',
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: 'rgb(27 61 88)',
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(27, 61, 88)',
    },
  },
});
