import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import Path from './enum/Path';
import { store } from './redux/stores/store';
import reportWebVitals from './tools/reportWebVitals';
import App from './App';
import { unregister } from './registerServiceWorker';

// import registerServiceWorker from './registerServiceWorker';

window.onload = () => {
  ReactDOM.render(
    <Provider store={store}>
      <MemoryRouter
        initialEntries={[Path.LOADING]}
      >
        <App />
      </MemoryRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

// module.hot.accept();
unregister();

if (REACT_APP_DEVELOPMENT) {
  // eslint-disable-next-line no-console
  reportWebVitals(console.log);
}
