import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import { currency } from '@/app/applicationSettings';

const useVatSumColumn = (
  column,
) => {
  const handleEditComponent = useCallback(({ rowData, onChange }) => (
    <CustomNumberInput
      disabled
      label={TEXT.COLUMN.HEADER.VAT_SUM}
      value={rowData.vatSum}
      decimalScale={2}
      onChange={onChange}
      suffix={` ${currency}`}
    />
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : true,
    title: TEXT.COLUMN.HEADER.VAT_SUM,
    field: 'vatSum',
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [column, handleEditComponent]);
};

export default useVatSumColumn;
