import { UserPartnerRequestInfo } from '@/interfaces/UserPartnerRequestInfo';
import { ClientRequestInfoActionType } from '@/redux/actions/clientRequestInfoActions';

export function clientRequestInfoReducer(
  clientRequestInfos: Array<UserPartnerRequestInfo> = [],
  { type, payload },
): Array<UserPartnerRequestInfo> {
  switch (type) {
    case ClientRequestInfoActionType.SET:
      return payload;
    case ClientRequestInfoActionType.UPDATE_ONE:
      const index = clientRequestInfos.findIndex((supplierInfo) => supplierInfo.id === payload.id);
      if (index > -1) {
        const newInfos = [...clientRequestInfos];
        newInfos[index] = payload;
        return newInfos;
      }
      return clientRequestInfos;

    default:
      return clientRequestInfos;
  }
}
