import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import React from 'react';
import Fade from '@material-ui/core/Fade';

export default function SubTabPanel({
  children,
  value,
  index,
  ...other
}) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index
      && (
        <Fade in={value === index} timeout={1000}>
          <Box py={3}>{children}</Box>
        </Fade>
      )}
    </Typography>
  );
}
