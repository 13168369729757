import React, { FC, useCallback, useEffect, useState } from 'react';
import { round } from '@/utils/Utils';
import ColumnName from '@/enum/ColumnName';
import clsx from 'clsx';
import ColumnDataKey from '@/constants/ColumnDataKey';
import { TableBuilderTypes } from '@/components/organisms/TableBuilder/tableBuilder.types';
import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import TEXT from '@/utils/Text';
import useStyles from './tableBuilder.styles';

const TableBuilder: FC<TableBuilderTypes> = ({
  rows,
  columns,
  disableTableFooter,
  className,
  saleReceipt,
}) => {
  const classes = useStyles();
  const [fullSum, setFullSum] = useState(0);
  const [prepayment, setPrepayment] = useState(0);

  useEffect(() => {
    const sum = round(
      rows.reduce((value, item) => value + item.sum || 0, 0),
      2
    );
    setFullSum(sum);
  }, [rows]);

  const handlePrepaymentChange = useCallback(value => {
    setPrepayment(value);
  }, []);

  const renderFooterCell = useCallback(
    columnName => {
      const count = round(
        rows.reduce((value, item) => value + item.count || 0, 0),
        4
      );
      const sumNoVat = round(
        rows.reduce((value, item) => value + item.sumNoVat || 0, 0),
        2
      );
      const vatSum = round(
        rows.reduce((value, item) => value + item.vatSum || 0, 0),
        2
      );
      const sum = round(
        rows.reduce((value, item) => value + item.sum || 0, 0),
        2
      );

      switch (columnName) {
        case ColumnName.NAME:
          return 'Итого';
        case ColumnName.SUM_NO_VAT:
          return sumNoVat;
        case ColumnName.VAT_SUM:
          return vatSum;
        case ColumnName.SUM:
          return sum;
        case ColumnName.COUNT:
          return count;
        default:
          return 'x';
      }
    },
    [rows]
  );

  const tableClasses = clsx(className || '', 'custom-table');

  return (
    <div style={{ width: '100%' }}>
      <table
        className={tableClasses}
        style={{
          borderBottom: '1px solid black',
          borderRight: '1px solid black',
        }}
      >
        <thead>
          <tr>
            {columns.map((column, i) => (
              <th
                key={`custom-table-col-${i}`}
                style={{
                  borderTop: '1px solid black',
                  borderLeft: '1px solid black',
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr
              key={`preview-table-row-${+i}`}
              style={{ background: i % 2 === 0 ? 'white' : '#f3f5f7' }}
            >
              {columns.map((column, j) => {
                const value = row[ColumnDataKey[column.value]];
                return (
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                    }}
                    key={`custom-table-col-${+i}${+j}`}
                  >
                    {value || value === 0 ? value : ''}
                  </td>
                );
              })}
            </tr>
          ))}
          {!disableTableFooter && (
            <>
              <tr className="custom-table-footer">
                {columns.map((column, i) => (
                  <td
                    style={{
                      borderTop: '1px solid black',
                      borderLeft: '1px solid black',
                    }}
                    key={`custom-table-footer-${+i}`}
                  >
                    {renderFooterCell(column.value) || 'x'}
                  </td>
                ))}
              </tr>
              {saleReceipt && (
                <>
                  <tr>
                    <td
                      style={{ textAlign: 'right', fontWeight: 'bold' }}
                      colSpan={4}
                    >
                      {TEXT.PREPAYMENT_AMOUNT}
                    </td>
                    <td>
                      <EditableDocumentInput
                        className={classes.prepaymentInput}
                        initialValue="0"
                        onChange={handlePrepaymentChange}
                        editing
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ textAlign: 'right', fontWeight: 'bold' }}
                      colSpan={4}
                    >
                      {TEXT.BALANCE_AMOUNT}
                    </td>
                    <td style={{ fontWeight: 'bold' }}>{`${round(
                      fullSum - prepayment,
                      2
                    )}`}</td>
                  </tr>
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableBuilder;
