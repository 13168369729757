import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';

const InputField = (
  {
    rootClasses,
    classes: { root, ...otherClasses },
    error,
    style,
    width,
    ...props
  },
) => (
  <MuiTextField
    helperText={typeof error === 'string' ? error : undefined}
    error={!!error}
    title={props.value}
    classes={{
      root: `${root} ${rootClasses || ''}`,
      ...otherClasses,
    }}
    style={{
      ...style,
      width,
    }}
    {...props}
  />
);

export default withStyles((theme) => ({
  root: {
    justifyContent: 'center',
  },
}))(InputField);
