import usePriceNoVatColumn from '@/hook/columns/usePriceNoVatColumn';
import useVatColumn from '@/hook/columns/useVatColumn';
import useVatSumColumn from '@/hook/columns/useVatSumColumn';
import useSumNoVatColumn from '@/hook/columns/useSumNoVatColumn';
import { useMemo } from 'react';
import { SaleType } from '@/enum/SaleType';

const useWholeSaleColumns = (columns, errors, saleType, disabled = false) => {
  const priceNoVatColumn = usePriceNoVatColumn(columns.priceNoVat);
  const vatColumn = useVatColumn(columns.vat, errors.vat, disabled);
  const vatSumColumn = useVatSumColumn(columns.vatSum);
  const sumNoVatColumn = useSumNoVatColumn(columns.sumNoVat);

  const noColumns = useMemo(() => [], []);
  const wholeSaleColumns = useMemo(() => [
    priceNoVatColumn,
    vatColumn,
    vatSumColumn,
    sumNoVatColumn,
  ], [
    priceNoVatColumn,
    vatColumn,
    vatSumColumn,
    sumNoVatColumn,
  ]);

  if (saleType === SaleType.WHOLESALE) {
    return wholeSaleColumns;
  }
  return noColumns;
};

export default useWholeSaleColumns;
