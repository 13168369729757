import React from 'react';
import ClientTable from '@/components/organisms/ClientsTable/ClientTable';

function MyClientsSubTab() {
  return (
    <div className="table-container">
      <ClientTable />
    </div>
  );
}

export default MyClientsSubTab;
