import { createAction } from 'redux-actions';
import { SaleTableFilter } from '@/interfaces/SaleTableConfig';

enum Type {
  SET_FILTER = 'SET_FILTER',
}

const setFilter = createAction<SaleTableFilter>(Type.SET_FILTER);

const SaleTableConfigActions = {
  Type,
  setFilter,
};

export default SaleTableConfigActions;
