import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { TEXT } from '@/utils/Text';
import { optionByIdAndName, supplierToOption } from '@/utils/toOption';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import Divider from '@/components/atoms/Divider/Divider';
import Button from '@/components/atoms/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import { putProductGroup } from '@/utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnits } from '@/redux/selectors/applicationSelector';
import {
  Box, FormControlLabel, Grid, Paper, Switch,
} from '@material-ui/core';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import { selectCurrentPriceFormationTypeId } from '@/redux/selectors/priceFormationTypeSelector';
import { ProductGroupUpdateRequest } from '@/interfaces/ProductInfo';
import arrayIntoChunks from '@/utils/arrayIntoChunks';
import ProductInfoActions from '@/redux/actions/ProductInfoInfoActions';
import LinearLabeledProgress from '@/components/molecules/LinearLabeledProgress';
import clsx from 'clsx';
import { useStyles } from './style';

const MAX_ENTITIES_PER_REQUEST = 200;

function ProductGroupEditModal({
  onClose,
  isOpen,
  selectedProducts,
  onSuccess,
}) {
  const classes = useStyles();
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [unitId, setUnitId] = useState(null);
  const [category, setCategory] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [markup, setMarkup] = useState(0);
  const [vat, setVat] = useState(0);
  const [errors, setErrors] = useState({});
  const [unitDisabled, setUnitDisabled] = useState(true);
  const [categoryDisabled, setCategoryDisabled] = useState(true);
  const [manufacturerDisabled, setManufacturerDisabled] = useState(true);
  const [vatDisabled, setVatDisabled] = useState(true);
  const [markupDisabled, setMarkupDisabled] = useState(true);
  const [supplierDisabled, setSupplierDisabled] = useState(true);
  const [supplierSelectedOption, setSupplierSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [showUpdatePage, setShowUpdatePage] = useState(false);
  const [archived, setArchived] = useState(false);

  const suppliers = useSelector(selectSupplierInfos);
  const units = useSelector(selectUnits);
  const priceFormationTypeId = useSelector(selectCurrentPriceFormationTypeId);

  const supplierOptions = useMemo(() => suppliers.map(supplierToOption), [suppliers]);
  const unitOptions = useMemo(() => units.map(optionByIdAndName), [units]);

  const onApply = useCallback(() => {
    setLoading(true);
    setShowUpdatePage(true);

    const productIds: string[] = selectedProducts.map((product) => product.id);
    const request: ProductGroupUpdateRequest = {
      productIds: [],
      priceFormationTypeId,
    };

    if (!unitDisabled) {
      request.unitId = unitId;
    }
    if (!categoryDisabled) {
      request.category = category;
    }
    if (!vatDisabled) {
      request.vat = vat;
    }
    if (!markupDisabled) {
      request.markup = markup;
    }
    if (!manufacturerDisabled) {
      request.manufacturer = manufacturer;
    }
    if (!supplierDisabled) {
      request.supplierId = supplierSelectedOption ? supplierSelectedOption.value : null;
    }
    request.archived = archived;
    const totalRequests = Math.ceil(productIds.length / MAX_ENTITIES_PER_REQUEST);
    const oneRequestValue = 100 / totalRequests;

    const productChunks = arrayIntoChunks(productIds, MAX_ENTITIES_PER_REQUEST);
    productChunks.reduce((previousPromise, chunk, index) => (
      previousPromise
        .then(() => putProductGroup({
          ...request,
          productIds: chunk,
        }).then(() => {
          setProgressValue((prevState) => prevState + oneRequestValue);
          if (index === productChunks.length - 1) {
            context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
            setLoading(false);
          }
          return Promise.resolve();
        }))
        .catch((error) => {
          setLoading(false);
          return Promise.reject(error);
        })
    ), Promise.resolve());
  }, [
    archived,
    manufacturerDisabled,
    manufacturer,
    priceFormationTypeId,
    vatDisabled,
    vat,
    unitId,
    supplierSelectedOption,
    supplierDisabled,
    unitDisabled,
    selectedProducts,
    markup,
    markupDisabled,
    category,
    categoryDisabled,
    context,
  ]);

  const handleReadyClick = useCallback(() => {
    onClose();
    onSuccess();
  }, [onClose, onSuccess]);

  const handleArchiveChange = useCallback((event) => {
    setArchived(event.target.checked);
  }, []);

  return (
    <ModalWindow
      keepMounted
      loading={loading}
      header={TEXT.HEADER.GROUP_SELECTION}
      isOpen={isOpen}
      hideCloseIcon={showUpdatePage}
      disableBackdropClick={showUpdatePage}
      onClose={onClose}
    >
      {!showUpdatePage ? (
        <Box className="flex-column-container">
          <Grid container>
            <Grid item xs={6}>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!categoryDisabled}
                    onChange={({ target: { checked } }) => setCategoryDisabled(!checked)}
                  />
                </div>
                <CustomInput
                  disabled={categoryDisabled}
                  error={errors.category}
                  id="category"
                  label={TEXT.COLUMN.HEADER.CATEGORY}
                  onChange={(e) => setCategory(e.target.value)}
                  value={category || ''}
                />
              </div>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!markupDisabled}
                    onChange={({ target: { checked } }) => (
                      setMarkupDisabled(!checked)
                    )}
                  />
                </div>
                <CustomNumberInput
                  disabled={markupDisabled}
                  error={errors.markup}
                  id="markup"
                  label={TEXT.COLUMN.HEADER.MARKUP}
                  onChange={setMarkup}
                  value={markup}

                  step={1}
                  decimalScale={2}
                  suffix=" %"
                />
              </div>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!vatDisabled}
                    onChange={({ target: { checked } }) => setVatDisabled(!checked)}
                  />
                </div>
                <CustomNumberInput
                  disabled={vatDisabled}
                  error={errors.vat}
                  id="vat"
                  label={TEXT.COLUMN.HEADER.VAT}
                  onChange={setVat}
                  value={vat}
                  min={0}
                  step={1}
                  decimalScale={2}
                  suffix=" %"
                />
              </div>
              <div className="flex-center mb-20">
                <FormControlLabel
                  control={(
                    <Switch
                      color="secondary"
                      onChange={handleArchiveChange}
                    />
                  )}
                  label={(
                    <span className={clsx({ disabled: !archived })}>
                      Отправить в архив
                    </span>
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!supplierDisabled}
                    onChange={({ target: { checked } }) => setSupplierDisabled(!checked)}
                  />
                </div>
                <InputSelect
                  disabled={supplierDisabled}
                  error={errors.supplierId}
                  id="supplierId"
                  label={TEXT.COLUMN.HEADER.SUPPLIER}
                  defaultOptions={supplierOptions}
                  value={supplierSelectedOption}
                  onChange={setSupplierSelectedOption}
                />
              </div>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!unitDisabled}
                    onChange={({ target: { checked } }) => setUnitDisabled(!checked)}
                  />
                </div>
                <InputSelect
                  disabled={unitDisabled}
                  error={errors.unitId}
                  id="unitId"
                  label={TEXT.COLUMN.HEADER.UNIT}
                  defaultOptions={unitOptions}
                  value={unitOptions.find((unitOption) => unitId === unitOption.value)}
                  onChange={(option) => setUnitId(option ? option.value : null)}
                />
              </div>
              <div className="d-flex mb-20">
                <div className="flex-center">
                  <Checkbox
                    edge="start"
                    checked={!manufacturerDisabled}
                    onChange={({ target: { checked } }) => setManufacturerDisabled(!checked)}
                  />
                </div>
                <CustomInput
                  disabled={manufacturerDisabled}
                  error={errors.manufacturer}
                  id="manufacturer"
                  label={TEXT.COLUMN.HEADER.MANUFACTURER}
                  onChange={(e) => setManufacturer(e.target.value)}
                  value={manufacturer}
                />
              </div>
            </Grid>
          </Grid>
          <Divider horizontal />
          <Button
            color="primary"
            disabled={loading || (
              vatDisabled && categoryDisabled && markupDisabled
              && unitDisabled && supplierDisabled && manufacturerDisabled && !archived
            )}
            onClick={onApply}
          >
            {TEXT.BUTTON.APPLY}
          </Button>
        </Box>
      ) : (
        <Paper className={classes.paperImport}>
          <Box width="100%" px={2} pt={5} pb={2}>
            <LinearLabeledProgress value={progressValue} />
          </Box>
          <Box className={classes.actionPanel}>
            <Button
              color="primary"
              disabled={loading}
              onClick={handleReadyClick}
            >
              {TEXT.READY}
            </Button>
          </Box>
        </Paper>
      )}
    </ModalWindow>
  );
}

export default ProductGroupEditModal;
