import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

class CustomizedMenu extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const {
      anchorEl,
      children,
      onClose,
      classes,
      menuProps,
    } = this.props;
    return (
      <Menu
        classes={classes}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

        {...menuProps}
      >
        {children}
      </Menu>
    );
  }
}

export default withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    margin: '10px 0',
    borderRadius: 15,
    outline: 'none',
    background: 'rgb(255, 255, 255)',
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
    maxHeight: '100%',
  },
  list: {
    padding: '0',
  },
})(CustomizedMenu);
