import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import TextField from '@material-ui/core/TextField';

function TextArea({
  rows,
  inputProps = {},
  ...other
}) {
  const ref = useRef();
  const [initialRows] = useState(rows);
  const [rowsState, setRowsState] = useState(rows || 1);

  const handleFocus = useCallback(() => {
    if (!initialRows) {
      setRowsState(3);
    }
  }, [initialRows]);

  const handleBlur = useCallback(() => {
    if (!initialRows) {
      setRowsState(1);
    }
  }, [initialRows]);

  return (
    <TextField
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
      inputRef={ref}
      rows={rowsState}
      multiline
      inputProps={{
        maxLength: 127,
        ...inputProps,
      }}
      {...other}
    />
  );
}

export default memo(TextArea);
