const textStyle = {
  h1: {
    fontStyle: 'normal',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '60px',
    lineHeight: '73px',
    marginBottom: '30px',
    // textAlign: "center"
  },
  h2: {
    fontStyle: 'normal',
    color: 'black',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: 'normal',
  },
  h3: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  h4: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  h5: {
    color: 'black',
    fontSize: 13,
  },
  h6: {
    color: 'black',
  },
  body1: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  body2: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  caption: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
  },
  overline: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  subtitle1: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  subtitle2: {
    color: 'black',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  subtitle3: {
    color: 'rgb(9, 21, 30)',
    fontSize: 22,
    fontWeight: 'bold',
  },
  button: {
    color: 'black',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: 'normal',
  },
  footer: {
    color: 'black',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  headerBold: {
    color: 'black',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: 'normal',
  },
  header: {
    color: 'black',
    textTransform: 'none',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: 'normal',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 80,
    maxWidth: 200,
  },
  multilineEllipsis2: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    fontSize: 14,
  },
};

export default textStyle;
