import React, { useCallback, useContext, useState } from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TEXT } from '@/utils/Text';
import { toggleRole } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import Switch from '@material-ui/core/Switch';
import Typography from '@/components/atoms/Typography';
import Accordion from '@/components/molecules/Accordion/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { isStoreEnabled, isSupplierEnabled } from '@/redux/selectors/rolesSelector';
import { Role } from '@/enum/Role';
import {
  createStoreKeeperRoleUpdateAction,
  createSupplierRoleUpdateAction,
} from '@/redux/actions/rolesActions';
import { selectUser } from '@/redux/selectors/applicationSelector';
import PersonalData from '@/components/organisms/PersonalData/PersonalData';
import User from '@/model/user/User';
import { SettingsModalContainer } from './SettingsModalContainer';

function SettingsModal({
  isOpen,
  onClose,
}) {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [personalDataOpened, setPersonalDataOpened] = useState(false);
  const [personalDataErrors, setPersonalDataErrors] = useState({});
  const user = useSelector<User>(selectUser);

  const storeEnabled = useSelector(isStoreEnabled);
  const supplierEnabled = useSelector(isSupplierEnabled);

  const handleStoreEnabledChange = useCallback((event) => {
    const enabled = event.target.checked;
    setLoading(true);
    toggleRole(Role.STOREKEEPER, enabled)
      .then(() => {
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        dispatch(createStoreKeeperRoleUpdateAction(enabled));
      })
      .finally(() => setLoading(false));
  }, [context, dispatch]);

  const handleSupplierRoleChange = useCallback((event) => {
    const enabled = event.target.checked;
    const {
      organization, contactEmail, description, phoneNumber, address,
    } = user;
    if (enabled && (!address || !organization || !contactEmail || !description || !phoneNumber || !phoneNumber || phoneNumber.length < 5)) {
      const errors = {
        organization: !organization,
        contactEmail: !contactEmail,
        description: !description,
        address: !address,
        phoneNumber: !phoneNumber || phoneNumber.length < 5,
      };
      setPersonalDataErrors(errors);
      setPersonalDataOpened(true);
      context({ message: MESSAGE.WARNING_FILL_PUBLIC_DATA, variant: 'warning' });
      return;
    }
    setLoading(true);
    toggleRole(Role.SUPPLIER, enabled)
      .then(() => {
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        dispatch(createSupplierRoleUpdateAction(enabled));
      })
      .finally(() => setLoading(false));
  }, [user, context, dispatch]);

  const onPersonalDataClose = useCallback(() => {
    setPersonalDataOpened(false);
  }, []);

  return (
    <ModalWindow
      loading={loading}
      header={TEXT.HEADER.SETTINGS}
      isOpen={isOpen}
      onClose={loading ? null : onClose}
    >
      <div className="flex-center flex-column">
        <div className="flex-center">
          <div className="flex-center flex-column ml-30 mr-30">
            <Accordion
              defaultExpanded={false}
              header={(
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  control={(
                    <Switch
                      disabled={loading}
                      checked={storeEnabled}
                      name="storeEnabled"
                      onChange={handleStoreEnabledChange}
                    />
                  )}
                  label={<Typography variant="caption">Роль кладовщика</Typography>}
                />
              )}
            >
              <div className="expandable-container">
                <div className="mb-10">
                  Роль позволят включить\отключить управление складом и его поставками.
                </div>
                <div>
                  При выключенном режиме на продажи не накладываются ограничения по количеству.
                </div>
              </div>
            </Accordion>

            <Accordion
              defaultExpanded={false}
              header={(
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  control={(
                    <Switch
                      disabled={loading}
                      checked={supplierEnabled}
                      name="supplierEnabled"
                      onChange={handleSupplierRoleChange}
                    />
                  )}
                  label={<Typography variant="caption">Роль поставщика</Typography>}
                />
              )}
            >
              <div className="expandable-container">
                <div className="mb-10">
                  Если роль поставщика активирована, пользователь может быть найден в публичном
                  поиске
                  в качестве поставщика.
                  А также сможет получать заявки на поставку от других пользовотелей.
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </div>
      {personalDataOpened && (
        <PersonalData
          onClose={onPersonalDataClose}
          isOpen={personalDataOpened}
          externalErrors={personalDataErrors}
        />
      )}
    </ModalWindow>
  );
}

export default SettingsModalContainer(SettingsModal);
