import { TEXT } from '@/utils/Text';
import React, { useCallback } from 'react';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

function CreditSumEditColumn({
  error,
  onChange,
  value,
  required,
}) {
  const handleChange = useCallback((value) => onChange(value), [onChange]);

  return (
    <CustomNumberInput
      required={required}
      error={error}
      id="sum"
      label={buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM)}
      onChange={handleChange}
      value={value}
      step={1}
      decimalScale={2}
      suffix={` ${currency}`}
    />
  );
}

export default CreditSumEditColumn;
