import React, { useCallback, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import InputField from '@/components/atoms/TextField/InputField';

const styles = {
  root: {
    justifyContent: 'center',
  },
};

const NumberInput = ({
  InputProps,
  error,
  rootClasses,
  decimalScale,
  readOnly,
  classes,
  variant,
  suffix,
  prefix,
  title,
  min,
  max = Number.MAX_SAFE_INTEGER,
  step,
  onChange,
  inputProps,
  ...props
}) => {
  const handleChange = useCallback((event) => {
    if (typeof onChange !== 'function') {
      return;
    }
    let number = Number(event.target.value);
    if (!isNaN(number)) {
      if (!isNaN(min) && number < min) {
        number = min;
      }
      if (!isNaN(max) && number > max) {
        const strLocalValue = String(number);
        if (strLocalValue.length > 1) {
          number = Number(strLocalValue.slice(0, -1));
        } else {
          number = max;
        }
      }
    }
    onChange(number, event.target.id);
  }, [max, min, onChange]);

  const memoizedInputProps = useMemo(() => ({
    inputComponent: NumberFormatCustom,
    ...InputProps,
  }), [InputProps]);

  return (
    <InputField
      error={error}
      variant={variant || 'outlined'}
      inputProps={{
        min, max, step, suffix, prefix, readOnly, decimalScale, ...inputProps,
      }}
      onChange={handleChange}
      InputProps={memoizedInputProps}
      classes={{ root: `${classes.root} ${rootClasses || ''}` }}
      {...props}
    />
  );
};

const NumberFormatCustom = ({
  format, inputRef, value, onChange, max, ...other
}) => {
  const handleValueChange = useCallback((values) => {
    const value = values.floatValue || 0;
    onChange({ target: { value } });
  }, [onChange]);

  return (
    <NumberFormat
      {...other}
      value={value}
      max={max || 999999999}
      allowLeadingZeros={false}
      defaultValue={0}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      thousandSeparator
    />
  );
};

export default withStyles(styles)(NumberInput);
