import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { TEXT } from '@/utils/Text';
import Button from '@/components/atoms/Button';
import { putUser } from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextArea from '@/components/atoms/TextArea/TextArea';
import PhoneField from '@/components/atoms/PhoneField/PhoneField';
import { PersonalDataContainer } from './PersonalDataContainer';

function PersonalData({
  user,
  onClose,
  dispatchSaveUser,
  isOpen,
  externalErrors,
}) {
  const context = useContext(ApplicationContext);

  const [name, setName] = useState('');
  const [unp, setUnp] = useState('');
  const [organization, setOrganization] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (externalErrors) {
      setErrors(externalErrors);
    }
  }, [externalErrors]);

  useEffect(() => {
    setName(user.name || '');
    setUnp(user.unp || '');
    setOrganization(user.organization || '');
    setPhoneNumber(user.phoneNumber || '');
    setContactEmail(user.contactEmail || '');
    setDescription(user.description || '');
    setAddress(user.address || '');
  }, [user]);

  const handleNameChange = useCallback(({ target: { value } }) => {
    setName(value);
  }, []);

  const handleUnpChange = useCallback(({ target: { value } }) => {
    setUnp(value);
  }, []);

  const handleOrganizationChange = useCallback(({ target: { value } }) => {
    setOrganization(value);
  }, []);

  const handleContactEmailChange = useCallback(({ target: { value } }) => {
    setContactEmail(value);
  }, []);

  const handlePhoneNumberChange = useCallback((value) => {
    setPhoneNumber(value);
  }, []);

  const handleDescriptionChange = useCallback(({ target: { value } }) => {
    setDescription(value);
  }, []);

  const handleAddressChange = useCallback(({ target: { value } }) => {
    setAddress(value);
  }, []);

  const onSave = useCallback(() => {
    if (!organization || !contactEmail || !description || !phoneNumber || phoneNumber.length < 5) {
      setErrors({
        organization: !organization,
        contactEmail: !contactEmail,
        description: !description,
        phoneNumber: !phoneNumber || phoneNumber.length < 5,
      });
      context({ message: MESSAGE.WARNING_FILL_REQUIRED_DATA, variant: 'warning' });
      return;
    }

    setLoading(true);
    setErrors({});
    const newData = {
      ...user, name, unp, organization, contactEmail, phoneNumber, description, address,
    };
    putUser(newData)
      .then(() => {
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        dispatchSaveUser(newData);
        onClose();
      })
      .catch((error) => {
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => setLoading(false));
  }, [address, contactEmail, description, phoneNumber, context, dispatchSaveUser, onClose, errors, user, name, unp, organization]);

  return (
    <ModalWindow
      loading={loading}
      header={TEXT.HEADER.PERSONAL_DATA}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box my={4} className="flex-center" flexDirection="column">
        <Grid container spacing={5} justify="center">
          <Grid
            container
            item
            xs={6}
            spacing={2}
            direction="column"
            alignItems="center"
            style={{ minWidth: 345 }}
          >
            <Grid item>
              <Box fontWeight="fontWeightBold">
                Приватные данные
              </Box>
            </Grid>
            <Grid item container>
              <CustomInput
                fullWidth
                required
                disabled
                value={user.email}
                label={TEXT.LOGIN_FORM.LABEL.USERNAME}
              />
            </Grid>
            <Grid item container>
              <CustomInput
                fullWidth
                id="unp"
                error={!!errors.unp}
                value={unp}
                label={TEXT.UNP}
                onChange={handleUnpChange}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={6}
            spacing={2}
            direction="column"
            alignItems="center"
            style={{ minWidth: 345 }}
          >
            <Grid item>
              <Box fontWeight="fontWeightBold">
                Публичные данные
              </Box>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6} container>
                <CustomInput
                  maxLength={40}
                  fullWidth
                  required
                  id="name"
                  error={!!errors.name}
                  value={name}
                  label={TEXT.LOGIN_FORM.LABEL.NAME}
                  onChange={handleNameChange}
                />
              </Grid>
              <Grid item xs={6} container>
                <CustomInput
                  required
                  maxLength={40}
                  fullWidth
                  id="organization"
                  error={!!errors.organization}
                  value={organization}
                  label={TEXT.ORGANIZATION}
                  onChange={handleOrganizationChange}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={6} container>
                <CustomInput
                  required
                  maxLength={40}
                  fullWidth
                  id="contactEmail"
                  error={!!errors.contactEmail}
                  value={contactEmail}
                  label={TEXT.CONTACT_EMAIL}
                  onChange={handleContactEmailChange}
                />
              </Grid>
              <Grid item xs={6} container>
                <PhoneField
                  inputExtraProps={{
								  required: true,
								  error: !!errors.phoneNumber,
                  }}
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <TextArea
                required
                error={!!errors.address}
                fullWidth
                rows={2}
                value={address}
                onChange={handleAddressChange}
                label={TEXT.ADDRESS}
              />
            </Grid>
            <Grid item container>
              <TextArea
                required
                error={!!errors.description}
                fullWidth
                rows={3}
                value={description}
                onChange={handleDescriptionChange}
                label={TEXT.DESCRIPTION}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="flex-center">
        <Button
          disabled={loading}
          onClick={onSave}
        >
          {TEXT.BUTTON.SAVE}
        </Button>
      </Box>
    </ModalWindow>
  );
}

export default PersonalDataContainer(PersonalData);
