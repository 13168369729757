import ColumnName from '@/enum/ColumnName';

const ColumnDataKey = {
  [ColumnName.CODE]: 'code',
  [ColumnName.NAME]: 'name',
  [ColumnName.CATEGORY]: 'category',
  [ColumnName.MANUFACTURER]: 'manufacturer',
  [ColumnName.SUPPLIER_NAME]: 'supplierName',
  [ColumnName.UNIT]: 'unitName',
  [ColumnName.PRODUCING_DATE]: 'producingDate',
  [ColumnName.SHELF_LIFE]: 'shelfLife',
  [ColumnName.PRICE_NO_VAT]: 'priceNoVat',
  [ColumnName.SUM_NO_VAT]: 'sumNoVat',
  [ColumnName.VAT]: 'vat',
  [ColumnName.PRICE]: 'price',
  [ColumnName.VAT_SUM]: 'vatSum',
  [ColumnName.SUM]: 'sum',
  [ColumnName.COUNT]: 'count',
  [ColumnName.NOTE]: 'note',
  [ColumnName.STORED_COUNT]: 'storedCount',
  [ColumnName.RESERVED_COUNT]: 'reservedCount',
  [ColumnName.LEFT_COUNT]: 'leftCount',
  [ColumnName.COUNT_IN_TRANSIT]: 'countInTransit',
  [ColumnName.SUPPLIER_PRICE]: 'supplierPrice',
  [ColumnName.RECEIVING_DATE]: 'receivingDate',
};

export default ColumnDataKey;
