import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import tinycolor from 'tinycolor2';

interface Props {
  color: string;
}

const ColoredBox: FC<Props> = ({
  color,
}) => (
  <Box
    mr={1}
    style={{
			  background: color,
			  borderRadius: '2px',
			  color: tinycolor(color).isDark() ? 'white' : 'black',
			  width: 20,
			  height: 20,
			  border: '1px solid gray',
    }}
		/>
);

export default ColoredBox;
