import React, { FC, memo } from 'react';
import Divider from '@/components/atoms/Divider/Divider';
import HeaderMenu from '@/components/atoms/HeaderMenu/HeaderMenu';
import HeaderLogo from '@/components/atoms/HeaderLogo';
import { useSelector } from 'react-redux';
import { isDropdownEnabled, selectCompanyUserInfos } from '@/redux/selectors/companySelector';
import CompanyUserSelect from '@/components/molecules/CompanyUserSelect';
import { selectUser } from '@/redux/selectors/applicationSelector';

const Header: FC = () => {
  const user = useSelector(selectUser);
  const companyUsers = useSelector(selectCompanyUserInfos);
  const isEnabled = useSelector(isDropdownEnabled);

  return (
    <div className="header">
      <HeaderLogo />
      <div className="flex-center m-10">
        {isEnabled && !!companyUsers.length
          ? <CompanyUserSelect />
          : <span className="bold header-text">{user.name}</span>}
        <Divider className="white-divider" />
        <HeaderMenu />
      </div>
    </div>
  );
};

export default memo(Header);
