import React, { FC } from 'react';
import { TEXT } from '@/utils/Text';

const FileDescriptionPanel: FC = () => (
  <div>
    <div className="mb-10">
      Вы можете загрузить весь ваш ассортимент с помощью одного Excel файла.
    </div>
    <div>Требования к файлу:</div>
    <ul>
      <li>допустимые форматы .xls, .xlsx</li>
      <li>первая строка является заглавной и не учитывается при обработке</li>
      <li>
        строгий порядок столбцов:
        <ol>
          <li>
            {TEXT.COLUMN.HEADER.NAME}
            {' '}
            (строка)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.CATEGORY}
            {' '}
            (строка)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.SUPPLIER}
            {' '}
            (строка)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.SUPPLIER_PRICE}
            {' '}
            (число)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.VAT}
            {' '}
            (число)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.PRICE_WITH_VAT}
            {' '}
            (число)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.UNIT}
            {' '}
            (строка)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.SHELF_LIFE}
            {' '}
            (целое число)
          </li>
          <li>
            {TEXT.COLUMN.HEADER.MANUFACTURER}
            {' '}
            (строка)
          </li>
        </ol>
      </li>
      <li>максимальный размер файла - 1 MB</li>
    </ul>
  </div>
);

export default FileDescriptionPanel;
