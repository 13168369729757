import { TEXT } from '@/utils/Text';
import React, { useMemo } from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';

const useEmailColumn = (
  column?,
  error?,
) => useMemo(() => ({
  width: 300,
  hidden: !!column?.hidden,
  title: TEXT.CONTACT_EMAIL,
  field: 'contactEmail',
  editComponent: (props) => (
    <CustomInput
      error={error}
      maxLength={40}
      id="contactEmail"
      label={TEXT.CONTACT_EMAIL}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value || ''}
    />
  ),
}), [error, column]);

export default useEmailColumn;
