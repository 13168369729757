import React, {
  FC, memo, useCallback, useEffect, useRef, useState,
} from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import CheckboxList from '@/components/atoms/CheckboxList/CheckboxList';
import TableBuilder from '@/components/organisms/TableBuilder';
import DocumentOutputMenu
  from '@/components/organisms/SaleExportModal/DocumentOutputMenu';
import { columnToOption } from '@/utils/toOption';
import { Box } from '@material-ui/core';
import { ExportTableColumn } from '@/interfaces/ExportTable';
import { ExportModalTypes } from '@/components/organisms/ExportModal/exportModal.types';
import Typography from '@/components/atoms/Typography';
import clsx from 'clsx';
import { useStyles } from '@/components/organisms/SaleExportModal/saleExportModal.styles';
import IconButton from '@/components/atoms/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ExportModal: FC<ExportModalTypes> = ({
  columnNames,
  records,
  onClose,
  isOpen,
  disableTableFooter,
}:ExportModalTypes) => {
  const classes = useStyles();

  const [columns, setColumns] = useState([] as ExportTableColumn[]);
  const [fullscreen, setFullscreen] = useState(false);

  const tableRef = useRef(null);

  useEffect(() => {
    setColumns(columnNames.map(
      (columnName) => columnToOption(columnName, true),
    ));
  }, [columnNames]);

  const onColumnChange = (value) => {
    setColumns((previousState) => previousState.map(
      (column) => (column.value === value
        ? { ...column, checked: !column.checked }
        : column),
    ));
  };

  const onPreviewClick = useCallback(() => {
    setFullscreen(true);
  }, []);

  const handleFullscreenClose = useCallback((event) => {
    event.stopPropagation();
    setFullscreen(false);
  }, []);

  return (
    <ModalWindow
      hideCloseIcon={fullscreen}
      isOpen={isOpen}
      onClose={onClose}
      header="Экспорт"
    >
      <Box className={classes.container}>
        <div className="flex-column-container">
          <div className={classes.paper}>
            <Typography className={classes.title} component="legend">Столбцы</Typography>
            <CheckboxList
              onChange={onColumnChange}
              list={columns}
              autoHeight
            />
          </div>
          <div className="flex-column-container">
            <div className={classes.paper}>
              <Typography className={classes.title} component="legend">Отправить</Typography>
              <DocumentOutputMenu
                disableEmail
                selectedDocument={{ columns }}
                tableRef={tableRef}
              />
            </div>
          </div>
        </div>
        <Box className={clsx('d-flex', classes.paper)}>
          <Box
            className={clsx({
              'full-size-preview': fullscreen,
              [classes.preview]: !fullscreen,
            })}
            onClick={onPreviewClick}
          >
            {fullscreen && (
              <IconButton
                className={classes.fullscreenBtn}
                onClick={handleFullscreenClose}
              >
                <CloseIcon />
              </IconButton>
            )}
            <div
              className={clsx({ 'page-fullscreen': fullscreen })}
              size="A4"
              ref={tableRef}
            >
              <TableBuilder
                disableTableFooter={disableTableFooter}
                rows={records}
                columns={columns.filter((option) => option.checked)}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </ModalWindow>
  );
};

export default memo(ExportModal);
