export default {
  materialTable: {
    body: {
      emptyDataSourceMessage: 'Нет записей',
      addTooltip: 'Добавить',
      deleteTooltip: 'Удалить',
      editTooltip: 'Изменить',
      editRow: {
        deleteText: 'Вы уверены, что хотите удалить эту запись?',
        cancelTooltip: 'Отменить',
        saveTooltip: 'Сохранить',
      },
    },
    header: {
      actions: '',
    },
    pagination: {
      labelDisplayedRows: '{from}-{to} из {count}',
      labelRowsSelect: 'записей',
      labelRowsPerPage: 'Записей на странице',
      firstAriaLabel: 'Первая страница',
      firstTooltip: 'Первая страница',
      previousAriaLabel: 'Предыдущая страница',
      previousTooltip: 'Предыдущая страница',
      nextAriaLabel: 'Следующая страница',
      nextTooltip: 'Следующая страница',
      lastAriaLabel: 'Последняя страница',
      lastTooltip: 'Последняя страница',
    },
    toolbar: {
      addRemoveColumns: 'Добавить/удалить столбцы',
      nRowsSelected: '{0} выбран(о)',
      showColumnsTitle: 'Показать столбцы',
      showColumnsAriaLabel: 'Показать столбцы',
      exportTitle: 'Экспорт',
      exportAriaLabel: 'Экспорт',
      exportName: 'Экспорт CSV',
      searchTooltip: 'Найти',
      searchPlaceholder: 'Найти',
    },
  },
};
