import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { SketchPicker } from 'react-color';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import tinycolor from 'tinycolor2';
import { TEXT } from '@/utils/Text';
import InputAdornment from '@material-ui/core/InputAdornment';
import ColoredBox from '@/components/atoms/ColoredBox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Box } from '@material-ui/core';

interface Props {
  onChange(color: string): void;

  color: string;
  required: boolean;
  error?: string | boolean;
  id?: string;
}

const ColorInput: FC<Props> = ({
								   onChange,
								   color,
								   required,
								   error,
							   }) => {
  const [isOpened, setOpened] = useState(false);
  const [hasError, setError] = useState(false);

  const handleChange = useCallback((color) => {
    onChange(color.hex);
  }, [onChange]);

  const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const handleInputChange = useCallback((event) => {
    onChange(event.target.value);
  }, [onChange]);

  useEffect(() => {
    if (color) {
      setError(!tinycolor(color).isValid());
    }
  }, [color]);

  const memoizedInputProps = useMemo(() => ({
    startAdornment: (
      <InputAdornment position="start">
        <ColoredBox
          color={color}
        />
      </InputAdornment>
    ),
  }), [color]);

  return (
    <div>
      <CustomInput
        error={error || hasError}
        label={TEXT.COLOR}
        required={required}
        fullWidth
        InputProps={memoizedInputProps}
        onClick={handleOpen}
        onChange={handleInputChange}
        value={color}
        maxLength={40}
      />
      {isOpened && (
      <ClickAwayListener onClickAway={handleClose}>
        <Box
          zIndex={2}
          position="absolute"
        >
          <SketchPicker color={color} onChange={handleChange} />
        </Box>
      </ClickAwayListener>
      )}
    </div>
  );
};

export default ColorInput;
