import React, { FC } from 'react';
import {
  FormControl, InputLabel, Select, SelectProps, WithStyles,
} from '@material-ui/core';

const CustomSelect: FC<WithStyles & SelectProps> = ({
  disabled,
  classes,
  id,
  labelId,
  children,
  autoWidth,
  defaultValue,
  displayEmpty,
  IconComponent,
  input,
  inputProps,
  label,
  labelWidth,
  MenuProps,
  multiple,
  native,
  onChange,
  onClose,
  onOpen,
  open,
  renderValue,
  SelectDisplayProps,
  value,
  variant,
  fullWidth,
}) => (
  <FormControl fullWidth={fullWidth}>
    <InputLabel shrink id={labelId}>
      {label}
    </InputLabel>
    <Select
      fullWidth={fullWidth}
      id={id}
      classes={classes}
      disabled={disabled}
      autoWidth={autoWidth}
      labelId={labelId}
      defaultValue={defaultValue}
      displayEmpty={displayEmpty}
      IconComponent={IconComponent}
      input={input}
      inputProps={inputProps}
      label={label}
      labelWidth={labelWidth}
      MenuProps={MenuProps}
      multiple={multiple}
      native={native}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      renderValue={renderValue}
      SelectDisplayProps={SelectDisplayProps}
      value={value}
      variant={variant}
    >
      {children}
    </Select>
  </FormControl>
);

export default CustomSelect;

CustomSelect.defaultProps = {
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    getContentAnchorEl: null,
  },
};
