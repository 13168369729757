import { SupplierInfo } from '@/interfaces/SupplierInfo';
import { createAction } from 'redux-actions';

enum Type {
  ADD = 'ADD_SUPPLIER_INFO',
  SET = 'SET_SUPPLIER_INFO',
  UPDATE_ONE = 'UPDATE_ONE_SUPPLIER_INFO',
  DELETE_ONE = 'DELETE_ONE_SUPPLIER_INFO'
}

const set = createAction<SupplierInfo[]>(Type.SET);
const update = createAction<SupplierInfo>(Type.UPDATE_ONE);
const remove = createAction<string>(Type.DELETE_ONE);
const add = createAction<SupplierInfo>(Type.ADD);

export const SupplierInfoActions = {
  Type,
  set,
  update,
  remove,
  add,
};
