import React from 'react';
import ruLocale from 'date-fns/locale/ru';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { KeyboardDateTimePicker as MuiKeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { TEXT } from '@/utils/Text';

moment.locale('ru');

const KeyboardDateTimePicker = (
  {
    onChange,
    value,
    label,
    ...props
  },
) => (
  <MuiPickersUtilsProvider
    libInstance={moment}
    utils={DateFnsUtils}
    locale={ruLocale}
  >
    <MuiKeyboardDateTimePicker
      {...props}
      ampm={false}
      autoOk
      variant="inline"
      format="dd/MM/yyyy HH:mm:ss"
      margin="normal"
      label={label}
      value={value}
      onChange={onChange}
      invalidDateMessage={TEXT.INVALID_VALUE}
      maxDateMessage={TEXT.INVALID_VALUE}
      minDateMessage={TEXT.INVALID_VALUE}
    />
  </MuiPickersUtilsProvider>
);

export default KeyboardDateTimePicker;
