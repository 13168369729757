import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import ColorInput from '@/components/atoms/ColorInput';
import { Box } from '@material-ui/core';
import ColoredBox from '@/components/atoms/ColoredBox';
import Typography from '@/components/atoms/Typography';

const useColorColumn = (
  error,
) => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <ColorInput
      required
      color={value}
      error={error}
      onChange={(color) => onChange(color)}
    />
  ), [error]);

  return useMemo(() => ({
    title: TEXT.COLOR,
    field: 'color',
    editComponent: handleEditComponent,
    render: ({ color }) => (
      <Box
        display="flex"
        alignItems="center"
      >
        <ColoredBox color={color} />
        <Typography>{color}</Typography>
      </Box>
    ),
  }), [handleEditComponent]);
};

export default useColorColumn;
