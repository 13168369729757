import React, { forwardRef } from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './style';

interface Props {
  showCount?: boolean;
  count?: number;
  unitName?: string;
  name: string;
  manufacturer?: string;
  category?: string;
  supplierName?: string;
}

const ProductOptionLabel = forwardRef<HTMLDivElement, Props>((
  {
    showCount = false,
    name,
    count,
    unitName,
    manufacturer,
    category,
    supplierName,
    ...other
  },
  ref,
) => {
  const classes = useStyles();
  return (
    <div ref={ref} {...other} className={classes.container}>
      <span className="text-overflow-ellipsis f-1 mr-5">
        {name}
        <span className={classes.titleBelow}>
          <span className={classes.titlePart}>
            {manufacturer || '-'}
          </span>
          <span className={classes.divider}>|</span>
          <span className={classes.titlePart}>
            {category || '-'}
          </span>
          <span className={classes.divider}>|</span>
          <span className={classes.titlePart}>
            {supplierName || '-'}
          </span>
        </span>
      </span>
      {showCount && (
      <span className="store-item-option-label-balance">
        {`${count} ${unitName || ''}`}
      </span>
      )}
    </div>
  );
});

export default ProductOptionLabel;
