import React, { FC } from 'react';
import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { Box } from '@material-ui/core';
import Typography from '@/components/atoms/Typography';
import { TEXT } from '@/utils/Text';
import Button from '@/components/atoms/Button';
import useStyles from './style';

interface Props {
  isOpened: boolean;
  onClose(): void;
  loading: boolean;
  onSyncProducts(): void;
}

const AskToSyncProductsModal: FC<Props> = ({
  isOpened,
  onClose,
  loading,
  onSyncProducts,
}) => {
  const classes = useStyles();
  return (
    <ModalWindow
      classes={classes}
      isOpen={isOpened}
      onClose={onClose}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Box p={2}>
          <Typography variant="subtitle3">{TEXT.SYNC_PRODUCTS_IF_WANT_TO_ADD_TO_STORE}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
          <Box m={1}>
            <Button
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={onSyncProducts}
            >
              {TEXT.UPDATE_PRODUCT_LIST}
            </Button>
          </Box>
          <Box m={1}>
            <Button
              color="primary"
              variant="outlined"
              disabled={loading}
              onClick={onClose}
            >
              {TEXT.CANCEL}
            </Button>
          </Box>
        </Box>
      </Box>
    </ModalWindow>
  );
};

export default AskToSyncProductsModal;
