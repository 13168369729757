import { compareByField } from '@/utils/comparator';
import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import RestitutionItemEditInput from '@/components/molecules/RestitutionItemEditInput';

const useRestitutionItemColumn = (
  saleId,
  error,
) => {
  const handleEditComponent = useCallback(({ rowData, onRowDataChange }) => (
    <RestitutionItemEditInput
      saleId={saleId}
      rowData={rowData}
      onRowDataChange={onRowDataChange}
      error={error}
    />
  ), [saleId, error]);

  return useMemo(() => (
    {
      removable: false,
      customSort: compareByField('name'),
      customFilterAndSearch: (value, item) => item.name.toUpperCase().includes(value.toUpperCase()),
      title: TEXT.COLUMN.HEADER.NAME,
      field: 'saleItemId',
      render: (item) => item.name,
      defaultSort: 'asc',
      editComponent: handleEditComponent,
    }
  ), [handleEditComponent]);
};

export default useRestitutionItemColumn;
