import React, { useCallback, useEffect, useState } from 'react';
import { TEXT } from '@/utils/Text';
import 'react-dropdown/style.css';
import StoreTab from '@/components/pages/tabs/StoreTab';
import ClientTab from '@/components/pages/tabs/ClientsTab';
import SupplierOrderTab from '@/components/pages/tabs/SupplierOrderTab';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  AccountBalance,
  Assessment,
  ExitToApp,
  Group,
  ListAlt,
  LocalShipping,
  SettingsApplications,
  ShoppingBasket,
  ShoppingCart,
  Store, SupervisedUserCircle,
} from '@material-ui/icons';
import SupplierTab from '@/components/pages/tabs/SupplierTab';
import { useSelector } from 'react-redux';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import ProductTab from '@/components/pages/tabs/ProductTab';
import ReportTab from '@/components/pages/tabs/ReportTab';
import SaleTab from '@/components/pages/tabs/SaleTab/SaleTab';
import CompanyTab from '@/components/pages/tabs/CompanyTab';
import TabPanel from '@/components/molecules/TabPanel';
import { Box, Fade, Slide } from '@material-ui/core';
import VendorTable from '@/components/organisms/VendorTable';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: 80,
    height: '100%',
    background: '#f0f0f0',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}));

enum Index {
  SUPPLIERS = 0,
  PRODUCTS = 1,
  ORDERS = 2,
  STORE = 3,
  SALES = 4,
  CLIENTS = 5,
  REPORTS = 6,
  COMPANY = 7,
  VENDORS = 8,
  UNITS = 9,
}

function TabsCreator() {
  const classes = useStyles();

  const [selectedTabIndex, setSelectedTabIndex] = useState(Index.SUPPLIERS);
  const [adminTabs, setAdminTabs] = useState(false);
  const [selectedAdminTabIndex, setSelectedAdminTabIndex] = useState(Index.VENDORS);

  const storeEnabled = useSelector(isStoreEnabled);

  useEffect(() => {
    if (selectedTabIndex === Index.STORE && !storeEnabled) {
      setSelectedTabIndex(0);
    }
  }, [selectedTabIndex, storeEnabled]);

  const handleChange = useCallback((event, newValue) => {
    if (newValue === selectedTabIndex) return;

    setSelectedTabIndex(newValue);
  }, [selectedTabIndex]);

  const handleAdminTabsChange = useCallback((event, newValue) => {
    setSelectedAdminTabIndex(newValue);
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Box display="flex" justifyContent="space-between" height={72}>
          <Box position="relative">
            <Fade
              mountOnEnter
              unmountOnExit
              timeout={1000}
              in={!adminTabs}
            >
              <Tabs
                style={{ position: 'absolute', marginRight: 50 }}
                value={selectedTabIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  value={Index.SUPPLIERS}
                  label={TEXT.TAB.SUPPLIERS}
                  icon={<LocalShipping />}
                />
                <Tab
                  value={Index.PRODUCTS}
                  label={TEXT.TAB.PRODUCTS}
                  icon={<ListAlt />}
                />
                <Tab
                  value={Index.ORDERS}
                  label={TEXT.TAB.SUPPLIER_ORDERS}
                  icon={<ShoppingCart />}
                />
                {storeEnabled && (
                <Tab
                  value={Index.STORE}
                  label={TEXT.TAB.STORE}
                  icon={<Store />}
                />
                )}
                <Tab
                  value={Index.SALES}
                  label={TEXT.TAB.SALES}
                  icon={<ShoppingBasket />}
                />
                <Tab
                  value={Index.CLIENTS}
                  label={TEXT.TAB.CLIENTS}
                  icon={<Group />}
                />
                <Tab
                  value={Index.REPORTS}
                  label={TEXT.TAB.REPORTS}
                  icon={<Assessment />}
                />
              </Tabs>
            </Fade>
          </Box>
          <Slide
            mountOnEnter
            unmountOnExit
            direction="right"
            timeout={1000}
            in={adminTabs}
          >
            <Tabs
              style={{ position: 'absolute' }}
              value={selectedAdminTabIndex}
              onChange={handleAdminTabsChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                value={Index.VENDORS}
                label={TEXT.TAB.VENDORS}
                icon={<SupervisedUserCircle />}
              />
              <Tab
                value={Index.COMPANY}
                label={TEXT.TAB.COMPANY}
                icon={<AccountBalance />}
              />
            </Tabs>
          </Slide>
          <Tab
            onClick={() => setAdminTabs(!adminTabs)}
            textColor="primary"
            icon={<ExitToApp fontSize="large" />}
          />
        </Box>
      </AppBar>

      {adminTabs ? (
        <>
          <TabPanel
            value={selectedAdminTabIndex}
            index={Index.COMPANY}
          >
            <CompanyTab />
          </TabPanel>
          <TabPanel
            value={selectedAdminTabIndex}
            index={Index.VENDORS}
          >
            <VendorTable />
          </TabPanel>
        </>
      )
        : (
          <>
            <TabPanel
              value={selectedTabIndex}
              index={Index.SUPPLIERS}
            >
              <SupplierTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.PRODUCTS}
            >
              <ProductTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.ORDERS}
            >
              <SupplierOrderTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.STORE}
            >
              <StoreTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.SALES}
            >
              <SaleTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.CLIENTS}
            >
              <ClientTab />
            </TabPanel>
            <TabPanel
              value={selectedTabIndex}
              index={Index.REPORTS}
            >
              <ReportTab />
            </TabPanel>
          </>
        )}
    </div>
  );
}

export default TabsCreator;
