import React, { FC } from 'react';
import { Box, Checkbox } from '@material-ui/core';
import { MultipleAutocompleteOptionProps } from '@/components/molecules/MultipleAutocompleteOption/attributeOption.types';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const MultipleAutocompleteOption: FC<MultipleAutocompleteOptionProps> = ({
  selected,
  name,
  prefix,
}) => (
  <Box
    display="flex"
    alignItems="center"
    className="full-width"
  >
    <Box mr={1}>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon color="primary" fontSize="small" />}
        color="primary"
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={selected}
      />
    </Box>
    {prefix}
    {name}
  </Box>
);

export default MultipleAutocompleteOption;
