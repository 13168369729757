import ColumnName from '@/enum/ColumnName';
import TEXT from '@/utils/Text';

export const ColumnLabel = {
  [ColumnName.NAME]: 'Наименование товара',
  [ColumnName.CATEGORY]: 'Категория',
  [ColumnName.MANUFACTURER]: TEXT.COLUMN.HEADER.MANUFACTURER,
  [ColumnName.SUPPLIER_NAME]: 'Поставщик',
  [ColumnName.UNIT]: 'Ед. изм.',
  [ColumnName.PRODUCING_DATE]: 'Дата производства',
  [ColumnName.SHELF_LIFE]: 'Срок годности',
  [ColumnName.PRICE_NO_VAT]: 'Цена, руб. коп.',
  [ColumnName.SUM_NO_VAT]: 'Стоимость, руб. коп.',
  [ColumnName.VAT]: 'Ставка НДС, %',
  [ColumnName.PRICE]: 'Цена с НДС, руб. коп.',
  [ColumnName.VAT_SUM]: 'Сумма НДС, руб. коп.',
  [ColumnName.SUM]: 'Стоимость с НДС, руб. коп.',
  [ColumnName.COUNT]: 'Кол-во',
  [ColumnName.NOTE]: 'Приме-чание',
  [ColumnName.STORED_COUNT]: 'Кол-во',
  [ColumnName.RESERVED_COUNT]: 'Резерв',
  [ColumnName.LEFT_COUNT]: 'Остаток',
  [ColumnName.COUNT_IN_TRANSIT]: 'Кол-во в пути',
  [ColumnName.SUPPLIER_PRICE]: 'Цена поставщика',
  [ColumnName.RECEIVING_DATE]: 'Дата получения',
};

export default ColumnLabel;
