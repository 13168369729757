import React, { FC, useCallback } from 'react';
import { useStyles } from '@/components/molecules/ReportHeaderItem/reportHeaderItem.styles';
import Typography from '@/components/atoms/Typography';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ChartType } from '@/components/organisms/IncomeSubTab';

interface Props {
  type: ChartType;
  onChange: (type: ChartType) => void;
  header: string | React.ReactNode;
  value: string | React.ReactNode;
  selected: ChartType;
}

const ReportHeaderItem: FC<Props> = ({
  header,
  value,
  selected,
  type,
  onChange,
}) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    onChange(type);
  }, [onChange, type]);

  return (
    <Box
      onClick={handleClick}
      className={clsx({ [classes.selected]: selected === type }, classes.balanceItem)}
    >
      <Typography className={classes.header}>
        {header}
      </Typography>
      <Box ml={3}>
        {value}
      </Box>
    </Box>
  );
};

export default ReportHeaderItem;
