import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import React from 'react';

function TtnFooter({
  editable,
  sale,
}) {
  return (
    <div className="mt-10">
      <div className="flex-column-container">
        <div className="d-flex">
          <span className="mr-5">Всего сумма НДС:</span>
          <span>{sale.writtenVatSum}</span>
        </div>
        <div className="d-flex mb-10">
          <span className="mr-5">Всего стоимость с НДС:</span>
          <span>{sale.writtenSum}</span>
        </div>
        <EditableDocumentInput
          label="Отпуск разрешил"
          placeholder="________________________________"
          editing={editable}
        />
        <EditableDocumentInput
          label="Сдал грузоотправитель"
          placeholder="________________________________"
          editing={editable}
        />
        <EditableDocumentInput
          label="Товар к доставке принял"
          placeholder="_______________________________"
          editing={editable}
        />
        <EditableDocumentInput
          label="Принял грузополучатель"
          placeholder="________________________________"
          editing={editable}
        />
      </div>
    </div>
  );
}

export default TtnFooter;
