import { TEXT } from '@/utils/Text';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

export const usePriceNoVatColumn = (
  column,
) => {
  const handleEditComponent = useCallback(({ rowData, onChange }) => (
    <CustomNumberInput
      disabled
      label={TEXT.COLUMN.HEADER.PRICE}
      value={rowData.priceNoVat}
      decimalScale={2}
      onChange={onChange}
      suffix={` ${currency}`}
    />
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: buildCurrencyHeader(TEXT.COLUMN.HEADER.PRICE),
    field: 'priceNoVat',
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [column, handleEditComponent]);
};

export default usePriceNoVatColumn;
