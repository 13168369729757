import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import useReceivingTimeColumn from '@/hook/columns/useReceivingTimeColumn';
import { useSelector } from 'react-redux';
import ID from '@/utils/id';
import { isCustomTableLoading } from '@/redux/selectors/customTableSelector';
import { buildCurrencyHeader } from '@/app/applicationSettings';
import { TEXT } from '@/utils/Text';
import {
  ClientOperation, ClientOperationType, CreditOperation, DebitOperation,
} from '@/interfaces/ClientOperation';

interface ReconciliationTableProps {
  debits: DebitOperation[];
  credits: CreditOperation[];
  loading: boolean;
}

const ReconciliationTable: FC<ReconciliationTableProps> = ({
  credits,
  debits,
  loading,
}) => {
  const [rows, setRows] = useState<ClientOperation[]>([]);

  const customTableLoading = useSelector(isCustomTableLoading);

  const receivingTimeColumn = useReceivingTimeColumn();

  useEffect(() => {
    setRows([...debits, ...credits]);
  }, [debits, credits]);

  const memoizedColumns = useMemo(() => (
    [
      receivingTimeColumn,
      {
        removable: false,
        title: TEXT.DOCUMENT,
        field: 'documentName',
      },
      {
        removable: false,
        title: buildCurrencyHeader(TEXT.DEBIT),
        field: 'sum',
        render: (row) => (row.operationType == ClientOperationType.DEBIT ? row.sum : ''),
      },
      {
        removable: false,
        title: buildCurrencyHeader(TEXT.CREDIT),
        field: 'sum',
        render: (row) => (row.operationType == ClientOperationType.CREDIT ? row.sum : ''),
      },
    ]
  ), [receivingTimeColumn]);

  const memoizedOptions = useMemo(() => ({
    search: false,
    columnsButton: false,
  }), []);

  return (
    <StyledTable
      tableKey={ID.RECONCILIATION_TABLE}
      columns={memoizedColumns}
      data={rows}
      loading={loading || customTableLoading}
      options={memoizedOptions}
    />
  );
};

export default ReconciliationTable;
