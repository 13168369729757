const tableStyles = {
  header: {
    small: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 120,
      display: 'inline-block',
    },
    date: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      maxWidth: 120,
    },
  },
  cell: {
    h1: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 150,
    },
    date: {
      maxWidth: 80,
    },
  },
};

export default tableStyles;
