import React, { useCallback, useState } from 'react';
import { TEXT } from '@/utils/Text';
import { recoverPassword } from '@/utils/fetch';
import { TextField } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import Path from '@/enum/Path';
import EmailConfirmingText from '@/app/EmailConfirmingText';
import RegExpPattern from '@/constants/RegExp';
import { useHistory } from 'react-router';

function ForgetPasswordForm() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onUsernameChange = useCallback((event) => {
    setEmail(event.target.value);
  }, []);

  const onSendButtonClick = useCallback(() => {
    setLoading(true);
    recoverPassword(email)
      .then(() => {
        setIsSent(true);
        setErrorMessage('');
      })
      .catch((response) => {
        setIsSent(false);
        setErrorMessage(response.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email]);

  const onForgetPasswordFormCancel = useCallback(() => {
    history.push(Path.LOGIN);
  }, [history]);

  return (
    <div className="login-form">
      {isSent
        ? (
          <EmailConfirmingText
            email={email}
          />
        )
        : (
          <div className="login-page-top-container">
            <TextField
              label={TEXT.LOGIN_FORM.LABEL.USERNAME}
              type="e-mail"
              onChange={onUsernameChange}
              name="email"
              maxLength={40}
              value={email}
            />
          </div>
        )}
      {errorMessage ? <span className="invalid-input-message">{errorMessage}</span> : ''}
      <div className="login-page-btn-bar">
        {isSent ? '' : (
          <Button
            disabled={!RegExpPattern.E_MAIL.test(email) || loading}
            onClick={onSendButtonClick}
            className="login-page-btn"
          >
            {TEXT.LOGIN_FORM.BUTTON.RESTORE}
          </Button>
        )}
        <Button
          className="login-page-btn"
          onClick={onForgetPasswordFormCancel}
        >
          {TEXT.LOGIN_FORM.BUTTON.BACK}
        </Button>
      </div>
    </div>
  );
}

export default ForgetPasswordForm;
