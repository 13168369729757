import ModalWindow from '@/components/molecules/ModalWindow/ModalWindow';
import { Box } from '@material-ui/core';
import Button from '@/components/atoms/Button';
import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { MESSAGE } from '@/utils/message';
import ApplicationContext from '@/app/snackbarContext';
import { TEXT } from '@/utils/Text';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import InputSelect from '@/components/molecules/CustomAutocomplete';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import MarkupInput from '@/components/atoms/MarkupInput';
import UnitEditComponent from '@/components/molecules/UnitEditComponent';
import { selectSupplierInfos } from '@/redux/selectors/supplierInfoSelector';
import { SupplierInfoOption, supplierToOption } from '@/utils/toOption';
import { getProductById, postProduct, putProduct } from '@/utils/fetch';
import validateProduct from '@/service/validator/validateProduct';
import { round } from '@/utils/Utils';
import ProductPostRequest from '@/interfaces/requests/ProductPostRequest';
import ProductUpdateRequest from '@/interfaces/requests/ProductUpdateRequest';
import PriceFormationTypeSelect from '@/components/molecules/PriceFormationTypeSelect';
import { ProductDialogProps, ProductInfoErrors } from './productActionDialog.types';

const ProductActionModal: FC<ProductDialogProps> = ({
  isOpened,
  onClose,
  productId,
  onAction,
  priceFormationTypeId,
  defaultErrors = {},
}: ProductDialogProps) => {
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [supplierId, setSupplierId] = useState('');
  const [supplierPrice, setSupplierPrice] = useState(0);
  const [unitId, setUnitId] = useState('');
  const [unitName, setUnitName] = useState('');
  const [category, setCategory] = useState('');
  const [shelfLife, setShelfLife] = useState(0);
  const [vat, setVat] = useState(0);
  const [markup, setMarkup] = useState(0);
  const [price, setPrice] = useState(0);
  const [priceNoVat, setPriceNoVat] = useState(0);
  const [manufacturer, setManufacturer] = useState('');
  const [synchronizingProductId, setSynchronizingProductId] = useState<null | string>(null);
  const [
    selectedPriceFormationTypeId,
    setSelectedPriceFormationTypeId,
  ] = useState<string | undefined>(priceFormationTypeId);
  const [errors, setErrors] = useState<ProductInfoErrors>(defaultErrors);

  const suppliers = useSelector(selectSupplierInfos);

  useEffect(() => {
    setSelectedPriceFormationTypeId(priceFormationTypeId);
  }, [priceFormationTypeId]);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      getProductById(productId, selectedPriceFormationTypeId)
        .then((response) => {
          setName(response.name);
          setSupplierId(response.supplierId);
          setManufacturer(response.manufacturer);
          setSupplierPrice(response.supplierPrice);
          setUnitId(response.unitId);
          setUnitName(response.unitName);
          setCategory(response.category);
          setShelfLife(response.shelfLife);
          setVat(response.vat);
          setMarkup(response.markup);
          setPrice(response.price);
          setPriceNoVat(response.priceNoVat);
          setSynchronizingProductId(response.synchronizingProductId);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedPriceFormationTypeId, productId]);

  const supplierOptions = useMemo(() => (
    suppliers.map(supplierToOption)
  ), [suppliers]);

  const selectedSupplier = useMemo(() => (
    supplierOptions.find((supplierOption) => (
      supplierOption && supplierId === supplierOption.value
    )) || null
  ), [supplierId, supplierOptions]);

  const unitData = useMemo(() => ({
    unitId,
    unitName,
  }), [unitId, unitName]);

  const handleNameChange = useCallback(({ target }) => {
    setName(target.value);
  }, []);

  const handleSupplierChange = useCallback((option: SupplierInfoOption) => {
    if (option) {
      setSupplierId(option.value);
      setManufacturer((prevState) => prevState || option.data.organization);
    } else {
      setSupplierId('');
    }
  }, []);

  const handleSupplierPriceChange = useCallback((newPrice) => {
    setSupplierPrice(newPrice);
    const newPriceNoVat = round(price / (vat / 100 + 1));
    setPriceNoVat(newPriceNoVat);
    setMarkup(round((newPriceNoVat / newPrice - 1) * 100));
  }, [price, vat]);

  const handleVatChange = useCallback((newVat) => {
    setVat(newVat);
    const newPriceNoVat = round(price / (newVat / 100 + 1));
    setPriceNoVat(newPriceNoVat);
    setMarkup(round((newPriceNoVat / supplierPrice - 1) * 100));
  }, [price, supplierPrice]);

  const handlePriceChange = useCallback((newPrice) => {
    setPrice(newPrice);
    const newPriceNoVat = round(newPrice / (vat / 100 + 1));
    setPriceNoVat(newPriceNoVat);
    setMarkup(round((newPriceNoVat / supplierPrice - 1) * 100));
  }, [supplierPrice, vat]);

  const handleCategoryChange = useCallback(({ target }) => {
    setCategory(target.value);
  }, []);

  const handleManufacturerChange = useCallback(({ target }) => {
    setManufacturer(target.value);
  }, []);

  const handleShelfLife = useCallback((value) => {
    setShelfLife(value);
  }, []);

  const handleUnitChange = useCallback((unit) => {
    setUnitId(unit.unitId);
    setUnitName(unit.unitName);
  }, []);

  const onSuccess = useCallback((response) => {
    context({
      message: MESSAGE.SUCCESS_OPERATION,
      variant: 'success',
    });
    if (typeof onAction === 'function') {
      onAction({
        productId: response.id,
        name: response.name,
        price: response.price,
        priceNoVat: response.priceNoVat,
        markup: response.markup,
        supplierPrice: response.supplierPrice,
        supplierId: response.supplierId,
        supplierName: response.supplierName,
        vat: response.vat,
      });
    }
    onClose();
  }, [context, onAction, onClose]);

  const handleSave = useCallback(() => {
    const data: ProductPostRequest = {
      category,
      manufacturer,
      markup,
      name,
      price,
      priceNoVat,
      shelfLife,
      supplierId,
      supplierPrice,
      unitId,
      vat,
      priceFormationTypeId: selectedPriceFormationTypeId,
    };

    const validationErrors = validateProduct(data);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    postProduct(data)
      .then(onSuccess)
      .catch((error) => {
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    manufacturer,
    onSuccess,
    category,
    errors,
    markup,
    name,
    price,
    priceNoVat,
    shelfLife,
    supplierId,
    supplierPrice,
    unitId,
    vat,
    selectedPriceFormationTypeId,
  ]);

  const handleUpdate = useCallback(() => {
    const data: ProductUpdateRequest = {
      id: productId!,
      category,
      manufacturer,
      markup,
      name,
      price,
      priceNoVat,
      shelfLife,
      supplierId,
      supplierPrice,
      unitId,
      vat,
      priceFormationTypeId: selectedPriceFormationTypeId,
    };

    const validationErrors = validateProduct(data);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    putProduct(data)
      .then(onSuccess)
      .catch((error) => {
        if (error && error.field) {
          setErrors({
            ...errors,
            [error.field]: error.message,
          });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    manufacturer,
    onSuccess,
    productId,
    category,
    errors,
    markup,
    name,
    price,
    priceNoVat,
    shelfLife,
    supplierId,
    supplierPrice,
    unitId,
    vat,
    selectedPriceFormationTypeId,
  ]);

  return (
    <ModalWindow
      isOpen={isOpened}
      onClose={onClose}
      header={productId ? TEXT.HEADER.UPDATE_PRODUCT : TEXT.HEADER.CREATE_PRODUCT}
    >
      <Box m={2} minWidth={400}>
        <Box pb={2}>
          <CustomInput
            disabled={loading}
            fullWidth
            error={errors.name}
            id="name"
            label={TEXT.COLUMN.HEADER.NAME}
            required
            style={{ width: 'auto' }}
            onChange={handleNameChange}
            value={name}
          />
        </Box>
        <Box pb={2} display="flex" justifyContent="space-between">
          <Box mr={2} flex={1}>
            <InputSelect
              disabled={!!synchronizingProductId || loading}
              error={errors.supplierId}
              id="supplierId"
              label={TEXT.COLUMN.HEADER.SUPPLIER}
              defaultOptions={supplierOptions}
              value={selectedSupplier}
              onChange={handleSupplierChange}
            />
          </Box>
          <CustomInput
            error={errors.manufacturer}
            width={315}
            id="manufacturer"
            maxLength={40}
            label={TEXT.COLUMN.HEADER.MANUFACTURER}
            onChange={handleManufacturerChange}
            value={manufacturer || ''}
          />
        </Box>
        <Box pb={2} display="flex" justifyContent="space-between" width={650}>
          <CustomInput
            disabled={loading}
            width={315}
            error={errors.category}
            id="category"
            label={TEXT.COLUMN.HEADER.CATEGORY}
            onChange={handleCategoryChange}
            value={category || ''}
          />
          <UnitEditComponent
            disabled={loading}
            onRowDataChange={handleUnitChange}
            rowData={unitData}
            error={errors.unitId}
          />
          <CustomNumberInput
            disabled={loading}
            width={170}
            error={errors.shelfLife}
            id="shelfLife"
            label={TEXT.COLUMN.HEADER.SHELF_LIFE}
            onChange={handleShelfLife}
            value={shelfLife}
            min={0}
            step={1}
            decimalScale={0}
          />
        </Box>
        <Box pb={2} display="flex" justifyContent="space-between" width={650}>
          <CustomNumberInput
            disabled={!!synchronizingProductId || loading}
            width={170}
            error={errors.supplierPrice}
            id="supplierPrice"
            required
            label={TEXT.COLUMN.HEADER.SUPPLIER_PRICE}
            onChange={handleSupplierPriceChange}
            value={supplierPrice}
            min={0}
            step={1}
            decimalScale={2}
            suffix={` ${currency}`}
          />
          <MarkupInput
            width={120}
            disabled
            error={errors.markup}
            value={markup}
          />
          <CustomNumberInput
            width={110}
            disabled={!!synchronizingProductId || loading}
            error={errors.vat}
            id="vat"
            label={TEXT.COLUMN.HEADER.VAT}
            onChange={handleVatChange}
            value={vat}
            min={0}
            step={1}
            decimalScale={2}
            suffix=" %"
          />
          <CustomNumberInput
            disabled={loading}
            required
            label={buildCurrencyHeader(TEXT.COLUMN.HEADER.PRICE_WITH_VAT)}
            width={170}
            error={errors.price}
            id="price"
            min={0}
            step={1}
            decimalScale={2}
            value={price}
            onChange={handlePriceChange}
          />
          <PriceFormationTypeSelect
            selectedValue={selectedPriceFormationTypeId}
            onChange={setSelectedPriceFormationTypeId}
          />
        </Box>
      </Box>
      <Box className="flex-center">
        <Button
          disabled={loading}
          color="primary"
          onClick={productId ? handleUpdate : handleSave}
        >
          {TEXT.BUTTON.SAVE}
        </Button>
      </Box>
    </ModalWindow>
  );
};

export default ProductActionModal;
