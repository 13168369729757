import { TEXT } from '@/utils/Text';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import { round } from '@/utils/Utils';
import { SaleType } from '@/enum/SaleType';
import PriceService from '@/service/PriceService';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

export const usePriceColumn = (
  column,
  error,
  saleType: SaleType,
  disabled = false,
) => {
  const handleEditComponent = useCallback(({ rowData, onRowDataChange, value }) => {
    let {
      supplierPrice,
      count,
      vat = 0,
      markup,
      priceNoVat,
      sum,
    } = rowData;
    return (
      <CustomNumberInput
        disabled={disabled}
        error={error}
        id="price"
        suffix={` ${currency}`}
        label={TEXT.COLUMN.HEADER.PRICE_WITH_VAT}
        onChange={(price) => {
          priceNoVat = round(price / (vat / 100 + 1));
          if (supplierPrice) {
            markup = (priceNoVat / supplierPrice - 1) * 100;
          } else {
            markup = 0;
          }
          if (saleType === SaleType.RETAIL) {
            sum = round(count * price);
          } else if (saleType === SaleType.WHOLESALE) {
            sum = PriceService.calculateSum(supplierPrice, markup, count, vat);
          }
          onRowDataChange({
            ...rowData,
            price,
            vat,
            priceNoVat,
            markup,
            supplierPrice,
            sum,
          });
        }}
        value={value}
        min={0}
        step={1}
        decimalScale={2}
      />
    );
  }, [disabled, error, saleType]);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: buildCurrencyHeader(TEXT.COLUMN.HEADER.PRICE_WITH_VAT),
    field: 'price',
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [handleEditComponent, column]);
};

export default usePriceColumn;
