import { TEXT } from '@/utils/Text';
import { buildCurrencyHeader, currency } from '@/app/applicationSettings';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

const useSumColumn = (
  column,
) => {
  const handleEditComponent = useCallback(({ rowData, onChange }) => (
    <CustomNumberInput
      disabled
      label={TEXT.COLUMN.HEADER.SUM_WITH_VAT}
      value={rowData.sum}
      decimalScale={2}
      onChange={onChange}
      suffix={` ${currency}`}
    />
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: buildCurrencyHeader(TEXT.COLUMN.HEADER.SUM_WITH_VAT),
    field: 'sum',
    initialEditValue: 0,
    editComponent: handleEditComponent,
  }), [column, handleEditComponent]);
};

export default useSumColumn;
