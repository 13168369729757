export enum ClientOperationType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export interface ClientOperation {
  receivingTime: Date;
  operationType: ClientOperationType;
  sum: number;
  documentName: string;
}

export interface DebitOperation extends ClientOperation{
  saleId: string;
}

export interface CreditOperation extends ClientOperation{
  creditId: string;
}
