import Column from './Column';

class ColumnService {
  static parse({ field, hidden }) {
    return new Column(
      field,
      hidden,
    );
  }
}

export default ColumnService;
