import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  wrapper: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#1d4663',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logoWrapper: {
    position: 'fixed',
  },

  logoImage: {
  },

  spinner: {
    display: 'block',
    position: 'relative',
    width: '350px',
    height: '350px',
    borderRadius: '50%',
    border: '8px solid transparent',
    borderTopColor: '#abc8d8',
    borderRightColor: '#abc8d8',
    boxShadow: '0px 0px 12px 6px rgba(0, 0, 0, 0.3)',
    background: '#1d4663',
    animation: '$spin 2s linear infinite', /* Chrome, Firefox 16+, IE 10+, Opera */
  },

  '@keyframes spin': {
    '0%': {
      WebkitTransform: 'rotate(0deg)', /* Chrome, Opera 15+, Safari 3.1+ */
      MsTransform: 'rotate(0deg)', /* IE 9 */
      transform: 'rotate(0deg)', /* Firefox 16+, IE 10+, Opera */
    },
    '100%': {
      WebkitTransform: 'rotate(360deg)', /* Chrome, Opera 15+, Safari 3.1+ */
      MsTransform: 'rotate(360deg)', /* IE 9 */
      transform: 'rotate(360deg)', /* Firefox 16+, IE 10+, Opera */
    },
  },
}));
