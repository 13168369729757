import { RestitutionInfo } from '@/interfaces/RestitutionInfo';

export enum RestitutionInfoActions {
  SET = 'SET_RESTITUTION_INFO',
  UPDATE_ONE = 'UPDATE_ONE_RESTITUTION_INFO',
  DELETE_ONE = 'DELETE_ONE_RESTITUTION_INFO'
}

export function createDeleteRestitutionInfoById(
  id: string,
) {
  return {
    type: RestitutionInfoActions.DELETE_ONE,
    payload: id,
  };
}

export function createUpdateRestitutionInfo(
  restitutionInfo: RestitutionInfo,
) {
  return {
    type: RestitutionInfoActions.UPDATE_ONE,
    payload: restitutionInfo,
  };
}

export function createSetRestitutionInfosAction(
  restitutionInfos: RestitutionInfo[],
) {
  return {
    type: RestitutionInfoActions.SET,
    payload: restitutionInfos,
  };
}
