import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemIcon from '@/components/atoms/ListItemIcon/ListItemIcon';
import { Sync } from '@material-ui/icons';
import ListItemText from '@/components/atoms/ListItemText/ListItemText';

import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { TEXT } from '@/utils/Text';
import { Column } from 'material-table';
import byName from '@/service/filterAndsearch';

const useProductNameColumn = (
  error?,
  editable: ('always' | 'onUpdate' | 'onAdd' | 'never' | ((columnDef: Column<any>, rowData: any) => boolean)) = 'always',
): Column<any> => {
  const handleEditComponent = useCallback(({ onChange, value }) => (
    <CustomInput
      error={error}
      id="name"
      label={TEXT.COLUMN.HEADER.NAME}
      required
      style={{ width: 200 }}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
    />
  ), [error]);

  return useMemo(() => ({
    cellStyle: { width: '100%' },
    removable: false,
    defaultSort: 'asc',
    title: TEXT.COLUMN.HEADER.NAME,
    field: 'name',
    customFilterAndSearch: byName,
    render: (rowData) => (
      <Box
        display="flex"
        alignItems="center"
      >
        {rowData.synchronizingProductId && (
          <Tooltip
            title={TEXT.IMPORTING_PRODUCT_TITLE}
            arrow
          >
            <ListItemIcon>
              <Sync fontSize="small" color="primary" />
            </ListItemIcon>
          </Tooltip>
        )}
        <ListItemText primary={rowData.name} />
      </Box>
    ),
    width: 250,
    editComponent: handleEditComponent,
    editable,
  }), [editable, handleEditComponent]);
};

export default useProductNameColumn;
