import { Chip } from '@material-ui/core';
import React, { FC } from 'react';
import tinycolor from 'tinycolor2';
import { useClasses } from '@/components/atoms/AttributeChip/attributeChip.styles';

const AttributeChip: FC<AttributeProps> = ({
  color,
  name,
  onDelete,
  maxWidth,
}) => {
  const classes = useClasses();
  const style = {
    backgroundColor: color,
    color: tinycolor(color).isDark() ? 'white' : 'black',
    maxWidth,
  };

  return (
    <Chip
      classes={classes}
      size="small"
      label={name}
      onDelete={onDelete}
      style={style}
    />
  );
};

export default AttributeChip;

interface AttributeProps {
  color: string;
  name: string;
  maxWidth: number;
  onDelete?: () => {} | undefined;
}
