import { TEXT } from '@/utils/Text';
import { useMemo } from 'react';
import { compareDateTime } from '@/utils/comparator';

const useReceivingTimeColumn = () => useMemo(() => ({
  width: 200,
  removable: false,
  title: TEXT.COLUMN.HEADER.RECEIVING_DATE,
  field: 'receivingTime',
  customSort: (a, b) => compareDateTime(a.receivingTime, b.receivingTime),
  defaultSort: 'desc',
  editable: 'never',
}), []);

export default useReceivingTimeColumn;
