const styles = (theme) => ({
  success: {
    '& > div': {
      backgroundColor: '#4caf50',
      flexWrap: 'nowrap',
    },
  },
  error: {
    '& > div': {
      backgroundColor: '#f44336',
      flexWrap: 'nowrap',
    },
  },
  info: {
    '& > div': {
      backgroundColor: '#0098f4',
      flexWrap: 'nowrap',
    },
  },
  warning: {
    '& > div': {
      backgroundColor: '#ff9800',
      flexWrap: 'nowrap',
    },
  },
});

export default styles;
