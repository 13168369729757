import React from 'react';
import ProductWaybillTable from '@/components/molecules/ProductWaybillTable/ProductWaybillTable';
import TtnFooter from '@/components/molecules/TtnFooter/TtnFooter';
import TtnHeader from '@/components/molecules/TtnHeader/TtnHeader';
import User from '@/model/user/User';
import { selectUser } from '@/redux/selectors/applicationSelector';
import { useSelector } from 'react-redux';
import EditableDocumentInput from '../../molecules/EditableDocumentInput/EditableDocumentInput';

function TtnAnnexDocument({ saleInfo, saleItemInfos, editable, clientInfo }) {
  const currentUser: User = useSelector(selectUser);

  return (
    <div>
      <div className="d-flex">
        <EditableDocumentInput
          initialValue={saleInfo.documentName}
          label="Приложение к накладной"
          placeholder="____________________________________________"
          editing={editable}
        />
      </div>
      <TtnHeader
        receiverAddress={clientInfo?.address}
        senderAddress={currentUser.address}
        senderName={currentUser.organization}
        receiverName={clientInfo?.organization}
        editable={editable}
      />
      <ProductWaybillTable
        editable={editable}
        showProducts
        saleInfo={saleInfo}
        saleItemInfos={saleItemInfos}
      />
      <TtnFooter sale={saleInfo} editable={editable} />
    </div>
  );
}

export default TtnAnnexDocument;
