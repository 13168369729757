import React, { RefObject } from 'react';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';

interface Props extends ButtonProps {
  text?: string;
  refElement?: RefObject<HTMLButtonElement>;
}

const Button = ({
  text,
  children = undefined,
  color,
  variant = 'contained',
  refElement,
  ...props
}: Props) => (
  <MuiButton
    ref={refElement}
    variant={variant}
    color={color}
    {...props}
  >
    {text || children}
  </MuiButton>
);

export default Button;
