import CompanyActionDialog from '@/components/organisms/CompanyActionDialog';
import Button from '@/components/atoms/Button';
import React, { FC, useCallback, useState } from 'react';
import { CompanyInfo } from '@/interfaces/CompanyInfo';

interface CompanyActionButtonProps {
  dialogTitle: string;
  text: string;
  company?: CompanyInfo;
  startIcon?: React.ReactNode;
}

const CompanyActionButton: FC<CompanyActionButtonProps> = ({
  dialogTitle,
  text,
  company,
  startIcon,
}: CompanyActionButtonProps) => {
  const [isOpened, setOpened] = useState(false);

  const handleClick = useCallback(() => (
    setOpened(true)
  ), []);

  const handleDialogClosed = useCallback(() => (
    setOpened(false)
  ), []);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={startIcon}
      >
        {text}
      </Button>
      <CompanyActionDialog
        title={dialogTitle}
        isOpened={isOpened}
        onClose={handleDialogClosed}
        company={company}
      />
    </>
  );
};

export default CompanyActionButton;
