import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { TEXT } from '@/utils/Text';
import {
  getSupplierOrderItems,
  putApproveSupplierOrder,
  putDeclineSendSupplierOrder,
} from '@/utils/fetch';
import SnackbarContext from '@/app/snackbarContext';
import { MESSAGE } from '@/utils/message';
import StyledTable, { subTableOptions } from '@/components/organisms/StyledTable/StyledTable';
import Button from '@/components/atoms/Button';
import ID from '@/utils/id';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCustomTableLoading,
  selectSupplierOrderItemTableColumns,
} from '@/redux/selectors/customTableSelector';
import { SupplierOrderState } from '@/enum/SupplierOrderState';
import { SupplierOrderItemInfo } from '@/interfaces/SupplierOrderItemInfo';
import { Block, ShoppingCart } from '@material-ui/icons';
import { IncomingSupplierOrderInfo } from '@/interfaces/IncomingSupplierOrderInfo';
import { Box } from '@material-ui/core';
import { createUpdateIncomingSupplierOrderInfoAction } from '@/redux/actions/incomingSupplierOrderInfoActions';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import useOrderItemColumns from '@/hook/columns/useOrderItemColumns';
import moment from 'moment';
import { dateTimeFormat } from '@/app/applicationSettings';

interface Props {
  selectedOrder: IncomingSupplierOrderInfo
}

function IncomingSupplierOrderItemsTable({
  selectedOrder,
}: Props) {
  const context = useContext(SnackbarContext);
  const dispatch = useDispatch();

  const [orderItems, setOrderItems] = useState<Array<SupplierOrderItemInfo>>([]);
  const [loading, setLoading] = useState(false);

  const customTableLoading = useSelector(isCustomTableLoading);
  const columns = useSelector(selectSupplierOrderItemTableColumns);
  const storeEnabled = useSelector(isStoreEnabled);

  const orderItemColumns = useOrderItemColumns(
    selectedOrder.supplierId,
    columns,
    storeEnabled,
    selectedOrder.type,
    {},
    selectedOrder.external,
  );

  useEffect(() => {
    if (selectedOrder) {
      setLoading(true);
      getSupplierOrderItems(selectedOrder.id)
        .then((response) => {
          setOrderItems(response);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setOrderItems([]);
    }
  }, [selectedOrder]);

  const globalLoading = customTableLoading || loading;

  const rightButtonBar = useMemo(() => {
    const { state, id } = selectedOrder;

    const handleApprove = () => {
      setLoading(true);
      putApproveSupplierOrder({
        supplierOrderId: id,
        formTime: moment(Date.now()).format(dateTimeFormat),
      })
        .then((supplierOrderInfo: IncomingSupplierOrderInfo) => {
          context({
            message: MESSAGE.SUCCESS_SUPPLIER_ORDER_APPROVE,
            variant: 'success',
          });
          dispatch(createUpdateIncomingSupplierOrderInfoAction(supplierOrderInfo));
        })
        .finally(() => setLoading(false));
    };

    const handleDecline = () => {
      setLoading(true);
      putDeclineSendSupplierOrder(id)
        .then((supplierOrderInfo: IncomingSupplierOrderInfo) => {
          context({
            message: MESSAGE.SUCCESS_SUPPLIER_ORDER_DECLINE,
            variant: 'success',
          });
          dispatch(createUpdateIncomingSupplierOrderInfoAction(supplierOrderInfo));
        })
        .finally(() => setLoading(false));
    };

    const hasAnyItems = orderItems.length && !!orderItems.find((item) => !!item.count);
    const approveAvailable = (state === SupplierOrderState.DECLINED
      || state === SupplierOrderState.PENDING)
      && hasAnyItems;

    return (
      <div className="right-button-bar">
        <Box mr={2}>
          <Button
            variant="outlined"
            text={TEXT.DECLINE}
            onClick={handleDecline}
            disabled={globalLoading
            || (state !== SupplierOrderState.PENDING
              && state !== SupplierOrderState.APPROVED)}
            startIcon={<Block />}
          />
        </Box>
        {approveAvailable && (
        <Button
          variant="outlined"
          text={TEXT.APPROVE}
          onClick={handleApprove}
          disabled={globalLoading || !approveAvailable}
          startIcon={<ShoppingCart />}
        />
        )}
      </div>
    );
  }, [globalLoading, dispatch, context, selectedOrder, orderItems]);

  return (
    <div className="table-container">
      <StyledTable
        className="sub-table"
        tableKey={ID.SUPPLIER_ORDER_ITEM_TABLE}
        loading={globalLoading}
        rightButtonBar={rightButtonBar}
        columns={orderItemColumns}
        data={orderItems}
        options={subTableOptions}
      />
    </div>
  );
}

export default IncomingSupplierOrderItemsTable;
