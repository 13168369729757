import React from 'react';
import ProductWaybillTable from '@/components/molecules/ProductWaybillTable/ProductWaybillTable';
import TtnFooter from '@/components/molecules/TtnFooter/TtnFooter';
import TtnTitle from '@/components/molecules/TtnTitle/TtnTitle';
import TtnHeader from '@/components/molecules/TtnHeader/TtnHeader';
import EditableDocumentInput from '@/components/molecules/EditableDocumentInput/EditableDocumentInput';
import { selectUser } from '@/redux/selectors/applicationSelector';
import { useSelector } from 'react-redux';
import User from '@/model/user/User';
import SaleInfo from '@/interfaces/SaleInfo';
import SaleItemInfo from '@/interfaces/SaleItemInfo';
import { ClientInfo } from '@/interfaces/ClientInfo';
import customMoment from '@/utils/customMoment';
import { dateFormat, documentDate } from '@/app/applicationSettings';

interface TttDocumentProps {
  saleInfo: SaleInfo;
  saleItemInfos: SaleItemInfo[];
  editable: boolean;
  clientInfo: ClientInfo | null;
}

function TtnDocument({
  saleInfo,
  saleItemInfos,
  editable,
  clientInfo,
}: TttDocumentProps) {
  const currentUser: User = useSelector(selectUser);

  return (
    <>
      <TtnTitle
        receiverUnp={clientInfo?.unp}
        senderUnp={currentUser.unp}
        editable={editable}
      />
      <div className="flex-center mt-100 mb-20">
        <div className="mr-5">от</div>
        <EditableDocumentInput
          initialValue={saleInfo.saleTime ? customMoment(saleInfo.saleTime, dateFormat).format(documentDate) : ''}
          placeholder="_________________________"
          editing={editable}
        />
        <div className="ml-5">г.</div>
      </div>
      <TtnHeader
        receiverAddress={clientInfo?.address}
        senderAddress={currentUser.address}
        senderName={currentUser.organization}
        receiverName={clientInfo?.organization}
        editable={editable}
      />
      <ProductWaybillTable
        saleItemInfos={saleItemInfos}
        saleInfo={saleInfo}
        showProducts={false}
        tableHeader="I. Товарный раздел"
      />
      <TtnFooter
        sale={saleInfo}
        editable={editable}
      />
    </>
  );
}

export default TtnDocument;
