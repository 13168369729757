import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import { round } from '@/utils/Utils';
import { SaleType } from '@/enum/SaleType';
import PriceService from '@/service/PriceService';
import IconButton from '@/components/atoms/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';
import useCalculateMaxCount from '@/hook/useCalculateMaxCount';

function CountEditInput({
  rowData,
  onRowDataChange,
  enableMaxCount,
  error,
  saleType,
  value,
}) {
  const calculateMaxCount = useCalculateMaxCount(enableMaxCount, rowData);

  const maxCount = useMemo(() => calculateMaxCount(rowData),
    [rowData, calculateMaxCount]);

  const {
    vat,
    supplierPrice,
    markup,
    count,
    price,
  } = rowData;

  const handleChange = useCallback((newValue) => {
    let newSum;
    if (saleType === SaleType.RETAIL) {
      newSum = round(newValue * price);
    } else if (saleType === SaleType.WHOLESALE) {
      newSum = PriceService.calculateSum(supplierPrice, markup, newValue, vat);
    }
    onRowDataChange({
      ...rowData,
      count: newValue,
      sum: newSum,
    });
  }, [rowData, saleType, onRowDataChange, price, supplierPrice, markup, vat]);

  const handleMaxCountClick = useCallback(() => {
    handleChange(maxCount);
  }, [maxCount, handleChange]);

  return (
    <div className="flex">
      <CustomNumberInput
        error={error}
        label={TEXT.COLUMN.HEADER.COUNT}
        onChange={handleChange}
        value={value}
        min={0}
        max={maxCount}
        step={1}
        decimalScale={4}
      />
      {enableMaxCount && typeof maxCount === 'number' && (
        <div className="flex-bottom">
          <IconButton
            onClick={handleMaxCountClick}
            disabled={!maxCount || count === maxCount}
            title={TEXT.BUTTON.TITLE.ADD_ALL}
          >
            <AddCircleIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default React.memo(CountEditInput);
