class ReceiveOrderToStoreRequest {
  constructor(supplierId, orderId, storeId, receivingDate) {
    this.supplierId = supplierId;
    this.orderId = orderId;
    this.storeId = storeId;
    this.receivingDate = receivingDate;
  }
}

export default ReceiveOrderToStoreRequest;
