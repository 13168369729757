import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import useClasses from './checkIcon.styles';

function CheckIcon(props) {
  const classes = useClasses();

  return (
    <CheckCircleOutlineIcon
      {...props}
      classes={classes}
    />
  );
}

export default CheckIcon;
