import { Box, Tab, Tabs } from '@material-ui/core';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Product } from '@/interfaces/ProductInfo';
import { TEXT } from '@/utils/Text';
import AccordionResultItems
  from '@/components/organisms/ProductImportModal/ExcelParseResult/AccordionResultItems';
import ProductList
  from '@/components/organisms/ProductImportModal/ExcelParseResult/ProductAccordion/ProductList';
import TabPanel from '@/components/molecules/TabPanel';
import clsx from 'clsx';
import useVerticalTabsStyles from '@/components/molecules/TabPanel/verticalTabs.style';
import useStyles from '../../style';

interface Props {
  products: Product[];
  onChange(index: number): void;
  onDelete(product: Product): void;
  index: number;
  selectedIndex: number | null;
}
const ProductAccordion: FC<Props> = ({
  products,
  index,
  selectedIndex,
  onChange,
  onDelete,
}) => {
  const classes = useStyles();
  const verticalTabsClasses = useVerticalTabsStyles();

  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [newProducts, setNewProducts] = useState<Product[]>([]);
  const [displayingNewProducts, setDisplayingNewProducts] = useState<Product[]>([]);

  const [searchValue, setSearchValue] = useState('');
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setDisplayedProducts(
      products.filter((product) => product.name.toLowerCase()
        .includes(searchValue.toLowerCase())),
    );
  }, [products, searchValue]);

  useEffect(() => {
    setNewProducts(products.filter((value) => !value.id));
  }, [products]);

  useEffect(() => {
    setDisplayingNewProducts(
      displayedProducts.filter((value) => !value.id),
    );
  }, [displayedProducts]);

  const handleSearchCancel = useCallback(() => {
    setSearchValue('');
  }, []);

  const handleTabChange = (event, newValue) => {
    if (newValue === selectedTabIndex) return;

    setSelectedTabIndex(newValue);
  };

  return (
    <AccordionResultItems
      onSearchChange={setSearchValue}
      onSearchCancel={handleSearchCancel}
      header={searchValue
        ? `Товары (${displayedProducts.length} ${TEXT.OF} ${products.length})`
        : `Товары (${products.length})`}
      disabled={!products.length}
      index={index}
      selectedIndex={selectedIndex}
      onChange={onChange}
    >
      <Box className={clsx(classes.expandablePanel, classes.productsPanel)}>
        <Tabs
          classes={verticalTabsClasses}
          textColor="secondary"
          indicatorColor="secondary"
          orientation="vertical"
          value={selectedTabIndex}
          onChange={handleTabChange}
          className={classes.tabs}
        >
          <Tab label={searchValue
            ? `Все (${displayedProducts.length} ${TEXT.OF} ${products.length}`
            : `Все (${products.length})`}
          />
          <Tab
            disabled={!newProducts.length}
            label={searchValue
              ? `Новые (${displayingNewProducts.length} ${TEXT.OF} ${newProducts.length})`
              : `Новые (${newProducts.length})`}
          />
        </Tabs>
        <TabPanel
          p={0}
          className={classes.tabPanel}
          value={selectedTabIndex}
          index={0}
        >
          <ProductList
            onDelete={onDelete}
            products={displayedProducts}
          />
        </TabPanel>
        <TabPanel
          p={0}
          className={classes.tabPanel}
          value={selectedTabIndex}
          index={1}
        >
          <ProductList
            onDelete={onDelete}
            products={displayingNewProducts}
          />
        </TabPanel>
      </Box>
    </AccordionResultItems>
  );
};

export default ProductAccordion;
