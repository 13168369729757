import { TEXT } from '@/utils/Text';
import { useCallback, useMemo } from 'react';
import { compareDateTime } from '@/utils/comparator';
import { CustomColumn } from '@/interfaces/CustomColumn';
import { Column } from 'material-table';

function useFormTimeColumn<RowData extends object>(
  column?: CustomColumn,
): Column<RowData> {
  const handleCustomSort = useCallback((a, b) => (
    compareDateTime(a.formTime, b.formTime)
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.FORMING_DATE,
    field: 'formTime',
    editable: 'never',
    customSort: handleCustomSort,
    defaultSort: 'desc',
    width: 200,
  }), [column, handleCustomSort]);
}

export default useFormTimeColumn;
