import { TEXT } from '@/utils/Text';
import { round } from '@/utils/Utils';
import React, { useCallback, useMemo } from 'react';
import CustomNumberInput from '@/components/organisms/StyledTable/Input/CustomNumberInput';

export const useMarkupColumn = (
  column,
  disabled = false,
) => {
  const handleEditComponent = useCallback(({ rowData, onChange }) => (
    <CustomNumberInput
      disabled={disabled}
      id="markup"
      label={TEXT.COLUMN.HEADER.MARKUP}
      value={rowData.markup}
      decimalScale={2}
      suffix=" %"
      onChange={onChange}
      width={80}
    />
  ), [disabled]);

  return useMemo(() => ({
    width: 80,
    cellStyle: {
      width: 80,
    },
    hidden: column ? column.hidden : false,
    title: TEXT.COLUMN.HEADER.MARKUP,
    render: (item) => round(item.markup),
    field: 'markup',
    editComponent: handleEditComponent,
  }), [handleEditComponent, column]);
};

export default useMarkupColumn;
