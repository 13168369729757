import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import CustomInput from '@/components/organisms/StyledTable/Input/CustomInput';
import { Clear, Search } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@/components/atoms/IconButton';

function SearchInput({
  onChange,
  onCancel,
  ...other
}) {
  const input = useRef(null);
  const [text, setText] = useState('');
  const [needFocus, setNeedFocus] = useState(false);

  useEffect(() => {
    if (needFocus && input.current) {
      input.current.focus();
      setNeedFocus(false);
    }
  }, [needFocus]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setText(value);
    if (onChange) {
      onChange(value);
    }
  }, [onChange]);

  const handleCancel = useCallback(() => {
    setText('');
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  return (
    <CustomInput
      value={text}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleCancel}
              edge="end"
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
}

export default SearchInput;
