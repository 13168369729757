import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: '100%',
  },
  titleBelow: {
    position: 'absolute',
    top: 23,
    fontSize: 11,
    left: 35,
  },
  titlePart: {
    display: 'inline-block',
    textOverflow: 'ellipsis',
    maxWidth: 100,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  divider: {
    display: 'inline-block',
    margin: '0 2px',
    overflow: 'hidden',
  },
}));

export default useStyles;
