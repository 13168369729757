import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  deleteClientPatternItem,
  getClientPatternItems,
  postClientPatternItem,
  putClientPatternItem,
} from '@/utils/fetch';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import { MESSAGE } from '@/utils/message';
import SnackbarContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import { useSelector } from 'react-redux';
import { isCustomTableLoading, selectClientPatternItemTableColumns } from '@/redux/selectors/customTableSelector';
import useMarkupColumn from '@/hook/columns/useMarkupColumn';
import usePriceColumn from '@/hook/columns/usePriceColumn';
import { SaleType } from '@/enum/SaleType';
import useProductColumn from '@/hook/columns/useProductColumn';
import { DEFAULT_COLUMN_CLIENT_PATTERN_ITEMS } from '@/constants/DefaultCheckedColumn';
import ExportIcon from '@/components/atoms/ExportIcon';

function ClientPatternItemTable({
  selectedClientPattern,
}) {
  const context = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [clientPatternItems, setClientPatternItems] = useState([]);

  const columns = useSelector(selectClientPatternItemTableColumns);
  const customTableLoading = useSelector(isCustomTableLoading);

  const productColumn = useProductColumn(undefined, errors.productId);
  const markupColumn = useMarkupColumn(columns.markup, true);
  const priceColumn = usePriceColumn(columns.price, errors.price, SaleType.RETAIL);

  const memoizedColumns = useMemo(() => (
    [
      productColumn,
      markupColumn,
      priceColumn,
    ]
  ), [productColumn, markupColumn, priceColumn]);

  useEffect(() => {
    if (selectedClientPattern) {
      const { id } = selectedClientPattern;
      setLoading(true);
      getClientPatternItems(id)
        .then((clientPatternItems) => setClientPatternItems(clientPatternItems))
        .finally(() => setLoading(false));
    } else {
      setClientPatternItems([]);
    }
  }, [selectedClientPattern]);

  const validateClientPatternItem = ({ productId }) => {
    const errors = {};
    if (!productId) {
      errors.productId = true;
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onAdd = useCallback((data) => new Promise((resolve, reject) => {
    if (!validateClientPatternItem(data)) {
      return reject(data);
    }
    setLoading(true);
    postClientPatternItem({ ...data, patternId: selectedClientPattern.id })
      .then((response) => {
        const newItems = [response, ...clientPatternItems.filter((item) => item.id !== response.id)];
        setClientPatternItems(newItems);
        context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
        resolve();
      })
      .catch((error) => {
        reject();
        if (error && error.field) {
          setErrors({ ...errors, [error.field]: error.message });
        }
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }), [context, errors, selectedClientPattern, clientPatternItems]);

  const onUpdate = useCallback((data, oldData) => new Promise(
    (resolve, reject) => {
      if (!validateClientPatternItem(data)) {
        reject();
        return;
      }
      setLoading(true);
      putClientPatternItem({ ...data, patternId: selectedClientPattern.id })
        .then((response) => {
          setClientPatternItems(clientPatternItems.map((item) => (item.id === response.id ? response : item)));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((error) => {
          reject();
          if (error && error.field) {
            setErrors({ ...errors, [error.field]: error.message });
          }
          return Promise.reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, errors, clientPatternItems, selectedClientPattern]);

  const onDelete = useCallback((oldData) => new Promise(
    (resolve, reject) => {
      setLoading(true);
      deleteClientPatternItem(selectedClientPattern.id, oldData.id)
        .then((response) => {
          setClientPatternItems(clientPatternItems.filter((item) => item.id !== response.id));
          context({ message: MESSAGE.SUCCESS_OPERATION, variant: 'success' });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => setLoading(false));
    },
  ), [context, selectedClientPattern, clientPatternItems]);

  const handleRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const editable = useMemo(() => ({
    onRowAdd: selectedClientPattern ? onAdd : null,
    onRowDelete: onDelete,
    onRowUpdate: onUpdate,
  }), [selectedClientPattern, onAdd, onDelete, onUpdate]);

  const options = useMemo(() => ({
    paging: false,
    minBodyHeight: null,
  }), []);

  const isLoading = loading || customTableLoading;
  return (
    <div className="table-container">
      <StyledTable
        tableKey={ID.CLIENT_PATTERN_ITEM_TABLE}
        rightButtonBar={(
          <div className="right-button-bar">
            <ExportIcon
              items={clientPatternItems}
              columnNames={DEFAULT_COLUMN_CLIENT_PATTERN_ITEMS}
              disabled={!clientPatternItems.length || isLoading}
            />
          </div>
   )}
        onRowEditing={handleRowEditing}
        editable={editable}
        loading={isLoading}
        data={clientPatternItems}
        columns={memoizedColumns}
        className="sub-table"
        options={options}
      />
    </div>
  );
}

export default ClientPatternItemTable;
