import React, { FC } from 'react';
import TabsCreator from '@/components/pages/WorkPage/TabsCreator/TabsCreator';
import Header from '@/components/molecules/Header';
import Footer from '@/app/Footer';
import useOfflineMessage from '@/hook/useOfflineMessage';

const VendorPage: FC = () => {
  useOfflineMessage();

  return (
    <div
      className="main-container"
    >
      <Header />
      <TabsCreator />
      <Footer className="footer" />
    </div>
  );
};

export default VendorPage;
