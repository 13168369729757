import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import StyledTable from '@/components/organisms/StyledTable/StyledTable';
import {
  deleteSaleById, getSaleInfosByUserId, postSale, putSale,
} from '@/utils/fetch';
import { MESSAGE } from '@/utils/message';
import { SaleContainer } from '@/model/sale/SaleContainer';
import ApplicationContext from '@/app/snackbarContext';
import ID from '@/utils/id';
import SaleRowContextMenu from '@/components/organisms/SaleRowContextMenu/SaleRowContextMenu';
import { SaleState } from '@/enum/SaleState';
import SaleItemTable from '@/components/organisms/SaleItemTable/SaleItemTable';
import { useDispatch, useSelector } from 'react-redux';
import { isStoreEnabled } from '@/redux/selectors/rolesSelector';
import useSaleTypeColumn from '@/hook/columns/useSaleTypeColumn';
import useClientColumn from '@/hook/columns/useClientColumn';
import useDocumentNameColumn from '@/hook/columns/useDocumentNameColumn';
import moment from 'moment';
import { dateTimeFormat } from '@/app/applicationSettings';
import useSaleStateColumn from '@/hook/columns/useSaleStateColumn';
import useFormTimeColumn from '@/hook/columns/useFormTimeColumn';
import useSumColumn from '@/hook/columns/useSumColumn';
import useSaleTimeColumn from '@/hook/columns/useSaleTimeColumn';
import useCommentColumn from '@/hook/columns/useCommentColumn';
import useSaleAttributesColumn from '@/hook/columns/useSaleAttributesColumn';
import { CompanyUserInfoActions } from '@/redux/actions/companyUserInfoActions';

import { isSelfSelected, selectSelectedUserId } from '@/redux/selectors/companySelector';
import usePriceFormationTypeColumn from '@/hook/columns/usePriceFormationTypeColumn';
import { Column } from 'material-table';
import SaleInfo from '@/interfaces/SaleInfo';
import { Box } from '@material-ui/core';
import OrderBySaleActionIcon from '@/components/organisms/SaleItemTable/OrderBySaleActionIcon';
import useVendorColumn from '@/hook/columns/useVendorColumn';
import { selectSaleTableFilter } from '@/redux/selectors/saleTableConfigSelector';
import { SaleTableFilter } from '@/interfaces/SaleTableConfig';

function SaleTable({
  dispatchSetSaleInfos,
  dispatchSaveSaleInfo,
  columns,
  saleInfos = [],
  customTableLoading,
  currentStore,
  dispatchDeleteRecord,
}) {
  const dispatch = useDispatch();
  const context = useContext(ApplicationContext);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [rowAnchor, setRowAnchor] = useState(null);
  const [contextMenuRowData, setContextMenuRowData] = useState(null);
  const [selectedRows, setSelectedRows] = useState<SaleInfo[]>([]);

  const selectedUserId = useSelector(selectSelectedUserId);
  const filter: SaleTableFilter = useSelector(selectSaleTableFilter);
  const isEditable = useSelector(isSelfSelected);
  const storeEnabled = useSelector(isStoreEnabled);

  const saleTypeColumn = useSaleTypeColumn();
  const documentColumn = useDocumentNameColumn(columns.documentName);
  const clientColumn = useClientColumn(columns.clientFirstName, errors.clientId, undefined, true);
  const saleStateColumn = useSaleStateColumn();
  const formTimeColumn = useFormTimeColumn(columns.formTime);
  const sumColumn = useSumColumn(columns.sum);
  const saleTimeColumn = useSaleTimeColumn();
  const commentColumn = useCommentColumn(columns.comment);
  const attributesColumn = useSaleAttributesColumn(columns.attributes);
  const priceFormationTypeColumn = usePriceFormationTypeColumn(
    columns.priceFormatonTypeId,
    errors.priceFormationTypeId,
  );
  const vendorColumn = useVendorColumn(columns.vendorId, errors.vendorId);

  const isLoading = loading || customTableLoading;

  useEffect(() => {
    dispatch(CompanyUserInfoActions.setSelectionEnabled(true));
    return () => {
      dispatch(CompanyUserInfoActions.setSelectionEnabled(false));
    };
  }, [dispatch]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    getSaleInfosByUserId(
      selectedUserId,
      filter.startDate,
      filter.endDate,
      filter.states,
      filter.attributes,
      filter.clients,
    )
      .then((response) => {
        dispatchSetSaleInfos(response);
      })
      .catch((response) => {
        if (response.status === 403 && isMounted) {
          context({
            message: response.message,
            variant: 'warning',
          });
          dispatch(CompanyUserInfoActions.removeOne(selectedUserId));
          dispatch(CompanyUserInfoActions.removeSelected());
          return undefined;
        }
        return Promise.reject(response);
      })
      .finally(() => setLoading(false));
    return () => {
      isMounted = false;
    };
  }, [context, dispatch, dispatchSetSaleInfos, selectedUserId, filter]);

  const memoizedColumns: Column<SaleInfo>[] = useMemo(() => ([
    saleStateColumn,
    attributesColumn,
    formTimeColumn,
    saleTimeColumn,
    clientColumn,
    sumColumn,
    saleTypeColumn,
    priceFormationTypeColumn,
    documentColumn,
    commentColumn,
    vendorColumn,
  ]), [
    saleStateColumn,
    saleTypeColumn,
    clientColumn,
    documentColumn,
    formTimeColumn,
    sumColumn,
    saleTimeColumn,
    attributesColumn,
    commentColumn,
    priceFormationTypeColumn,
    vendorColumn,
  ]);

  const onDelete = useCallback((item) => new Promise(
    (
      resolve,
      reject,
    ) => {
      setLoading(true);
      deleteSaleById(item.id)
        .then((response) => {
          dispatchDeleteRecord(response.id);
          context({
            message: MESSAGE.SUCCESS_OPERATION,
            variant: 'success',
          });
          resolve();
        })
        .catch((response) => {
          reject();
          if (response.status === 424) {
            context({
              message: response.message,
              variant: 'warning',
            });
            return undefined;
          }
          return Promise.reject(response);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatchDeleteRecord]);

  const onAdd = useCallback((data) => new Promise(
    (
      resolve,
      reject,
    ) => {
      setLoading(true);
      const {
        type,
        clientId,
        external,
        documentName,
        comment,
        attributes,
        priceFormationTypeId,
        vendorId,
      } = data;
      postSale({
        external,
        clientId,
        storeId: storeEnabled ? currentStore.id : null,
        type,
        documentName,
        formTime: moment(Date.now())
          .format(dateTimeFormat),
        comment,
        attributes,
        priceFormationTypeId,
        vendorId,
      })
        .then((response) => {
          dispatchSaveSaleInfo(response);
          context({
            message: MESSAGE.SUCCESS_OPERATION,
            variant: 'success',
          });
          resolve();
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatchSaveSaleInfo, storeEnabled, currentStore]);

  const onUpdate = useCallback((data) => new Promise(
    (
      resolve,
      reject,
    ) => {
      setLoading(true);
      const {
        id,
        clientId,
        external,
        documentName,
        comment,
        attributes,
        priceFormationTypeId,
        vendorId,
      } = data;
      putSale({
        id,
        clientId,
        external,
        documentName,
        comment,
        attributes,
        priceFormationTypeId,
        vendorId,
      })
        .then((response) => {
          resolve(response);
          // TODO: create own sale infos redux.reducer
          setTimeout(() => {
            dispatchSaveSaleInfo(response);
            context({
              message: MESSAGE.SUCCESS_OPERATION,
              variant: 'success',
            });
          }, 0);
        })
        .catch((response) => {
          reject();
          return Promise.reject(response);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  ), [context, dispatchSaveSaleInfo]);

  const onRowEditing = useCallback(() => {
    setErrors({});
  }, []);

  const onRowContextMenu = useCallback((
    event,
    rowData,
  ) => {
    if (!isEditable) {
      return;
    }
    setRowAnchor(event.target);
    setContextMenuRowData(rowData);
  }, [isEditable]);

  const handleRowContextMenuClose = useCallback(() => {
    setRowAnchor(null);
    setContextMenuRowData(null);
  }, []);

  const detailPanel = useCallback((rowData) => {
    if (!rowData || !rowData.id) {
      return null;
    }
    return (
      <SaleItemTable
        selectedSale={rowData}
      />
    );
  }, []);

  const editable = useMemo(() => (
    isEditable
      ? {
        onRowAdd: onAdd,
        onRowDelete: onDelete,
        onRowUpdate: onUpdate,
        isDeletable: (rowData) => rowData && rowData.state !== SaleState.COMPLETED,
      }
      : {}
  ), [onAdd, onDelete, onUpdate, isEditable]);

  const handleSelectionChange = useCallback((selectedItems) => {
    setSelectedRows(selectedItems);
  }, []);

  const rightButtonBarSelectionMode = useMemo(() => (
    <>
      <Box mr={1}>
        <OrderBySaleActionIcon
          saleIds={selectedRows.map((row) => row.id)}
          disabled={!selectedRows.length}
        />
      </Box>
    </>
  ), [selectedRows]);

  return (
    <div className="table-container">
      <StyledTable
        onRowContextMenu={onRowContextMenu}
        rightButtonBarSelectionMode={rightButtonBarSelectionMode}
        tableKey={ID.SALE_TABLE}
        onRowEditing={onRowEditing}
        editable={editable}
        loading={isLoading}
        columns={memoizedColumns}
        data={saleInfos}
        detailPanel={detailPanel}
        selection={isEditable}
        onSelectionChange={handleSelectionChange}
      />
      {!!contextMenuRowData && (
      <SaleRowContextMenu
        setLoading={setLoading}
        onClose={handleRowContextMenuClose}
        rowAnchor={rowAnchor}
        rowData={contextMenuRowData}
      />
      )}
    </div>
  );
}

export default SaleContainer(SaleTable);
