import { TEXT } from '@/utils/Text';
import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import AttributeChip from '@/components/atoms/AttributeChip';
import AttributeEditComponent from '@/components/molecules/AttributeEditComponent';

const useSaleAttributesColumn = (
  column,
) => {
  const handleEditComponent = useCallback(({ value, onChange }) => (
    <AttributeEditComponent
      onChange={onChange}
      addButtonEnabled
      value={value}
    />
  ), []);

  return useMemo(() => ({
    hidden: column ? column.hidden : false,
    title: TEXT.ATTRIBUTES,
    field: 'attributes',
    sorting: false,
    customFilterAndSearch: (value, rowData) => {
      const { attributes } = rowData;
      const normalizedValue = value.toUpperCase();
      for (let i = 0; i < attributes.length; i++) {
        if (attributes[i].name.toUpperCase().includes(normalizedValue)) {
          return true;
        }
      }
      return false;
    },
    render: ({ attributes }) => (
      <Box>
        {attributes.map(({ name, id, color }) => (
          <AttributeChip
            key={id}
            maxWidth={150}
            name={name}
            color={color}
          />
        ))}
      </Box>
    ),
    editComponent: handleEditComponent,
  }), [column, handleEditComponent]);
};

export default useSaleAttributesColumn;
