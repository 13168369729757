import { createAction } from 'redux-actions';
import PriceFormationType from '@/interfaces/PriceFormationType';

enum Type {
  ADD = 'ADD_PRICE_FORMATION_TYPE',
  SET_CUSTOM = 'SET_PRICE_FORMATION_TYPE',
  SET_DEFAULT = 'SET_DEFAULT_PRICE_FORMATION_TYPE',
  UPDATE = 'UPDATE_PRICE_FORMATION_TYPE',
  DELETE = 'DELETE_PRICE_FORMATION_TYPE',
  SET_CURRENT_BY_ID = 'SET_CURRENT_PRICE_FORMATION_TYPE_BY_ID',
  SET_LOADED = 'SET_LOADED_CUSTOM_PRICE_FORMATION_TYPES',
}

const set = createAction<PriceFormationType[]>(Type.SET_CUSTOM);
const setDefault = createAction<PriceFormationType[]>(Type.SET_DEFAULT);
const update = createAction<PriceFormationType>(Type.UPDATE);
const remove = createAction<string>(Type.DELETE);
const add = createAction<PriceFormationType>(Type.ADD);
const setCurrentById = createAction<string>(Type.SET_CURRENT_BY_ID);
const setLoaded = createAction<boolean>(Type.SET_LOADED);

export const PriceFormationTypeActions = {
  Type,
  set,
  setDefault,
  update,
  remove,
  add,
  setCurrentById,
  setLoaded,
};

export type PriceFormationTypeActionsType = Omit<typeof PriceFormationTypeActions, 'Type'>;
